import React, { useEffect, useState } from 'react';

import { CircularProgress, Dialog, Grid, Paper } from '@material-ui/core';
import { GoogleApiWrapper } from 'google-maps-react';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import {
  GetCompanyInfoAction,
  GetMyUserInfoAction,
} from '../../graphql/graphql';
import GetRequestKeyQuery from '../../graphql/queries/get-request-key';
import {
  determineManagingCompanyInfo,
  isDefaultCompanyName,
  isDefaultUsername,
} from '../../helpers';
import { useQueryStringParams } from '../../hooks';
import OnboardingCompanyInfoStep from './onboarding-company-info-step';
import OnboardingCongratsOnPurchase from './onboarding-congrats-on-purchase';
import OnboardingFinalMobileStep from './onboarding-final-mobile-step';
import OnboardingLoading from './onboarding-loading';
import OnboardingSelectProductsStep from './onboarding-select-products-step';
import OnboardingUserProfileStep from './onboarding-user-profile-step';
import OnboardingWelcomeStep from './onboarding-welcome-step';
import { ONBOARDING_STEP } from './onboarding.constants';
import OnboardingContext from './onboarding.context';
import { useStyles } from './onboarding.styles';

const Onboarding = ({
  userInfo,
  userInfoLoading,
  userInfoState,
  dispatch,
  refetchGetUserInfo,
  companyRegistrationKey,
  getCompanyInfo,
  getCompanyInfoRefetch,
  managingCompanyInfo,
}) => {
  const classes = useStyles();
  const isCopilot = managingCompanyInfo?.isCopilot;
  const queryStringParams = useQueryStringParams();
  const queryCheckoutParam = queryStringParams.get('checkout');

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialCompanyUserInfoSet, setInitialCompanyUserInfoSet] = useState(
    false
  );

  const requestKeyResponse = useQuery(GetRequestKeyQuery, {
    variables: { requestKey: companyRegistrationKey },
    skip: !companyRegistrationKey,
  });

  const [steps, setSteps] = useState([ONBOARDING_STEP.WELCOME_TO_LEVEL]);
  const [stepsHaveBeenSet, setStepsHaveBeenSet] = useState(false);

  const companyInfo = _.get(getCompanyInfo, '[0]', null);

  useEffect(() => {
    if (userInfo?.userId && companyInfo) {
      const updatedManagingCompanyInfo = determineManagingCompanyInfo({
        companies: [companyInfo],
        userInfo,
      });

      if (updatedManagingCompanyInfo) {
        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: { ...updatedManagingCompanyInfo },
        });

        setInitialCompanyUserInfoSet(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, companyInfo]);

  useEffect(() => {
    if (initialCompanyUserInfoSet && !stepsHaveBeenSet && managingCompanyInfo) {
      const newSteps = [...steps];

      const finish = () => {
        setStepsHaveBeenSet(true);
        setSteps(newSteps);
      };

      if (managingCompanyInfo?.hasProjectManagementProduct) {
        // User has project management product, add update profile info step if needed
        const hasNotFilledInPersonal =
          !userInfo.username ||
          isDefaultUsername(userInfo.username) ||
          !userInfo.firstName ||
          !userInfo.lastName;

        if (hasNotFilledInPersonal) {
          newSteps.push(ONBOARDING_STEP.UPDATE_PROFILE_INFO);
        }
      }

      if (
        (managingCompanyInfo?.isCompanyOwner ||
          managingCompanyInfo?.isCompanyBookkeeper) &&
        isDefaultCompanyName(managingCompanyInfo?.managingCompanyName)
      ) {
        newSteps.push(ONBOARDING_STEP.ENTER_COMPANY_INFO);
      }

      if (isMobile && managingCompanyInfo?.hasProjectManagementProduct) {
        newSteps.push(ONBOARDING_STEP.FINAL_IS_MOBILE);
      }

      finish();
    }

    // Update user info in redux store if it has changed
    if (!_.isEmpty(userInfo) && !_.isEqual(userInfo, userInfoState)) {
      dispatch({
        type: 'USER_INFO_UPDATE',
        payload: { ...userInfo },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInfo,
    managingCompanyInfo,
    queryCheckoutParam,
    initialCompanyUserInfoSet,
  ]);

  const onOnboardingComplete = async () => {
    setLoading(true);
    // Clear onboarding user role
    dispatch({
      type: 'SET_ONBOARDING_USER_ROLE',
      payload: { userRole: null },
    });
    const [userInfoResponse, getCompanyInfoResponse] = await Promise.all([
      refetchGetUserInfo(),
      getCompanyInfoRefetch(),
    ]);
    const updatedUserInfo = _.get(userInfoResponse, 'data.getMyUserInfo');
    // update user info in redux store if it has changed
    if (updatedUserInfo && !_.isEqual(updatedUserInfo, userInfoState)) {
      dispatch({
        type: 'USER_INFO_UPDATE',
        payload: { ...updatedUserInfo },
      });
    }
    const updatedCompanies = _.get(
      getCompanyInfoResponse,
      'data.getCompanyInfo.items'
    );
    const updatedManagingCompanyInfo = determineManagingCompanyInfo({
      companies: updatedCompanies,
      userInfo: updatedUserInfo,
    });
    // update managing company info in redux store if it has changed
    if (
      updatedManagingCompanyInfo &&
      !_.isEqual(updatedManagingCompanyInfo, managingCompanyInfo)
    ) {
      dispatch({
        type: 'SET_MANAGING_COMPANY_INFO',
        payload: { ...updatedManagingCompanyInfo },
      });
    }
    setLoading(false);
  };

  let stepComponent;
  switch (steps[activeStep]) {
    case ONBOARDING_STEP.WELCOME_TO_LEVEL:
      stepComponent = <OnboardingWelcomeStep />;
      break;
    case ONBOARDING_STEP.UPDATE_PROFILE_INFO:
      stepComponent = (
        <OnboardingUserProfileStep
          userInfo={userInfo}
          userInfoLoading={userInfoLoading}
          setLoading={setLoading}
        />
      );
      break;
    case ONBOARDING_STEP.ENTER_COMPANY_INFO:
      stepComponent = (
        <OnboardingCompanyInfoStep
          companyInfo={companyInfo}
          setLoading={setLoading}
          isCopilot={isCopilot}
        />
      );
      break;
    case ONBOARDING_STEP.SELECT_PRODUCTS:
      stepComponent = (
        <OnboardingSelectProductsStep
          companyInfo={companyInfo}
          setLoading={setLoading}
        />
      );
      break;
    case ONBOARDING_STEP.FINAL_IS_MOBILE:
      stepComponent = <OnboardingFinalMobileStep userInfo={userInfo} />;
      break;
    case ONBOARDING_STEP.CONGRATS_ON_PURCHASE:
      stepComponent = (
        <OnboardingCongratsOnPurchase
          onOnboardingComplete={onOnboardingComplete}
        />
      );
      break;

    default:
      stepComponent = <OnboardingLoading />;
  }

  // What to do at end of onboarding workflow
  if (stepsHaveBeenSet && activeStep === steps.length) {
    // eslint-disable-next-line no-console
    let whereToGo = '/projects';
    if (_.includes(steps, ONBOARDING_STEP.ENTER_COMPANY_INFO)) {
      whereToGo += '?from-company-onboarding=true';
    }

    if (isCopilot) {
      whereToGo = '/alerts';
    }

    return <Redirect to={whereToGo} />;
  }

  const showInitialLoading =
    !initialCompanyUserInfoSet || requestKeyResponse.loading;

  return (
    <OnboardingContext.Provider
      value={{ activeStep, steps, setActiveStep, onOnboardingComplete }}
    >
      <Grid container className={classes.root}>
        <Dialog fullScreen open>
          <Grid container item className={classes.container}>
            <div className={classes.backgroundImage} />

            {(showInitialLoading || loading) && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                className={classes.loader}
              >
                <CircularProgress color="inherit" />
              </Grid>
            )}

            {!showInitialLoading && userInfo && !!steps.length && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                item
                xs={12}
                className={classes.onboardingContainer}
                elevation={20}
                component={Paper}
              >
                <Grid container className={classes.stepContainer}>
                  {stepComponent}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Dialog>
      </Grid>
    </OnboardingContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    companyRegistrationKey: state.appState.companyRegistrationKey || null,
    changeFilenamesOnUpload: state.appState.changeFilenamesOnUpload || false,
    userInfoState: state.userInfo,
    onboardingUserRole: state.currentAuth.onboardingUserRole,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
};

export default compose(
  GetMyUserInfoAction,
  GetCompanyInfoAction,
  GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }),
  withRouter,
  connect(mapStateToProps)
)(Onboarding);
