import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'uuid';
import numeral from 'numeral';
import { fromString } from 'html-to-text';

// UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  LinearProgress,
  Drawer,
  FormControlLabel,
  Switch,
  Chip,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  Add as AddIcon,
  CloudDownload as CloudDownloadIcon,
  Settings as SettingsIcon,
  Mail,
  Layers,
  Equalizer as EqualizerIcon,
} from '@material-ui/icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';

// utilities
import _ from 'lodash';
import moment from 'moment';

// UI components
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import LoadingCover from '../../../components/LoadingCover/loadingCover';

// GrapQL
import {
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  ListCompanyProjectsAction,
  AddCompanyRfisAction,
  UpdateCompanyRfisAction,
  RemoveCompanyRfiAction,
  AddOrUpdateCompanyAction,
} from '../../../graphql/graphql';
import GetCompanyRfisQuery from '../../../graphql/queries/get-company-rfis';
import GetUncategorizedTxns from '../../../graphql/queries/get-uncategorized-txns';
import TriggerRfiReadyApprovalEmail from '../../../graphql/queries/trigger-rfi-ready-approval-email';

import {
  RFI_STATUSES,
  RFI_STATUSES_INTERNAL_FILTERS,
  RFI_MODES,
  RFI_SOURCES,
  RFI_STATUS_COLORS,
  NOT_SAVED_WORDING,
  RFI_TXN_ANSWER_TYPES,
  RFI_SOURCES_ARRAY,
} from '../../../config/appDefaults';

// helpers
import {
  monetaryRender,
  prepRfisForSaving,
  makeMoney,
  isItSpent,
  capitalizeSentence,
} from '../../../helpers';

import {
  amountFilter,
  simpleSortForAmount,
} from '../../../helpers/react-datagrid-helpers';

import themePalette from '../../../theme/palette';

import AdminCreateEditRfiForm from './admin-create-edit-rfi-form';
import AdminToolsIconButton from '../../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';
import ButtonWithTooltip from '../../../components/button-with-tooltip/button-with-tooltip';
import RfiAdminModePrompt from './rfi-admin-mode-prompt';

import ChooseUncategorizedAccounts from './choose-uncategorized-accounts';
import HandleExistingRfiTxns from './handle-existing-rfi-txns';
import SendApprovedRfis from './send-approved-rfis';
import ReadyApprovalDialog from './ready-approval-dialog';
import RequestsForInfoStatsDialog from './requests-for-info-stats-dialog';
import BulkRfiUpdater from './bulk-rfi-updater';

// for ReactDataGrid
window.moment = moment;
const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  actionButtonsWrapper: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  headerIcon: {
    color: '#999',
    fontSize: 30,
  },
  statDialogLabel: {
    textTransform: 'uppercase',
  },
  statSectionWrapper: {
    paddingTop: 25,
  },
  statSectionHeader: {
    color: theme.palette.brandColorPrimary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
  },
  statText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
  },
  helperText: {
    color: theme.palette.brandColorPrimary,
    alignItems: 'center',
  },
  drawerPaper: {
    width: '90%',
    maxWidth: 1200,
  },
}));

const defaultSortInfo = [
  {
    name: 'txnDate',
    dir: 1,
    type: 'date',
  },
];

const buildColumnObj = options => {
  if (!options.name) return null;
  const basicColumn = {
    ...options,
    header:
      options.header !== undefined
        ? options.header
        : capitalizeSentence(options.name),
  };
  return basicColumn;
};

const ManageRequestsForInfo = ({
  // MSTP
  userInfo,
  managingCompanyInfo,
  managingCompanyId,
  // HOC
  getCompanyInfo,
  companyProjects,
  companyCrew,
  onAddOrUpdateCompany,
  onAddCompanyRfis,
  onUpdateCompanyRfis,
  onRemoveCompanyRfi,
}) => {
  const companyInfo = getCompanyInfo?.[0];
  const hasUncategorizedTxnAccounts = !!companyInfo?.uncategorizedTxnAccounts
    ?.length;
  const rfiRecipients = _.get(companyInfo, 'rfiRecipients', []);
  const isConnectedToQuickBooks = !!managingCompanyInfo?.isConnectedToQuickBooks;

  const classes = useStyles();
  const [showImportLoader, setShowImportLoader] = useState(false);
  const [showAccountPicker, setShowAccountPicker] = useState(false);

  const [removingRfi, setRemovingRfi] = useState(false);
  const [txnsAlreadyHaveAnRfi, setTxnsAlreadyHaveAnRfi] = useState([]);
  const [gridRef, setGridRef] = useState(null);
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
  });
  const gridStyle = { height: '100%', minHeight: '100%' };
  const [dataSource, setDataSource] = useState([]);
  const [localRfis, setLocalRfis] = useState([]);
  const [selected, setSelected] = useState({});
  const [createOrEditPanel, setCreateOrEditPanel] = useState({ open: false });
  const [adminMode, setAdminMode] = useState(false);
  const [showAdminDialog, setShowAdminDialog] = useState({ open: false });
  const [sendApprovedRfisInfo, setSendApprovedRfisInfo] = useState({
    open: false,
  });
  const [bulkRfiUpdaterOptions, setBulkRfiUpdaterOptions] = useState({
    status: null,
    rfisToUpdate: [],
  });
  const [
    showApprovalEmailSentDialog,
    setShowApprovalEmailSentDialog,
  ] = useState({ status: null });
  const [showStatsDialog, setShowStatsDialog] = useState({ open: false });

  const GetInternalRfisQuery = useQuery(GetCompanyRfisQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyId,
      mode: RFI_MODES.INTERNAL.value,
    },
  });

  const internalRfisLoading = _.get(GetInternalRfisQuery, 'loading');
  const internalRfis = _.get(
    GetInternalRfisQuery,
    `data.getCompanyRfis.items`,
    null
  );

  const { refetch: getUncategorizedTxns } = useQuery(GetUncategorizedTxns, {
    skip: true, // this will prevent it from running until refetch is invoked
    fetchPolicy: 'no-cache',
    variables: { companyId: managingCompanyId },
  });

  useEffect(() => {
    // dedupe two arrays of objects based on requestId, and merge them giving the second array priority
    const deduped = _.uniqBy(
      [...(localRfis || []), ...(internalRfis || [])],
      'requestId'
    );

    const dataSourceTransform = _.map(deduped, rfi => {
      const dataSourceRfi = {
        ...rfi,
        _requestType: '',
        _txnSpent: '',
        _txnReceived: '',
      };
      if (rfi.requestType) {
        const requestTypeInfo = _.find(RFI_TXN_ANSWER_TYPES, {
          value: rfi.requestType,
        });
        if (requestTypeInfo) {
          dataSourceRfi._requestType = requestTypeInfo.label;
        }
      }

      if (rfi.txnSpent) {
        dataSourceRfi._txnSpent = rfi.txnSpent.value;
      }
      if (rfi.txnReceived) {
        dataSourceRfi._txnReceived = rfi.txnReceived.value;
      }

      return dataSourceRfi;
    });

    setDataSource(dataSourceTransform);
  }, [localRfis, internalRfis]);

  const approvedRfis = useMemo(() => {
    return _.filter(internalRfis, {
      requestStatus: RFI_STATUSES.IN_APPROVED.value,
    });
  }, [internalRfis]);

  const toggleAdminMode = () => {
    if (adminMode) {
      setAdminMode(false);
    } else {
      // prompt for a password and if correct, turn on admin mode
      // if not, let them know it was wrong
      setShowAdminDialog({ open: true });
      // setAdminMode(true);
    }
  };

  const createLocalRfi = overrides => {
    return {
      requestId: uuid(),
      txnDate: moment(),
      dateCreated: moment().toISOString(),
      local: true,
      ...overrides,
    };
  };

  const createOrEditRfi = ({ rfiToEdit }) => {
    setCreateOrEditPanel({ open: true, rfiToEdit });
  };

  const handleDrawerClose = ({
    closeEvent = {},
    askIfSure = false,
    proceedToNext = false,
  }) => {
    if (closeEvent.type === 'keydown' || closeEvent.type === 'click') {
      return;
    }
    // TODO: are you sure you want to close? In case they had changes and clicked by accident
    if (askIfSure) {
      // console.log('should have asked if sure');
    }
    if (proceedToNext) {
      // set the contents of the drawer to the next rfi in the list
      const currentRfi = createOrEditPanel.rfiToEdit;
      const currentRfiIndex = gridRef.current.data.findIndex(
        rfi => rfi.requestId === currentRfi.requestId
      );
      const nextRfi = gridRef.current.data[currentRfiIndex + 1];
      if (nextRfi) {
        createOrEditRfi({ rfiToEdit: nextRfi });
      } else {
        setCreateOrEditPanel({ open: false });
      }
    } else {
      setCreateOrEditPanel({ open: false });
    }
  };

  const monetizeAmount = moneyValue => {
    return monetaryRender({ value: moneyValue, withDecimals: true });
  };

  const onSelectionChange = useCallback(({ selected: isSelected }) => {
    setSelected(isSelected);
  }, []);

  // Process incoming QBO transactions
  const processTransactions = txns => {
    const transactions = [];
    const txnsThatAlreadyHaveRfi = [];

    // dedupe based on txnId so they can't create a duplicate RFI
    const dedupedTransactions = [];
    _.each(txns, txn => {
      const found = _.find(
        dataSource,
        existingTxn => existingTxn.txnId === txn.txnId
      );
      if (!found) {
        dedupedTransactions.push(txn);
      }
    });

    dedupedTransactions.forEach(txn => {
      if (txn.existingRfiIds) {
        txnsThatAlreadyHaveRfi.push(txn);
        return;
      }

      const whatToAdd = createLocalRfi({
        txnId: txn.txnId,
        txnAccount: txn.accountName,
        txnAccountType: txn.accountType,
        txnType: txn.txnType,
        txnPayee: txn.vendorName || txn.name || txn.customerName,
        txnDescription: txn.memo,
        initialNotes: '', // txn.memo - accounting team wanted this blank since the memo is in the description
        txnDate: moment(txn.txnDate)
          .utc()
          .set('hour', 12)
          .set('second', Math.floor(Math.random() * 60)) // do this so they don't all have the exact same date if they are on the same day
          .format('YYYY-MM-DDTHH:mm:ss'),
        requestSource: RFI_SOURCES.UNBOOKED.value,
        requestType: null,
        bankAccount:
          txn.splitAccount && txn.splitAccount !== '-Split-'
            ? txn.splitAccount
            : '',
      });

      // decide if we should be setting the received or spent value based on the type of account it's coming from
      const amountToUse = numeral(txn.taxInAmount || txn.amount).value();
      const isPositive = amountToUse > 0;

      const txnIsSpent = isItSpent({
        accountType: txn.accountType,
        isPositive,
      });

      if (txnIsSpent) {
        whatToAdd.txnSpent = makeMoney(amountToUse);
      } else {
        whatToAdd.txnReceived = makeMoney(amountToUse);
      }

      transactions.push(whatToAdd);
    });

    // add to any existing info in the datasource
    setLocalRfis([...localRfis, ...transactions]);

    if (txnsThatAlreadyHaveRfi.length > 0) {
      setTxnsAlreadyHaveAnRfi(txnsThatAlreadyHaveRfi);
    }
  };

  const setupUncategorizedAccounts = () => {
    if (isConnectedToQuickBooks) {
      setShowAccountPicker(true);
    } else {
      setDialogInfo({
        title: 'Setup Needed',
        okButtonText: 'Close',
        customChildren: (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">
                To import transactions, you need to connect to your QuickBooks
                Online account first.
              </Typography>
              <Typography variant="body1">
                Please do this setup in the Settings page.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Link to="/settings" style={{ marginTop: 16 }}>
                <Button variant="contained">Go to Settings</Button>
              </Link>
            </Grid>
            <Typography variant="caption" style={{ marginTop: 8 }}>
              Note: going to the Settings page will cause any unsaved work on
              this page to be lost. Be sure to save any RFI&apos;s before
              clicking the link.
            </Typography>
          </Grid>
        ),
        open: true,
        onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
        hideCancel: true,
        onConfirm: () => setDialogInfo({ ...dialogInfo, open: false }),
      });
    }
  };

  const handleAccountPickerSave = async (selections = []) => {
    try {
      await onAddOrUpdateCompany({ uncategorizedTxnAccounts: selections });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('onAddOrUpdateCompany -> set uncat accounts err: ', err);
    }
  };

  const handleAccountPickerClose = () => {
    setShowAccountPicker(false);
  };

  const importExternalData = async () => {
    setShowImportLoader(true);

    let txns;
    try {
      const getUncategorizedTxnResult = await getUncategorizedTxns();

      txns = _.get(
        getUncategorizedTxnResult,
        'data.getUncategorizedTxns.items'
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('GetUncategorizedTxns err: ', err);

      const message =
        JSON.stringify(err).indexOf('is not a bookkeeper') > -1
          ? 'This user is not a bookkeeper for the company and cannot import data.'
          : 'Please ensure you have connected your QBO account and try again later.';

      setDialogInfo({
        title: `Error`,
        message,
        open: true,
        onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
        hideCancel: false,
        onConfirm: () => setDialogInfo({ ...dialogInfo, open: false }),
      });

      setShowImportLoader(false);
      return;
    }

    setShowImportLoader(false);

    if (txns?.length) {
      // bring in the data as drafts
      processTransactions(txns);
    } else {
      // sorry, no data was able to be pulled
      setDialogInfo({
        title: `No transactions found`,
        message: 'There were no transactions found for import.',
        open: true,
        onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
        hideCancel: false,
        onConfirm: () => setDialogInfo({ ...dialogInfo, open: false }),
      });
    }
  };

  const onEditComplete = useCallback(
    params => {
      const { value, columnId, rowId } = params;
      const data = [...dataSource];
      const whereIsIt = _.findIndex(data, { requestId: rowId });
      if (whereIsIt > -1) {
        data[whereIsIt][columnId] = value;
      }
      setDataSource(data);
    },
    [dataSource]
  );

  const deleteRow = async requestIdToRemove => {
    setRemovingRfi(true);
    const data = [...dataSource];
    const record = _.find(data, { requestId: requestIdToRemove });
    if (!record.local) {
      // if not local delete it from the server
      await onRemoveCompanyRfi(record);
    } else {
      const newlocals = [...localRfis];
      // Remove this rfi from the list
      _.remove(newlocals, { requestId: requestIdToRemove });
      setLocalRfis(newlocals);
    }

    setRemovingRfi(false);
  };

  const deleteThisContent = async contentIdToDelete => {
    setDialogInfo({
      title: 'Just making sure...',
      message: 'Are you sure you want to remove this RFI?',
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: () => {
        deleteRow(contentIdToDelete);
      },
    });
  };

  const COLUMN_MIN_WIDTH_EXTRA_SMALL = 82;
  const COLUMN_MIN_WIDTH_SMALL = 120;
  const COLUMN_MIN_WIDTH_MEDIUM = 180;
  const COLUMN_MIN_WIDTH_LARGE = 250;

  const showInTable = [
    {
      name: 'edit',
      header: null,
      minWidth: COLUMN_MIN_WIDTH_EXTRA_SMALL,
      maxWidth: COLUMN_MIN_WIDTH_EXTRA_SMALL,
    },
    {
      name: 'requestStatus',
      header: 'Status',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.map(
          [
            { id: NOT_SAVED_WORDING, label: NOT_SAVED_WORDING },
            ...RFI_STATUSES_INTERNAL_FILTERS,
          ],
          item => ({
            id: item.value,
            label: item.label,
          })
        ),
      },
    },
    {
      name: 'txnDate',
      header: 'Transaction Date',
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: {
        dateFormat: 'MMM D, YYYY',
        cancelButton: false,
        highlightWeekends: false,
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'requestSource',
      header: 'Source',
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: RFI_SOURCES_ARRAY.map(item => ({
          id: item.value,
          label: item.label,
        })),
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnAccount',
      header: 'Account',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.uniq(
          _.map(dataSource, ({ txnAccount }) => txnAccount || '').filter(
            txnAccount => !!txnAccount
          )
        )
          .sort((a, b) => {
            // sort by lowercase
            const aLower = a.toLowerCase();
            const bLower = b.toLowerCase();
            if (aLower < bLower) {
              return -1;
            }
            if (aLower > bLower) {
              return 1;
            }
            return 0;
          })
          .map(txnAccount => ({ id: txnAccount, label: txnAccount })),
      },
    },
    {
      name: 'bankAccount',
      header: 'Bank Account',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.uniq(
          _.map(dataSource, ({ bankAccount }) => bankAccount || '').filter(
            bankAccount => !!bankAccount
          )
        )
          .sort((a, b) => {
            // sort by lowercase
            const aLower = a.toLowerCase();
            const bLower = b.toLowerCase();
            if (aLower < bLower) {
              return -1;
            }
            if (aLower > bLower) {
              return 1;
            }
            return 0;
          })
          .map(bankAccount => ({ id: bankAccount, label: bankAccount })),
      },
    },
    {
      name: '_requestType',
      header: 'Type',
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.map(RFI_TXN_ANSWER_TYPES, item => ({
          id: item.label,
          label: item.label,
        })),
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnType',
      header: 'Transaction Type',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnDescription',
      header: 'Description',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnPayee',
      header: 'Payee',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: '_txnSpent',
      header: 'Spent',
      type: 'amount',
      textAlign: 'center',
      defaultFlex: 10,
      minWidth: COLUMN_MIN_WIDTH_SMALL,
      sort: simpleSortForAmount,
    },
    {
      name: '_txnReceived',
      header: 'Received',
      type: 'amount',
      textAlign: 'center',
      defaultFlex: 15,
      minWidth: COLUMN_MIN_WIDTH_SMALL,
      sort: simpleSortForAmount,
    },
    {
      name: 'initialNotes',
      header: 'Accountant Notes',
      defaultFlex: 50,
      minWidth: COLUMN_MIN_WIDTH_LARGE,
    },
    {
      name: 'txnId',
      header: 'Transaction ID',
      defaultFlex: 10,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
      visible: false,
    },
  ];

  // build the table columns off the datasource
  const columns = showInTable.map(attribute => {
    const attributePlus = { ...attribute, editable: false };
    if (attribute.name === 'edit') {
      attributePlus.render = ({ data }) => {
        return (
          <>
            <Tooltip title="Delete Entry">
              <Button
                onClick={() => {
                  deleteThisContent(data.requestId);
                }}
                className={classes.editButton}
              >
                <DeleteOutlineIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Edit RFI">
              <Button
                onClick={() => {
                  createOrEditRfi({ rfiToEdit: data });
                }}
                className={classes.editButton}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </>
        );
      };
    } else if (attribute.name === 'requestStatus') {
      attributePlus.render = ({ data }) => {
        // console.log('requestStatus data: ', data);
        let statusLabel = _.get(RFI_STATUSES, [data.requestStatus, 'label']);
        // let statusFound = true;
        if (!statusLabel) {
          statusLabel = NOT_SAVED_WORDING;
          // statusFound = false;
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <Chip
              label={statusLabel}
              style={{
                color: RFI_STATUS_COLORS[data.requestStatus]?.color || 'red',
                backgroundColor:
                  RFI_STATUS_COLORS[data.requestStatus]?.background ||
                  'transparent',
                textTransform: RFI_STATUS_COLORS[data.requestStatus]?.background
                  ? 'uppercase'
                  : undefined,
              }}
            />
          </div>
        );
      };
    } else if (attribute.name === 'requestSource') {
      attributePlus.render = ({ data }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {_.find(RFI_SOURCES_ARRAY, { value: data.requestSource })?.label ||
              'N/A'}
          </div>
        );
      };
    } else if (
      attribute.name === '_txnReceived' ||
      attribute.name === '_txnSpent'
    ) {
      attributePlus.render = ({ value }) => {
        return (
          <Grid container justifyContent="center" alignItems="center">
            {monetizeAmount(value)}
          </Grid>
        );
      };
    } else if (attribute.name === 'txnDate') {
      attributePlus.render = ({ value }) => {
        if (!value) return '-';
        return moment(value).format('MMM D, YYYY');
      };
    } else if (attribute.name === 'initialNotes') {
      attributePlus.render = ({ value }) => {
        if (!value) return '';
        return fromString(value);
      };
    } else if (attribute.name === 'receiptImage') {
      attributePlus.render = ({ value, data }) => {
        if (!value) {
          return 'n/a';
        }

        return (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className="basicStyledLink"
            style={{ textTransform: 'capitalize' }}
          >
            {data.type || 'Receipt'}
          </a>
        );
      };
    }
    return buildColumnObj(attributePlus);
  });

  const filterValue = [
    {
      name: '_txnSpent',
      operator: 'Starts With',
      type: 'amount',
      value: '',
    },
    {
      name: '_txnReceived',
      operator: 'Starts With',
      type: 'amount',
      value: '',
    },
    {
      name: 'requestStatus',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'requestSource',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: '_requestType',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'txnType',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'txnAccount',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'bankAccount',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'txnDate',
      operator: 'afterOrOn',
      type: 'date',
      value: '',
    },
    {
      name: 'txnDescription',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'initialNotes',
      operator: 'contains',
      type: 'string',
      value: '',
    },
  ];

  const addNewRfiManually = () => {
    // open the modal with an empty rfi
    const emptyRow = createLocalRfi();
    createOrEditRfi({ rfiToEdit: emptyRow });
    setLocalRfis([...localRfis, emptyRow]);
  };

  const handleSaveRfis = async ({ listOfRfis: saveThese, addOrUpdate }) => {
    const allRfis = prepRfisForSaving({
      rfisToSave: saveThese,
      companyId: managingCompanyId,
    });
    if (addOrUpdate === 'add') {
      try {
        // clean parentPath from all rfis without mutating the original
        const rfisToSave = allRfis.map(rfi => {
          const rfiCopy = { ...rfi };
          delete rfiCopy.parentPath;
          return rfiCopy;
        });

        await onAddCompanyRfis({ listOfRfis: rfisToSave });
        // for each item that was saved, remove it from localRfis
        const tempLocalRfis = [...localRfis];
        allRfis.forEach(rfi => {
          _.remove(tempLocalRfis, { requestId: rfi.requestId });
        });
        setLocalRfis(tempLocalRfis);
      } catch (err) {
        // TODO: if some fail, let the user know
        // eslint-disable-next-line no-console
        console.log('onAddCompanyRfis err: ', err);
      }
    } else {
      try {
        // eslint-disable-next-line no-plusplus
        const originalDetailsOfFirst = _.find(dataSource, {
          requestId: allRfis[0].requestId,
        });
        await onUpdateCompanyRfis(
          allRfis,
          originalDetailsOfFirst.requestStatus,
          null,
          true
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Manage RFIs onUpdateCompanyRfis err: ', err);
      }
    }
  };

  const handleExistingRfiTxnsSave = async ({ reopenThese = [] }) => {
    const forMutation = reopenThese.map(txn => {
      return {
        requestId: txn.existingRfiIds[0],
        requestStatus: RFI_STATUSES.IN_DRAFT.value,
      };
    });
    try {
      await onUpdateCompanyRfis(forMutation);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('onUpdateCompanyRfis err: ', err);
    }
  };

  const handleSendApprovedRfis = async () => {
    // Get list of approved RFIs
    setSendApprovedRfisInfo({
      open: true,
      rfis: approvedRfis,
    });
  };

  // this is set up with a skip so the refetch can be used to invoke manually
  const { refetch: triggerRfiReadyApprovalEmail } = useQuery(
    TriggerRfiReadyApprovalEmail,
    {
      fetchPolicy: 'no-cache',
      variables: {
        companyId: managingCompanyId,
      },
      skip: true, // Wait until specifically invoked
    }
  );
  const handleNotifyManager = async () => {
    setShowApprovalEmailSentDialog({ status: 'loading' });

    let sendQueryStatus = null;
    try {
      const triggerRfiReadyApprovalEmailResponse = await triggerRfiReadyApprovalEmail();

      sendQueryStatus = _.get(
        triggerRfiReadyApprovalEmailResponse,
        'data.triggerRfiReadyApprovalEmail.status',
        ''
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('triggerRfiReadyApprovalEmail err: ', err);
    }

    if (sendQueryStatus === 'success') {
      setShowApprovalEmailSentDialog({ status: 'success' });
    } else {
      // Show error notification to user
      setShowApprovalEmailSentDialog({ status: 'failed' });
    }
  };

  const openBulkUpdater = () => {
    const visibleRfis = gridRef?.current?.data;
    const rfisToUpdate = [];
    _.keys(selected).forEach(rfiId => {
      // 1. ensure we're only updating rfi's that are selected AND visible (i.e. not filtered out)
      // 2. use the keys on the "selected" object to get the actual rfis from the dataSource since
      //  the values on the "selected" object are old if changes were made
      const updatedRfi = _.find(visibleRfis, { requestId: rfiId });
      if (updatedRfi) {
        rfisToUpdate.push(updatedRfi);
      }
    });
    if (!rfisToUpdate.length) {
      return;
    }
    const toSet = {
      status: 'open',
      selected: rfisToUpdate,
    };
    setBulkRfiUpdaterOptions(toSet);
  };

  const scrollProps = {
    ...ReactDataGrid.defaultProps.scrollProps,
    autoHide: false,
    scrollThumbWidth: 12,
    scrollThumbStyle: {
      background: themePalette.brandColorPrimary,
    },
  };

  const rfisLoading = internalRfisLoading;
  const hasApprovedRfis = approvedRfis?.length > 0;

  // check dataSource to see if any RFI's have the requestStatus of IN_PENDING_APPROVAL
  const anyPendingApproval = !!_.find(dataSource, {
    requestStatus: RFI_STATUSES.IN_PENDING_APPROVAL.value,
  });

  const someItemsAreSelected = _.keys(selected).length > 0;

  return (
    <div className={classes.scrollableColumn}>
      {companyProjects && companyCrew && !rfisLoading ? (
        <>
          <div style={{ flex: 0 }}>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              className={classes.actionButtonsWrapper}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <AdminToolsTitle
                  Icon={InfoOutlinedIcon}
                  titleText="Create RFI's"
                />
              </div>
              <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                <ButtonWithTooltip
                  tooltipText="Apply changes to selected RFIs"
                  disabled={!someItemsAreSelected}
                  onClick={openBulkUpdater}
                  endIcon={<Layers />}
                >
                  Bulk Update
                </ButtonWithTooltip>
                {adminMode && (
                  <ButtonWithTooltip
                    tooltipText={
                      hasApprovedRfis
                        ? 'Send all approved RFIs to the client'
                        : 'No approved RFIs to send'
                    }
                    disabled={!hasApprovedRfis}
                    onClick={handleSendApprovedRfis}
                    style={{ marginLeft: !hasApprovedRfis ? 16 : null }}
                  >
                    Send Approved RFIs
                  </ButtonWithTooltip>
                )}

                {!setupUncategorizedAccounts && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={setupUncategorizedAccounts}
                  >
                    Setup RFI Accounts
                  </Button>
                )}

                <AdminToolsIconButton
                  tooltipText="View stats for current data"
                  onClick={() => setShowStatsDialog({ open: true })}
                  disabled={!gridRef?.current}
                >
                  <EqualizerIcon />
                </AdminToolsIconButton>
                {hasUncategorizedTxnAccounts && (
                  <AdminToolsIconButton
                    tooltipText="Import From QBO"
                    onClick={importExternalData}
                  >
                    <CloudDownloadIcon />
                  </AdminToolsIconButton>
                )}
                <AdminToolsIconButton
                  tooltipText="Add New RFI"
                  onClick={addNewRfiManually}
                >
                  <AddIcon />
                </AdminToolsIconButton>

                <AdminToolsIconButton
                  tooltipText="Edit RFI Accounts"
                  onClick={setupUncategorizedAccounts}
                >
                  <SettingsIcon />
                </AdminToolsIconButton>

                {!adminMode && anyPendingApproval && (
                  <AdminToolsIconButton
                    tooltipText="Notify Manager of RFIs Needing Approval"
                    onClick={() =>
                      setShowApprovalEmailSentDialog({ status: 'asking' })
                    }
                  >
                    <Mail />
                  </AdminToolsIconButton>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 8,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch checked={adminMode} onChange={toggleAdminMode} />
                    }
                    label="Admin Mode"
                    labelPlacement="start"
                  />
                </div>
              </div>
            </Grid>
          </div>
          <div style={{ flex: 1, height: 200 }}>
            <ReactDataGrid
              // grab the ref
              onReady={setGridRef}
              // set which property is used as the ID
              idProperty="requestId"
              // set which columns show
              columns={columns}
              // set the data to build from
              dataSource={dataSource}
              // set basic styling for the overall table
              style={gridStyle}
              className="reactDataGridFixLastItemOverlap"
              // filtering
              enableFiltering
              defaultFilterValue={filterValue}
              filterTypes={filterTypes}
              // sorting
              defaultSortInfo={defaultSortInfo}
              allowUnsort={false}
              // scrollbar
              scrollProps={scrollProps}
              checkboxColumn
              checkboxOnlyRowSelect
              // checkboxColumn={adminMode}
              selected={selected}
              onSelectionChange={onSelectionChange}
              // editing options
              onEditComplete={onEditComplete}
            />
          </div>
        </>
      ) : (
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              Loading RFI&apos;s...
            </Typography>
            <LinearProgress
              style={{ width: '50%', margin: '0 auto', marginTop: 16 }}
            />
          </Grid>
        </Grid>
      )}
      {dialogInfo.open && (
        <OkCancelDialog
          title={dialogInfo.title}
          open={dialogInfo.open}
          onClose={dialogInfo.onClose}
          hideCancel={dialogInfo.hideCancel}
          okButtonText={dialogInfo.okButtonText}
          onConfirm={dialogInfo.onConfirm}
        >
          {dialogInfo.message && <Typography>{dialogInfo.message}</Typography>}
          {!!dialogInfo.customChildren && dialogInfo.customChildren}
        </OkCancelDialog>
      )}
      <Drawer
        anchor="right"
        open={createOrEditPanel.open}
        onClose={closeEvent =>
          handleDrawerClose({ closeEvent, askIfSure: true })
        }
        PaperProps={{ className: classes.drawerPaper }}
      >
        {createOrEditPanel.rfiToEdit && (
          <AdminCreateEditRfiForm
            rfiToEdit={createOrEditPanel.rfiToEdit}
            companyCrew={companyCrew}
            onSave={handleSaveRfis}
            onClose={({ askIfSure, proceedToNext }) => {
              handleDrawerClose({ askIfSure, proceedToNext });
            }}
            onCancel={() => handleDrawerClose({ askIfSure: true })}
            adminMode={adminMode}
            managingCompanyId={managingCompanyId}
            userInfo={userInfo}
            managementMode
          />
        )}
      </Drawer>
      <RfiAdminModePrompt
        shouldShow={showAdminDialog.open}
        onSuccess={() => {
          setAdminMode(true);
          setShowAdminDialog({ open: false });
        }}
        onCancel={() => {
          setShowAdminDialog({ open: false });
        }}
      />
      {showImportLoader && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Importing Data...
          </Typography>
        </LoadingCover>
      )}
      {removingRfi && (
        <LoadingCover>
          <Typography variant="h3" align="center">
            Removing...
          </Typography>
        </LoadingCover>
      )}
      {showAccountPicker && (
        <ChooseUncategorizedAccounts
          currentAccounts={companyInfo?.uncategorizedTxnAccounts || []}
          handleDialogClose={handleAccountPickerClose}
          handleSave={handleAccountPickerSave}
        />
      )}
      {!!txnsAlreadyHaveAnRfi?.length && (
        <HandleExistingRfiTxns
          txns={txnsAlreadyHaveAnRfi}
          handleDialogClose={() => setTxnsAlreadyHaveAnRfi(null)}
          handleSave={handleExistingRfiTxnsSave}
        />
      )}
      {sendApprovedRfisInfo.open && (
        <SendApprovedRfis
          rfisToSend={sendApprovedRfisInfo.rfis}
          rfiRecipients={rfiRecipients}
          handleDialogClose={() => setSendApprovedRfisInfo({ open: false })}
          handleSaveRfis={handleSaveRfis}
        />
      )}
      {showStatsDialog.open && (
        <RequestsForInfoStatsDialog
          open={showStatsDialog.open}
          currentData={gridRef.current?.data || null}
          onClose={() => {
            setShowStatsDialog({ open: false });
          }}
        />
      )}
      {!!showApprovalEmailSentDialog.status && (
        <ReadyApprovalDialog
          status={showApprovalEmailSentDialog.status}
          toClose={() => setShowApprovalEmailSentDialog({ status: null })}
          toProceed={handleNotifyManager}
        />
      )}
      {!!bulkRfiUpdaterOptions.status && (
        <BulkRfiUpdater
          toClose={() => setBulkRfiUpdaterOptions({ status: null })}
          rfis={bulkRfiUpdaterOptions.selected}
          adminMode={adminMode}
          handleSaveRfis={handleSaveRfis}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    managingCompanyId: _.get(
      state.appState,
      'managingCompanyInfo.managingCompanyId',
      null
    ),
  };
}

export default compose(
  // Queries
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  // GetCompanyRfisAction,
  ListCompanyProjectsAction,
  // Mutations
  AddCompanyRfisAction,
  UpdateCompanyRfisAction,
  RemoveCompanyRfiAction,
  AddOrUpdateCompanyAction
)(connect(mapStateToProps)(ManageRequestsForInfo));
