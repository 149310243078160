// import {
//   Platform,
// } from 'react-native';
import lodash from 'lodash';
// import { parsePhoneNumberFromString, format } from 'libphonenumber-js';
// import { Notifications } from 'expo';

const defaultCountry = {
  callingCode: '1',
  cca2: 'US',
  currency: 'USD',
  flag: undefined,
  name: 'United States',
};

const INITIAL_STATE = {
  jrnToAddTo: null,
  whereFrom: 'Explore',
  saveLocalCopiesOfContent: true,
  cameFromSignUp: false,
  initialLogin: true,
  changeFilenamesOnUpload: false,
  setNewContentPublic: false,
  localContactsList: null,
  localContactsListFlat: null,
  addressBookAccessOkd: true,
  defaultPhoneNumberCountry: defaultCountry,
  lastMessageReadTimestamps: [],
  numOfUnreadConvos: 0,
  uploadProgressPercent: null,
  contentLabelSuggestions: null,
  projectLabelSuggestions: null,
  companyRegistrationKey: null,

  managingCompanyInfo: null,
  // EXPECTED:
  // managingCompanyInfo: {
  //   managingCompanyId: String,
  //   isCompanyAdmin: Boolean,
  // }

  holder001: null,
  holder002: null,
  holder003: null,
  holder004: null,
  holder005: null,
  holder006: null,
  holder007: null,
  holder008: null,
  holder009: null,
  holder010: null,
};

// const setUnreadNumber = (number) => {
//   // if (Platform.OS === 'ios') {
//   //   Notifications.setBadgeNumberAsync(number);
//   // }
// };

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'SET_A_LAST_READ_TIMESTAMP': {
      const { contentId } = payload;
      const existingTimestamps = [...state.lastMessageReadTimestamps] || [];
      const alreadyThere = lodash.findIndex(existingTimestamps, { contentId });
      let { numOfUnreadConvos } = state;

      // console.log('alreadyThere: ', alreadyThere);
      // if it's already there, just update that one
      if (alreadyThere >= 0) {
        if (
          existingTimestamps[alreadyThere].lastMessageRead <
          existingTimestamps[alreadyThere].lastMessageSent
        ) {
          numOfUnreadConvos -= 1;
        }

        existingTimestamps[alreadyThere].lastMessageRead =
          payload.lastMessageRead;
      } else {
        // if it's not already there, then add it to the copy of the existing ones
        existingTimestamps.push(payload);
      }
      // check if that timestamp was originally before the lastSent
      //  if so, decrement the counter by 1 (make sure it's not somehow already 0)
      // numOfUnreadConvos -= 1;
      // setUnreadNumber(numOfUnreadConvos);
      // trigger
      return {
        ...state,
        lastMessageReadTimestamps: existingTimestamps,
        numOfUnreadConvos: numOfUnreadConvos >= 0 ? numOfUnreadConvos : 0,
      };
    }

    // case 'SET_A_LAST_MESSAGE_SENT_TIMESTAMP': {
    //   const existingListOfTimestamps = state.lastMessageReadTimestamps || [];
    //   const convo = payload;
    //   let updatedNumOfUnreadConvos = state.numOfUnreadConvos || 0;

    //   const foundLocalIndex = lodash.findIndex(
    //     existingListOfTimestamps,
    //     { contentId: convo.contentId },
    //   );

    //   if (foundLocalIndex >= 0) {
    //     // if it's found, check the timestamp and
    //     updatedNumOfUnreadConvos += 1;
    //     // if the local read timestamp is earlier than the new timestamp, up the counter
    //     existingListOfTimestamps[foundLocalIndex] = {
    //       ...existingListOfTimestamps[foundLocalIndex],
    //       lastMessageSent: convo.lastMessageSent,
    //     };
    //   }
    //   // if it's not in the list at all, it's new,
    //   //  so add it to the list of convos with the current timestamp
    //   return {
    //     ...convo,
    //     lastMessageRead: 0,
    //   };

    //   return {...state,
    //     lastMessageReadTimestamps: localSimpleConvos,
    //     numOfUnreadConvos: updatedNumOfUnreadConvos,
    //   });
    // }

    case 'UPDATE_LAST_MESSAGE_SENT_TIMESTAMPS': {
      // console.log('updating last message stuff');
      // using the new list, figure out how many convos need reading
      //  (which convos need a notification can be done in the messageGroups screen)
      // state.lastMessageReadTimestamps
      if (!payload.convos) return state;
      let numOfUnreadConvos = 0;

      // generate the latest info
      const localSimpleConvos = payload.convos.map(convo => {
        // using the new info, check if the local list has this convo in it
        const foundLocalToo = lodash.find(state.lastMessageReadTimestamps, {
          contentId: convo.contentId,
        });
        if (foundLocalToo) {
          // if it's found, check the timestamp and
          if (
            convo.lastMessageSent &&
            foundLocalToo.lastMessageRead < convo.lastMessageSent &&
            foundLocalToo.lastMessageRead !== 0
          ) {
            numOfUnreadConvos += 1;
          }
          // if the local read timestamp is earlier than the new timestamp, up the counter
          return {
            ...foundLocalToo,
            lastMessageSent: convo.lastMessageSent,
          };
        }
        // if it's not in the list at all, it's new,
        //  so add it to the list of convos with the current timestamp
        return {
          ...convo,
          lastMessageRead: 0,
        };
      });
      // setUnreadNumber(numOfUnreadConvos);
      return {
        ...state,
        lastMessageReadTimestamps: localSimpleConvos,
        numOfUnreadConvos,
      };
    }
    case 'CAME_FROM_SIGNUP': {
      return { ...state, cameFromSignUp: payload };
    }
    case 'UPDATE_DEFAULT_PHONE_NUMBER_COUNTRY': {
      return { ...state, defaultPhoneNumberCountry: payload };
    }
    case 'ADDRESS_BOOK_ACCESS_OKD': {
      return { ...state, addressBookAccessOkd: payload };
    }
    case 'SET_NEW_CONTENT_PUBLIC': {
      return { ...state, setNewContentPublic: payload };
    }
    case 'CHANGE_FILENAMES_ON_UPLOAD': {
      return { ...state, changeFilenamesOnUpload: payload };
    }
    case 'TURN_OFF_INITIAL_LOGIN': {
      return { ...state, initialLogin: false };
    }
    case 'TURN_ON_INITIAL_LOGIN': {
      return { ...state, initialLogin: true };
    }
    case 'SET_CAME_FROM_EXPLORE_OR_MYJRNS': {
      return { ...state, whereFrom: payload };
    }
    case 'UPDATE_CONTENT_LABEL_SUGGESTIONS': {
      return {
        ...state,
        contentLabelSuggestions: payload.contentLabelSuggestions || [],
      };
    }
    case 'SET_ADMIN_TOOLS_SETTINGS': {
      return {
        ...state,
        adminToolsSettings: {
          ...state.adminToolsSettings,
          ...payload,
        },
      };
    }
    case 'UPDATE_ALL_PROJECT_LABEL_SUGGESTIONS': {
      return {
        ...state,
        projectLabelSuggestions: payload.projectLabelSuggestions || [],
      };
    }
    case 'CLEAR_JRN_TO_ADD_TO': {
      return { ...state, jrnToAddTo: null };
    }
    case 'SET_SAVE_LOCAL_COPIES': {
      return { ...state, saveLocalCopiesOfContent: payload };
    }
    case 'SET_UPLOADING_STATUS': {
      return {
        ...state,
        uploadProgressPercent: payload.progressPercent,
      };
    }
    case 'SIGNIN_SUCCEEDED': {
      const newState = { ...state };
      if (payload?.managingCompanyInfo) {
        newState.managingCompanyInfo = {
          ...payload?.managingCompanyInfo,
        };
      }
      return newState;
    }
    case 'SIGNOUT_SUCCEEDED': {
      if (state.adminToolsSettings) {
        // keep the admin tools settings across users - useful for people signing
        //  in and out of accounts but doing the same work across them
        return {
          ...INITIAL_STATE,
          adminToolsSettings: state.adminToolsSettings,
        };
      }
      return { ...INITIAL_STATE };
    }
    case 'COMPANY_REGISTRATION_KEY': {
      return { ...state, companyRegistrationKey: payload };
    }
    case 'SET_MANAGING_COMPANY_INFO': {
      return { ...state, managingCompanyInfo: payload };
    }
    case 'SHOW_ERROR_INVITE_MODAL': {
      return { ...state, showErrorInviteModal: payload };
    }
    case 'LINK_REDIRECT': {
      return {
        ...state,
        redirectLink: payload,
      };
    }
    case 'PROJECT_SORT': {
      return { ...state, projectSort: payload };
    }
    case 'VENDOR_SORT': {
      return { ...state, vendorSort: payload };
    }
    default: {
      return state;
    }
  }
};
