import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { isDefaultCompanyName, isDefaultUsername } from '../../helpers';

const UnauthRoute = ({
  component: Component,
  layout: Layout = null,
  currentAuth,
  userInfo,
  managingCompanyInfo,
  location,
  dispatch,
  redirectLink,
  ...rest
}) => {
  if (currentAuth.isLoggedIn === true) {
    if (
      location.pathname &&
      (location.pathname.startsWith('/auth/signup') ||
        location.pathname.startsWith('/auth/invitation') ||
        location.pathname.startsWith('/auth/email-sign-up'))
    ) {
      if (userInfo && managingCompanyInfo) {
        let hasCompletedOnboarding = true;
        if (isDefaultCompanyName(managingCompanyInfo?.managingCompanyName)) {
          hasCompletedOnboarding = false;
        }

        if (managingCompanyInfo.hasProjectManagementProduct) {
          if (userInfo.email && isDefaultUsername(userInfo.username)) {
            hasCompletedOnboarding = false;
          }
        }

        if (hasCompletedOnboarding) {
          return <Redirect to="/" />;
        }
      }

      return <Redirect to="/onboarding" />;
    }

    if (redirectLink) {
      dispatch({
        type: 'LINK_REDIRECT',
        payload: null,
      });
      return <Redirect to={redirectLink} />;
    }

    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={matchProps =>
        Layout ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Component {...matchProps} />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    currentAuth: state.currentAuth,
    redirectLink: state.appState.redirectLink,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    userInfo: state.userInfo,
  };
}

export default connect(mapStateToProps)(UnauthRoute);
