import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import ExcelJs from 'exceljs';

// UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Grid,
  ListItem,
  IconButton,
  InputAdornment,
  InputLabel,
  Switch as MuiSwitch,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';

import {
  Add as AddIcon,
  ArrowForward as ArrowForwardIcon,
  Block as BlockIcon,
  Edit as EditIcon,
  Equalizer as EqualizerIcon,
  DeleteOutline as DeleteOutlineIcon,
  HighlightOff as HighlightOffIcon,
  Image as ImageIcon,
  MoreHoriz as MoreHorizIcon,
  PersonPinCircle as PersonPinCircleIcon,
  Publish as PublishIcon,
  Save as SaveIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Papa from 'papaparse';

// utilities
import _, { uniqueId } from 'lodash';
import moment from 'moment';
import copyToClipboard from 'copy-to-clipboard';

import { TimeManager } from 'level-foundation';

// GraphQL
import GetAdminTimetrackingData from '../../../graphql/queries/get-admin-timetracking-data';
import ListCompanyProjects from '../../../graphql/queries/list-company-projects';
import {
  DeleteContentAction,
  GetCompanyInfoAction,
} from '../../../graphql/graphql';

import ButtonWithTooltip from '../../../components/button-with-tooltip';
import AddEditTimetracking from './add-edit-timetracking';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import AdminToolsIconButton from '../../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import MoveTimetrackingEntryDialog from './move-timetracking-entry';

// helpers
import {
  locationify,
  runAnalytics,
  twoDatesWithinInterval,
  applyPreferences,
} from '../../../helpers/index';

import {
  amountFilter,
  simpleSortForAmount,
} from '../../../helpers/react-datagrid-helpers';

import {
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_TIME,
  DEFAULT_TIME_FORMAT,
  EXPORT_FORMAT,
  TOP_PROJECT_ID,
} from '../../../config/appDefaults';

import themePalette from '../../../theme/palette';
import store from '../../../store';
import ResponseTooBigWarning from '../../../components/response-too-big-warning/response-too-big-warning';
import { useRepetitiveQuery } from '../../../hooks';

// for ReactDataGrid
window.moment = moment;
const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  headerWrapper: {
    padding: theme.spacing(3),
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 460,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
  headerIcon: {
    color: '#999',
    fontSize: 30,
    position: 'absolute',
    top: 5,
  },
  statModalLabel: {
    textTransform: 'uppercase',
  },
  statText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
  },
  labelBasics: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  payPeriodDateWrapper: {
    marginRight: theme.spacing(1),
  },
  setPayPeriodButton: {
    color: theme.palette.brandColorPrimary,
    textDecoration: 'underline',
  },
  iconButton: {
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.09)',
    },
  },
}));

const checkInOutFormat = 'MMM D, YYYY @ h:mma';
const downloadBlob = (blob, fileName = 'grid-data.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const ManageTime = ({
  // MapStateToProps
  userInfo,
  managingCompanyInfo,
  columnSettings,

  // HOC props
  onDeleteContent,
  getCompanyInfo,
}) => {
  const classes = useStyles();
  const [gridRef, setGridRef] = useState(null);
  const [showPayPeriodFields, setShowPayPeriodFields] = useState(false);
  const [payPeriodInfo, setPayPeriodInfo] = useState({
    firstDay: {
      name: 'firstDay',
      date: null,
      errorMsg: null,
      placeholder: 'Choose first day...',
    },
    lastDay: {
      name: 'lastDay',
      date: null,
      errorMsg: null,
      placeholder: 'Choose last day...',
    },
  });
  const [includeArchive, setIncludeArchive] = useState(false);
  const [hhmmDuration, setHhmmDuration] = useState(false);
  const [entriesStats, setEntriesStats] = useState({ total: 0, avg: 0 });
  const [statsDialogInfo, setStatsDialogInfo] = useState({ open: false });
  const [filterValue, setFilterValue] = useState(null);
  const [columnOrder, setColumnOrder] = useState(null);
  const [tableKey, setTableKey] = useState(uniqueId());
  const resetTableKey = () => setTableKey(uniqueId());

  const basicSortInfo = {
    name: 'checkInTime',
    dir: -1,
    type: 'date',
  };
  const [defaultSortInfo, setDefaultSortInfo] = useState(basicSortInfo);

  const [addEditOptions, setAddEditOptions] = useState({
    open: false,
  });
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
  });
  const [showMoveDialog, setShowMoveDialog] = useState({
    open: false,
    checkInContent: null,
    checkOutContent: null,
  });
  const [showLoadingCover, setShowLoadingCover] = useState({
    open: false,
    message: '',
  });

  const getAdminTimetrackingDataAccessor = 'getAdminTimetrackingData';
  const adminTimetrackingData = useRepetitiveQuery(GetAdminTimetrackingData, {
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo.managingCompanyId,
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      includeArchive,
    },
    accessor: getAdminTimetrackingDataAccessor,
  });

  // Get active projects and subproject query items
  const timetrackingDataLoading = _.get(adminTimetrackingData, 'loading', {});
  const timetrackingDataRefetch = _.get(adminTimetrackingData, 'refetch', null);
  const {
    items: timetrackingItems,
    projects: timetrackingProjects,
    users: timetrackingUsers,
    customers: timetrackingCustomers,
    timetrackingQuestions,
    queryNote: timetrackingQueryNote,
  } = _.get(adminTimetrackingData.data, getAdminTimetrackingDataAccessor, {});

  const timetrackingQuestionsMap = useMemo(() => {
    const questionsMap = {};
    _.forEach(timetrackingQuestions, question => {
      questionsMap[question.questionId] = question;
    });
    return questionsMap;
  }, [timetrackingQuestions]);

  // END - get admin content

  // Get list of customers
  const timetrackingCustomerInfoMap = useMemo(() => {
    const compiledCustomerMap = {};
    if (timetrackingCustomers) {
      _.forEach(timetrackingCustomers, customer => {
        compiledCustomerMap[customer.customerId] = customer;
      });
    }
    return compiledCustomerMap;
  }, [timetrackingCustomers]);

  // Get map of all projects and their customers
  // Sub-projects do not have customer info so need to get it from parent
  const projectCustomerMap = useMemo(() => {
    const compiledProjectCustomerMap = {};

    if (timetrackingProjects) {
      const addCustomerInfoToMaps = project => {
        if (project.customerId) {
          // If has customer info, add to maps
          compiledProjectCustomerMap[project.contentId] = project.customerId;

          return project.customerId;
        }

        if (project.jrnId !== TOP_PROJECT_ID && project.jrnId) {
          // If is subproject recursively search for parent then determine if it should have a customer
          const parentProject = _.find(
            timetrackingProjects,
            projectToCheck => projectToCheck.contentId === project.jrnId
          );

          const parentCustomerId = addCustomerInfoToMaps(parentProject);

          compiledProjectCustomerMap[project.contentId] = parentCustomerId;

          return parentCustomerId;
        }

        // If not a subproject and no customer info, there is no customer
        compiledProjectCustomerMap[project.contentId] = null;
        return null;
      };

      timetrackingProjects.forEach(project => addCustomerInfoToMaps(project));
    }

    return compiledProjectCustomerMap;
  }, [timetrackingProjects]);

  const listCompanyProjectQuery = useQuery(ListCompanyProjects, {
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      first: null,
      after: null,
    },
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo.managingCompanyId,
  });
  const companyProjects = _.get(
    listCompanyProjectQuery,
    `data.listCompanyProjects.items`
  );

  // // END - get projects

  const capitalizeSentence = sentence => {
    const words = sentence.split(' ');
    words
      .map(word => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
    return words;
  };
  const buildColumnObj = options => {
    if (!options.name) return null;
    const basicColumn = {
      ...options,
      header:
        options.header !== undefined
          ? options.header
          : capitalizeSentence(options.name),
    };
    return basicColumn;
  };

  const gridStyle = { height: '100%', minHeight: '100%' };

  const [dataSource, setDataSource] = useState(null);
  const [columns, setColumns] = useState(null);
  const [tableReady, setTableReady] = useState(false);

  const editPair = (checkInContentId, checkOutContentId) => {
    const checkInContent = _.find(timetrackingItems, {
      contentId: checkInContentId,
    });
    const checkOutContent = _.find(timetrackingItems, {
      contentId: checkOutContentId,
    });
    const projectToAddToProp = _.find(timetrackingProjects, {
      contentId: checkInContent.jrnId,
    });
    const assigneeInfo = _.find(timetrackingUsers, {
      userId: checkInContent.assignedTo,
    });

    if (projectToAddToProp && (checkInContent || checkOutContent)) {
      setAddEditOptions({
        open: true,
        checkInContent,
        checkOutContent,
        projectToAddToProp,
        assigneeInfo,
      });
    } else {
      // throw up a modal to let them know something went wrong
      // eslint-disable-next-line no-console
      console.log('something went wrong getting the content and project info');
    }
  };

  const movePair = (checkInContentId, checkOutContentId) => {
    const checkInContent = _.find(timetrackingItems, {
      contentId: checkInContentId,
    });
    const checkOutContent = _.find(timetrackingItems, {
      contentId: checkOutContentId,
    });
    setShowMoveDialog({
      open: true,
      checkInContent,
      checkOutContent,
    });
  };

  const deletePair = async (checkInContentId, checkOutContentId, projectId) => {
    setDialogInfo({
      title: 'Just making sure...',
      message: 'Are you sure you want to delete these time-tracking events?',
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: async () => {
        const doTheDelete = async id => {
          await onDeleteContent(id, projectId, {
            fromWhichAdminTool: 'adminTimetrackingData',
            includeArchive,
          });
        };
        const starterPromise = Promise.resolve(null); // intentionally empty to kick things off
        await [checkInContentId, checkOutContentId].reduce(
          (p, spec) => p.then(() => doTheDelete(spec)),
          starterPromise
        );
      },
    });
  };

  const buildDataGrid = ({ withoutPreferences = false } = {}) => {
    // now that we have all content needed, build the timetracking pairs to be shown in the table
    // seperate the content by project
    const splitContentOnAttribute = (content, attribute) => {
      const contentByAttribute = new Map();
      content.forEach(item => {
        if (!contentByAttribute.get(item[attribute])) {
          contentByAttribute.set(item[attribute], []);
        }
        contentByAttribute.get(item[attribute]).push(item);
      });
      return contentByAttribute;
    };
    const timetrackingByProject = splitContentOnAttribute(
      timetrackingItems,
      'jrnId'
    );

    const uniqueProjectNames = [];
    // build pairs
    // TimeManger for each project here
    const pairedUpTimetracking = [];
    timetrackingByProject.forEach((arrayOfContent, projectId) => {
      if (!arrayOfContent || !arrayOfContent.length) {
        return;
      }
      let projectInfo = _.find(timetrackingProjects, { contentId: projectId });
      if (!projectInfo) {
        // this covers the user if they add an entry to a project that didn't have any before
        //  so it wouldn't have been in the projects list returned from the server with the adminContent
        projectInfo = _.find(companyProjects, { contentId: projectId });
      }

      if (!projectInfo) {
        return;
      }

      let timeManager = null;
      if (projectInfo && arrayOfContent && timetrackingUsers) {
        timeManager = new TimeManager(projectInfo);
        try {
          timeManager.generateTimesheets({
            users: timetrackingUsers,
            timetrackingItems: arrayOfContent,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(
            `${projectInfo.title} -> timeManager.generateTimesheets err: `,
            err
          );
          return;
        }
        const timesheets = timeManager._getFilteredTimesheets();

        // for each time entry, we'll need the following info and an edit button
        // per timesheet, per entry
        _.forEach(timesheets, timesheet => {
          _.forEach(timesheet._entries, entry => {
            // check if, based on the pay period, it should be included
            const checkInItemDate = _.get(entry, '_checkInItem.date');
            const checkOutItemDate = _.get(entry, '_checkOutItem.date');
            if (payPeriodInfo) {
              if (payPeriodInfo.firstDay.date) {
                // if the check in date is not greater than the first day
                if (checkInItemDate) {
                  if (
                    checkInItemDate <
                    payPeriodInfo.firstDay.date.startOf('day').toISOString()
                  ) {
                    return;
                  }
                }
              }
              if (payPeriodInfo.lastDay.date) {
                // if the check in date is great than the last day of the pay period, ignore it
                if (checkInItemDate) {
                  if (
                    checkInItemDate >
                    payPeriodInfo.lastDay.date.endOf('day').toISOString()
                  ) {
                    return;
                  }
                }
              }
            }

            let checkInTimeMoment;
            let checkOutTimeMoment;
            let checkInTimeCreatedMoment;
            let checkOutTimeCreatedMoment;
            let lengthOfShift = null;
            let checkInTime = '';
            let checkInTimeCreated = '';
            let checkOutTime = '';
            let checkOutTimeCreated = '';
            let checkInContentId = '';
            let checkOutContentId = '';
            let checkInLocation = null;
            let checkOutLocation = null;
            let selfieUrl = null;
            let answers = null;
            let answersCheckout = null;
            let checkInAddress = null;
            let checkOutAddress = null;
            let checkInTimeDiscrepancy = false;
            let checkOutTimeDiscrepancy = false;
            let discrepancy = false;
            // let labels = null;

            // doing this since a check in/out could be missing from the pair
            // check in info
            if (checkInItemDate) {
              checkInTimeMoment = moment(entry._checkInItem.date);
              checkInTime = checkInTimeMoment.format('YYYY-MM-DDTHH:mm:ssZ');
              checkInTimeCreatedMoment = moment(entry._checkInItem.dateCreated);
              checkInTimeCreated = checkInTimeCreatedMoment.format(
                'YYYY-MM-DDTHH:mm:ssZ'
              );
              checkInContentId = entry._checkInItem.contentId;
              checkInLocation = entry._checkInItem.latitude
                ? [entry._checkInItem.latitude, entry._checkInItem.longitude]
                : null;
              selfieUrl =
                entry._checkInItem.contentUrl &&
                entry._checkInItem.contentUrl !== '[]'
                  ? entry._checkInItem.contentUrl
                  : null;
              answers = entry._checkInItem.timetrackingAnswers;
              checkInAddress = entry._checkInItem.address;
              // check for time discrepancy
              if (
                !twoDatesWithinInterval({
                  date1: entry._checkInItem.date,
                  date2: entry._checkInItem.dateCreated,
                  intervalAmount: 60,
                  intervalType: 'seconds',
                })
              ) {
                checkInTimeDiscrepancy = true;
              }
            }

            // check out info
            if (checkOutItemDate) {
              checkOutTimeMoment = moment(entry._checkOutItem.date);
              lengthOfShift =
                Math.round(
                  checkOutTimeMoment.diff(checkInTimeMoment, 'hours', true) *
                    100
                ) / 100;
              checkOutTime = checkOutTimeMoment.format('YYYY-MM-DDTHH:mm:ssZ');
              checkOutTimeCreatedMoment = moment(
                entry._checkOutItem.dateCreated
              );
              checkOutTimeCreated = checkOutTimeCreatedMoment.format(
                'YYYY-MM-DDTHH:mm:ssZ'
              );
              checkOutContentId = entry._checkOutItem.contentId;
              checkOutLocation = entry._checkOutItem.latitude
                ? [entry._checkOutItem.latitude, entry._checkOutItem.longitude]
                : null;
              answersCheckout = entry._checkOutItem.timetrackingAnswers;
              checkOutAddress = entry._checkOutItem.address;
              // check for time discrepancy
              if (
                !twoDatesWithinInterval({
                  date1: entry._checkOutItem.date,
                  date2: entry._checkOutItem.dateCreated,
                  intervalAmount: 60,
                  intervalType: 'seconds',
                })
              ) {
                checkOutTimeDiscrepancy = true;
              }
            }

            discrepancy = checkInTimeDiscrepancy || checkOutTimeDiscrepancy;

            let projectName = projectInfo.title;
            if (projectInfo.jrnId && projectInfo.jrnId !== TOP_PROJECT_ID) {
              const parentProjectInfo = _.find(timetrackingProjects, {
                contentId: projectInfo.jrnId,
              });
              if (parentProjectInfo) {
                projectName = `${parentProjectInfo.title} > ${projectInfo.title}`;
              }
            }
            if (!uniqueProjectNames.includes(projectName)) {
              uniqueProjectNames.push(projectName);
            }

            const user = timesheet.getUser();

            const getCustomerName = projId => {
              if (projectCustomerMap && timetrackingCustomerInfoMap) {
                const customerId = projectCustomerMap[projId];
                if (customerId !== null) {
                  const customerInfo = timetrackingCustomerInfoMap[customerId];
                  if (customerInfo) {
                    const { firstName, lastName } = customerInfo;
                    if (firstName || lastName) {
                      const fullName = [];
                      if (firstName) {
                        fullName.push(firstName);
                      }
                      if (lastName) {
                        fullName.push(lastName);
                      }
                      return fullName.join(' ');
                    }

                    if (customerInfo.companyName) {
                      return customerInfo.companyName;
                    }
                  }
                }
              }

              return null;
            };

            const whatToAdd = {
              projectId: projectInfo.contentId,
              projectName,
              customerId: projectInfo.customerId,
              customerName: getCustomerName(projectInfo.contentId),
              userId: user.userId,
              username: user.username,
              firstName: user.firstName || null,
              lastName: user.lastName || null,
              checkInContentId,
              checkOutContentId,
              checkInTime,
              checkInTimeDiscrepancy: checkInTimeDiscrepancy ? 'yes' : null,
              checkInTimeCreated,
              checkOutTime,
              checkOutTimeDiscrepancy: checkOutTimeDiscrepancy ? 'yes' : null,
              checkOutTimeCreated,
              lengthOfShift,
              selfieUrl,
              checkInLocation,
              checkOutLocation,
              checkInAddress,
              checkOutAddress,
              // labels,
              discrepancy: discrepancy ? 'yes' : null,
            };

            timetrackingQuestions.forEach(question => {
              whatToAdd[question.questionId] = [];
            });
            if (answers) {
              answers.forEach(answerDetails => {
                if (
                  answerDetails.answer &&
                  whatToAdd[answerDetails.questionId]
                ) {
                  // Ignore answers belonging to unknown questions
                  whatToAdd[answerDetails.questionId].push({
                    type: 'in',
                    answer: answerDetails.answer,
                  });
                }
              });
            }
            if (answersCheckout) {
              answersCheckout.forEach(answerDetails => {
                if (
                  answerDetails.answer &&
                  whatToAdd[answerDetails.questionId]
                ) {
                  whatToAdd[answerDetails.questionId].push({
                    type: 'out',
                    answer: answerDetails.answer,
                  });
                }
              });
            }
            pairedUpTimetracking.push(whatToAdd);
          });
        });
      }
    });
    uniqueProjectNames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    // set the datasource
    setDataSource(pairedUpTimetracking);

    // prep data by allowlisting wanted attributes
    const uniqueUsernames = [];
    const uniqueFirstNames = [];
    const uniqueLastNames = [];
    timetrackingUsers.forEach(user => {
      if (!uniqueUsernames.includes(user.username)) {
        uniqueUsernames.push(user.username);
      }
      if (user.firstName && !uniqueFirstNames.includes(user.firstName)) {
        uniqueFirstNames.push(user.firstName);
      }
      if (user.lastName && !uniqueLastNames.includes(user.lastName)) {
        uniqueLastNames.push(user.lastName);
      }
    });
    uniqueUsernames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    uniqueFirstNames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    uniqueLastNames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    const columnWidthButtons = 148;
    const columnMinWidthExtraSmall = 82;
    const columnMinWidthSmall = 100;
    const columnMinWidthMedium = 180;
    const columnMinWidthLarge = 250;
    const showInTable = [
      {
        name: 'edit',
        header: null,
        minWidth: columnWidthButtons,
        maxWidth: columnWidthButtons,
      },
      { name: 'userId', header: 'User Id', defaultVisible: false },
      {
        name: 'username',
        header: 'Username',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueUsernames.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 20,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'firstName',
        header: 'First Name',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueFirstNames.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 20,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'lastName',
        header: 'Last Name',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueLastNames.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 20,
        minWidth: columnMinWidthMedium,
      },
      { name: 'projectId', header: 'Project Id', defaultVisible: false },
      {
        name: 'projectName',
        header: 'Project Name',
        defaultFlex: 50,
        minWidth: columnMinWidthLarge,
      },
      { name: 'customerId', header: 'Customer Id', defaultVisible: false },
      {
        name: 'customerName',
        header: 'Customer Name',
        defaultFlex: 50,
        minWidth: columnMinWidthLarge,
      },
      {
        name: 'checkInTime',
        header: 'Clock-In Time',
        filterEditor: DateFilter,
        dateFormat: 'YYYY-MM-DD',
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'checkInTimeDiscrepancy',
        header: 'Clock-in Time Discrepancy',
        defaultFlex: 15,
        minWidth: columnMinWidthSmall,
        defaultVisible: false,
      },
      {
        name: 'checkInTimeCreated',
        header: 'Clock-in Created At',
        filterEditor: DateFilter,
        dateFormat: 'YYYY-MM-DD',
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        defaultVisible: false,
      },
      {
        name: 'checkInLocation',
        header: <PersonPinCircleIcon className={classes.headerIcon} />,
        headerAlign: 'center',
        headerVerticalAlign: 'bottom',
        textAlign: 'center',
        // header: 'Clock-In Location',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
      },
      {
        name: 'checkOutTime',
        header: 'Clock-Out Time',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'checkOutTimeDiscrepancy',
        header: 'Clock-out Time Discrepancy',
        defaultFlex: 15,
        minWidth: columnMinWidthSmall,
        defaultVisible: false,
      },
      {
        name: 'checkOutTimeCreated',
        header: 'Clock-out Created At',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        defaultVisible: false,
      },
      {
        name: 'checkOutLocation',
        header: <PersonPinCircleIcon className={classes.headerIcon} />,
        // header: 'Clock-Out Location',
        headerAlign: 'center',
        headerVerticalAlign: 'bottom',
        textAlign: 'center',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
      },
      {
        name: 'lengthOfShift',
        header: `Duration (${hhmmDuration ? 'hh:mm' : 'h'})`,
        type: 'amount',
        textAlign: 'center',
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        sort: simpleSortForAmount,
      },
      {
        name: 'discrepancy',
        header: 'Discrepancy',
        defaultFlex: 15,
        minWidth: columnMinWidthSmall,
      },
      {
        name: 'selfieUrl',
        header: <ImageIcon className={classes.headerIcon} />,
        // header: 'Clock-In Photo',
        headerAlign: 'center',
        headerVerticalAlign: 'bottom',
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
      },
    ];

    // loop through the clock-in questions
    // adminContentQuestions
    timetrackingQuestions.forEach(question => {
      showInTable.push({
        name: question.questionId,
        header: question.questionText,
        defaultFlex: 5,
        minWidth: columnMinWidthExtraSmall,
        headerProps: {
          style: {
            background: 'rgb(102 175 105)',
            color: '#fff',
          },
        },
      });
    });
    // build the table columns off the datasource
    const columnsInfo = showInTable.map(attribute => {
      const attributePlus = { ...attribute };
      if (attribute.name === 'edit') {
        attributePlus.render = ({ data }) => {
          return (
            <>
              <Tooltip title="Delete Entry">
                <Button
                  onClick={() => {
                    deletePair(
                      data.checkInContentId,
                      data.checkOutContentId,
                      data.projectId
                    );
                  }}
                  className={classes.editButton}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Edit Entry">
                <Button
                  onClick={() => {
                    editPair(data.checkInContentId, data.checkOutContentId);
                  }}
                  className={classes.editButton}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Move Entry">
                <Button
                  onClick={() => {
                    movePair(data.checkInContentId, data.checkOutContentId);
                  }}
                  className={classes.editButton}
                >
                  <ArrowForwardIcon />
                </Button>
              </Tooltip>
              {data.discrepancy && (
                <Tooltip title="Time Discrepancy">
                  <Button
                    onClick={() => {
                      editPair(data.checkInContentId, data.checkOutContentId);
                    }}
                    className={classes.editButton}
                  >
                    <WarningIcon htmlColor={themePalette.brandColorOrange} />
                  </Button>
                </Tooltip>
              )}
            </>
          );
        };
      } else if (attribute.name === 'projectName') {
        attributePlus.render = ({ value, data }) => {
          return (
            <a
              href={`/projects/${data.projectId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              {value}
            </a>
          );
        };
      } else if (attribute.name === 'customerName') {
        attributePlus.render = ({ value, data }) => {
          if (!value) {
            return '';
          }
          return (
            <a
              href={`/customers/${projectCustomerMap[data.projectId]}`}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              {value}
            </a>
          );
        };
      } else if (attribute.name === 'lengthOfShift') {
        attributePlus.render = ({ value }) => {
          if (!value) {
            return 'n/a';
          }

          if (hhmmDuration) {
            const hours = Math.floor(value);
            const minutes = Math.round((value - hours) * 60);
            return `${hours}h ${minutes}m`;
          }

          return value;
        };
      } else if (
        attribute.name === 'checkInTime' ||
        attribute.name === 'checkOutTime' ||
        attribute.name === 'checkInTimeCreated' ||
        attribute.name === 'checkOutTimeCreated'
      ) {
        attributePlus.render = ({ value }) => {
          if (!value) {
            return 'n/a';
          }

          return moment(value).format('MMM D, YYYY @ h:mma');
        };
      } else if (
        attribute.name === 'checkInLocation' ||
        attribute.name === 'checkOutLocation'
      ) {
        attributePlus.render = ({ value, data }) => {
          if (!value) {
            return 'n/a';
          }

          let locationText = 'Location';
          if (attribute.name === 'checkInLocation' && data.checkInAddress) {
            locationText = data.checkInAddress;
          } else if (
            attribute.name === 'checkOutLocation' &&
            data.checkOutAddress
          ) {
            locationText = data.checkOutAddress;
          }
          return (
            <a
              href={locationify(value[0], value[1])}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              {locationText}
            </a>
          );
        };
      } else if (
        attribute.name === 'discrepancy' ||
        attribute.name === 'checkInTimeDiscrepancy' ||
        attribute.name === 'checkOutTimeDiscrepancy'
      ) {
        attributePlus.render = ({ value, data }) => {
          if (!value) {
            return null;
          }
          if (value === 'yes') {
            return (
              <Tooltip title="Time Discrepancy">
                <Button
                  onClick={() => {
                    editPair(data.checkInContentId, data.checkOutContentId);
                  }}
                  style={{ width: '100%' }}
                >
                  {value}
                </Button>
              </Tooltip>
            );
          }
          return null;
        };
      } else if (attribute.name === 'selfieUrl') {
        attributePlus.render = ({ value }) => {
          if (!value) {
            return 'n/a';
          }

          return (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              Picture
            </a>
          );
        };
      } else if (timetrackingQuestionsMap[attribute.name]) {
        attributePlus.render = ({ value }) => {
          if (_.isEmpty(value)) {
            return null;
          }
          return (
            <ListItem style={{ padding: 0 }}>
              {value.map((answer, index) => {
                return (
                  <Tooltip
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    title={
                      answer.type === 'in'
                        ? 'Clock-in answer'
                        : 'Clock-out answer'
                    }
                  >
                    <Chip
                      avatar={
                        <Avatar>{answer.type === 'in' ? 'I' : 'O'}</Avatar>
                      }
                      size="small"
                      label={answer.answer}
                      style={{ margin: '0 4px' }}
                    />
                  </Tooltip>
                );
              })}
            </ListItem>
          );
        };
      }
      return buildColumnObj(attributePlus);
    });

    const levelFilterValues = [
      {
        name: 'projectName',
        operator: 'contains',
        type: 'string',
        value: '',
      },
      {
        name: 'customerName',
        operator: 'contains',
        type: 'string',
        value: '',
      },
      {
        name: 'username',
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      {
        name: 'firstName',
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      {
        name: 'lastName',
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      {
        name: 'lengthOfShift',
        type: 'amount',
        operator: 'Starts With',
        value: '',
      },
      {
        name: 'checkInTime',
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      {
        name: 'checkInTimeCreated',
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      {
        name: 'checkOutTime',
        operator: 'beforeOrOn',
        type: 'date',
        value: '',
      },
      {
        name: 'checkOutTimeCreated',
        operator: 'beforeOrOn',
        type: 'date',
        value: '',
      },
      {
        name: 'discrepancy',
        operator: 'contains',
        type: 'string',
        value: '',
      },
    ];
    timetrackingQuestions.forEach(question => {
      levelFilterValues.push({
        name: question.questionId,
        operator: 'contains',
        type: 'string',
        value: '',
      });
    });

    const getTablePreferences = () => {
      // This takes into account if a user makes changes to the prefs but
      //  doesn't save them and then adds an item.
      //  We would expect the columns to stay as-is until
      //  the page is refreshed.
      //  When refreshed, it load back in the saved prefs.
      let settingsToBaseOn;

      // Apply the saved preferences to the table first (before it's loaded).
      // From then on, apply the visually set preferences which were initally
      //  based on the saved ones, but then change along with the user's changes
      // If withoutPreferences is requested, pass in null to return the default preferences.

      if (!tableReady) {
        settingsToBaseOn = columnSettings;
      } else {
        settingsToBaseOn = gridRef?.current?.columnsMap;
        if (withoutPreferences) {
          settingsToBaseOn = null;
        }
      }

      return applyPreferences({
        preferences: settingsToBaseOn,
        defaultColumnsInfo: columnsInfo,
        defaultFilterInfo: levelFilterValues,
        defaultSortInfo: basicSortInfo,
        defaultColumnOrder: null,
      });
    };

    const {
      newColumnsInfo: newColumns,
      newSortInfo,
      newFilterInfo,
      newColumnOrder,
    } = getTablePreferences();

    setColumns(newColumns);
    setDefaultSortInfo(newSortInfo);
    setFilterValue(newFilterInfo);
    setColumnOrder(newColumnOrder);

    if (!tableReady || withoutPreferences) {
      resetTableKey(); // this is to force the table to re-render
    }

    if (!tableReady) {
      setTableReady(true);
    }
  };

  useEffect(() => {
    if (!timetrackingItems || !timetrackingUsers || !timetrackingProjects) {
      return;
    }
    buildDataGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timetrackingItems, hhmmDuration, timetrackingProjects, payPeriodInfo]);

  const generateFormattedExportTable = (format = EXPORT_FORMAT.CSV) => {
    const currentColumns = [...gridRef.current.allColumns];
    // Overwrite header titles
    const columnsToOverwriteHeader = {
      checkInLocation: { header: 'Clock-In Location' },
      checkOutLocation: { header: 'Clock-Out Location' },
      selfieUrl: { header: 'Clock-In Photo' },
    };

    // Do the overwrite
    currentColumns.forEach((columnInfo, index) => {
      if (columnsToOverwriteHeader[columnInfo.name]) {
        currentColumns[index].header =
          columnsToOverwriteHeader[columnInfo.name].header;
      }
    });

    // Remove columns
    const columnsToRemove = ['edit', 'discrepancy'];

    // Remove additional columns in XLSX format
    if (format === EXPORT_FORMAT.XLSX) {
      columnsToRemove.push('userId', 'projectId');
    }

    // Do the removal
    _.remove(currentColumns, column => columnsToRemove.includes(column.name));

    // Find index of "Clock-in Time" and replace it with Clock-in Date, Clock-in Time, Clock-in Day
    const indexOfEntireCheckInTime = _.findIndex(currentColumns, {
      id: 'checkInTime',
    });
    const clockInTimeColumn = currentColumns.splice(
      indexOfEntireCheckInTime,
      1
    );
    currentColumns.splice(
      indexOfEntireCheckInTime,
      0,
      {
        ...clockInTimeColumn[0],
        id: 'clockInDate',
        header: 'Clock-in Date',
        name: 'clockInDate',
      },
      {
        ...clockInTimeColumn[0],
        id: 'clockInTime',
        header: 'Clock-in Time',
        name: 'clockInTime',
      },
      {
        ...clockInTimeColumn[0],
        id: 'clockInDay',
        header: 'Clock-in Day',
        name: 'clockInDay',
      }
    );
    // Find index of "Clock-out Time" and replace it with Clock-out Date, Clock-out Time, Clock-out Day
    const indexOfEntireCheckOutTime = _.findIndex(currentColumns, {
      id: 'checkOutTime',
    });
    const clockOutTimeColumn = currentColumns.splice(
      indexOfEntireCheckOutTime,
      1
    );
    currentColumns.splice(
      indexOfEntireCheckOutTime,
      0,
      {
        ...clockOutTimeColumn[0],
        id: 'clockOutDate',
        header: 'Clock-out Date',
        name: 'clockOutDate',
      },
      {
        ...clockOutTimeColumn[0],
        id: 'clockOutTime',
        header: 'Clock-out Time',
        name: 'clockOutTime',
      },
      {
        ...clockOutTimeColumn[0],
        id: 'clockOutDay',
        header: 'Clock-out Day',
        name: 'clockOutDay',
      }
    );

    // for every row, break the check-in date and check-out date into 3 parts/columns
    const columnDataDefinition = {
      clockInDate: {
        dataToUse: 'checkInTime',
        csvFormat: DEFAULT_DATE_FORMAT,
        xlsxType: 'datetime',
        xlsxFormat: 'm/d/yyyy',
      },
      clockOutDate: {
        dataToUse: 'checkOutTime',
        csvFormat: DEFAULT_DATE_FORMAT,
        xlsxType: 'datetime',
        xlsxFormat: 'm/d/yyyy',
      },
      clockInTime: {
        dataToUse: 'checkInTime',
        csvFormat: DEFAULT_TIME_FORMAT,
        xlsxType: 'datetime',
        xlsxFormat: 'h:mm:ss\\ AM/PM',
      },
      clockOutTime: {
        dataToUse: 'checkOutTime',
        csvFormat: DEFAULT_TIME_FORMAT,
        xlsxType: 'datetime',
        xlsxFormat: 'h:mm:ss\\ AM/PM',
      },
      clockInDay: {
        dataToUse: 'checkInTime',
        csvFormat: 'dddd',
        xlsxType: 'day',
      },
      clockOutDay: {
        dataToUse: 'checkOutTime',
        csvFormat: 'dddd',
        xlsxType: 'day',
      },
      checkInTimeCreated: {
        dataToUse: 'checkInTimeCreated',
        csvFormat: DEFAULT_DATE_FORMAT_WITH_TIME,
        xlsxType: 'datetime',
        xlsxFormat: 'm/d/yyyy h:mm:ss\\ AM/PM',
      },
      checkOutTimeCreated: {
        dataToUse: 'checkOutTimeCreated',
        csvFormat: DEFAULT_DATE_FORMAT_WITH_TIME,
        xlsxType: 'datetime',
        xlsxFormat: 'm/d/yyyy h:mm:ss\\ AM/PM',
      },
    };

    const rows = gridRef.current.data.map(data => {
      return currentColumns.map(column => {
        // START -  info for split clock in/out times
        if (columnDataDefinition[column.id]) {
          const getInfoFrom = data[columnDataDefinition[column.id].dataToUse];
          if (!getInfoFrom) {
            return null;
          }
          switch (format) {
            case EXPORT_FORMAT.CSV: {
              return moment(getInfoFrom).format(
                columnDataDefinition[column.id].csvFormat
              );
            }
            case EXPORT_FORMAT.XLSX: {
              if (columnDataDefinition[column.id].xlsxType === 'datetime') {
                return moment(getInfoFrom)
                  .utcOffset(0, true)
                  .toDate();
              }
              if (columnDataDefinition[column.id].xlsxType === 'day') {
                return moment(getInfoFrom).format('dddd');
              }
              break;
            }
            default:
              break;
          }
        }
        // END - info for split clock in/out times

        // if nothing there, just return null
        if (!data[column.id] && data[column.id] !== 0) {
          return null;
        }

        if (
          column.id === 'checkInLocation' ||
          column.id === 'checkOutLocation'
        ) {
          const locationUrl = locationify(
            data[column.id][0],
            data[column.id][1]
          );

          if (format === EXPORT_FORMAT.XLSX) {
            return {
              text: locationUrl,
              hyperlink: locationUrl,
              tooltip: locationUrl,
            };
          }
          return locationUrl;
        }

        // Use Excel TRUE and FALSE for XLSX
        if (format === EXPORT_FORMAT.XLSX) {
          if (column.id === 'selfieUrl') {
            return {
              text: data[column.id],
              hyperlink: data[column.id],
              tooltip: data[column.id],
            };
          }

          if (
            column.id === 'checkInTimeDiscrepancy' ||
            column.id === 'checkOutTimeDiscrepancy'
          ) {
            return data[column.id] === 'yes' ? 'TRUE' : '';
          }
        }

        if (timetrackingQuestionsMap[column.id]) {
          return _.map(
            data[column.id],
            answer => `clock-${answer.type}: "${answer.answer}"`
          ).join(', ');
        }

        // default case
        return data[column.id];
      });
    });

    let columnFormats = null;
    if (format === EXPORT_FORMAT.XLSX) {
      columnFormats = currentColumns.map(column => {
        // START -  info for split clock in/out times
        if (columnDataDefinition[column.id]) {
          return columnDataDefinition[column.id].xlsxFormat || null;
        }
        return null;
      });
    }

    return {
      columnHeaders: currentColumns.map(column => column.header),
      rows,
      columnFormats,
    };
  };

  const exportCSV = () => {
    runAnalytics('Contents', {
      contentAction: 'Export Content',
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Admin Timetracking',
    });

    const { columnHeaders, rows } = generateFormattedExportTable();

    const csvOutput = Papa.unparse({
      fields: columnHeaders,
      data: rows,
    });

    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });

    const filename = `time-export-${moment().format(
      'YYYY-MM-DD-HH-mm-ss'
    )}.csv`;

    downloadBlob(blob, filename);
  };

  const generatePayrollReport = async () => {
    runAnalytics('Contents', {
      contentAction: 'Payroll Report',
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Admin Timetracking',
    });

    const companyInfo = getCompanyInfo
      ? _.find(getCompanyInfo, {
          companyId: managingCompanyInfo.managingCompanyId,
        })
      : null;

    const companyName = _.get(companyInfo, 'companyName') || '';

    const workbook = new ExcelJs.Workbook();

    workbook.creator = 'Check The Level';
    workbook.lastModifiedBy = 'Check The Level';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const formatTitleRow = cell => {
      const thisCell = cell;
      thisCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00cccccc' },
      };
      thisCell.font = { italic: true };
    };

    const formatSubtotalRow = cell => {
      const thisCell = cell;
      thisCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00eeeeee' },
      };
      thisCell.font = { italic: true };
    };

    const formatTotalRow = cell => {
      const thisCell = cell;
      thisCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00cccccc' },
      };
      thisCell.font = { bold: true };
    };

    // Add worksheets to workbook
    // Initialize employee time grouped by day
    const employeeTimeGroupedByDayWorksheet = workbook.addWorksheet(
      'Time Grouped by Day'
    );

    employeeTimeGroupedByDayWorksheet.columns = [
      { key: 'username', header: 'Username', width: 25 },
      { key: 'firstName', header: 'First Name', width: 25 },
      { key: 'lastName', header: 'Last Name', width: 25 },
      {
        key: 'clockInDate',
        header: 'Clock-in Date',
        numFmt: DEFAULT_DATE_FORMAT,
        width: 25,
      },
      { key: 'clockInDay', header: 'Clock-in Day', width: 25 },
      { key: 'hoursWorked', header: 'Hours Worked (h)', width: 25 },
    ];

    employeeTimeGroupedByDayWorksheet.getRow(1).eachCell(formatTitleRow);

    // Initialize project grouping sheet
    const employeeProjectGroupingWorksheet = workbook.addWorksheet(
      'Time Grouped by Project'
    );

    employeeProjectGroupingWorksheet.columns = [
      { key: 'username', header: 'Username', width: 25 },
      { key: 'firstName', header: 'First Name', width: 25 },
      { key: 'lastName', header: 'Last Name', width: 25 },
      { key: 'projectName', header: 'Project Name', width: 50 },
      { key: 'customerName', header: 'Customer Name', width: 25 },
      { key: 'hoursWorked', header: 'Hours Worked (h)', width: 25 },
    ];

    employeeProjectGroupingWorksheet.getRow(1).eachCell(formatTitleRow);

    // Initialize employee responses grouped by day
    const employeeResponsesGroupedByDayWorksheet = workbook.addWorksheet(
      'Responses Grouped by Day'
    );

    employeeResponsesGroupedByDayWorksheet.columns = [
      { key: 'username', header: 'Username', width: 25 },
      { key: 'firstName', header: 'First Name', width: 25 },
      { key: 'lastName', header: 'Last Name', width: 25 },
      { key: 'projectName', header: 'Project Name', width: 50 },
      { key: 'date', header: 'Date', width: 25, numFmt: DEFAULT_DATE_FORMAT },
      { key: 'question', header: 'Question', width: 50 },
      { key: 'answer', header: 'Answer', width: 50 },
    ];

    employeeResponsesGroupedByDayWorksheet.getRow(1).eachCell(formatTitleRow);

    const timeEntriesWorksheet = workbook.addWorksheet('Time Entries');

    // Generate export data
    const { columnHeaders, rows, columnFormats } = generateFormattedExportTable(
      EXPORT_FORMAT.XLSX
    );

    // Set formats
    columnFormats.forEach((columnFormat, columnIndex) => {
      if (columnFormat) {
        timeEntriesWorksheet.getColumn(columnIndex + 1).numFmt = columnFormat;
      }
    });

    // Add to worksheet
    timeEntriesWorksheet.addRow(columnHeaders);
    timeEntriesWorksheet.getRow(1).eachCell(formatTitleRow);

    // Add data rows
    timeEntriesWorksheet.addRows(rows);

    // Get all users
    const userMap = {};
    const tableDataRows = _.cloneDeep(gridRef.current.data);
    tableDataRows.forEach(({ username, firstName, lastName }) => {
      if (!userMap[username]) {
        userMap[username] = { firstName, lastName };
      }
    });

    // Put usernames in order
    const orderedUsernames = _.sortBy(_.keys(userMap), key =>
      key.toLowerCase()
    );

    let grandTotalHours = 0;
    _.forEach(orderedUsernames, username => {
      const usersTableDataRows = _.filter(
        tableDataRows,
        ({ username: rowUsername }) => username === rowUsername
      );

      let totalHoursWorked = 0;

      // Build project grouping rows
      const totalProjectTime = {};
      const totalDateTime = {};
      const responsesByProjectDate = {};

      usersTableDataRows.forEach(
        ({
          projectId,
          projectName,
          customerName,
          lengthOfShift,
          checkInTime,
          ...rest
        }) => {
          // Build project totals
          if (!totalProjectTime[projectId]) {
            totalProjectTime[projectId] = {
              projectName,
              customerName,
              hoursWorked: 0,
            };
          }
          totalProjectTime[projectId].hoursWorked += lengthOfShift;

          // Build day totals
          const checkInMoment = moment(checkInTime).utcOffset(0, true);
          const checkInDate = checkInMoment.format(DEFAULT_DATE_FORMAT);
          const checkInDay = checkInMoment.format('dddd');
          if (!totalDateTime[checkInDate]) {
            totalDateTime[checkInDate] = {
              date: checkInMoment.startOf('day').toDate(),
              dayOfWeek: checkInDay,
              hoursWorked: 0,
            };
          }
          totalDateTime[checkInDate].hoursWorked += lengthOfShift;

          _.each(rest, (value, key) => {
            if (
              timetrackingQuestionsMap[key] &&
              (value !== null || value !== undefined)
            ) {
              // Question with answer was found
              const question = timetrackingQuestionsMap[key].questionText;
              const answer = value;

              if (!responsesByProjectDate[projectId]) {
                responsesByProjectDate[projectId] = {
                  projectName,
                  checkInsByDate: {},
                };
              }

              const checkInMillis = checkInMoment.toDate().getTime();
              if (
                !responsesByProjectDate[projectId].checkInsByDate[checkInMillis]
              ) {
                responsesByProjectDate[projectId].checkInsByDate[
                  checkInMillis
                ] = [];
              }

              responsesByProjectDate[projectId].checkInsByDate[
                checkInMillis
              ].push({
                question,
                answer,
              });
            }
          });

          totalHoursWorked += lengthOfShift;
        }
      );

      const userDateTotals = _.sortBy(
        _.values(totalDateTime),
        ({ date }) => date
      );

      userDateTotals.forEach(({ date, dayOfWeek, hoursWorked }) => {
        employeeTimeGroupedByDayWorksheet.addRow([
          username,
          userMap[username].firstName,
          userMap[username].lastName,
          date,
          dayOfWeek,
          hoursWorked,
        ]);
      });

      const userTimeTotalRow = employeeTimeGroupedByDayWorksheet.addRow([
        `${username} Total`,
        '', // Required for formatting with each cell
        '', // Required for formatting with each cell
        '', // Required for formatting with each cell
        '', // Required for formatting with each cell
        totalHoursWorked,
      ]);

      userTimeTotalRow.eachCell(formatSubtotalRow);

      const userProjectTotals = _.sortBy(
        _.values(totalProjectTime),
        ({ projectName }) => projectName.toLowerCase()
      );

      userProjectTotals.forEach(
        ({ projectName, customerName, hoursWorked }) => {
          employeeProjectGroupingWorksheet.addRow([
            username,
            userMap[username].firstName,
            userMap[username].lastName,
            projectName,
            customerName,
            hoursWorked,
          ]);
        }
      );

      const userProjectTotalRow = employeeProjectGroupingWorksheet.addRow([
        `${username} Total`,
        '', // Required for formatting with each cell
        '', // Required for formatting with each cell
        '', // Required for formatting with each cell
        '', // Required for formatting with each cell
        totalHoursWorked,
      ]);

      userProjectTotalRow.eachCell(formatSubtotalRow);

      const sortedProjectResponses = _.sortBy(
        _.values(responsesByProjectDate),
        ({ projectName }) => projectName.toLowerCase()
      );

      sortedProjectResponses.forEach(({ projectName, checkInsByDate }) => {
        const projectCheckInKeys = _.keys(checkInsByDate)
          .map(checkInMillis => parseInt(checkInMillis, 10))
          .sort();

        projectCheckInKeys.forEach(projectCheckInMillis => {
          const checkInQuestionAnswerPairs =
            checkInsByDate[projectCheckInMillis];

          const sortedQuestionAnswerPairs = _.sortBy(
            checkInQuestionAnswerPairs,
            ({ question }) => question.toLowerCase()
          );

          sortedQuestionAnswerPairs.forEach(({ question, answer }) => {
            employeeResponsesGroupedByDayWorksheet.addRow([
              username,
              userMap[username].firstName,
              userMap[username].lastName,
              projectName,
              new Date(projectCheckInMillis),
              question,
              answer,
            ]);
          });
        });
      });

      grandTotalHours += totalHoursWorked;
    });

    const grandTimeTotalRow = employeeTimeGroupedByDayWorksheet.addRow([
      `Grand Total`,
      '', // Required for formatting with each cell
      '', // Required for formatting with each cell
      '', // Required for formatting with each cell
      '', // Required for formatting with each cell
      grandTotalHours,
    ]);

    grandTimeTotalRow.eachCell(formatTotalRow);

    const grandProjectTotalRow = employeeProjectGroupingWorksheet.addRow([
      `Grand Total`,
      '', // Required for formatting with each cell
      '', // Required for formatting with each cell
      '', // Required for formatting with each cell
      '', // Required for formatting with each cell
      grandTotalHours,
    ]);

    grandProjectTotalRow.eachCell(formatTotalRow);

    // Download
    const workbookBuffer = await workbook.xlsx.writeBuffer();
    const workbookBlob = new Blob([workbookBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const filename = `${moment(payPeriodInfo.firstDay.date).format(
      'YYYYMMDD'
    )} - ${moment(payPeriodInfo.lastDay.date).format(
      'YYYYMMDD'
    )} ${companyName} Payroll Report.xlsx`;

    downloadBlob(workbookBlob, filename);
  };

  const calcLengthStats = () => {
    let total = 0;
    const entriesCount = gridRef.current.data.length;
    gridRef.current.data.forEach(data => {
      total += data.lengthOfShift || 0;
    });
    total = Number.parseFloat(total).toFixed(2);
    const avg = Number.parseFloat(total / entriesCount).toFixed(2);
    setEntriesStats({ total, avg, count: entriesCount });
    setStatsDialogInfo({ open: true });
  };

  const openAddModal = () => {
    setAddEditOptions({
      open: true,
      checkInContent: null,
      checkOutContent: null,
      projectToAddTo: null,
    });
  };

  const handleStatDialogClose = () => {
    setStatsDialogInfo({
      ...statsDialogInfo,
      open: false,
    });
  };

  const handleIncludeArchiveChange = event => {
    setIncludeArchive(event.target.checked);
  };

  const handleDurationFormatChange = event => {
    setHhmmDuration(event.target.checked);
  };

  const copyThis = textToCopy => {
    copyToClipboard(textToCopy);
  };

  const handleFirstDayChange = firstDay => {
    setPayPeriodInfo({
      ...payPeriodInfo,
      firstDay: {
        ...payPeriodInfo.firstDay,
        date: firstDay,
      },
    });
  };

  const clearFirstDay = () => {
    setPayPeriodInfo({
      ...payPeriodInfo,
      firstDay: {
        ...payPeriodInfo.firstDay,
        date: null,
      },
    });
  };

  const handleLastDayChange = lastDay => {
    setPayPeriodInfo({
      ...payPeriodInfo,
      lastDay: {
        ...payPeriodInfo.lastDay,
        date: lastDay,
      },
    });
  };
  const clearLastDay = () => {
    setPayPeriodInfo({
      ...payPeriodInfo,
      lastDay: {
        ...payPeriodInfo.lastDay,
        date: null,
      },
    });
  };

  const triggerPayPeriodFields = () => {
    setShowPayPeriodFields(true);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showPreferencesMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closePreferencesMenu = () => {
    setMenuAnchorEl(null);
  };

  const saveColumnPrefs = () => {
    const allColumnInfo = gridRef.current.columnsMap;
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { timetracking: allColumnInfo },
    });
    closePreferencesMenu();
  };

  const clearColumnPrefs = () => {
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { timetracking: null },
    });
    // rebuild the table now
    buildDataGrid({ withoutPreferences: true });
    closePreferencesMenu();
  };

  const respondToMove = ({ msg, idOfProjectMovedTo }) => {
    if (msg === 'success') {
      // check if the current list of projects includes the idOfProjectMovedTo and if not, then refresh the data from the server
      if (!_.find(timetrackingProjects, { contentId: idOfProjectMovedTo })) {
        // refresh data
        timetrackingDataRefetch();
      }
    }
  };

  const scrollProps = {
    ...ReactDataGrid.defaultProps.scrollProps,
    autoHide: false,
    scrollThumbWidth: 12,
    scrollThumbStyle: {
      background: themePalette.brandColorPrimary,
    },
  };

  return (
    <div className={classes.scrollableColumn}>
      {timetrackingProjects &&
      timetrackingItems &&
      timetrackingUsers &&
      !timetrackingDataLoading &&
      tableReady ? (
        <>
          <div style={{ flex: 0, background: '#eee' }}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.headerWrapper}
              spacing={1}
            >
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item>
                  <AdminToolsTitle
                    Icon={CONTENT_DEFINITION[CONTENT_TYPE.TIMETRACKING].Icon}
                    titleText="Manage Time-Tracking"
                  />
                </Grid>
                <Grid item className={classes.actionButtonsContainer}>
                  <ButtonWithTooltip
                    tooltipText="Pay period is required"
                    onClick={() => generatePayrollReport()}
                    disabled={
                      !(
                        payPeriodInfo.firstDay.date &&
                        payPeriodInfo.lastDay.date
                      )
                    }
                  >
                    Payroll Report
                  </ButtonWithTooltip>

                  <AdminToolsIconButton
                    tooltipText="Export to Excel/CSV"
                    onClick={() => {
                      exportCSV();
                    }}
                  >
                    <PublishIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View stats for current data"
                    onClick={calcLengthStats}
                  >
                    <EqualizerIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="Add an item"
                    onClick={openAddModal}
                  >
                    <AddIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View other options"
                    onClick={showPreferencesMenu}
                  >
                    <MoreHorizIcon />
                  </AdminToolsIconButton>
                  <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={closePreferencesMenu}
                  >
                    <MenuItem onClick={saveColumnPrefs}>
                      <SaveIcon />
                      &nbsp;&nbsp;Save Column Preferences
                    </MenuItem>
                    <MenuItem onClick={clearColumnPrefs}>
                      <BlockIcon />
                      &nbsp;&nbsp;Clear Column Preferences
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {!showPayPeriodFields ? (
                  <Button
                    type="button"
                    onClick={triggerPayPeriodFields}
                    className={classes.setPayPeriodButton}
                  >
                    Set Pay Period
                  </Button>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid
                        item
                        xs={12}
                        className={classes.payPeriodDateWrapper}
                      >
                        <InputLabel className={classes.labelBasics}>
                          First Day:
                        </InputLabel>
                        <DatePicker
                          name={payPeriodInfo.firstDay.name}
                          value={payPeriodInfo.firstDay.date}
                          // helperText={payPeriodInfo.firstDay.helperText}
                          placeholder={payPeriodInfo.firstDay.placeholder}
                          error={payPeriodInfo.firstDay.errorMsg}
                          onChange={handleFirstDayChange}
                          variant="inline"
                          format="ddd MMM D, YYYY"
                          fullWidth
                          autoOk
                          InputProps={
                            payPeriodInfo.firstDay.date && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={clearFirstDay}>
                                    <HighlightOffIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.payPeriodDateWrapper}
                      >
                        <InputLabel className={classes.labelBasics}>
                          Last Day
                        </InputLabel>
                        <DatePicker
                          name={payPeriodInfo.lastDay.name}
                          value={payPeriodInfo.lastDay.date}
                          // helperText={payPeriodInfo.lastDay.helperText}
                          placeholder={payPeriodInfo.lastDay.placeholder}
                          error={payPeriodInfo.lastDay.errorMsg}
                          onChange={handleLastDayChange}
                          variant="inline"
                          format="ddd MMM D, YYYY"
                          fullWidth
                          autoOk
                          InputProps={
                            payPeriodInfo.lastDay.date && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={clearLastDay}>
                                    <HighlightOffIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                style={{ justifyContent: 'flex-end', display: 'flex' }}
              >
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={includeArchive}
                      onChange={handleIncludeArchiveChange}
                    />
                  }
                  label="Include archived projects"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={hhmmDuration}
                      onChange={handleDurationFormatChange}
                    />
                  }
                  label="Duration as hh:mm"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </div>

          {!!timetrackingQueryNote && (
            <Grid container style={{ marginBottom: 32 }}>
              <ResponseTooBigWarning
                managingCompanyId={managingCompanyInfo?.managingCompanyId}
              />
            </Grid>
          )}

          <div style={{ flex: 1, height: 200 }}>
            <ReactDataGrid
              key={tableKey}
              // grab the ref
              onReady={setGridRef}
              // set which property is used as the ID
              idProperty="contentId"
              // set which columns show
              columns={columns}
              // column order
              defaultColumnOrder={columnOrder}
              // set the data to build from
              dataSource={dataSource}
              // set basic styling for the overall table
              style={gridStyle}
              className="reactDataGridFixLastItemOverlap"
              // filtering
              enableFiltering
              defaultFilterValue={filterValue}
              filterTypes={filterTypes}
              // sorting
              defaultSortInfo={defaultSortInfo}
              allowUnsort={false}
              // scrollbar
              scrollProps={scrollProps}
              headerHeight={0}
            />
          </div>
        </>
      ) : (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Loading data from across your projects...
          </Typography>
          {!!includeArchive && (
            <Typography variant="h4" align="center" style={{ marginTop: 8 }}>
              Including archived data can take a while to process. Please give
              us a few moments.
            </Typography>
          )}
        </LoadingCover>
      )}
      {!!showLoadingCover.open && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            {showLoadingCover.message}
          </Typography>
        </LoadingCover>
      )}
      {addEditOptions.open && (
        <AddEditTimetracking
          open
          onClose={() => setAddEditOptions({ ...addEditOptions, open: false })}
          projectToAddToProp={addEditOptions.projectToAddToProp}
          checkInContent={addEditOptions.checkInContent}
          checkOutContent={addEditOptions.checkOutContent}
          projects={timetrackingProjects}
          allMyProjects={companyProjects}
          assigneeInfo={addEditOptions.assigneeInfo}
          includeArchive={includeArchive}
        />
      )}
      {dialogInfo.open && (
        <OkCancelDialog
          title={dialogInfo.title}
          open={dialogInfo.open}
          onClose={dialogInfo.onClose}
          hideCancel={dialogInfo.hideCancel}
          onConfirm={dialogInfo.onConfirm}
        >
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {showMoveDialog.open && (
        <MoveTimetrackingEntryDialog
          showMoveDialog={showMoveDialog}
          setShowMoveDialog={setShowMoveDialog}
          setShowLoadingCover={setShowLoadingCover}
          callback={respondToMove}
        />
      )}
      {statsDialogInfo.open && (
        <Dialog
          open
          onClose={handleStatDialogClose}
          aria-labelledby="data stats"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Typography variant="h6" className={classes.statText}>
              <span className={classes.statModalLabel}>Sum</span>
              <Tooltip title="Click to copy">
                <Button
                  onClick={() => {
                    copyThis(entriesStats.total);
                  }}
                >
                  {entriesStats.total} hr{entriesStats.total > 1 && 's'}
                </Button>
              </Tooltip>
            </Typography>
            <Typography variant="h6" className={classes.statText}>
              <span className={classes.statModalLabel}>Count</span>
              <Tooltip title="Click to copy">
                <Button
                  onClick={() => {
                    copyThis(entriesStats.count);
                  }}
                >
                  {entriesStats.count} entries
                </Button>
              </Tooltip>
            </Typography>
            <Typography variant="h6" className={classes.statText}>
              <span className={classes.statModalLabel}>
                Avg Hours Per Shift
              </span>
              <Tooltip title="Click to copy">
                <Button
                  onClick={() => {
                    copyThis(entriesStats.avg);
                  }}
                >
                  {entriesStats.avg} hr
                  {entriesStats.avg > 1 && 's'}
                </Button>
              </Tooltip>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleStatDialogClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  const columnSettings = _.get(
    state,
    'appState.adminToolsSettings.timetracking',
    null
  );

  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
    columnSettings,
  };
}

export default compose(
  DeleteContentAction,
  GetCompanyInfoAction
)(connect(mapStateToProps)(ManageTime));
