const INITIAL_STATE = {
  isLoggedIn: false,
  redirectToOnSignOut: null,
  onboardingUserRole: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SIGNIN_SUCCEEDED': {
      return { ...state, ...action.payload?.auth };
    }

    case 'SIGNOUT_SUCCEEDED': {
      const redirectTo =
        action && action.payload && action.payload.redirectToOnSignOut
          ? action.payload.redirectToOnSignOut
          : null;
      const newState = { ...INITIAL_STATE };

      if (redirectTo) {
        newState.redirectToOnSignOut = redirectTo;
      }

      return newState;
    }

    case 'SET_ONBOARDING_USER_ROLE': {
      return {
        ...state,
        onboardingUserRole: action.payload?.userRole || null,
      };
    }

    default:
      return state;
  }
};
