import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

import amplitude from 'amplitude-js';

import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { CloudinaryContext } from 'cloudinary-react';
import awsConfig from './config/aws-exports';

import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import validators from './common/validators';

import store, { persistor } from './store';

// eslint-disable-next-line import/no-cycle
import App from './App';
import { cloudindaryCloudName } from './config/appDefaults';

import * as serviceWorker from './serviceWorker';

import './index.scss';

validate.validators = {
  ...validate.validators,
  ...validators,
};

try {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
} catch (err) {
  // Analytics call messed up
}
Auth.configure(awsConfig);

export const appsyncClient = new AWSAppSyncClient({
  disableOffline: true,
  offlineConfig: {
    keyPrefix: 'privateAppsync',
  },
  cacheOptions: {
    dataIdFromObject: object => {
      // eslint-disable-next-line no-underscore-dangle
      switch (object.__typename) {
        case 'Jrn':
          return object.contentId; // use `jrnId` as the primary key
        case 'Content':
          return object.contentId;
        case 'Customer':
          return object.customerId;
        case 'Company':
          return object.companyId;
        case 'SimpleUser':
          return object.userId;
        case 'SimpleJrn':
          return object.contentId;
        case 'SimpleTimetracking':
          return object.contentId;
        case 'SimpleCustomer':
          return object.customerId;
        case 'FriendRequest':
          return object.requestId;
        case 'Request':
          return object.requestId;
        case 'Rfi':
          return object.requestId;
        case 'User':
          return object.userId;
        case 'Message':
          return object.messageId;
        case 'NotificationPrefsInfo':
          return object.requestorId;
        case 'FinancialAccount':
          return object.accountId;
        case 'CompanyShoeboxItem':
          return object.contentId;
        case 'Relation':
          return object.relationId;
        case 'BookkeepingReportResponse':
          return object.snapshotId;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
  },
  url: awsConfig.aws_appsync_graphqlEndpoint,
  region: awsConfig.aws_appsync_region,
  auth: {
    type: awsConfig.aws_appsync_authenticationType,
    jwtToken: async () => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          return session.getAccessToken().getJwtToken();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('session issue:', e);
      }
      return false;
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={appsyncClient}>
    <ApolloHooksProvider client={appsyncClient}>
      <Provider store={store}>
        <CloudinaryContext cloudName={cloudindaryCloudName}>
          <Rehydrated>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                maxSnack={3}
              >
                <Router>
                  <PersistGate loading={null} persistor={persistor}>
                    <App />
                  </PersistGate>
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          </Rehydrated>
        </CloudinaryContext>
      </Provider>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
