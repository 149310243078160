import React, { useContext } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import { useStyles } from './onboarding.styles';
import OnboardingContext from './onboarding.context';
import { ONBOARDING_STEP } from './onboarding.constants';

const OnboardingStepButtons = ({ allowGoToNext, nextAction, showLoading }) => {
  const classes = useStyles();
  const { steps, activeStep, setActiveStep, onOnboardingComplete } = useContext(
    OnboardingContext
  );

  if (steps[activeStep] === ONBOARDING_STEP.FINAL_IS_MOBILE) {
    return null;
  }

  const handleBack = () => {
    setActiveStep(currentStep => currentStep - 1);
  };

  const handleSubmit = async () => {
    if (nextAction) {
      await nextAction();
    }

    // on the last step, update user info and managing company info in redux store
    if (activeStep === steps.length - 1) {
      await onOnboardingComplete();
    }

    setActiveStep(currentStep => currentStep + 1);
  };

  return (
    <Grid container item justifyContent="center" style={{ marginTop: 24 }}>
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={!allowGoToNext}
        onClick={allowGoToNext ? handleSubmit : null}
        className={classes.button}
      >
        {showLoading && <CircularProgress size={24} color="inherit" />}
        {!showLoading && (activeStep !== steps.length - 1 ? 'Next' : 'Finish')}
      </Button>
    </Grid>
  );
};

export default OnboardingStepButtons;
