import gql from 'graphql-tag';

import * as Fragment from '../fragments';

export default gql`
  mutation ConnectToMazumaGo($companyId: String!) {
    connectToMazumaGo(input: { companyId: $companyId }) {
      ...CompanyFields
    }
  }
  ${Fragment.Company}
`;
