import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';

import camWave from '../../../assets/images/cam/copilot_mascot_003.png';
import { ACTIVITY_STATUS } from '../../../config/appDefaults';
import GetCompanyInfo from '../../../graphql/queries/get-company-info';
import OkCancelDialog from '../../OkCancelDialog/okCancelDialog';
import styles from '../bookkeeping-alerts.styles';
import { BOOKKEEPING_ALERT_SETTINGS_LAYOUT } from './bookkeeping-alerts-settings-layout';
import BookkeepingAlertsSettingsListHeader from './bookkeeping-alerts-settings-list-header';
import BookkeepingAlertsSettingsListItem from './bookkeeping-alerts-settings-list-item';
import {
  WIDTH_OF_ALERT_TOGGLE_COLUMN,
  WIDTH_OF_WORDING_COLUMN,
} from './bookkeeping-alerts-settings.constants';
import { BOOKKEEPING_ALERT_SETTINGS_INFO } from './bookkeeping-report-settings-info';

const useStyles = makeStyles(styles);

const BookkeepingAlertsSettingsList = ({
  alerts,
  alertLists,
  refetchAlerts,
  refetchRules,
  rules,
  onChange,
  setListRef,
  setHeaderWrapperRef,
  onEditListNameButtonClick,
  loading,
  managingCompanyInfo,
  bookkeepingAlertPreferences,
  refetchPreferences,
}) => {
  const classes = useStyles();
  const [
    isScanningQboUsageForRuleSetup,
    setIsScanningQboUsageForRuleSetup,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const previousScanningStatusRef = useRef(null);

  const [showFinalMessage, setShowFinalMessage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFinalMessage(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const companyInfoQuery = useQuery(GetCompanyInfo, {
    fetchPolicy: 'cache-and-network',
  });

  const getCompanyInfo = _.get(
    companyInfoQuery,
    'data.getCompanyInfo.items',
    null
  );

  const companyInfo = useMemo(() => {
    if (!managingCompanyInfo?.managingCompanyId || !getCompanyInfo) {
      return null;
    }

    return _.find(getCompanyInfo, {
      companyId: managingCompanyInfo.managingCompanyId,
    });
  }, [getCompanyInfo, managingCompanyInfo]);

  useEffect(() => {
    const checkActivityStatus = async () => {
      const scanStatus = companyInfo?.qboUsageBasedRulesActivityInfo?.status;
      const previousScanStatus = previousScanningStatusRef.current;

      previousScanningStatusRef.current = scanStatus;

      if (scanStatus) {
        const isScanPending = scanStatus === ACTIVITY_STATUS.PENDING;

        if (isScanPending) {
          setIsScanningQboUsageForRuleSetup(isScanPending);
          companyInfoQuery.startPolling(5000);
          return;
        }

        companyInfoQuery.stopPolling();

        if (scanStatus === ACTIVITY_STATUS.ERROR) {
          if (isScanningQboUsageForRuleSetup) {
            // only show the error message if we are currently scanning for rule setup (avoid showing the error message every time the page loads)
            setErrorMessage(
              'We ran into an issue while customizing your rules. Instead, we set a default set of rules for your type of business.'
            );
          }
        }

        // only refetch the rules, alerts, and preferences
        // if the scanStatus is changeed from PENDING to SUCCESS
        if (
          scanStatus === ACTIVITY_STATUS.SUCCESS &&
          previousScanStatus === ACTIVITY_STATUS.PENDING &&
          refetchRules &&
          refetchAlerts &&
          refetchPreferences
        ) {
          await Promise.all([
            refetchRules(),
            refetchAlerts(),
            refetchPreferences(),
          ]);
        }

        if (!isScanPending) {
          setIsScanningQboUsageForRuleSetup(false);
        }
      }
    };

    checkActivityStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    companyInfo?.backfillActivityInfo?.status,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    companyInfo?.qboUsageBasedRulesActivityInfo?.status,
  ]);

  const rulesByAlertIdentifier = useMemo(() => {
    return _.groupBy(rules, 'alertIdentifier');
  }, [rules]);

  const alertsByAlertIdentifier = useMemo(() => {
    return _.groupBy(alerts, 'alertIdentifier');
  }, [alerts]);

  const numberOfAlertColumns = _.size(alertLists);
  const totalColumns = numberOfAlertColumns + 1; // 1 for the enabled (RULE) column
  const totalWidth =
    WIDTH_OF_WORDING_COLUMN + WIDTH_OF_ALERT_TOGGLE_COLUMN * totalColumns;
  return (
    <div
      ref={setListRef}
      style={{ width: totalWidth, minWidth: '100%', background: '#fff' }}
    >
      <div
        ref={setHeaderWrapperRef}
        style={{ width: totalWidth, minWidth: '100%', position: 'relative' }}
      >
        <BookkeepingAlertsSettingsListHeader
          alertLists={alertLists}
          onEditListNameButtonClick={onEditListNameButtonClick}
          width={totalWidth}
        />
      </div>
      {!!errorMessage && (
        <OkCancelDialog
          open
          okButtonText="Ok"
          hideCancel
          dividers={false}
          loaderStyle={{ opacity: 1 }}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        >
          <Typography>{errorMessage}</Typography>
        </OkCancelDialog>
      )}

      {!!isScanningQboUsageForRuleSetup && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 60, textAlign: 'center', paddingBottom: 64 }}
        >
          <Grid item xs={12} sm={8} container justifyContent="center">
            <Grid item xs={12} sm={2}>
              <img
                src={camWave}
                alt="CAM waving"
                style={{ height: 80, marginBottom: 16 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              container
              direction="column"
              alignItems="center"
            >
              <LinearProgress style={{ width: '75%', marginBottom: 16 }} />

              <Typography variant="h5" align="center">
                We&apos;re currently scanning your QuickBooks Online usage to
                build a set of rules customized to your business!
              </Typography>
              {showFinalMessage && (
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    marginTop: 32,
                    background: 'rgb(9, 153, 8)',
                    color: '#fff',
                    padding: '16px',
                    borderRadius: 10,
                  }}
                >
                  Almost done! Your rules will show up automatically once
                  they&apos;re ready.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      {!loading &&
        !isScanningQboUsageForRuleSetup &&
        BOOKKEEPING_ALERT_SETTINGS_LAYOUT.map(
          ({ group: groupName, alerts: alertsInGroup }) => {
            return (
              <React.Fragment key={groupName}>
                <Grid container direction="column">
                  <Grid item className={classes.listSubheader}>
                    {groupName}
                  </Grid>
                  {alertsInGroup
                    .filter(alertIdentifier => {
                      if (!BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier]) {
                        // eslint-disable-next-line no-console
                        console.warn(
                          `WARN: No settings info entry for ${alertIdentifier}`
                        );
                        return false;
                      }

                      return true;
                    })
                    .map(alertIdentifier => {
                      return (
                        <BookkeepingAlertsSettingsListItem
                          key={alertIdentifier}
                          alertIdentifier={alertIdentifier}
                          filteredRules={
                            rulesByAlertIdentifier[alertIdentifier]
                          }
                          filteredAlerts={
                            alertsByAlertIdentifier[alertIdentifier]
                          }
                          alertLists={alertLists}
                          subs={
                            BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier]
                              .subs
                          }
                          generalSettings={
                            BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier]
                              .generalSettings
                          }
                          bookkeepingAlertPreferences={
                            bookkeepingAlertPreferences
                          }
                          onChange={onChange}
                        />
                      );
                    })}
                </Grid>
              </React.Fragment>
            );
          }
        )}
    </div>
  );
};
export default BookkeepingAlertsSettingsList;
