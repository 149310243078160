import _ from 'lodash';
import {
  BOOKKEEPING_ALERT,
  BOOKKEEPING_ALERT_ENTITY_TYPE,
  BOOKKEEPING_ALERT_ENTITY_TYPE_PRETTY_AND_PLURAL,
  IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER,
} from '../bookkeeping-alerts.constants';
import { BOOKKEEPING_REPORT_ALERT_DICTIONARY } from '../bookkeeping-alert-dictionary';

const ignoreUndepositedFundsOption = {
  label: 'Ignore Undeposited Funds transactions',
  value: IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER,
};

const QBO_ACCOUNT_TYPE_OPTIONS = [
  { label: 'Bank', value: 'bank' },
  { label: 'Accounts Receivable', value: 'accountsReceivable' },
  { label: 'Other Current Assets', value: 'otherCurrentAssets' },
  { label: 'Fixed Asset', value: 'fixedAsset' },
  { label: 'Other Assets', value: 'otherAssets' },
  { label: 'Accounts Payable', value: 'accountsPayable' },
  { label: 'Credit Card', value: 'creditCard' },
  { label: 'Other Current Liabilities', value: 'otherCurrentLiabilities' },
  { label: 'Long Term Liabilities', value: 'longTermLiabilities' },
  { label: 'Equity', value: 'equity' },
  { label: 'Income', value: 'income' },
  { label: 'Cost Of Goods Sold', value: 'costOfGoodsSold' },
  { label: 'Expenses', value: 'expenses' },
  { label: 'Other Income', value: 'otherIncome' },
  { label: 'Other Expense', value: 'otherExpense' },
];

const labelAndValueArrayForEntityTypes = entityTypes => {
  const labelAndValues = _.map(entityTypes, entityType => ({
    label: BOOKKEEPING_ALERT_ENTITY_TYPE_PRETTY_AND_PLURAL[entityType],
    value: entityType,
  }));

  return labelAndValues;
};

const FISCAL_YEAR_START_MONTH_TO_LAST_MONTH_MAP = {
  January: 'December',
  February: 'January',
  March: 'February',
  April: 'March',
  May: 'April',
  June: 'May',
  July: 'June',
  August: 'July',
  September: 'August',
  October: 'September',
  November: 'October',
  December: 'November',
};

const BOOKKEEPING_ALERT_SETTINGS_INFO = {
  [BOOKKEEPING_ALERT.NEW_VENDOR]: {
    headline:
      'Check if a new vendor has been created in the accounting software.',
  },
  [BOOKKEEPING_ALERT.JOURNAL_ENTRY_CREATED_OR_MODIFIED]: {
    headline:
      'Check if journal entries have been created or modified within your accounting software.',
  },
  [BOOKKEEPING_ALERT.TRANSACTIONS_MISSING_VENDOR_CUSTOMER_INFORMATION]: {
    headline:
      'Check if an Expense, Income, Deposit or similar transaction that has been posted to your accounting software is missing a vendor or customer name.',
  },
  [BOOKKEEPING_ALERT.DUPLICATE_EXPENSE_TRANSACTIONS]: {
    headline:
      'Compare expense transactions and open bills from the same supplier to check if an expense was duplicated or not applied against a bill.',
  },
  [BOOKKEEPING_ALERT.MISSING_BILL_NUMBERS]: {
    headline: 'Check if a bill was entered without a bill number.',
  },
  [BOOKKEEPING_ALERT.DELETED_TRANSACTIONS]: {
    headline:
      'Check if transactions have been deleted. This can compromise the accuracy of your accounting data.',
    subs: labelAndValueArrayForEntityTypes([
      BOOKKEEPING_ALERT_ENTITY_TYPE.ACCOUNT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.DEPOSIT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.ESTIMATE,
      BOOKKEEPING_ALERT_ENTITY_TYPE.PURCHASE,
      BOOKKEEPING_ALERT_ENTITY_TYPE.INVOICE,
      BOOKKEEPING_ALERT_ENTITY_TYPE.JOURNAL_ENTRY,
      BOOKKEEPING_ALERT_ENTITY_TYPE.PAYMENT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.SALES_RECEIPT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.REFUND_RECEIPT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.BILL_PAYMENT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.BILL,
      BOOKKEEPING_ALERT_ENTITY_TYPE.CREDIT_MEMO,
      BOOKKEEPING_ALERT_ENTITY_TYPE.TRANSFER,
      BOOKKEEPING_ALERT_ENTITY_TYPE.PURCHASE_ORDER,
    ]),
  },
  [BOOKKEEPING_ALERT.VOIDED_TRANSACTIONS]: {
    headline:
      'Check if transactions have been voided. This can compromise the accuracy of your accounting data.',
    subs: labelAndValueArrayForEntityTypes([
      BOOKKEEPING_ALERT_ENTITY_TYPE.INVOICE,
      BOOKKEEPING_ALERT_ENTITY_TYPE.PURCHASE,
      BOOKKEEPING_ALERT_ENTITY_TYPE.PAYMENT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.SALES_RECEIPT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.CREDIT_MEMO,
      BOOKKEEPING_ALERT_ENTITY_TYPE.REFUND_RECEIPT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.BILL_PAYMENT,
      BOOKKEEPING_ALERT_ENTITY_TYPE.TRANSFER,
    ]),
  },
  [BOOKKEEPING_ALERT.NEW_VENDOR_IN_ACCOUNT]: {
    headline:
      'Check if a chart of account item has been assigned a transaction with a new vendor or a vendor that has not been transacted with for at least 13 months.',
  },
  [BOOKKEEPING_ALERT.NEW_PRODUCT]: {
    headline: `Check if new products or services have been created in the accounting software.`,
  },
  [BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_PARENT_CUSTOMERS]: {
    headline:
      'Check if non-posting (e.g. estimates) or posting transactions have been recorded against parent customers.',
    generalSettings: [ignoreUndepositedFundsOption],
  },
  [BOOKKEEPING_ALERT.EXPENSE_TRANSACTIONS_WITH_CUSTOMERS_NOT_CODED_TO_COST_OF_SALES]: {
    headline:
      'Check if a transaction has been mistakenly treated as an overhead.',
  },
  [BOOKKEEPING_ALERT.COST_OF_SALES_TRANSACTIONS_MISSING_CUSTOMER_INFORMATION]: {
    headline:
      'Check if a transaction has been posted to cost of sales and it has not been assigned to a customer.',
  },
  [BOOKKEEPING_ALERT.NONBILLABLE_TRANSACTIONS_WITH_CUSTOMERS]: {
    headline:
      'Check if an expense, bill or check assigned to a customer has not been marked as billable.',
  },
  [BOOKKEEPING_ALERT.BILLABLE_EXPENSE_TRANSACTIONS_WITHOUT_ATTACHMENT]: {
    headline:
      'Check if an attachment has not been added to an expense or bill marked as billable.',
  },
  [BOOKKEEPING_ALERT.NONBILLABLE_TIME_ACTIVITY]: {
    headline:
      'Check if a time charge assigned to a customer has not been marked as billable.',
  },
  [BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_PARENT_ACCOUNTS]: {
    headline:
      'Parent accounts have been set up to account for a major category of financial transactions, where sub-accounts are used to break that major category down into more detail. Check if transactions have been coded to a parent account, which impacts the accuracy of your accounting data.',
    subs: QBO_ACCOUNT_TYPE_OPTIONS,
    generalSettings: [ignoreUndepositedFundsOption],
  },
  [BOOKKEEPING_ALERT.CLASS_ALERTS]: {
    headline:
      'Check if classes have been used to track transactions by department. Missing classes impacts the accuracy of your accounting data.',
    subs: QBO_ACCOUNT_TYPE_OPTIONS,
  },
  [BOOKKEEPING_ALERT.LOCATION_ALERTS]: {
    headline:
      'Chick if location has been used to track transactions by location. Missing location impacts the accuracy of your accounting data.',
    subs: QBO_ACCOUNT_TYPE_OPTIONS,
  },
  [BOOKKEEPING_ALERT.TRANSACTIONS_MISSING_PRODUCT_OR_SERVICE]: {
    headline:
      'Check if a transaction has been posted to an account without a product/service.',
    subs: QBO_ACCOUNT_TYPE_OPTIONS,
  },
  [BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_CLOSED_PERIOD]: {
    headline:
      'Check if a transaction has been posted to a closed bookkeeping period.',
    isNew: true,
    settingsFromPreferences: [
      {
        key: 'qbFiscalYearStartMonth',
        label: 'When is the last month of your business’s financial year?',
        isEditable: false,
        transformFunc: value => {
          return FISCAL_YEAR_START_MONTH_TO_LAST_MONTH_MAP[value];
        },
        valueNote: '(Note: pulled from QBO)',
      },
      {
        key: 'frequencyOfBookkeepingTasks',
        label:
          'How often do you typically perform your bookkeeping tasks for this company?',
        isEditable: true,
        transformFunc: value => value,
      },
      {
        key: 'daysAfterPeriodBookCompleted',
        label:
          'How many days after the end of your bookkeeping period do you aim to have the books completed / closed for this company?',
        isEditable: true,
        transformFunc: value => value,
      },
    ],
  },
  [BOOKKEEPING_ALERT.VENDORS_WITH_NEGATIVE_BALANCES]: {
    headline: `Check if a vendor's A/P balance has turned negative.`,
  },
  [BOOKKEEPING_ALERT.CUSTOMERS_WITH_NEGATIVE_BALANCES]: {
    headline: `Check if a customer's A/R balance has turned negative.`,
  },
};

_.merge(BOOKKEEPING_ALERT_SETTINGS_INFO, BOOKKEEPING_REPORT_ALERT_DICTIONARY);

export { BOOKKEEPING_ALERT_SETTINGS_INFO };
