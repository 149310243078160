import React, { useEffect } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';

const LevelNumberInput = ({ value, onChange, inlineLabel, disabled, min }) => {
  const [counterValue, setCounterValue] = React.useState(value || 0);

  useEffect(() => {
    setCounterValue(value);
  }, [value]);

  const handleChange = event => {
    let newVal = parseInt(event.target.value, 10);

    if (Number.isNaN(newVal)) {
      newVal = 0;
    }

    const valToSet = typeof min === 'number' && newVal < min ? min : newVal;
    onChange(valToSet);
    setCounterValue(valToSet);
  };

  const handleIncrement = () => {
    const newVal = parseInt(counterValue, 10) + 1;
    handleChange({ target: { value: newVal } });
  };

  const handleDecrement = () => {
    const newVal = parseInt(counterValue, 10) - 1;
    handleChange({ target: { value: newVal } });
  };

  return (
    <Grid container alignItems="center" style={{ width: '100%' }}>
      {!!inlineLabel && (
        <Grid item style={{ flex: 0, marginRight: 8 }}>
          {inlineLabel.replace(/ /g, '\u00a0')}
        </Grid>
      )}
      <Grid item style={{ flex: 1, position: 'relative' }}>
        <TextField
          value={counterValue}
          onChange={handleChange}
          type="number"
          style={{ width: '100%' }}
          inputProps={{
            style: {
              textAlign: 'center',
              flex: 1,
              paddingLeft: 36,
              paddingRight: 36,
              paddingTop: 8,
            },
          }}
          disabled={disabled}
        />
        <IconButton
          onClick={handleDecrement}
          style={{
            padding: 6,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
        <IconButton
          onClick={handleIncrement}
          style={{
            padding: 6,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default LevelNumberInput;
