import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Card,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  InfoOutlined as InfoIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { QboTools } from 'level-foundation';
import _ from 'lodash';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

import { monetaryRender } from '../../../helpers';
import { renderDateString } from '../../../helpers/renderDateString';
import palette from '../../../theme/palette';
import LoadingCover from '../../LoadingCover/loadingCover';
import { SELECT_ALL_VALUE } from '../../dashboard/dashboard-control-bar-select';
import { BOOKKEEPING_REPORT_ALERT_DICTIONARY } from '../bookkeeping-alert-dictionary';
import {
  BOOKKEEPING_ALERT,
  BOOKKEEPING_ALERT_REPORT_DATA_TYPE,
  IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER,
} from '../bookkeeping-alerts.constants';
import styles from '../bookkeeping-alerts.styles';
import BookkeepingAlertsReportAlertList from './bookkeeping-alerts-report-alert-list';

const useStyles = makeStyles(styles);

// FUTURE: create a snapshot test for the printing of this component
// FUTURE: add yarn test to pull request for CI/CD

const BookkeepingAlertsReport = ({
  reportDate,
  reportData,
  selectedAlertTypeId = SELECT_ALL_VALUE,
  addressedStateEventMetaMap,
  isCustomScan = false,
  showPrintButton = false,
  reportMetaData = {},
}) => {
  const classes = useStyles();

  const contentToPrintRef = useRef(null);

  const [processingAlertInstanceMap, setProcessingAlertInstanceMap] = useState(
    {}
  );

  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => contentToPrintRef.current,
    documentTitle: isCustomScan
      ? `Level Custom Scan Report_${moment(reportMetaData.startDate).format(
          'YYYYMMDD'
        )}_${moment(reportMetaData.endDate).format('YYYYMMDD')}_${Date.now()}`
      : `Level Bookkeeping Report_${moment(reportDate).format('YYYY-MM-DD')}`,
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const reportDataToUse = useMemo(() => {
    if (selectedAlertTypeId === SELECT_ALL_VALUE) {
      return reportData;
    }

    // If we have a selected alert type, we want to pick that and the summary (if it exists)
    const typesToPick = [selectedAlertTypeId];

    const alertInfo = BOOKKEEPING_REPORT_ALERT_DICTIONARY[selectedAlertTypeId];
    if (alertInfo?.summary) {
      typesToPick.push(alertInfo.summary);
    }

    return _.pick(reportData, typesToPick);
  }, [reportData, selectedAlertTypeId]);

  const hasAlerts = _.some(BOOKKEEPING_ALERT, alertIdentifier => {
    const alertInfo = BOOKKEEPING_REPORT_ALERT_DICTIONARY[alertIdentifier];

    if (!alertInfo) {
      return false;
    }

    if (isCustomScan && reportDataToUse?.[alertIdentifier]?.data?.length) {
      return true;
    }

    if (
      !isCustomScan &&
      !alertInfo.isSummarySection &&
      reportDataToUse?.[alertIdentifier]?.data?.length
    ) {
      // Return true if we have some alert data
      return true;
    }

    return false;
  });

  if (!hasAlerts) {
    const filterApplied = selectedAlertTypeId !== SELECT_ALL_VALUE;
    let messageEnding = 'period 🗓️';
    if (filterApplied) {
      messageEnding = 'alert type 📢';
    } else if (reportDate) {
      messageEnding = 'date 🗓️';
    }
    return (
      <Typography
        variant="h4"
        style={{
          background: '#f5f5f5',
          padding: 24,
          borderRadius: 20,
          alignSelf: 'flex-start',
          marginTop: 24,
        }}
      >
        {`🚫 No alerts reported for this ${messageEnding}`}
      </Typography>
    );
  }

  const renderAlert = alertIdentifier => {
    const alertInfo = BOOKKEEPING_REPORT_ALERT_DICTIONARY[alertIdentifier];

    if (!alertInfo || alertInfo?.isSummarySection) {
      return null;
    }

    const alertDetails = reportDataToUse?.[alertIdentifier];

    if (!isCustomScan && _.isEmpty(alertDetails?.data)) {
      return null;
    }

    if (isCustomScan) {
      // If no summary section and no alert data, don't render anything
      if (!alertInfo.summary && _.isEmpty(alertDetails?.data)) {
        return null;
      }

      // If we have a summary section, but no data in the summary section or in the alert section, don't render anything
      if (
        alertInfo.summary &&
        _.isEmpty(alertDetails?.data) &&
        _.isEmpty(reportDataToUse?.[alertInfo.summary]?.data)
      ) {
        return null;
      }
    }

    let numOfAlerts = 0;
    let absoluteValueOfTxns = 0;
    switch (alertDetails.type) {
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.USER_TXNS_ARRAY: {
        // User txns arrays are the only ones with amounts
        _.forEach(alertDetails.data, userTxns => {
          const txns = userTxns.data;
          if (txns[0]?.type === BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TXN_INFO) {
            const idMap = {};

            let idPropName = 'alertInstanceId';

            if (isCustomScan) {
              idPropName = 'txnId';
            }

            _.forEach(txns, txn => {
              idMap[txn[idPropName]] = true;
              absoluteValueOfTxns += Math.abs(txn.amount);
            });

            numOfAlerts += _.keys(idMap).length;
          } else if (
            txns[0]?.type === BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TXN_PAIR
          ) {
            numOfAlerts += txns.length || 0;
            absoluteValueOfTxns += _.sumBy(txns, ({ txn: { amount } }) =>
              Math.abs(amount)
            );
          } else {
            // eslint-disable-next-line no-console
            console.warn(
              `Unknown bookkeeping alert userTxn type ${txns[0]?.type}`
            );
          }
        });
        break;
      }
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.ENTITY_TXN_COUNT_ARRAY:
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.ITEM_INFO_ARRAY:
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TIME_ACTIVITY_INFO_ARRAY:
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TXN_STATUS_INFO_ARRAY:
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.VENDOR_INFO_ARRAY: {
        numOfAlerts += alertDetails.data.length;
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.warn(`Unknown bookkeeping alert type ${alertDetails.type}`);
    }

    const monetaryRenderOfTotalValue = monetaryRender({
      value: absoluteValueOfTxns,
      withDecimals: false,
    });

    let footerHtmlToUse = alertInfo.footer || null;
    if (
      [
        BOOKKEEPING_ALERT.DELETED_TRANSACTIONS,
        BOOKKEEPING_ALERT.VOIDED_TRANSACTIONS,
      ].includes(alertIdentifier) &&
      reportDate
    ) {
      const qboAuditLogBaseUrl = `${QboTools.QBO_APP_BASE_URL}/auditlog`;

      const reportDateMoment = moment(reportDate);
      const endDateISO = reportDateMoment.endOf('day').toISOString();
      const startDateISO = reportDateMoment
        .subtract(1, 'day')
        .startOf('day')
        .toISOString();

      const qsData = {
        selectedDate: 'custom',
        startDate: `"${startDateISO}"`,
        endDate: `"${endDateISO}"`,
        events: `["deleted_or_voided_transactions"]`,
      };

      const queryString = _.keys(qsData)
        .map(key => `${key}=${qsData[key]}`)
        .join('&');

      const qboAuditLogLink = `${qboAuditLogBaseUrl}?${encodeURI(queryString)}`;

      footerHtmlToUse = footerHtmlToUse.replace(
        '{{QBO_AUDIT_LOG_LINK}}',
        qboAuditLogLink
      );
    }

    return (
      <Grid
        key={alertIdentifier}
        container
        item
        className={classes.alertContainer}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          id={alertDetails.alertIdentifier}
          style={{
            marginTop: 8,
            marginBottom: 8,
            minHeight: isPrinting ? 24 : 32,
            breakInside: 'avoid',
          }}
        >
          <Typography variant={isPrinting ? 'h5' : 'h4'} color="primary">
            {alertInfo.name}
          </Typography>
          {!!numOfAlerts && (
            <Chip
              label={`${numOfAlerts} alert${numOfAlerts !== 1 ? 's' : ''}`}
              style={{
                backgroundColor: palette.brandColorDarkGrey,
                color: '#fff',
                marginLeft: 8,
              }}
              size={isPrinting ? 'small' : 'medium'}
            />
          )}
          {!!numOfAlerts &&
            !!absoluteValueOfTxns &&
            !!monetaryRenderOfTotalValue && (
              <Chip
                label={monetaryRenderOfTotalValue}
                style={{
                  backgroundColor: palette.brandColorGreen,
                  color: '#fff',
                  marginLeft: 8,
                }}
                size={isPrinting ? 'small' : 'medium'}
              />
            )}
        </Grid>
        {alertInfo.summary && (
          <Grid
            container
            item
            xs={12}
            style={{ marginBottom: _.isEmpty(alertDetails?.data) ? 0 : 16 }}
            direction="column"
          >
            <Typography
              variant="body1"
              style={{ color: palette.brandColorBlackish }}
            >
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    BOOKKEEPING_REPORT_ALERT_DICTIONARY[alertInfo.summary]
                      .description,
                }}
              />
            </Typography>
            <BookkeepingAlertsReportAlertList
              alertIdentifier={alertInfo.summary}
              alertDetails={reportDataToUse[alertInfo.summary]}
              addressedStateEventMetaMap={addressedStateEventMetaMap}
              processingAlertInstanceMap={processingAlertInstanceMap}
              setProcessingAlertInstanceMap={setProcessingAlertInstanceMap}
              hideAddressedColumn={isCustomScan}
              isPrinting={isPrinting}
            />
          </Grid>
        )}
        {!_.isEmpty(alertDetails?.data) && (
          <>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ color: palette.brandColorBlackish }}
              >
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: alertInfo.description,
                  }}
                />
              </Typography>
            </Grid>
            <BookkeepingAlertsReportAlertList
              alertIdentifier={alertIdentifier}
              alertDetails={alertDetails}
              addressedStateEventMetaMap={addressedStateEventMetaMap}
              processingAlertInstanceMap={processingAlertInstanceMap}
              setProcessingAlertInstanceMap={setProcessingAlertInstanceMap}
              hideAddressedColumn={isCustomScan}
              isPrinting={isPrinting}
            />
          </>
        )}
        {footerHtmlToUse && (
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <Typography
              variant="body1"
              style={{ color: palette.brandColorBlackish }}
            >
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: footerHtmlToUse,
                }}
              />
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPrintingReportHeader = () => {
    let count = 0;
    _.forEach(reportMetaData.selectedRules, ({ alertIdentifierSub }) => {
      if (alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER) {
        count += 1;
      }
    });

    const selectedRulesText = `${count} ${count === 1 ? 'Rule' : 'Rules'}`;

    return (
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        style={{ marginTop: 8, marginBottom: 24 }}
      >
        <Typography variant="h2" color="primary" style={{ marginBottom: 8 }}>
          Custom Scan Report
        </Typography>
        <Typography variant="h5" style={{ marginBottom: 4 }}>
          {renderDateString(
            reportMetaData.startDate,
            reportMetaData.endDate,
            'short'
          )}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 4 }}>
          {selectedRulesText}
        </Typography>
        <Typography variant="body2">
          Generated at{' '}
          {moment(reportMetaData.dateCreated).format('MMM D, YYYY @ h:mma')}
        </Typography>
      </Grid>
    );
  };

  // Add page margins to the print view
  const getPageMargins = () => {
    return `@page { margin: 30px 20px 30px 80px !important; }`;
  };

  return (
    <>
      <style>{getPageMargins()}</style>
      {showPrintButton && (
        <Tooltip title="Print Report">
          <IconButton className={classes.printButton} onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Tooltip>
      )}
      <Grid
        ref={contentToPrintRef}
        container
        item
        xs={12}
        sm={11}
        md={10}
        lg={8}
        xl={7}
        style={{ backgroundColor: '#fff' }}
        direction="column"
        alignItems="center"
      >
        {isPrinting && isCustomScan && renderPrintingReportHeader()}
        <Grid item style={{ width: '100%', marginBottom: 8 }}>
          <Card
            variant="outlined"
            color="primary"
            style={{ padding: 16, borderColor: palette.brandColorLightGrey }}
          >
            <Grid container alignItems="center">
              <Grid item style={{ flex: 0, marginRight: 16 }}>
                <InfoIcon color="primary" />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography variant="body1">
                  {isCustomScan ? (
                    <>
                      This bookkeeping report, created with our Custom Scan
                      Tool, summarizes transactions within the selected
                      reporting date range that might have issues.
                    </>
                  ) : (
                    <>
                      This bookkeeping report is based on user activity within
                      your accounting software, including creation of new
                      transactions or modifications to existing transactions,
                      during the 24-hour period leading up to the specified
                      report time.
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {_.values(BOOKKEEPING_ALERT).map(renderAlert)}
      </Grid>
      {isPrinting && (
        <LoadingCover withCam customStyles={{ opacity: 1 }}>
          <Typography variant="h2">Processing...</Typography>
        </LoadingCover>
      )}
    </>
  );
};

export default BookkeepingAlertsReport;
