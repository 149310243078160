import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  query CheckMazumaGoConnection($companyId: String!) {
    checkMazumaGoConnection(input: { companyId: $companyId }) {
      ...CompanyFields
    }
  }
  ${Fragment.Company}
`;
