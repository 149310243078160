import React, { useEffect, useRef, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'react-apollo';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { USER_ACTIVITY_STATUS } from '../../config/appDefaults';
import StartCustomScan from '../../graphql/mutations/mutation_start-custom-scan';
import GetBookkeepingRules from '../../graphql/queries/get-bookkeeping-rules';
import GetCustomScanReport from '../../graphql/queries/get-custom-scan-report';
import GetUserActivity from '../../graphql/queries/get-user-activity';
import BackToTopButton from '../back-to-top/back-to-top';
import BookkeepingAlertsReport from '../bookkeeping-alerts/bookkeeping-alerts-report';
import { Dashboard, DASHBOARD_LAYOUT } from '../dashboard';
import LoadingCover from '../LoadingCover/loadingCover';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';
import CustomScanDashboardControlBar from './custom-scan-dashboard-control-bar';
import CustomScanSettings, {
  LAYOUT as CUSTOM_SCAN_SETTINGS_LAYOUT,
} from './custom-scan-settings';

const SESSION_STORAGE_KEY = {
  ACTIVITY_ID_TO_SCAN: 'activityIdToScan',
  ACTIVITY_ID_TO_GET_REPORT: 'activityIdToGetReport',
  REPORT_META_DATA: 'reportMetaData',
};

const CustomScanDashboard = ({ managingCompanyInfo }) => {
  const isCopilot = managingCompanyInfo?.isCopilot;
  const containerRef = useRef(null);

  const [reportData, setReportData] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [settingsError, setSettingsError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activityIdToScan, setActivityIdToScan] = useState(() => {
    return (
      sessionStorage.getItem(SESSION_STORAGE_KEY.ACTIVITY_ID_TO_SCAN) || null
    );
  });

  const [reportMetaData, setReportMetaData] = useState(() => {
    const metaDataStr = sessionStorage.getItem(
      SESSION_STORAGE_KEY.REPORT_META_DATA
    );
    return metaDataStr ? JSON.parse(metaDataStr) : {};
  });

  const [activityIdToGetReport, setActivityIdToGetReport] = useState(() => {
    return (
      sessionStorage.getItem(SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT) ||
      null
    );
  });

  const [startCustomScan] = useMutation(StartCustomScan);

  const bookkeepingRulesQuery = useQuery(GetBookkeepingRules, {
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo,
  });

  const bookkeepingRules = _.get(
    bookkeepingRulesQuery,
    'data.getBookkeepingRules.items',
    null
  );

  const userActivityQuery = useQuery(GetUserActivity, {
    skip: !activityIdToScan || !managingCompanyInfo?.managingCompanyId,
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
      activityId: activityIdToScan,
    },
  });

  useEffect(() => {
    if (!activityIdToScan) {
      return;
    }

    setLoaderMessage('Scanning...');
    userActivityQuery.startPolling(2500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityIdToScan]);

  const userActivity = _.get(userActivityQuery, 'data.getUserActivity', null);

  const { refetch: getCustomScanReport } = useQuery(GetCustomScanReport, {
    skip: true,
    fetchPolicy: 'network-only',
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
    },
  });

  const dateToString = date => moment(date).format('YYYY-MM-DD');

  const stringToDate = dateStr => {
    const date = new Date(dateStr);
    date.setFullYear(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return date;
  };

  useEffect(() => {
    if (!userActivity) {
      return;
    }
    const { status } = userActivity;

    if (status === USER_ACTIVITY_STATUS.SUCCESS) {
      userActivityQuery.stopPolling();

      setActivityIdToGetReport(userActivity.id);

      // store the activityIdToGetReport in the local session
      sessionStorage.setItem(
        SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT,
        userActivity.id
      );

      // remove the activityIdToScan from the local session
      sessionStorage.removeItem(SESSION_STORAGE_KEY.ACTIVITY_ID_TO_SCAN);
      sessionStorage.removeItem(SESSION_STORAGE_KEY.REPORT_META_DATA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActivity]);

  useEffect(() => {
    if (!activityIdToGetReport) {
      return;
    }

    const getReportData = async () => {
      setLoaderMessage('Loading report...');

      try {
        const response = await getCustomScanReport({
          companyId: managingCompanyInfo?.managingCompanyId,
          activityId: activityIdToGetReport,
        });

        const report = _.get(response, 'data.getCustomScanReport', {});

        if (report.dataJson) {
          setReportData(JSON.parse(report.dataJson));

          if (report.startDate && report.endDate && report.bookkeepingRules) {
            const metaData = {
              startDate: stringToDate(report.startDate),
              endDate: stringToDate(report.endDate),
              selectedRules: report.bookkeepingRules,
              dateCreated: new Date(report.dateCreated),
            };

            setReportMetaData(metaData);
          }
        } else {
          // remove the activityIdToGetReport from the local session
          sessionStorage.removeItem(
            SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT
          );
        }

        setLoaderMessage(null);
      } catch (err) {
        setLoaderMessage(null);
        // eslint-disable-next-line no-console
        console.error('error:', err, 'error.message', err?.message);

        // remove the activityIdToGetReport from the local session
        sessionStorage.removeItem(
          SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT
        );

        if (
          _.includes(err?.message, 'size exceeded maximum allowed payload size')
        ) {
          setErrorMessage(
            'The report is too large to load. Please reduce the reporting period or the number of rules and try again.'
          );
        } else {
          setErrorMessage(
            'An error occurred while loading the report. Please try again later!'
          );
        }
      }
    };

    getReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityIdToGetReport, managingCompanyInfo.managingCompanyId]);

  const handleOnScanClick = async ({ startDate, endDate, selectedRules }) => {
    const startDateString = dateToString(startDate);
    const endDateString = dateToString(endDate);

    if (startDateString > endDateString) {
      setSettingsError({
        startDate: true,
        endDate: true,
        form: 'The start date cannot be after the end date',
      });
      return;
    }

    setLoaderMessage('Scanning...');
    setReportData(null);

    const newActivityId = uuid();

    sessionStorage.setItem(
      SESSION_STORAGE_KEY.ACTIVITY_ID_TO_SCAN,
      newActivityId
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.REPORT_META_DATA,
      JSON.stringify({
        startDate,
        endDate,
        selectedRules,
      })
    );

    // this will be used to populate the settings on the dashboard toolbar
    setReportMetaData({
      startDate,
      endDate,
      selectedRules,
    });

    sessionStorage.removeItem(SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT);

    try {
      // send the request to start the retroactive report generation
      const response = await startCustomScan({
        variables: {
          activityId: newActivityId,
          companyId: managingCompanyInfo?.companyId,
          startDate: startDateString,
          endDate: endDateString,
          bookkeepingRules: _.map(
            selectedRules,
            ({ __typename, type, ...rest }) => ({
              ...rest,
            })
          ),
        },
      });

      const responseStatus = _.get(response, 'data.startCustomScan.status');

      if (responseStatus !== 'success') {
        setErrorMessage(
          'An unexpected error occurred. Please try again later!'
        );
        setLoaderMessage(null);
        return;
      }

      setActivityIdToScan(newActivityId);
    } catch (err) {
      setErrorMessage('An error occurred while starting scanning');
    }
  };

  const hideControlBar = !reportData && !loaderMessage;

  return (
    <Dashboard
      layout={DASHBOARD_LAYOUT.SIMPLE}
      controlBar={
        <CustomScanDashboardControlBar
          title="Custom Scan"
          loading={bookkeepingRulesQuery.loading}
          disabled={!!loaderMessage}
          hideAccessExplainer={isCopilot}
          defaultBookkeepingRules={bookkeepingRules}
          initialSettings={reportMetaData}
          error={settingsError}
          setError={setSettingsError}
          onScanClick={handleOnScanClick}
        />
      }
      hideControlBar={hideControlBar}
      dashboardFullStyle={
        hideControlBar
          ? { height: 'calc(100vh - 64px)', backgroundColor: '#e8e8e8' }
          : {}
      }
    >
      <Grid
        container
        justifyContent="center"
        style={{ overflowY: 'auto', paddingTop: isCopilot ? 0 : 8 }}
        ref={containerRef}
      >
        {!!hideControlBar && (
          <CustomScanSettings
            defaultBookkeepingRules={bookkeepingRules}
            loading={bookkeepingRulesQuery.loading}
            disabled={!!loaderMessage}
            onScanClick={handleOnScanClick}
            layout={CUSTOM_SCAN_SETTINGS_LAYOUT.BOX}
            containerStyle={{ alignSelf: 'center' }}
            initialSettings={reportMetaData}
            error={settingsError}
            setError={setSettingsError}
          />
        )}
        {!!reportData && (
          <BookkeepingAlertsReport
            reportData={reportData}
            isCustomScan
            showPrintButton
            reportMetaData={reportMetaData}
          />
        )}
        {!!errorMessage && (
          <OkCancelDialog
            open
            okButtonText="Ok"
            hideCancel
            title="Oops!"
            dividers={false}
            loaderStyle={{ opacity: 1 }}
            onConfirm={() => {
              setErrorMessage(null);
            }}
          >
            <Typography>{errorMessage}</Typography>
          </OkCancelDialog>
        )}
        <BackToTopButton scrollElementRef={containerRef} />
        {(bookkeepingRulesQuery.loading || !!loaderMessage) && (
          <LoadingCover withCam customStyles={{ opacity: 1 }}>
            <Typography variant="h2">
              {loaderMessage || 'Loading your company rules...'}
            </Typography>
          </LoadingCover>
        )}
      </Grid>
    </Dashboard>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(connect(mapStateToProps))(CustomScanDashboard);
