import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

const brandColors = {
  brandColorPrimary: 'rgba(57, 124, 165, 1)',
  brandColorPrimary10: 'rgba(57, 124, 165, 0.1)',
  brandColorPrimary25: 'rgba(57, 124, 165, 0.25)',
  brandColorPrimary40: 'rgba(57, 124, 165, 0.4)',
  brandColorPrimary50: 'rgba(57, 124, 165, 0.5)',
  brandColorPrimary60: 'rgba(57, 124, 165, 0.6)',
  brandColorPrimary75: 'rgba(57, 124, 165, 0.75)',
  brandColorPrimary85: 'rgba(57, 124, 165, 0.85)',
  brandColorPrimary90: 'rgba(57, 124, 165, 0.9)',
  brandColorPrimaryLight: 'rgba(168, 184, 194, 1)',
  brandColorPrimaryHighlight: '#f0f7fd',
  brandColorDarkestGrey: '#333',
  brandColorDarkGrey: '#666',
  brandColorDarkGrey50: 'rgba(102, 102, 102 ,0.50)',
  brandColorDarkGrey75: 'rgba(102, 102, 102 ,0.75)',
  brandColorLightGrey: '#eee',
  brandColorMidGrey: '#aaa',
  brandColorError: 'rgba(232, 41, 57, 1)',
  brandColorError50: 'rgba(232, 41, 57, 0.5)',
  brandColorError75: 'rgba(232, 41, 57, 0.75)',
  progressRed: 'red',
  progressOrange: 'orange',
  brandColorOrange: 'rgba(223, 134, 19, 1)',
  brandColorOrange25: 'rgba(223, 134, 19, 0.25)',
  brandColorOrange50: 'rgba(223, 134, 19, 0.5)',
  brandColorOrange75: 'rgba(223, 134, 19, 0.75)',
  brandColorYellow: 'rgb(250, 236, 2, 1)',
  brandColorYellow25: 'rgb(250, 236, 2, 0.25)',
  brandColorYellow50: 'rgb(250, 236, 2, 0.5)',
  brandColorYellow75: 'rgb(250, 236, 2, 0.75)',
  brandColorDeepBlue: 'rgba(8, 65, 92, 1)',
  brandColorDeepestBlue: 'rgba(39, 50, 57,1)',
  progressGreen: 'green',
  brandColorGreenDark: 'rgba(64, 124, 21, 1)',
  brandColorGreen: 'rgba(129, 178, 94, 1)',
  brandColorGreen50: 'rgba(129, 178, 94, 0.5)',
  brandColorGreen75: 'rgba(129, 178, 94, 0.75)',
  brandColorGreenBg: '#acd6ac',
  brandColorBlackish: 'rgba(47, 50, 58, 1)',
  brandOcrTurquoise: '#02afa1',
};

export default {
  ...brandColors,

  taskStatusIncomplete: 'rgba(136, 136, 136, 1)',
  taskStatusInProgress: brandColors.brandColorPrimary75,
  taskStatusPendingApproval: brandColors.brandColorOrange,
  taskStatusCompleted: brandColors.brandColorGreen,

  black,
  white,
  primary: {
    contrastText: white,
    dark: brandColors.brandColorDeepBlue,
    main: brandColors.brandColorPrimary,
    light: brandColors.brandColorPrimaryLight,
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A400,
    light: colors.blue.A400,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
    alt: '#F5F5F5',
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  quickBooks: {
    green: '#2ca01c',
  },
  mazumaGo: {
    purple: '#5C58FF',
  },
  truss: {
    green: '#4fe1d4',
  },
};
