import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import userflow from 'userflow.js';

import _ from 'lodash';
import moment from 'moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Button,
  Tooltip,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Typography,
} from '@material-ui/core';

import { TimerOutlined as TimerOutlinedIcon } from '@material-ui/icons';

import LoadingCover from '../../../../components/LoadingCover/loadingCover';

import { daysLeftInSubscription, runAnalytics } from '../../../../helpers';
import { GetMyUserInfoAction } from '../../../../graphql/graphql';
import RefreshMyActiveSessions from '../../../../graphql/queries/refresh-my-active-sessions';
import GetUserInfo from '../../../../graphql/queries/GetUserInfo';

import ConnectCompanyModal from '../../../../components/connect-company-modal';
import InvitedWhileLoggedInDialog from '../../../../components/invited-while-logged-in-dialog/invited-while-logged-in-dialog';
import ManageSubscriptionDialog from './manage-subscription-dialog/manage-subscription-dialog';
import { SUBSCRIPTION_STATUSES } from '../../../../config/appDefaults';
import clockRunOut from '../../../../assets/images/subscriptions/clock-run-out-01.jpg';
import OkCancelDialog from '../../../../components/OkCancelDialog/okCancelDialog';
import LevelLogo from '../../../../components/level-logo/level-logo';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor:
      process.env.REACT_APP_BACKEND === 'nonprod'
        ? theme.palette.brandColorOrange
        : theme.palette.brandColorPrimary,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(3),
  },
  headerIconButton: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
  freeTrialText: {
    color: theme.palette.brandColorPrimary,
    background: '#fff',
    borderColor: 'white',
    position: 'relative',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: '#fff',
      transform: 'scale(1.04)',
    },
  },
  clockImage: {
    height: 34,
    width: 'auto',
    marginRight: 8,
  },
}));

const Topbar = ({
  className,
  dispatch,
  userInfo,
  history,
  client,
  companyRegistrationKey,
  showErrorInviteModal,
  currentAuth,
  managingCompanyInfo,
}) => {
  useEffect(() => {
    if (userInfo?.userId && managingCompanyInfo?.managingCompanyId) {
      const isCopilot = managingCompanyInfo?.isCopilot;
      if (isCopilot) {
        userflow.identify(userInfo?.userId, {
          name: managingCompanyInfo?.companyName,
          email: userInfo?.email,
        });
        userflow.setCustomNavigate(to => {
          history.push(to);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managingCompanyInfo, userInfo]);

  const classes = useStyles();
  const [refreshingSessions, setRefreshingSessions] = useState(false);
  const [showActiveSessionModal, setShowActiveSessionModal] = useState({
    open: false,
  });
  const [dismissConnectCompanyModal, setDismissConnectCompanyModal] = useState(
    false
  );
  const [
    showSignOutConfirmationDialog,
    setShowSignOutConfirmationDialog,
  ] = useState(false);

  const handleSignOut = async () => {
    await Auth.signOut();

    userflow.reset();
    sessionStorage.clear();

    const options = {
      userId: userInfo?.userId,
      username: userInfo?.username,
      type: 'Log Out',
      email: userInfo?.email,
    };

    runAnalytics('Log', options);
    dispatch({ type: 'SIGNOUT_SUCCEEDED' });
  };

  const handleClose = () => {
    setShowActiveSessionModal({ ...showActiveSessionModal, open: false });
  };

  const goToSession = sessionInfo => {
    const { projectId } = sessionInfo;
    handleClose();
    history.push(`/projects/${projectId}`);
  };

  const refreshSessions = async () => {
    setRefreshingSessions(true);
    let newUserInfo;
    try {
      const result = await client.query({
        query: RefreshMyActiveSessions,
        fetchPolicy: 'network-only',
      });
      newUserInfo = _.get(result, 'data.refreshMyActiveSessions');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    if (newUserInfo) {
      // write it back to the cache for getMyUserInfo
      client.writeQuery({
        query: GetUserInfo,
        variables: { userId: 'willBePulledFromCognitoSubContentInResolver' },
        data: {
          getMyUserInfo: newUserInfo,
        },
      });
    }
    setRefreshingSessions(false);
  };

  const handleCheckedIn = () => {
    refreshSessions();
    const activeSessions = _.orderBy(
      userInfo.activeSessions,
      ['dateCreated'],
      ['asc']
    );
    setShowActiveSessionModal({ open: true, activeSessions });
  };

  let activeSessions = null;
  let activeSessionsLength = 0;
  if (userInfo && userInfo.activeSessions && userInfo.activeSessions.length) {
    activeSessions = _.orderBy(
      userInfo.activeSessions,
      ['dateCreated'],
      ['asc']
    );
    activeSessionsLength = userInfo.activeSessions.length;
  }

  const [showTrialMessageInfo, setShowTrialMessageInfo] = useState({
    show: false,
  });

  const checkDaysLeft = () => {
    if (
      managingCompanyInfo?.subscriptionStatus ===
        SUBSCRIPTION_STATUSES.ACTIVE_FREE_TRIAL &&
      managingCompanyInfo?.subscriptionEndDate
    ) {
      const daysLeft = daysLeftInSubscription(
        managingCompanyInfo?.subscriptionEndDate
      );
      setShowTrialMessageInfo({ show: true, daysLeft });
    } else if (
      managingCompanyInfo?.subscriptionStatus ===
      SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION
    ) {
      setShowTrialMessageInfo({ show: false });
    }
  };

  useEffect(() => {
    if (managingCompanyInfo) {
      checkDaysLeft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managingCompanyInfo]);

  const [showGoToPaymentModal, setShowGoToPaymentModal] = useState(false);
  const handleTrialMessageClick = () => {
    setShowGoToPaymentModal(true);
  };

  let whatToShow = 'Some';
  if (typeof showTrialMessageInfo?.daysLeft === 'number') {
    if (showTrialMessageInfo.daysLeft >= 0) {
      whatToShow = showTrialMessageInfo.daysLeft;
    } else {
      whatToShow = '0';
    }
  }

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <LevelLogo
            logoVariation="alt"
            style={{ height: 45, width: 'auto' }}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {activeSessionsLength > 0 && (
          <Button
            onClick={handleCheckedIn}
            className={classes.headerIconButton}
          >
            <TimerOutlinedIcon style={{ marginRight: 8 }} />
            Clocked into {activeSessionsLength} project
            {activeSessionsLength !== 1 ? 's' : ''}
          </Button>
        )}
        {showTrialMessageInfo.show && (
          <Button
            onClick={handleTrialMessageClick}
            color="default"
            variant="outlined"
            className={classes.freeTrialText}
          >
            <img
              src={clockRunOut}
              className={classes.clockImage}
              alt="a clock show time running out"
            />
            <b>{whatToShow}</b>&nbsp;days left in your free trial
          </Button>
        )}
        {currentAuth.isLoggedIn && (
          <Tooltip title="Sign out">
            <Button
              onClick={() => {
                setShowSignOutConfirmationDialog(true);
              }}
              className={classes.signOutButton}
              color="inherit"
            >
              Logout
            </Button>
          </Tooltip>
        )}
      </Toolbar>
      {showActiveSessionModal.open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="simple-dialog-title">
            Active Clock-In Sessions
          </DialogTitle>
          <List>
            {activeSessionsLength > 0 ? (
              activeSessions.map(session => {
                return (
                  <ListItem
                    button
                    onClick={() => goToSession(session)}
                    key={session.uniqueId}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <TimerOutlinedIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={session.projectTitle}
                      secondary={`Since: ${moment(session.date).format(
                        'MMM D @ h:mma'
                      )}`}
                    />
                  </ListItem>
                );
              })
            ) : (
              <Typography align="center">No active sessions</Typography>
            )}
            <ListItem
              button
              onClick={refreshSessions}
              style={{ textAlign: 'right' }}
            >
              <ListItemText primary="Refresh My Sessions" />
            </ListItem>
          </List>
          {refreshingSessions && <LoadingCover />}
        </Dialog>
      )}

      {showGoToPaymentModal && (
        <ManageSubscriptionDialog
          closeDialog={() => {
            setShowGoToPaymentModal(false);
          }}
          managingCompanyInfo={managingCompanyInfo}
        />
      )}

      {showErrorInviteModal && <InvitedWhileLoggedInDialog />}

      {companyRegistrationKey && !dismissConnectCompanyModal && (
        <ConnectCompanyModal
          handleClose={() => setDismissConnectCompanyModal(true)}
        />
      )}

      {showSignOutConfirmationDialog && (
        <OkCancelDialog
          open
          okButtonText="Yes"
          cancelButtonText="Cancel"
          title="Confirm Sign Out"
          dividers={false}
          onClose={() => {
            setShowSignOutConfirmationDialog(false);
          }}
          onConfirm={() => {
            handleSignOut();
          }}
          style={{ zIndex: 10000 }}
        >
          <Typography>Are you sure you want to sign out of Level?</Typography>
        </OkCancelDialog>
      )}
    </AppBar>
  );
};

function mapStateToProps(state) {
  return {
    currentAuth: state.currentAuth,
    showErrorInviteModal: state.appState.showErrorInviteModal,
    companyRegistrationKey: state.appState.companyRegistrationKey || null,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default withRouter(
  connect(mapStateToProps)(compose(GetMyUserInfoAction, withApollo)(Topbar))
);
