import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import constructionSign from '../../assets/images/construction-sign@4x.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 100,
    textAlign: 'center',
  },
}));

const NoAccess = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">You do not have access</Typography>
            <br />
            <br />
            <Typography variant="h4">
              <a href="/">Click here to go back to the main screen.</a>
            </Typography>
            <br />
            <br />
            <img alt="construction sign" src={constructionSign} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NoAccess;
