import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';

import GetUsersByIds from '../../graphql/queries/GetUsersByIds';
import {
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  ListMyConversationsAction,
} from '../../graphql/graphql';

import ListOfConvosView from './ListOfConvos.view';

const ListOfConvos = props => {
  const {
    userId,
    onClickOfProject,
    chosenConvoId,
    setShowManualAddDialog,
    companyCrew,
    getCompanyCrewLoading,
    managingCompanyInfo,
    getCompanyInfo,
    myConversations,
    listMyConversationsLoading,
    listMyConversationsRefetch,
  } = props;

  const [myCrew, setMyCrew] = useState(null);
  const [missingUserIds, setMissingUserIds] = useState(null);

  // Get missing users
  const GetUsersByIdsQuery = useQuery(GetUsersByIds, {
    variables: { ids: missingUserIds || [] },
    skip: missingUserIds === null,
    fetchPolicy: 'cache-and-network',
  });

  let otherUsers = null;
  if (GetUsersByIdsQuery.data) {
    otherUsers = _.get(GetUsersByIdsQuery.data, 'getUsersByIds.items', []);
  }

  // Set my crew
  useEffect(() => {
    if (
      companyCrew &&
      getCompanyInfo &&
      managingCompanyInfo?.managingCompanyId
    ) {
      const company = _.find(
        getCompanyInfo,
        ({ companyId }) => companyId === managingCompanyInfo.managingCompanyId
      );

      if (company) {
        const allCompanyUsers = [...company.admins, ...company.users];
        const activeUsers = companyCrew.filter(({ userId: thisUserId }) =>
          allCompanyUsers.includes(thisUserId)
        );

        setMyCrew(activeUsers);
      }
    }
  }, [companyCrew, getCompanyInfo, managingCompanyInfo]);

  // Check if any users are missing
  useEffect(() => {
    if (!myConversations || !myCrew) {
      return;
    }

    const missingIds = [];
    myConversations.forEach(conversation => {
      if (
        conversation.type === 'conversation' &&
        (conversation.title === 'hold' || conversation.title === null) &&
        conversation.usersEverOnJrn.length === 2
      ) {
        // Get list of users on conversation
        const allConversationUsers = [...conversation.usersEverOnJrn];
        // Remove self
        _.pull(allConversationUsers, userId);

        const [otherUserId] = allConversationUsers;

        // Check if user knows of other user
        const knownUser = _.find(myCrew, { userId: otherUserId });
        if (!knownUser) {
          missingIds.push(otherUserId);
        }
      }
    });

    setMissingUserIds(_.uniq(missingIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myConversations, myCrew]);

  // Generate complete conversation list
  const conversationInfo = useMemo(() => {
    if (!otherUsers || !myCrew || !myConversations) {
      return null;
    }

    const conversationsInfoForView = myConversations.map(conversation => {
      let thumbnailSrc;
      let convoTitle;
      let isDirectMessage = false;
      if (
        conversation.type === 'project' ||
        (conversation.type === 'conversation' &&
          conversation.title !== 'hold' &&
          conversation.title !== null)
      ) {
        // if it's either a project or a group convo
        convoTitle = conversation.title;
        thumbnailSrc = conversation.contentUrl;
      } else {
        // if it's a direct conversation find the other user
        const conversationUsers = [...conversation.usersEverOnJrn];
        _.pull(conversationUsers, userId);
        const [otherUserId] = conversationUsers;

        const friend = _.find([...myCrew, ...otherUsers], {
          userId: otherUserId,
        });

        if (friend) {
          convoTitle = friend.username;
          thumbnailSrc = friend.profilePic;
          isDirectMessage = true;
        }
      }

      return {
        ...conversation,
        thumbnailSrc,
        title: convoTitle,
        isDirectMessage,
      };
    });

    return (
      _.orderBy(
        conversationsInfoForView,
        [
          convo => (convo.lastMessageSent ? convo.lastMessageSent : 0),
          'endDate',
        ],
        ['desc', 'asc']
      ) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myConversations, myCrew, otherUsers]);

  // Refresh conversations
  const runRefetch = () => {
    listMyConversationsRefetch();
  };

  // Set loading state
  const loading = !!(
    listMyConversationsLoading ||
    getCompanyCrewLoading ||
    !otherUsers ||
    !conversationInfo
  );

  return (
    <ListOfConvosView
      loading={loading}
      convos={conversationInfo}
      userId={userId}
      refetchListingConvos={runRefetch}
      onClickOfProject={onClickOfProject}
      chosenConvoId={chosenConvoId}
      setShowManualAddDialog={setShowManualAddDialog}
      myCrew={myCrew}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo.userId,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  ListMyConversationsAction
)(connect(mapStateToProps)(ListOfConvos));
