import './qbo-required-wrapper.scss';

import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ConnectQboWrapper from '../../components/connect-qbo-wrapper/connect-qbo-wrapper';
import { useQueryStringParams } from '../../hooks';

import productIcon from '../../assets/images/cam/copilot_mascot_004.jpg';
import camSad from '../../assets/images/cam/copilot_mascot_frown_no-arm.jpg';
import LevelModal from '../../components/Modal/level-modal';
import ConnectToQuickbooks from '../../components/connect-to-quickbooks';
import LevelSupportEmailAddressLink from '../../components/level-support-email-address-link/level-support-email-address-link';
import { modifyQueryParams } from '../../helpers';

const QboRequiredWrapperComponent = ({
  managingCompanyInfo,
  redirectToOnQboConnect,
  children,
}) => {
  const history = useHistory();

  const [congratsPaidDialog, setCongratsPaidDialog] = useState(false);
  const [errorLinkingDialog, setErrorLinkingDialog] = useState(false);
  const [errorOnCheckoutDialog, setErrorOnCheckoutDialog] = useState(false);

  const queryStringParams = useQueryStringParams();
  const queryCheckoutParam = queryStringParams.get('checkout');
  const queryErrorParam = queryStringParams.get('error');
  const isConnectedToQuickBooks = !!managingCompanyInfo?.isConnectedToQuickBooks;

  useEffect(() => {
    if (queryCheckoutParam) {
      if (queryCheckoutParam === 'success') {
        setCongratsPaidDialog(true);
      } else {
        setErrorOnCheckoutDialog(true);
      }
    }
  }, [queryCheckoutParam]);

  useEffect(() => {
    if (queryErrorParam === 'qb') {
      setErrorLinkingDialog(true);
    }
  }, [queryErrorParam]);

  const goToSettings = () => {
    history.push('/alerts/settings');
  };

  const renderErrorOnCheckoutDialog = () => {
    return (
      <LevelModal
        open
        fullWidth={false}
        maxWidth="sm"
        dialogStyle={{ zIndex: '9999' }}
      >
        <Grid container alignItems="center" style={{ position: 'relative' }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Typography
              variant="h3"
              style={{ textAlign: 'center', paddingLeft: 8, flex: 1 }}
            >
              Something went wrong while processing your payment 😢
            </Typography>
            <IconButton
              onClick={() => {
                setErrorOnCheckoutDialog(false);
                modifyQueryParams({
                  history,
                  clearAllParams: true,
                  pushOrReplace: 'replace',
                });
              }}
              style={{ flex: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginBottom: 16 }}
            alignItems="center"
          >
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <img
                src={camSad}
                style={{ width: 'auto', height: 200 }}
                alt="robot missing an arm with a sad look on its face"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body1"
                style={{ fontSize: 18, paddingLeft: 16 }}
              >
                Please try again or{' '}
                <LevelSupportEmailAddressLink underline>
                  contact support
                </LevelSupportEmailAddressLink>{' '}
                if you continue to have issues.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </LevelModal>
    );
  };

  const renderCongratsPaidDialog = () => {
    return (
      <LevelModal
        open
        fullWidth={false}
        maxWidth="sm"
        dialogStyle={{ zIndex: '9999' }}
      >
        <Grid container alignItems="center" style={{ position: 'relative' }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Typography
              variant="h3"
              style={{ textAlign: 'center', paddingLeft: 8, flex: 1 }}
            >
              Congratulations, you&apos;re subscribed to Level! 🎉
            </Typography>
            <IconButton
              onClick={() => {
                setCongratsPaidDialog(false);
                modifyQueryParams({
                  history,
                  paramsToModify: [{ name: 'checkout', value: null }],
                  pushOrReplace: 'replace',
                });
              }}
              style={{ flex: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginBottom: 16 }}
            alignItems="center"
          >
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <img
                src={productIcon}
                style={{ width: 'auto', height: 200 }}
                alt="group of abstract objects representing bookkeeping alerts"
              />
            </Grid>
            <Grid item xs={8}>
              {isConnectedToQuickBooks ? (
                <>
                  <Typography
                    variant="body1"
                    style={{ fontSize: 18, paddingLeft: 16 }}
                  >
                    If you haven&apos;t already, we recommend you customize your
                    bookkeeping rules to suit your business needs.
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="flex-end"
                    style={{ marginTop: 32 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={goToSettings}
                    >
                      Go to settings
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: 18,
                      paddingLeft: 16,
                      marginBottom: 16,
                    }}
                  >
                    We notice that you haven&apos;t connected your QuickBooks
                    account yet though.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontSize: 18, paddingLeft: 16 }}
                  >
                    Let&apos;s get that done so you can get the most out of
                    Level.
                  </Typography>

                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: 16 }}
                  >
                    <ConnectToQuickbooks redirectTo={redirectToOnQboConnect} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </LevelModal>
    );
  };

  const renderErrorLinkingDialog = () => {
    return (
      <LevelModal
        open
        fullWidth={false}
        maxWidth="sm"
        dialogStyle={{ zIndex: '9999' }}
      >
        <Grid container alignItems="center" style={{ position: 'relative' }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Typography
              variant="h3"
              style={{ textAlign: 'center', paddingLeft: 8, flex: 1 }}
            >
              Something went wrong linking your
              <br />
              books to Level 😢
            </Typography>
            <IconButton
              onClick={() => {
                setErrorLinkingDialog(false);
                modifyQueryParams({
                  history,
                  clearAllParams: true,
                  pushOrReplace: 'replace',
                });
              }}
              style={{ flex: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginBottom: 16 }}
            alignItems="center"
          >
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <img
                src={camSad}
                style={{ width: 'auto', height: 200 }}
                alt="robot missing an arm with a sad look on its face"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body1"
                style={{ fontSize: 18, paddingLeft: 16 }}
              >
                Please try again or{' '}
                <LevelSupportEmailAddressLink underline>
                  contact support
                </LevelSupportEmailAddressLink>{' '}
                if you continue to have issues.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </LevelModal>
    );
  };

  return (
    <>
      {!isConnectedToQuickBooks ? (
        <ConnectQboWrapper
          title="Step 1: Connect your QuickBooks Online account"
          titleAlign="center"
          redirectTo={redirectToOnQboConnect}
          customCssId="bookkeeping-alerts-connect-qbo-wrapper" // do not remove - for Userflow targeting
        >
          <Typography variant="body1" align="center">
            You&apos;re on your way to getting email alerts setup! Just click
            below to connect your QuickBooks Online account.
          </Typography>
        </ConnectQboWrapper>
      ) : (
        children
      )}

      {congratsPaidDialog && renderCongratsPaidDialog()}

      {errorLinkingDialog && renderErrorLinkingDialog()}

      {errorOnCheckoutDialog && renderErrorOnCheckoutDialog()}
    </>
  );
};
const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(QboRequiredWrapperComponent);
