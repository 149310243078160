import React, { useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { GoogleApiWrapper } from 'google-maps-react';

import LevelSupportEmailAddressLink from '../../../../../components/level-support-email-address-link/level-support-email-address-link';
import LevelLogo from '../../../../../components/level-logo/level-logo';
import DialogTitle from '../../../../../components/dialog-title/dialog-title';
import OnboardingSelectBookkeepingCopilotPrice from '../../../../../views/Onboarding/onboarding-select-pricing-step';
import BillingAddressCapture from '../../../../../views/Onboarding/billing-address-capture';
import LoadingCover from '../../../../../components/LoadingCover/loadingCover';

const ManageSubscriptionDialog = ({ closeDialog, managingCompanyInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [whatToShow, setWhatToShow] = useState('initialMessage');
  const [companyBillingAddress, setCompanyBillingAddress] = useState(null);
  const onClose = () => {
    closeDialog();
  };

  const goToBillingAddressCapture = () => {
    setWhatToShow('billingAddress');
  };

  const billingAddressFormRef = useRef(null);
  const renderBillingAddressCapture = () => {
    return (
      <Grid container>
        <Typography variant="body1" style={{ marginTop: 36, width: '100%' }}>
          Please enter your billing address to continue.
        </Typography>
        <BillingAddressCapture
          formRef={billingAddressFormRef}
          managingCompanyInfo={managingCompanyInfo}
          setLoading={setIsLoading}
          hideCompanyAddressInput
        />
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // submit the form and if it's valid, then go to pricing
              const form = billingAddressFormRef.current;
              form.submitForm();
              const validForm = form.isFormValid();
              const dirtyForm = form.isFormDirty();
              if (validForm && dirtyForm) {
                // need to make sure the address is set first
                setCompanyBillingAddress(form.values);
                setWhatToShow('pricing');
              }
            }}
            style={{ marginBottom: 16, marginTop: 16 }}
            disabled={isLoading}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderPricingOptions = () => {
    return (
      <Grid container style={{ marginBottom: 24 }}>
        <OnboardingSelectBookkeepingCopilotPrice
          companyInfo={managingCompanyInfo}
          setLoading={setIsLoading}
          companyBillingAddress={companyBillingAddress}
          stepBack={() => setWhatToShow('billingAddress')}
          hideLogo
          customEndpoints={{
            success: `${window.location.origin}/alerts`,
            cancel: `${window.location.origin}/alerts`,
          }}
        />
      </Grid>
    );
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose} />
      <DialogContent>
        <Grid item xs={12} container justifyContent="center">
          <LevelLogo logoVariation="tagline" style={{ maxWidth: 350 }} />
        </Grid>

        {whatToShow === 'initialMessage' && (
          <Grid container style={{ marginTop: 48 }}>
            <Typography
              variant="body1"
              style={{ paddingBottom: 16, fontSize: 16, textAlign: 'center' }}
            >
              Thank you for choosing to purchase a Level Subscription. This
              shouldn&apos;t take more than a few minutes. Let&apos;s get you
              started!
            </Typography>
            <Typography
              variant="body1"
              style={{
                paddingBottom: 32,
                fontSize: 16,
                textAlign: 'center',
                fontStyle: 'italic',
              }}
            >
              Please note, your free trial will continue until its end date and
              then your subscription will begin.
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingBottom: 16, fontSize: 16, textAlign: 'center' }}
            >
              If you have any issues, please contact Level Support at{' '}
              <LevelSupportEmailAddressLink />.
            </Typography>
            <Grid item container xs={12} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={goToBillingAddressCapture}
                style={{ marginBottom: 16, marginTop: 16 }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}

        {whatToShow === 'billingAddress' && renderBillingAddressCapture()}
        {whatToShow === 'pricing' && renderPricingOptions()}
      </DialogContent>
      {isLoading && <LoadingCover />}
    </Dialog>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
})(ManageSubscriptionDialog);
