import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { compose } from 'react-apollo';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  AddOrUpdateCompanyAction,
  CreateBookkeepingAlertAction,
  CreateBookkeepingAlertPreferencesAction,
  GetBookkeepingAlertsAction,
  GetBookkeepingAlertsPreferencesAction,
  GetBookkeepingRulesAction,
  GetCompanyInfoAction,
  UpdateBookkeepingAlertPreferencesAction,
  UpsertBookkeepingRuleAction,
} from '../../../graphql/graphql';
import { browserBasedTimezoneAutoSelect } from '../../../helpers';
import { useOnScreen } from '../../../hooks';
import palette from '../../../theme/palette';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import ChooseTimeTimezone from '../../../components/choose-time-timezone/choose-time-timezone';
import LevelButtonSegment from '../../../components/level-button-segment/level-button-segment';
import BookkeepingAlertsBackButton from '../../../components/bookkeeping-alerts/bookkeeping-alerts-back-button';
import { BOOKKEEPING_ALERT_TYPES } from '../../../components/bookkeeping-alerts/bookkeeping-alerts.constants';
import styles from '../../../components/bookkeeping-alerts/bookkeeping-alerts.styles';
import BookkeepingAlertsEmailLists from '../../../components/bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings-email-lists';
import BookkeepingAlertsSettingsList from '../../../components/bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings-list';
import BookkeepingAlertsSettingsListHeader from '../../../components/bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings-list-header';
import BackToTopButton from '../../../components/back-to-top/back-to-top';
import NextReportWillRun from '../../../components/bookkeeping-alerts/bookkeeping-alerts-dashboard/next-report-will-run';
import {
  WIDTH_OF_ALERT_TOGGLE_COLUMN,
  WIDTH_OF_WORDING_COLUMN,
} from '../../../components/bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings.constants';

const DEFAULT_BOOKKEEPING_ALERT_EMAIL_LISTS = [
  {
    listId: uuid(),
    listName: 'Group 1',
    recipientEmails: [],
    initializeFromRules: false,
  },
];

const useStyles = makeStyles(styles);

const BookkeepingAlertsSettings = ({
  managingCompanyInfo,
  bookkeepingRules,
  bookkeepingRulesLoading,
  bookkeepingRulesRefetch,
  bookkeepingAlertsLoading,
  bookkeepingAlertsRefetch,
  bookkeepingAlerts,
  onCreateBookkeepingAlert: onUpsertBookkeepingAlert,
  onCreateBookkeepingAlertPreferences,
  onUpdateBookkeepingAlertPreferences,
  onUpsertBookkeepingRule,
  bookkeepingAlertPreferencesRefetch,
  bookkeepingAlertPreferencesLoading,
  bookkeepingAlertPreferences,
  onAddOrUpdateCompany,
  getCompanyInfo,
  getCompanyInfoLoading,
  getCompanyInfoRefetch,
}) => {
  const classes = useStyles();
  const location = useLocation();

  const [updatingTimeZoneTime, setUpdatingTimeZoneTime] = useState(false);
  const [updatingRecipients, setUpdatingRecipients] = useState(false);
  const [emailListEditorOptions, setEmailListEditorOptions] = useState({
    open: false,
  });
  const [
    showDisabledEmailAlertsConfirmDialog,
    setShowDisabledEmailAlertsConfirmDialog,
  ] = useState(false);
  const [backUrl, setBackUrl] = useState('/alerts');

  const listRef = useRef(null);
  const headerWrapperRef = useRef(null);
  const isListOnScreen = useOnScreen(listRef);
  const isHeaderOnScreen = useOnScreen(headerWrapperRef);
  const levelButtonSegmentRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const backType = searchParams.get('backType');
    searchParams.delete('backType');

    const backPeriod = searchParams.get('backPeriod');
    searchParams.delete('backPeriod');

    // Set the back URL to make sure the dashboard is restored to the
    // previous state before the user navigated to this report
    if (backType || backPeriod) {
      const backSearchParams = new URLSearchParams();
      if (backType) {
        backSearchParams.set('type', backType);
      }

      if (backPeriod) {
        backSearchParams.set('period', backPeriod);
      }

      const searchParamsString = searchParams.toString();
      const url = searchParamsString
        ? `${window.location.pathname}?${searchParams.toString()}`
        : window.location.pathname;
      window.history.replaceState({}, document.title, url);

      setBackUrl(`/alerts?${backSearchParams.toString()}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSticky =
    headerWrapperRef.current &&
    listRef.current &&
    isListOnScreen &&
    !isHeaderOnScreen;

  const alertLists = useMemo(() => {
    let bookkeepingAlertEmailLists = null;
    if (managingCompanyInfo?.managingCompanyId && getCompanyInfo) {
      const companyInfo = getCompanyInfo
        ? _.find(getCompanyInfo, {
            companyId: managingCompanyInfo.managingCompanyId,
          })
        : null;

      if (companyInfo) {
        bookkeepingAlertEmailLists = _.get(
          companyInfo,
          'bookkeepingAlertEmailLists',
          []
        );
      }
    }
    return bookkeepingAlertEmailLists;
  }, [getCompanyInfo, managingCompanyInfo]);

  const closeEmailListEditor = () => {
    setEmailListEditorOptions({ open: false });
  };

  const setTimezoneTime = async newPref => {
    setUpdatingTimeZoneTime(true);

    const newPreferences = {
      ...bookkeepingAlertPreferences,
      ...newPref,
    };

    await onUpdateBookkeepingAlertPreferences(newPreferences);
    setUpdatingTimeZoneTime(false);
  };

  const handleItemChange = async ({ type, ...changedItem }) => {
    if (type === BOOKKEEPING_ALERT_TYPES.RULE) {
      await onUpsertBookkeepingRule(changedItem);
    } else if (type === BOOKKEEPING_ALERT_TYPES.ALERT) {
      await onUpsertBookkeepingAlert(changedItem);
    }
  };

  const handleEmailAlertsEnabledToggle = async enabled => {
    // if they dont already have a timezone and time set, then set it to their current timezone and pick 9pm for them,
    //  they can change it as they see fit
    if (!bookkeepingAlertPreferences?.timeZone) {
      const timeZone = browserBasedTimezoneAutoSelect();
      const timeOfDay = '02:00';
      await onCreateBookkeepingAlertPreferences({
        id: uuid(),
        companyId: managingCompanyInfo.managingCompanyId,
        enabled,
        timeZone,
        timeOfDay,
      });
    } else {
      await onUpdateBookkeepingAlertPreferences({
        ...bookkeepingAlertPreferences,
        enabled,
      });
    }
    await bookkeepingAlertPreferencesRefetch();
  };

  const showEmailListEditor = (initialListId = null) => {
    setEmailListEditorOptions({
      open: true,
      initialListId,
    });
  };

  const handleEditListNameButtonClick = ({ listId }) => {
    showEmailListEditor(listId);
  };

  const handleEmailListsChange = async ({ newEmailLists }) => {
    setUpdatingRecipients(true);
    const cleanEmailLists = newEmailLists.map(list => {
      const cleanList = { ...list };
      delete cleanList.__typename;
      return cleanList;
    });

    try {
      await onAddOrUpdateCompany({
        bookkeepingAlertEmailLists: cleanEmailLists,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('handleEmailListChanges onAddOrUpdateCompany err: ', err);
    }
    await Promise.all([
      await getCompanyInfoRefetch(),
      await bookkeepingRulesRefetch(),
      await bookkeepingAlertsRefetch(),
    ]);
    setUpdatingRecipients(false);
  };

  const initializing =
    (!bookkeepingAlertPreferences || !alertLists) &&
    (bookkeepingAlertPreferencesLoading ||
      bookkeepingRulesLoading ||
      bookkeepingAlertsLoading ||
      getCompanyInfoLoading);

  let warningToShow = '';
  let warningColorToUse = palette.brandColorOrange;
  if (!initializing) {
    if (!bookkeepingAlertPreferences?.enabled) {
      warningToShow = `Bookkeeping alert email notifications are currently paused. To
      receive email notifications set Email Notification Status to
      ENABLED.`;
      warningColorToUse = palette.brandColorDarkGrey;
    } else if (!alertLists?.length) {
      warningToShow = `You have not set up any recipient groups. To receive
      email notifications, please set up at least one email recipient group.`;
    }
  }

  const numberOfAlertColumns = _.size(alertLists);
  const totalColumns = numberOfAlertColumns + 1; // 1 for the enabled (RULE) column
  const totalWidth =
    WIDTH_OF_WORDING_COLUMN + WIDTH_OF_ALERT_TOGGLE_COLUMN * totalColumns;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'auto',
          height: 'calc(100vh - 64px)',
        }}
        ref={containerRef}
      >
        <Grid container item className={classes.settingsHeader}>
          <Grid container item alignItems="center">
            <BookkeepingAlertsBackButton to={backUrl} />
            <Typography variant="h3">Bookkeeping Alert Settings</Typography>
          </Grid>
        </Grid>
        {warningToShow && (
          <Grid
            container
            item
            className={classes.headerWarning}
            style={{ backgroundColor: warningColorToUse }}
          >
            <Grid item>
              <Typography
                variant="body2"
                style={{ color: palette.white, fontWeight: 'bold' }}
              >
                {warningToShow}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container item>
          {showSticky && (
            <BookkeepingAlertsSettingsListHeader
              alertLists={alertLists}
              showSticky
              onEditListNameButtonClick={handleEditListNameButtonClick}
              width={totalWidth}
            />
          )}
          <Grid container item spacing={2} style={{ padding: 16, margin: 0 }}>
            <Grid item lg={6} sm={12}>
              <Paper className={classes.card}>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.optionsHeaderWrapper}
                >
                  <Typography
                    variant="body1"
                    className={classes.optionsHeader}
                    style={{ display: 'inline-flex' }}
                  >
                    Overview
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.optionsBody}>
                  <Typography variant="body1">
                    Welcome to the settings for your bookkeeping alerts. From
                    here you can set your preferences, and enable or disable the
                    bookkeeping rules you&apos;d like included in your
                    Bookkeeping Report.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ marginTop: 16, marginBottom: 16 }}
                  >
                    <b>IMPORTANT</b>
                  </Typography>
                  <Typography variant="body1">
                    Use the <i>Preferences</i> menu to set the time Level
                    automatically generates your Bookkeeping Report. This report
                    will capture all bookkeeping transactions created/modified
                    in the past 24 hours that meet your enabled Bookkeeping
                    Rules. To ensure accuracy, it&apos;s best to set a time when
                    your bookkeeping software has the least activity, like 2 AM.
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={6} sm={12}>
              <Paper
                className={classes.card}
                id="userflowAnchor--bkaPreferencesPane"
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.optionsHeaderWrapper}
                >
                  <Typography
                    variant="body1"
                    className={classes.optionsHeader}
                    style={{ display: 'inline-flex' }}
                  >
                    Preferences
                  </Typography>
                  {updatingTimeZoneTime && (
                    <CircularProgress size={20} style={{ marginLeft: 8 }} />
                  )}
                </Grid>
                <Grid container item xs={12} className={classes.optionsBody}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.optionsSection}
                  >
                    <Grid
                      container
                      item
                      xs={6}
                      className={classes.optionsSection}
                    >
                      <ChooseTimeTimezone
                        label="Generate Bookkeeping Report at"
                        selectedTimezone={
                          bookkeepingAlertPreferences?.timeZone ||
                          managingCompanyInfo?.timeZone ||
                          browserBasedTimezoneAutoSelect()
                        }
                        selectedTimeOfDay={
                          bookkeepingAlertPreferences?.timeOfDay || '02:00'
                        }
                        passbackChoice={setTimezoneTime}
                        disabled={updatingTimeZoneTime}
                        loading={initializing}
                        hideTimezoneDropdownInput={
                          managingCompanyInfo?.isSubscriptionBased
                        }
                        showTimezoneAbbrevInTimeInput={
                          managingCompanyInfo?.isSubscriptionBased
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NextReportWillRun customStyles={{ marginLeft: 8 }} />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel id="time-to-send" shrink>
                        Email Alerts Status
                      </InputLabel>
                      <Grid item style={{ marginTop: 8 }}>
                        {initializing && (
                          <div style={{ width: 180, height: 35 }}>
                            <ContentLoader width={100} height={18}>
                              <rect
                                x="0"
                                y="0"
                                rx="0"
                                ry="0"
                                width="100"
                                height="18"
                              />
                            </ContentLoader>
                          </div>
                        )}
                        {!initializing && (
                          <LevelButtonSegment
                            ref={levelButtonSegmentRef}
                            size="medium"
                            initiallySelected={
                              bookkeepingAlertPreferences?.enabled
                                ? 'enabled'
                                : 'paused'
                            }
                            options={[
                              {
                                label: 'ENABLED',
                                value: 'enabled',
                              },
                              {
                                label: 'PAUSED',
                                value: 'paused',
                              },
                            ]}
                            passbackChosenValue={type => {
                              const enabled = type === 'enabled';

                              if (
                                bookkeepingAlertPreferences?.enabled === enabled
                              ) {
                                // if no change, do nothing
                                return;
                              }

                              if (!enabled) {
                                setShowDisabledEmailAlertsConfirmDialog(true);
                              } else {
                                handleEmailAlertsEnabledToggle(true);
                              }
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.optionsSection}>
                    <Grid item xs={6}>
                      <InputLabel id="time-to-send" shrink>
                        Alert Email Recipients
                      </InputLabel>
                      <Button
                        onClick={() => {
                          showEmailListEditor();
                        }}
                        variant="outlined"
                        style={{ marginTop: 8 }}
                        color="primary"
                        disabled={getCompanyInfoLoading}
                      >
                        Manage Recipient Groups
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.card}>
                <BookkeepingAlertsSettingsList
                  alerts={bookkeepingAlerts}
                  rules={bookkeepingRules}
                  refetchAlerts={bookkeepingAlertsRefetch}
                  refetchRules={bookkeepingRulesRefetch}
                  alertLists={alertLists}
                  onChange={handleItemChange}
                  onEditListNameButtonClick={handleEditListNameButtonClick}
                  setListRef={ref => {
                    listRef.current = ref;
                  }}
                  setHeaderWrapperRef={ref => {
                    headerWrapperRef.current = ref;
                  }}
                  loading={initializing}
                  managingCompanyInfo={managingCompanyInfo}
                  showSticky={showSticky}
                  bookkeepingAlertPreferences={bookkeepingAlertPreferences}
                  refetchPreferences={bookkeepingAlertPreferencesRefetch}
                />
              </Paper>
            </Grid>
          </Grid>
          <BackToTopButton scrollElementRef={containerRef} />
        </Grid>
        {initializing && (
          <LoadingCover loader="linear">
            <Typography variant="h3" align="center">
              Loading your settings…
            </Typography>
          </LoadingCover>
        )}
      </div>
      {emailListEditorOptions.open && !getCompanyInfoLoading && (
        <Dialog open maxWidth="md" fullWidth>
          <BookkeepingAlertsEmailLists
            listOfEmailLists={
              alertLists || DEFAULT_BOOKKEEPING_ALERT_EMAIL_LISTS
            }
            initialListId={emailListEditorOptions.initialListId}
            toClose={closeEmailListEditor}
            onEmailListsChange={handleEmailListsChange}
          />
          {updatingRecipients && <LoadingCover />}
        </Dialog>
      )}
      {showDisabledEmailAlertsConfirmDialog && (
        <OkCancelDialog
          open
          okButtonText="Yes"
          cancelButtonText="Cancel"
          title="Pause Email Alerts?"
          dividers={false}
          onClose={reason => {
            if (reason === 'cancel') {
              levelButtonSegmentRef.current.setChosenValue('enabled');
            }

            setShowDisabledEmailAlertsConfirmDialog(false);
          }}
          onConfirm={() => {
            handleEmailAlertsEnabledToggle(false);
          }}
        >
          <Typography>
            This will turn off all email alerts for all groups. Are you sure you
            want to do this?
          </Typography>
          <br />
          <Typography>
            If you just want to stop receiving emails for a certain email
            address or group, please manage the recipient groups instead.
          </Typography>
        </OkCancelDialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  CreateBookkeepingAlertPreferencesAction,
  GetBookkeepingAlertsPreferencesAction,
  UpdateBookkeepingAlertPreferencesAction,
  GetBookkeepingRulesAction,
  GetBookkeepingAlertsAction,
  CreateBookkeepingAlertAction,
  UpsertBookkeepingRuleAction,
  AddOrUpdateCompanyAction,
  GetCompanyInfoAction,
  connect(mapStateToProps)
)(BookkeepingAlertsSettings);
