import React from 'react';

import { DashboardControlBar } from '../dashboard';
import CustomScanSettings, { LAYOUT } from './custom-scan-settings';

const CustomScanDashboardControlBar = ({
  title,
  loading,
  hideAccessExplainer,
  initialSettings,
  defaultBookkeepingRules,
  disabled,
  error,
  setError,
  onScanClick,
}) => {
  return (
    <DashboardControlBar
      title={title}
      hideAccessExplainer={hideAccessExplainer}
    >
      <CustomScanSettings
        loading={loading}
        defaultBookkeepingRules={defaultBookkeepingRules}
        initialSettings={initialSettings}
        layout={LAYOUT.TOOLBAR}
        disabled={disabled}
        error={error}
        setError={setError}
        onScanClick={onScanClick}
      />
    </DashboardControlBar>
  );
};

export default CustomScanDashboardControlBar;
