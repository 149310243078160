import React, { useContext, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { QboTools } from 'level-foundation';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'react-apollo';
import uuid from 'uuid';

import { UpsertBookkeepingEventMetaAction } from '../../../graphql/graphql';
import { batchRequest, monetaryRender } from '../../../helpers';
import palette from '../../../theme/palette';
import { BOOKKEEPING_REPORT_ALERT_DICTIONARY } from '../bookkeeping-alert-dictionary';
import BookkeepingAlertsToggle, {
  COMPONENT_TYPES,
} from '../bookkeeping-alerts-toggle';
import {
  ACCOUNT_KEY_TO_TYPE,
  BOOKKEEPING_ALERT_ENTITY_TYPE_PRETTY_AND_PLURAL,
  BOOKKEEPING_ALERT_REPORT_DATA_TYPE,
  BOOKKEEPING_REPORT_EVENT_META_STATE,
  BOOKKEEPING_REPORT_EVENT_META_TYPE,
} from '../bookkeeping-alerts.constants';
import BookkeepingAlertsReportAlertListItemTableCellCheckboxArray from './bookkeeping-alerts-report-alert-list-item-table-cell-checkbox-array';
import BookkeepingAlertsReportAlertListItemTableCellText from './bookkeeping-alerts-report-alert-list-item-table-cell-text';
import BookkeepingAlertsReportAlertListItemTableCellTxnArray from './bookkeeping-alerts-report-alert-list-item-table-cell-txn-array';
import BookkeepingAlertsReportContext from './bookkeeping-alerts-report.context';

const FORMAT_TYPE = {
  MONEY: 'money',
  DATE: 'date',
  ACCOUNT_TYPE: 'accountType',
  TEXT: 'text',
};

const RENDER_TYPE = {
  CHECKBOX: 'checkbox',
  CHECKBOX_ARRAY: 'checkboxArray',
  TEXT: 'text',
  TXN_ARRAY: 'txnArray',
};

const COLUMN_KEY_TO_FORMAT_TYPE_MAP = {
  amount: FORMAT_TYPE.MONEY,
  debitAmount: FORMAT_TYPE.MONEY,
  creditAmount: FORMAT_TYPE.MONEY,
  taxInAmount: FORMAT_TYPE.MONEY,
  txnDate: FORMAT_TYPE.DATE,
  accountType: FORMAT_TYPE.ACCOUNT_TYPE,
};

const COLUMN_KEYS_TO_SKIP_IF_SAME_TXN_PRECEDING = [
  'txnDate',
  'txnType',
  'docNum',
  'alertInstanceId',
];

const CHECKBOX_COLUMN_WIDTH = 110;

const useStyles = makeStyles(theme => ({
  styledTable: {
    borderCollapse: 'collapse',
    borderSpacing: '0 !important',
    border: '1px solid #333',
    borderBottomColor: '#ccc',
    boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.15)',
  },
  styledTableForPrint: {
    borderCollapse: 'collapse',
    borderSpacing: '0 !important',
    border: '1px solid #333',
    borderBottomColor: '#ccc',
  },
  tableHeader: {
    backgroundColor: theme.palette.brandColorPrimary,
    color: '#ffffff',
    textAlign: 'left',
  },
}));

const BookkeepingAlertsReportAlertListItemTable = ({
  alertIdentifier,
  dataType = null,
  data,
  addressedStateEventMetaMap,
  onUpsertBookkeepingEventMeta,
  processingAlertInstanceMap,
  setProcessingAlertInstanceMap,
  hideAddressedColumn,
  isPrinting = false,
}) => {
  const bookkeepingAlertReportContext = useContext(
    BookkeepingAlertsReportContext
  );

  const classes = useStyles();
  const [expandedRowIdsMap, setExpandedRowIdsMap] = useState({});

  const columnKeyToFormatType = columnKey => {
    // Currently, all key value pair tables are summary and value is always money
    if (
      columnKey === 'value' &&
      dataType === BOOKKEEPING_ALERT_REPORT_DATA_TYPE.KEY_VALUE_PAIR_ARRAY
    ) {
      return FORMAT_TYPE.MONEY;
    }

    return COLUMN_KEY_TO_FORMAT_TYPE_MAP[columnKey] || FORMAT_TYPE.TEXT;
  };

  const columnKeyToRenderType = columnKey => {
    if (columnKey === 'alertInstanceId') {
      return RENDER_TYPE.CHECKBOX;
    }

    return RENDER_TYPE.TEXT;
  };

  const formatValue = ({ value, columnKey }) => {
    const type = columnKeyToFormatType(columnKey);
    switch (type) {
      case FORMAT_TYPE.MONEY:
        return monetaryRender({
          value,
          withDecimals: true,
        });
      case FORMAT_TYPE.DATE:
        return moment.utc(value).format('YYYY-MM-DD');
      case FORMAT_TYPE.ACCOUNT_TYPE:
        return ACCOUNT_KEY_TO_TYPE[value];
      case FORMAT_TYPE.TEXT:
        return value;
      default:
        return value;
    }
  };

  const tableDataToUse = useMemo(() => {
    let typeOfAlertDetails =
      BOOKKEEPING_REPORT_ALERT_DICTIONARY[alertIdentifier];
    if (!typeOfAlertDetails) {
      return null;
    }

    if (hideAddressedColumn) {
      // remove the alertInstanceId column
      typeOfAlertDetails = {
        ...typeOfAlertDetails,
        labelsAndLocation: _.filter(
          typeOfAlertDetails.labelsAndLocation,
          item => item.key !== 'alertInstanceId'
        ),
      };
    }

    const { customSortFunc, labelsAndLocation } = typeOfAlertDetails;
    const sortFunc =
      customSortFunc ||
      (transactions => {
        return _.orderBy(
          transactions,
          ['txnDate', 'txnType', txn => Number(txn.txnId)],
          ['asc', 'asc', 'asc']
        );
      });

    const headerRow = [];
    const generalCellStyles = [];
    const generalCellContainerStyles = [];
    const columnRenderTypes = [];
    _.forEach(labelsAndLocation, ({ label, key }) => {
      const formatType = columnKeyToFormatType(key);
      const renderType = columnKeyToRenderType(key);
      const cellStyle = { breakInside: 'avoid' };
      const cellContainerStyle = {};
      const headerCellStyle = { breakInside: 'avoid' };
      if (formatType === FORMAT_TYPE.MONEY) {
        cellStyle.justifyContent = 'flex-end';
      }

      if (formatType === FORMAT_TYPE.DATE) {
        cellContainerStyle.width = 140;
      }

      if (renderType === RENDER_TYPE.CHECKBOX) {
        headerCellStyle.justifyContent = 'center';
        cellStyle.justifyContent = 'center';
        cellContainerStyle.width = CHECKBOX_COLUMN_WIDTH;
      }

      headerRow.push({
        cellId: uuid(),
        value: label,
        renderType: RENDER_TYPE.TEXT, // Header is always text
        cellStyle: { ...headerCellStyle },
        cellContainerStyle: { ...cellContainerStyle },
      });

      columnRenderTypes.push(renderType);
      generalCellContainerStyles.push(cellContainerStyle);
      generalCellStyles.push(cellStyle);
    });

    const tableData = [
      {
        rowId: uuid(),
        data: headerRow,
        rowStyle: { fontWeight: 'bold' },
        isHeader: true,
      },
    ];

    let hasQboUrlColumn = false;

    switch (dataType) {
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.USER_TXNS: {
        const sortedData = sortFunc(data);
        if (
          sortedData[0]?.type === BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TXN_PAIR
        ) {
          _.forEach(sortedData, txnPair => {
            const { txn, otherTxn } = txnPair;
            _.forEach([otherTxn, txn], (txnInfo, txnPairIndex) => {
              const row = [];
              _.forEach(labelsAndLocation, ({ key }, index) => {
                let value = formatValue({
                  value: txnInfo[key],
                  columnKey: key,
                });

                if (key === 'alertInstanceId' && txnPairIndex === 0) {
                  // No need to show checkbox for the duplicate txn (most recent modified txn)
                  value = '';
                }

                row.push({
                  cellId: uuid(),
                  value,
                  cellStyle: { ...generalCellStyles[index] },
                  cellContainerStyle: { ...generalCellContainerStyles[index] },
                  renderType: columnRenderTypes[index],
                });
              });

              const qboTxnUrl = QboTools.qboUrlFromTxn(txnInfo);
              if (qboTxnUrl) {
                hasQboUrlColumn = true;
              }

              tableData.push({
                rowId: uuid(),
                data: row,
                rowStyle: {
                  color: txnPairIndex === 0 ? palette.brandColorMidGrey : null,
                },
                qboTxnUrl,
              });
            });
          });
        } else if (
          sortedData[0]?.type === BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TXN_INFO
        ) {
          let lastRow = null;

          _.forEach(sortedData, (txnInfo, i) => {
            const rowStyle = {};
            let hasTxnPreceding = false;
            if (i > 0) {
              const previousTxnInfo = sortedData[i - 1];

              // Check if this row has the same txnId as the preceding row. If so, they should have the same background color.
              // Otherwise, toggle the background color between white and grey.
              if (txnInfo.txnId === previousTxnInfo.txnId) {
                hasTxnPreceding = true;

                if (lastRow?.rowStyle?.backgroundColor) {
                  rowStyle.backgroundColor = lastRow.rowStyle.backgroundColor;
                }
              } else if (!lastRow?.rowStyle?.backgroundColor) {
                rowStyle.backgroundColor = palette.brandColorLightGrey;
              }
            }

            const row = [];
            _.forEach(labelsAndLocation, ({ key }, index) => {
              let value = formatValue({ value: txnInfo[key], columnKey: key });
              const cellContainerStyle = {
                ...generalCellContainerStyles[index],
              };
              if (
                hasTxnPreceding &&
                COLUMN_KEYS_TO_SKIP_IF_SAME_TXN_PRECEDING.includes(key)
              ) {
                value = '';
                cellContainerStyle.borderTopWidth = 0;
              }

              row.push({
                cellId: uuid(),
                value,
                cellStyle: { ...generalCellStyles[index] },
                cellContainerStyle,
                renderType: columnRenderTypes[index],
              });
            });

            let qboTxnUrl = QboTools.qboUrlFromTxn(txnInfo);
            if (qboTxnUrl) {
              hasQboUrlColumn = true;
            }

            if (hasTxnPreceding) {
              // If the txnId is the same as the previous one, don't show the qbo link
              qboTxnUrl = '';
            }

            const tableRow = {
              rowId: uuid(),
              data: row,
              rowStyle,
              qboTxnUrl,
            };
            tableData.push(tableRow);

            lastRow = tableRow;
          });
        }
        break;
      }
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.ENTITY_TXN_COUNT_ARRAY: {
        const groupedData = _.groupBy(data, 'entityType');
        // add addressed column to header
        tableData[0].data.push({
          cellId: uuid(),
          value: 'Addressed',
          renderType: RENDER_TYPE.TEXT,
          cellStyle: { justifyContent: 'center' },
          cellContainerStyle: {},
        });

        _.forEach(groupedData, (entityTxnCountArray, entityType) => {
          const sortedEntityTxnCountArray = sortFunc(entityTxnCountArray);

          const txnArray = sortedEntityTxnCountArray.map(txn => {
            const qboTxnUrl = QboTools.qboUrlFromTxn({
              txnId: txn.txnId,
              txnType: QboTools.QBO_TXN_TYPE.VOIDED_OR_DELETED,
            });

            return { ...txn, qboTxnUrl };
          });
          const tableRow = {
            data: [
              {
                cellId: uuid(),
                value:
                  BOOKKEEPING_ALERT_ENTITY_TYPE_PRETTY_AND_PLURAL[entityType],
                cellStyle: { ...generalCellStyles[0] },
                cellContainerStyle: { ...generalCellContainerStyles[0] },
                renderType: columnRenderTypes[0],
              },
              {
                cellId: uuid(),
                value: txnArray,
                cellStyle: { ...generalCellStyles[1] },
                cellContainerStyle: { padding: 0, width: '40%' },
                renderType: RENDER_TYPE.TXN_ARRAY,
              },
              {
                cellId: uuid(),
                value: sortedEntityTxnCountArray.map(
                  ({ alertInstanceId }) => alertInstanceId
                ),
                renderType: RENDER_TYPE.CHECKBOX_ARRAY,
                cellContainerStyle: {
                  width: CHECKBOX_COLUMN_WIDTH,
                  padding: 0,
                },
              },
            ],
            rowId: uuid(),
          };
          tableData.push(tableRow);
        });
        break;
      }
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.ITEM_INFO_ARRAY:
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.KEY_VALUE_PAIR_ARRAY:
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TIME_ACTIVITY_INFO_ARRAY: {
        const sortedData = sortFunc(data);
        let lastRow = null;
        _.forEach(sortedData, (info, i) => {
          const rowStyle = {};
          if (i > 0) {
            // toggle the background color between white and grey.
            if (!lastRow?.rowStyle?.backgroundColor) {
              rowStyle.backgroundColor = palette.brandColorLightGrey;
            }
          }

          const row = [];
          _.forEach(labelsAndLocation, ({ key }, index) => {
            row.push({
              cellId: uuid(),
              value: formatValue({ value: info[key], columnKey: key }),
              cellStyle: { ...generalCellStyles[index] },
              cellContainerStyle: { ...generalCellContainerStyles[index] },
              renderType: columnRenderTypes[index],
            });
          });

          const qboTxnUrl = QboTools.qboUrlFromTxn({
            txnId: info.id,
            txnType: QboTools.QBO_TXN_TYPE.TIME_CHARGE,
          });

          if (qboTxnUrl) {
            hasQboUrlColumn = true;
          }

          const tableRow = {
            data: row,
            qboTxnUrl,
            rowStyle,
            rowId: uuid(),
          };
          tableData.push(tableRow);

          lastRow = tableRow;
        });
        break;
      }
      case BOOKKEEPING_ALERT_REPORT_DATA_TYPE.VENDOR_INFO_ARRAY: {
        const sortedData = sortFunc(data);
        let lastRow = null;
        _.forEach(sortedData, (info, i) => {
          const rowStyle = {};
          if (i > 0) {
            // toggle the background color between white and grey.
            if (!lastRow?.rowStyle?.backgroundColor) {
              rowStyle.backgroundColor = palette.brandColorLightGrey;
            }
          }
          const row = [];
          _.forEach(labelsAndLocation, ({ key }, index) => {
            row.push({
              cellId: uuid(),
              value: formatValue({ value: info[key], columnKey: key }),
              cellStyle: { ...generalCellStyles[index] },
              cellContainerStyle: { ...generalCellContainerStyles[index] },
              renderType: columnRenderTypes[index],
            });
          });

          const qboTxnUrl = QboTools.qboUrlFromVendor({
            vendorId: info.vendorId,
          });

          if (qboTxnUrl) {
            hasQboUrlColumn = true;
          }

          const tableRow = {
            data: row,
            qboTxnUrl,
            rowStyle,
            rowId: uuid(),
          };
          tableData.push(tableRow);

          lastRow = tableRow;
        });
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.warn('dataType not yet supported', dataType);
    }

    if (hasQboUrlColumn) {
      // add padding or qbo link to first cell
      _.forEach(tableData, row => {
        if (!row.isHeader) {
          const firstCell = row.data[0];
          if (row.qboTxnUrl) {
            firstCell.qboTxnUrl = row.qboTxnUrl;
          } else {
            firstCell.cellStyle.paddingLeft = 38;
          }
        }
      });
    }

    return tableData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertIdentifier, data, dataType]);

  const createAddressedStateEventMetaInfo = ({ alertInstanceId, newValue }) => {
    const existingEventMeta = addressedStateEventMetaMap[alertInstanceId];

    let eventMetaInfo;
    if (existingEventMeta) {
      eventMetaInfo = {
        ...existingEventMeta,
        value: newValue,
      };
    } else {
      eventMetaInfo = {
        id: uuid(),
        alertInstanceId,
        metaType: BOOKKEEPING_REPORT_EVENT_META_TYPE.ADDRESSED_STATE,
        value: newValue,
        alertIdentifier,
      };
    }

    return eventMetaInfo;
  };

  const handleToggle = ({ alertInstanceId }) => async event => {
    const { companyId, snapshotId, reportDate } = bookkeepingAlertReportContext;
    const newValue = event.target.checked
      ? BOOKKEEPING_REPORT_EVENT_META_STATE.ADDRESSED
      : '';

    setProcessingAlertInstanceMap(currentState => {
      return {
        ...currentState,
        [alertInstanceId]: true,
      };
    });

    const eventMetaInfo = createAddressedStateEventMetaInfo({
      alertInstanceId,
      newValue,
    });

    await onUpsertBookkeepingEventMeta({
      ...eventMetaInfo,
      companyId,
      snapshotId,
      reportDate,
    });

    setProcessingAlertInstanceMap(currentState => {
      return {
        ...currentState,
        [alertInstanceId]: false,
      };
    });
  };

  const handleCellCheckboxArrayChange = async changedItems => {
    const { companyId, snapshotId, reportDate } = bookkeepingAlertReportContext;
    const processingTrueMap = {};
    const processingFalseMap = {};
    _.forEach(changedItems, changedItem => {
      processingTrueMap[changedItem.alertInstanceId] = true;
      processingFalseMap[changedItem.alertInstanceId] = false;
    });

    setProcessingAlertInstanceMap(currentState => {
      return {
        ...currentState,
        ...processingTrueMap,
      };
    });

    const { rejectedItems } = await batchRequest({
      items: changedItems,
      noOfItemsPerBatch: 5,
      actionFunction: ({ alertInstanceId, newValue }) => {
        const eventMetaInfo = createAddressedStateEventMetaInfo({
          alertInstanceId,
          newValue,
        });

        return onUpsertBookkeepingEventMeta({
          ...eventMetaInfo,
          companyId,
          snapshotId,
          reportDate,
        });
      },
    });

    if (!_.isEmpty(rejectedItems)) {
      // eslint-disable-next-line no-console
      console.error('Failed to update the following items', rejectedItems);
    }

    setProcessingAlertInstanceMap(currentState => {
      return {
        ...currentState,
        ...processingFalseMap,
      };
    });
  };

  return (
    <table
      {...(dataType !==
        BOOKKEEPING_ALERT_REPORT_DATA_TYPE.KEY_VALUE_PAIR_ARRAY && {
        width: '100%',
      })}
      cellPadding="0"
      cellSpacing="0"
      className={isPrinting ? classes.styledTableForPrint : classes.styledTable}
    >
      <tbody>
        {_.map(tableDataToUse, ({ data: row, rowStyle, rowId, isHeader }) => {
          return (
            <tr
              style={{ height: 32, ...rowStyle }}
              className={isHeader ? classes.tableHeader : classes.tableRow}
              key={rowId}
            >
              {_.map(
                row,
                ({
                  cellId,
                  value,
                  cellStyle,
                  cellContainerStyle,
                  qboTxnUrl,
                  renderType,
                }) => {
                  return (
                    <td
                      key={cellId}
                      style={{
                        border: '1px solid #ccc',
                        borderBottomWidth: 0,
                        paddingTop: 2,
                        paddingRight: 4,
                        paddingBottom: 2,
                        paddingLeft: 4,
                        ...cellContainerStyle,
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          ...cellStyle,
                        }}
                      >
                        {renderType === RENDER_TYPE.TEXT && (
                          <BookkeepingAlertsReportAlertListItemTableCellText
                            value={value}
                            qboTxnUrl={qboTxnUrl}
                          />
                        )}

                        {renderType === RENDER_TYPE.CHECKBOX && value && (
                          <BookkeepingAlertsToggle
                            component={COMPONENT_TYPES.CHECKBOX}
                            checked={
                              addressedStateEventMetaMap[value]?.value ===
                              BOOKKEEPING_REPORT_EVENT_META_STATE.ADDRESSED
                            }
                            isLoading={processingAlertInstanceMap[value]}
                            size="small"
                            onToggle={handleToggle({
                              alertInstanceId: value,
                            })}
                          />
                        )}

                        {renderType === RENDER_TYPE.TXN_ARRAY && (
                          <BookkeepingAlertsReportAlertListItemTableCellTxnArray
                            transactions={value}
                            expanded={!!expandedRowIdsMap[rowId]}
                            onExpandedChange={() => {
                              setExpandedRowIdsMap(currentState => {
                                return {
                                  ...currentState,
                                  [rowId]: !currentState[rowId],
                                };
                              });
                            }}
                          />
                        )}

                        {renderType === RENDER_TYPE.CHECKBOX_ARRAY && (
                          <BookkeepingAlertsReportAlertListItemTableCellCheckboxArray
                            alertInstanceIds={value}
                            addressedStateEventMetaMap={
                              addressedStateEventMetaMap
                            }
                            processingAlertInstanceMap={
                              processingAlertInstanceMap
                            }
                            onChange={handleCellCheckboxArrayChange}
                            expanded={!!expandedRowIdsMap[rowId]}
                          />
                        )}
                      </span>
                    </td>
                  );
                }
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default compose(UpsertBookkeepingEventMetaAction)(
  BookkeepingAlertsReportAlertListItemTable
);
