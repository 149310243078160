import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import palette from '../../theme/palette';

const LevelSegmentButton = ({
  options = [],
  passbackChosenValue,
  size = 'medium',
  initiallySelected,
  disabled,
  buttonStyle,
  levelSegmentButtonRef,
}) => {
  const [chosenValue, setChosenValue] = useState(initiallySelected || null);

  const handleChoiceChange = newChoice => () => {
    setChosenValue(newChoice);
    passbackChosenValue(newChoice);
  };

  // expose setChosenValue function to parent component
  useImperativeHandle(levelSegmentButtonRef, () => ({
    setChosenValue,
  }));

  const renderSegments = () => {
    return options.map(buttonInfo => {
      return (
        <Button
          key={buttonInfo.value}
          value={buttonInfo.value}
          disabled={disabled}
          onClick={handleChoiceChange(buttonInfo.value)}
          style={{
            backgroundColor:
              chosenValue === buttonInfo.value
                ? palette.primary.main
                : palette.white,
            color:
              chosenValue === buttonInfo.value
                ? palette.white
                : palette.primary.main,
            ...buttonStyle,
          }}
        >
          {buttonInfo.label || buttonInfo.value}
        </Button>
      );
    });
  };

  return (
    <ButtonGroup size={size} variant="outlined" color="primary">
      {renderSegments()}
    </ButtonGroup>
  );
};

export default forwardRef((props, ref) => (
  <LevelSegmentButton {...props} levelSegmentButtonRef={ref} />
));
