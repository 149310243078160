import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Refresh as ResetIcon } from '@material-ui/icons';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import palette from '../../theme/palette';
import { BOOKKEEPING_ALERT_SETTINGS_LAYOUT } from '../bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings-layout';
import BookkeepingAlertsSettingsListItem from '../bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings-list-item';
import { BOOKKEEPING_ALERT_SETTINGS_INFO } from '../bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-report-settings-info';
import {
  ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_CUSTOM_SCAN,
  IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER,
} from '../bookkeeping-alerts/bookkeeping-alerts.constants';
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip';
import InputContentLoader from '../input-content-loader/input-content-loader';

export const LAYOUT = {
  TOOLBAR: 'toolbar',
  BOX: 'box',
};

const styles = theme => {
  return {
    listSubheader: {
      width: '100%',
      paddingTop: 16,
      paddingRight: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      borderBottom: '1px solid #e0e0e0',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: '110%',
      background: '#fff',
    },
  };
};

const useStyles = makeStyles(styles);

const CustomScanSettingsRuleMultiSelect = ({
  label,
  loading,
  initialSelectedRules,
  defaultBookkeepingRules,
  allSupportedRules,
  setAllSupportedRules,
  managingCompanyInfo,
  showChangeAdornment,
  disabled,
  error,
}) => {
  const classes = useStyles();
  const [layoutToUse, setLayoutToUse] = useState([]);
  const [showMenuDialog, setShowMenuDialog] = useState(false);
  const isAllSupportedRulesInitialized = useRef(false);
  const isInitialSelectedRuleApplied = useRef(false);
  const [defaultBookkeepingRulesMap, setDefaultBookkeepingRulesMap] = useState(
    {}
  );

  const createRuleKey = rule => {
    return `${rule.alertIdentifier}-${rule.alertIdentifierSub || 'null'}`;
  };

  const generateAllRules = ({ supportedAlertIdentifiers, defaultRulesMap }) => {
    const determineRuleToUse = ({
      alertIdentifier,
      alertIdentifierSub = null,
    }) => {
      const existingRule =
        defaultRulesMap[createRuleKey({ alertIdentifier, alertIdentifierSub })];
      if (existingRule) {
        return existingRule;
      }

      return {
        id: uuid(),
        alertIdentifier,
        alertIdentifierSub,
        companyId: managingCompanyInfo.managingCompanyId,
        enabled: false,
      };
    };

    const allRules = [];
    _.forEach(supportedAlertIdentifiers, alertIdentifier => {
      const settingsInfo = BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier];
      const { subs, generalSettings } = settingsInfo;
      const hasSubs = !_.isEmpty(subs);

      if (hasSubs) {
        _.forEach(subs, ({ value: alertIdentifierSub }) => {
          const rule = determineRuleToUse({
            alertIdentifier,
            alertIdentifierSub,
          });
          allRules.push(rule);
        });
      } else {
        const rule = determineRuleToUse({ alertIdentifier });
        allRules.push(rule);
      }

      _.forEach(generalSettings, ({ value: alertIdentifierSub }) => {
        const rule = determineRuleToUse({
          alertIdentifier,
          alertIdentifierSub,
        });
        allRules.push(rule);
      });
    });

    return allRules;
  };

  useEffect(() => {
    const layout = [];
    const defaultRulesMap = {};

    if (!isAllSupportedRulesInitialized.current && defaultBookkeepingRules) {
      const notSupportedAlertIdentifiersMap = {};
      _.forEach(
        ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_CUSTOM_SCAN,
        alertIdentifier => {
          notSupportedAlertIdentifiersMap[alertIdentifier] = true;
        }
      );

      // remove unsupported rules
      _.forEach(defaultBookkeepingRules, rule => {
        if (!notSupportedAlertIdentifiersMap[rule.alertIdentifier]) {
          const key = createRuleKey(rule);
          defaultRulesMap[key] = rule;
        }
      });

      const supportedAlertIdentifiers = [];
      _.forEach(BOOKKEEPING_ALERT_SETTINGS_LAYOUT, ({ alerts, ...others }) => {
        const supportedAlerts = _.filter(alerts, alertIdentifier => {
          const hasSettingsInfo = !!BOOKKEEPING_ALERT_SETTINGS_INFO[
            alertIdentifier
          ];
          if (!hasSettingsInfo) {
            // eslint-disable-next-line no-console
            console.warn(`WARN: No settings info entry for ${alertIdentifier}`);
          }

          return (
            !notSupportedAlertIdentifiersMap[alertIdentifier] && hasSettingsInfo
          );
        });

        if (!_.isEmpty(supportedAlerts)) {
          layout.push({
            alerts: supportedAlerts,
            ...others,
          });
          supportedAlertIdentifiers.push(...supportedAlerts);
        }
      });

      // generate all types of rules
      const allRules = generateAllRules({
        supportedAlertIdentifiers,
        defaultRulesMap,
      });

      setDefaultBookkeepingRulesMap(defaultRulesMap);
      setAllSupportedRules(allRules);
      setLayoutToUse(layout);

      isAllSupportedRulesInitialized.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBookkeepingRules]);

  const supportedRulesByAlertIdentifier = useMemo(() => {
    return _.groupBy(allSupportedRules, 'alertIdentifier');
  }, [allSupportedRules]);

  const setSelectedRules = rulesMapToSet => {
    setAllSupportedRules(currentState => {
      return _.map(currentState, rule => {
        const key = createRuleKey(rule);
        let enabled = false;

        if (rulesMapToSet[key]) {
          enabled = rulesMapToSet[key].enabled;
        }

        return {
          ...rule,
          enabled,
        };
      });
    });
  };

  useEffect(() => {
    // apply initial selected rules if provided
    if (
      !isInitialSelectedRuleApplied.current &&
      !_.isEmpty(allSupportedRules) &&
      initialSelectedRules
    ) {
      const initialSelectedRulesMap = {};
      _.forEach(initialSelectedRules, rule => {
        initialSelectedRulesMap[createRuleKey(rule)] = rule;
      });

      setSelectedRules(initialSelectedRulesMap);

      isInitialSelectedRuleApplied.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSupportedRules, initialSelectedRules]);

  const areOnlyDefaultRulesSelected = useMemo(() => {
    for (let i = 0; i < allSupportedRules.length; i += 1) {
      const rule = allSupportedRules[i];
      const key = createRuleKey(rule);
      const defaultRule = defaultBookkeepingRulesMap[key];
      if (!defaultRule && rule.enabled) {
        return false;
      }

      if (defaultRule && defaultRule.enabled !== rule.enabled) {
        return false;
      }
    }

    return true;
  }, [allSupportedRules, defaultBookkeepingRulesMap]);

  const totalRulesSelected = useMemo(() => {
    let totalRules = 0;
    _.forEach(allSupportedRules, rule => {
      if (
        rule.enabled &&
        rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER
      ) {
        totalRules += 1;
      }
    });

    return totalRules;
  }, [allSupportedRules]);

  const handleClearAllClick = () => {
    setAllSupportedRules(currentState => {
      return currentState.map(rule => {
        if (
          rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER
        ) {
          return {
            ...rule,
            enabled: false,
          };
        }
        return rule;
      });
    });
  };

  const handleRuleChange = updatedRule => {
    setAllSupportedRules(currentState => {
      const updatedRuleKey = createRuleKey(updatedRule);

      return _.map(currentState, rule => {
        const ruleKey = createRuleKey(rule);
        if (ruleKey === updatedRuleKey) {
          return updatedRule;
        }

        return rule;
      });
    });
  };

  const resetToCompanyRules = () => {
    setSelectedRules(defaultBookkeepingRulesMap);
  };

  let textToDisplay = `${totalRulesSelected} ${
    totalRulesSelected === 1 ? 'Rule' : 'Rules'
  } Selected`;

  if (areOnlyDefaultRulesSelected && totalRulesSelected > 0) {
    textToDisplay = 'Company Rules Selected';
  }

  return (
    <>
      <InputContentLoader label={label} loading={loading}>
        <TextField
          fullWidth
          label={label}
          value={textToDisplay}
          disabled={disabled}
          error={error}
          InputProps={{
            readOnly: true,
            endAdornment: showChangeAdornment ? (
              <InputAdornment position="end" style={{ marginRight: 16 }}>
                <span style={{ color: palette.brandColorPrimary }}>change</span>
              </InputAdornment>
            ) : null,
          }}
          onClick={() => {
            setShowMenuDialog(true);
          }}
        />
      </InputContentLoader>
      {!_.isEmpty(layoutToUse) && showMenuDialog && (
        <Dialog
          open={showMenuDialog}
          maxWidth="md"
          fullWidth
          onClose={(_event, reason) => {
            if (reason !== 'backdropClick') {
              setShowMenuDialog(false);
            }
          }}
          disableEscapeKeyDown
        >
          <DialogTitle disableTypography>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Typography variant="h5">Rules to Scan</Typography>
            </Grid>
          </DialogTitle>

          <DialogContent style={{ minHeight: '70vh' }}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={handleClearAllClick}
                style={{ marginRight: 8 }}
              >
                Clear
              </Button>
              <Tooltip title="Reset To Company Rules">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  endIcon={<ResetIcon />}
                  onClick={resetToCompanyRules}
                >
                  Reset
                </Button>
              </Tooltip>
            </Grid>
            {layoutToUse.map(({ group: groupName, alerts: alertsInGroup }) => {
              return (
                <React.Fragment key={groupName}>
                  <Grid container direction="column">
                    <Grid item className={classes.listSubheader}>
                      {groupName}
                    </Grid>
                    {alertsInGroup.map(alertIdentifier => {
                      return (
                        <BookkeepingAlertsSettingsListItem
                          key={alertIdentifier}
                          alertIdentifier={alertIdentifier}
                          filteredRules={
                            supportedRulesByAlertIdentifier[alertIdentifier]
                          }
                          subs={
                            BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier]
                              .subs
                          }
                          generalSettings={
                            BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier]
                              .generalSettings
                          }
                          useCheckboxForRules
                          onChange={handleRuleChange}
                          toggleRowContainerStyle={{ marginRight: 52 }}
                        />
                      );
                    })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </DialogContent>
          <DialogActions>
            <ButtonWithTooltip
              color="primary"
              variant="contained"
              disabled={totalRulesSelected === 0}
              tooltipText={
                totalRulesSelected === 0
                  ? 'Please select at least one rule'
                  : ''
              }
              onClick={() => {
                setShowMenuDialog(false);
              }}
            >
              Done
            </ButtonWithTooltip>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(connect(mapStateToProps))(
  CustomScanSettingsRuleMultiSelect
);
