import React from 'react';
import {
  makeStyles,
  ButtonBase,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

import { formatNumberAsMoneyDollar } from '../../helpers';

const useStyles = makeStyles(theme => ({
  radioButtons: {
    color: theme.palette.brandColorPrimary,
  },
  radioButtonsUnchecked: {
    color: '#666',
  },
  pricingOptionButton: {
    width: '100%',
    cursor: 'pointer',
    marginBottom: 16,
  },
  pricingOptionPaper: {
    width: '100%',
    display: 'flex',
    padding: 16,
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  optionTitle: {
    fontSize: 22,
    textTransform: 'capitalize',
  },
  optionSecondary: {
    fontSize: 18,
  },
  bestValueWrapper: {
    color: '#fff',
    backgroundColor: theme.palette.brandColorPrimary,
    padding: '4px 8px',
    borderRadius: 6,
    marginLeft: 8,
    display: 'inline-flex',
    fontSize: 13,
  },
  optionSubtext: {
    fontSize: 16,
  },
}));

const PricingOption = ({
  pricingInfo = {},
  savingsPercentage,
  selected,
  onSelect,
}) => {
  const classes = useStyles();

  const { unitAmount, recurring } = pricingInfo;
  let term;
  let title;
  if (recurring.interval === 'year') {
    term = 'annually';
    title = 'Annual Plan';
  } else if (recurring.interval === 'month') {
    if (recurring.intervalCount === 1) {
      term = 'monthly';
      title = 'Monthly Plan';
    } else if (recurring.intervalCount === 3) {
      term = 'quarterly';
      title = 'Quarterly Plan';
    }
  }

  const price = unitAmount / 100;
  const readableTermPrice = formatNumberAsMoneyDollar(price);

  let readableMonthlyPrice = formatNumberAsMoneyDollar(price); // default to monthly
  if (term === 'annually') {
    // annually
    readableMonthlyPrice = formatNumberAsMoneyDollar(price / 12);
  } else if (recurring.interval === 'month' && term === 'quarterly') {
    // quarterly
    readableMonthlyPrice = formatNumberAsMoneyDollar(price / 3);
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      justifyContent="center"
      style={{ width: '100%' }}
    >
      <Grid container justifyContent="center">
        <ButtonBase
          onClick={onSelect}
          disableRipple
          className={classes.pricingOptionButton}
        >
          <Paper
            elevation={5}
            className={classes.pricingOptionPaper}
            style={{ border: selected ? '2px solid #3a7ca5' : 'none' }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                flex: 0,
                paddingRight: 8,
                paddingTop: 2,
              }}
            >
              {selected ? (
                <RadioButtonChecked className={classes.radioButtons} />
              ) : (
                <RadioButtonUnchecked
                  className={classes.radioButtonsUnchecked}
                />
              )}
            </Grid>
            <Grid item style={{ display: 'flex', flex: 1 }}>
              <Grid
                item
                style={{ textAlign: 'left' }}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  style={{ marginBottom: 8 }}
                >
                  <Grid item style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Typography variant="body1" className={classes.optionTitle}>
                      {title}{' '}
                    </Typography>
                    {term === 'annually' && (
                      <span className={classes.bestValueWrapper}>
                        Best Value
                      </span>
                    )}
                  </Grid>
                  {!!savingsPercentage && (
                    <Grid item>
                      <Typography variant="h5" style={{ color: 'red' }}>
                        Save {savingsPercentage}%
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.optionSecondary}>
                    {readableMonthlyPrice}/month
                  </Typography>
                  <Typography variant="body1" className={classes.optionSubtext}>
                    Billed {term} at {readableTermPrice}. Plus applicable taxes.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default PricingOption;
