import React, { useState, useEffect } from 'react';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { TextField, Typography, InputLabel, Grid } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import { Formik, Field, Form } from 'formik';

import { MAX_CHARACTERS_IN_USERNAME } from '../../config/appDefaults';

import BasicDialog from '../basic-dialog/basic-dialog';
import LoadingCover from '../LoadingCover/loadingCover';
import SettingsSection from '../settings-section';

import { UpdateUserMutationAction } from '../../graphql/graphql';
import GetUserInfo from '../../graphql/queries/GetUserInfo';

const ProfileInfo = props => {
  const { onUpdateUser, client, onlyBka } = props;

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [localUserInfo, setLocalUserInfo] = useState({});
  const [originalUserInfo, setOriginalUserInfo] = useState({});
  const [newUsername, setNewUsername] = useState('');
  const [newFirstname, setNewFirstname] = useState('');
  const [newLastname, setNewLastname] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [showBasicDialog, setShowBasicDialog] = useState({
    open: false,
    title: '',
    message: '',
  });

  const isUserNameNotSubmitable =
    originalUserInfo &&
    (originalUserInfo.username === newUsername || newUsername === '');

  const isFirstNameNotSubmitable =
    originalUserInfo && originalUserInfo.firstName === newFirstname;

  const isLastNameNotSubmitable =
    originalUserInfo && originalUserInfo.lastName === newLastname;

  const isPhoneNumberNotSubmitable =
    (originalUserInfo && originalUserInfo.phoneNumber === newPhoneNumber) ||
    (!originalUserInfo.phoneNumber && !newPhoneNumber);

  const isNotSubmitable =
    isUserNameNotSubmitable &&
    isFirstNameNotSubmitable &&
    isLastNameNotSubmitable &&
    isPhoneNumberNotSubmitable;

  let userEmail = '';
  if (originalUserInfo && originalUserInfo.email) {
    userEmail = originalUserInfo.email;
  }

  useEffect(() => {
    // https://github.com/facebook/react/issues/14369#issuecomment-468267798
    // flag to make sure we're not doing anything while the component is unmounted since it could have since we asyn/await'd
    let didCancel = false;
    let userInfoQuery;
    const runQuery = async () => {
      userInfoQuery = await client.query({
        query: GetUserInfo,
        options: { fetchPolicy: 'network-only' },
        variables: { userId: 'willBePulledFromCognitoSubContentInResolver' },
      });
      const tempInfo = _.get(userInfoQuery, 'data.getMyUserInfo');
      if (!didCancel) {
        setLocalUserInfo(tempInfo);
        setOriginalUserInfo(tempInfo);
        setNewUsername(tempInfo.username);
        setNewFirstname(tempInfo.firstName || '');
        setNewLastname(tempInfo.lastName || '');
        setNewPhoneNumber(tempInfo.phoneNumber || '');
      }
    };
    runQuery();
    return () => {
      didCancel = true; // this will happen when the component unmounts
    };
  }, [client]);

  const cancelUsernameEdit = async () => {
    // set userInfo back
    setLocalUserInfo(originalUserInfo);
    setEditMode(false);
  };

  const validUsernameChecker = usernameToCheck => {
    // change consecutive ".", "-", and "_" to singles
    if (usernameToCheck === '') {
      setNewUsername('');
    }
    if (!usernameToCheck) return false;
    const cleanUsername = usernameToCheck.replace(/[^-\w.]|(\W|_)(?=\1)/gi, '');
    setNewUsername(cleanUsername);

    // "-", word characters, and "." allowed, 2-30 characters
    //  long, but no consecutive ".", "-", or "_"
    const usernameRegex = new RegExp(
      /^(?!.*__.*)(?!.*\.\..*)(?!.*--.*)[-\w.]{2,30}$/
    );
    if (usernameRegex.test(cleanUsername)) {
      return true;
    }
    return false;
  };

  const handleUsernameChange = e => {
    const passedUsername = e.target.value;
    validUsernameChecker(passedUsername);
  };

  const handleFirstnameChange = e => {
    const passedFirstname = e.target.value;
    setNewFirstname(passedFirstname);
  };

  const handleLastnameChange = e => {
    const passedLastname = e.target.value;
    setNewLastname(passedLastname);
  };

  const handlePhoneNumberChange = e => {
    const passedPhoneNumber = e.target.value;
    setNewPhoneNumber(passedPhoneNumber);
  };
  const userInfoSubmit = async () => {
    const validUsername = validUsernameChecker(newUsername);

    if (!validUsername) {
      setShowBasicDialog({
        open: true,
        title: 'Invalid Username',
        message: `Please also make sure your username is 2-${MAX_CHARACTERS_IN_USERNAME} characters long and contains only letters, numbers, underscores, and/or dashes.`,
      });
      return;
    }

    setLoading(true);

    let updateResponse;
    try {
      updateResponse = await onUpdateUser({
        profilePic: null,
        username: validUsername ? newUsername : originalUserInfo.username,
        email: null,
        userId: localUserInfo.userId,
        synced: false,
        coverImage: null,
        firstName: newFirstname && newFirstname.trim(),
        lastName: newLastname && newLastname.trim(),
        phoneNumber: newPhoneNumber && newPhoneNumber.trim(),
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e: ', e);
      setLoading(false);
      // show user a message that the username is not available
      setShowBasicDialog({
        open: true,
        title: 'Oops...',
        message: 'Something went wrong. Please try again!',
      });
      return;
    }

    const returnedUsername = _.get(updateResponse, 'data.updateUser.username');

    if (returnedUsername !== newUsername) {
      // show user a message that the username is not available
      setShowBasicDialog({
        open: true,
        title: 'Oops... Username Not Available',
        message: `The username "${newUsername}" is already taken. Please choose another one!`,
      });
      setLoading(false);
      return;
    }

    const {
      profilePic,
      username,
      email,
      firstName,
      lastName,
      phoneNumber,
    } = updateResponse.data.updateUser;

    const newInfo = {
      profilePic,
      username,
      email,
      userId: localUserInfo.userId,
      synced: false,
      coverImage: null,
      firstName,
      lastName,
      phoneNumber,
    };
    setLocalUserInfo(newInfo);
    setOriginalUserInfo(newInfo);
    setLoading(false);
    setEditMode(false);
  };

  const handleEditMode = () => {
    setNewUsername(localUserInfo.username);
    setNewFirstname(localUserInfo.firstName || '');
    setNewLastname(localUserInfo.lastName || '');
    setNewPhoneNumber(localUserInfo.phoneNumber || '');
    setEditMode(true);
  };

  return (
    <SettingsSection
      icon={<PersonIcon />}
      title="Profile Info"
      description="Manage your profile details"
      {...(!onlyBka && {
        onCancel: editMode ? cancelUsernameEdit : null,
        onCancelText: editMode ? 'Cancel' : null,
        onDone: editMode ? userInfoSubmit : handleEditMode,
        onDoneText: editMode ? 'Save' : 'Edit',
        onDoneDisabled: editMode && isNotSubmitable,
      })}
    >
      {localUserInfo && (
        <Formik onSubmit={userInfoSubmit}>
          <Form
            style={{
              width: '100%',
              maxWidth: 500,
            }}
          >
            <Grid container item xs={12} direction="column" spacing={1}>
              {!onlyBka && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ minHeight: 38 }}
                  >
                    <Grid item xs={4}>
                      <InputLabel>
                        <Typography color="textSecondary">Username</Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      {!editMode ? (
                        <Typography
                          variant="h5"
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                        >
                          {localUserInfo.username}
                        </Typography>
                      ) : (
                        <Field
                          name="username"
                          onChange={handleUsernameChange}
                          value={newUsername}
                          type="text"
                          component={TextField}
                          inputProps={{
                            readOnly: !editMode,
                            maxLength: MAX_CHARACTERS_IN_USERNAME,
                          }}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ minHeight: 38 }}
                  >
                    <Grid item xs={4}>
                      <InputLabel>
                        <Typography color="textSecondary">
                          First Name
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      {!editMode ? (
                        <Typography
                          variant="h5"
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                        >
                          {localUserInfo.firstName}
                        </Typography>
                      ) : (
                        <Field
                          name="firstname"
                          onChange={handleFirstnameChange}
                          value={newFirstname}
                          type="text"
                          component={TextField}
                          inputProps={{
                            readOnly: !editMode,
                          }}
                          style={{ width: '100%', maxheight: 40 }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ minHeight: 38 }}
                  >
                    <Grid item xs={4}>
                      <InputLabel>
                        <Typography color="textSecondary">Last Name</Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      {!editMode ? (
                        <Typography
                          variant="h5"
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                        >
                          {localUserInfo.lastName}
                        </Typography>
                      ) : (
                        <Field
                          name="lastname"
                          onChange={handleLastnameChange}
                          value={newLastname}
                          type="text"
                          component={TextField}
                          inputProps={{
                            readOnly: !editMode,
                          }}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ minHeight: 38 }}
                  >
                    <Grid item xs={4}>
                      <InputLabel>
                        <Typography color="textSecondary">
                          Phone Number
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      {!editMode ? (
                        <Typography
                          variant="h5"
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                        >
                          {localUserInfo.phoneNumber}
                        </Typography>
                      ) : (
                        <Field
                          name="phoneNumber"
                          onChange={handlePhoneNumberChange}
                          value={newPhoneNumber}
                          type="text"
                          component={TextField}
                          inputProps={{
                            readOnly: !editMode,
                          }}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                style={{ minHeight: 38 }}
              >
                <Grid item xs={4}>
                  <InputLabel>
                    <Typography color="textSecondary">Email</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="h5"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    {userEmail}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {loading && (
              <LoadingCover>
                <Typography variant="h3" align="center">
                  Saving...
                </Typography>
              </LoadingCover>
            )}
          </Form>
        </Formik>
      )}
      <BasicDialog
        open={!!showBasicDialog.open}
        title={showBasicDialog.title}
        customChildren
        handleClose={() => setShowBasicDialog({ open: false, message: '' })}
      >
        <Typography>{showBasicDialog.message}</Typography>
      </BasicDialog>
    </SettingsSection>
  );
};
export default compose(
  UpdateUserMutationAction,
  withApollo
)(withRouter(ProfileInfo));
