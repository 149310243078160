import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Query, compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';

import { LIST_TYPE } from './list-of-projects-definitions';
import ListOfProjectsView from './ListOfProjects.view';
import ErrorHandle from '../ErrorHandle/errorHandle';
import ListMyJrns from '../../graphql/queries/ListMyJrns';
import {
  GetCompanyInfoAction,
  GetMyUserInfoAction,
} from '../../graphql/graphql';
import ListCompanyProjects from '../../graphql/queries/list-company-projects';
import ListCompanyArchivedProjects from '../../graphql/queries/list-company-archived-projects';
import ListCompanyTemplates from '../../graphql/queries/list-company-templates';

const ListOfProjects = ({
  onClickOfProject,
  idOfProjectBeingViewed,
  triggerQueryChange,
  setTriggerQueryChange,
  userInfo,
  getCompanyInfo,
  managingCompanyInfo,
  fromCompanyOnboarding,
}) => {
  const refetchRef = useRef(null);
  const refetchIntervalIdRef = useRef(null);
  const initializationCompleteRef = useRef(!fromCompanyOnboarding);
  const [refetchCount, setRefetchCount] = useState(0);

  const [selectedData, setSelectedData] = useState(LIST_TYPE.MY_PROJECTS);

  const endInitialization = () => {
    clearInterval(refetchIntervalIdRef.current);
    initializationCompleteRef.current = true;
  };

  useEffect(() => {
    if (!initializationCompleteRef.current) {
      // Start initialization polling

      if (!refetchIntervalIdRef.current) {
        // Start polling
        refetchIntervalIdRef.current = setInterval(() => {
          if (refetchRef.current) {
            refetchRef.current();
            setRefetchCount(currentState => currentState + 1);
          }
        }, 2000);
      }
    }

    return () => {
      // clear interval if user navigates away before initialization is complete
      if (refetchIntervalIdRef.current) {
        clearInterval(refetchIntervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Stop polling if count is 5
    if (refetchCount === 6) {
      endInitialization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchCount]);

  useEffect(() => {
    if (triggerQueryChange && triggerQueryChange !== selectedData) {
      setSelectedData(triggerQueryChange);
      setTriggerQueryChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerQueryChange, selectedData]); // only want this to trigger if triggerQueryChange changes

  let companyInfo = null;
  if (getCompanyInfo && managingCompanyInfo.managingCompanyId) {
    companyInfo = _.find(getCompanyInfo, {
      companyId: managingCompanyInfo.managingCompanyId,
    });
  }

  let queryToUse;
  let subkeyToUse;
  let variablesToUse;
  if (selectedData === LIST_TYPE.MY_PROJECTS) {
    queryToUse = ListMyJrns;
    subkeyToUse = 'listMyJrns';
    variablesToUse = { userId: 'willBePulledFromCognitoSubContentInResolver' };
  } else if (selectedData === LIST_TYPE.ARCHIVED_PROJECTS) {
    queryToUse = ListCompanyArchivedProjects;
    subkeyToUse = 'listCompanyArchivedProjects';
    variablesToUse = {
      companyId: managingCompanyInfo && managingCompanyInfo.managingCompanyId,
      first: null,
      after: null,
    };
  } else if (selectedData === LIST_TYPE.COMPANY_PROJECTS) {
    queryToUse = ListCompanyProjects;
    subkeyToUse = 'listCompanyProjects';
    variablesToUse = {
      companyId: managingCompanyInfo.managingCompanyId,
      first: null,
      after: null,
    };
  } else if (selectedData === LIST_TYPE.TEMPLATES) {
    queryToUse = ListCompanyTemplates;
    subkeyToUse = 'listCompanyTemplates';
    variablesToUse = {
      companyId: managingCompanyInfo.managingCompanyId,
      first: null,
      after: null,
    };
  }

  // NOTE: the Query component was not working with listCompanyTemplates for some reason, so running it with hook instead
  // Scenario: create a template from a project, then click "GO TO TEMPLATE" button. The template will not show up in the list of templates
  const listCompanyTemplatesQuery = useQuery(ListCompanyTemplates, {
    fetchPolicy: 'cache-and-network',
    skip:
      selectedData !== LIST_TYPE.TEMPLATES ||
      !managingCompanyInfo.managingCompanyId,
    variables: variablesToUse,
  });

  const renderView = ({ loading, data, refetch }) => {
    return (
      <ListOfProjectsView
        projects={data}
        onClickOfProject={onClickOfProject}
        refetchListingProjects={refetch}
        idOfProjectBeingViewed={idOfProjectBeingViewed}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        userInfo={userInfo}
        companyInfo={companyInfo}
        loading={loading}
      />
    );
  };

  if (selectedData === LIST_TYPE.TEMPLATES) {
    return renderView({
      loading: listCompanyTemplatesQuery.loading,
      data: _.get(listCompanyTemplatesQuery.data, `${subkeyToUse}.items`),
      refetch: listCompanyTemplatesQuery.refetch,
    });
  }

  return (
    <Query
      query={queryToUse}
      variables={variablesToUse}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data, refetch }) => {
        if (error) {
          return <ErrorHandle refetch={refetch} />;
        }

        if (!refetchRef.current) {
          refetchRef.current = refetch;
        }

        const foundData = _.get(data, `${subkeyToUse}.items`, []);

        if (!initializationCompleteRef.current && foundData.length === 5) {
          endInitialization();
        }

        return renderView({
          loading: loading || !initializationCompleteRef.current,
          data: foundData,
          refetch,
        });
      }}
    </Query>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
  };
}

export default compose(
  GetMyUserInfoAction,
  GetCompanyInfoAction
)(connect(mapStateToProps)(ListOfProjects));
