import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import FadeIn from 'react-fade-in/lib/FadeIn';

import {
  GetCompanyInfoAction,
  GetMyUserInfoAction,
} from '../../../../graphql/graphql';
import { determineManagingCompanyInfo } from '../../../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
    margin: '0 auto',
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const UserDisplay = props => {
  const {
    currentAuth,
    className,
    dispatch,
    refetchGetUserInfo,
    userInfoError,
    userInfo,
    userInfoState,
    userInfoLoading,
    getCompanyInfo: companies,
    getCompanyInfoError,
    getCompanyInfoLoading,
    getCompanyInfoRefetch,
    managingCompanyInfoState,
    ...rest
  } = props;
  const classes = useStyles();
  const displayNameRef = useRef(null);

  const [infoToUse, setInfoToUse] = useState(null);

  useEffect(() => {
    if (currentAuth.isLoggedIn && !_.isEmpty(userInfo)) {
      let hasUpdates = false;

      _.keys(userInfo).forEach(key => {
        if (!_.isEqual(userInfo[key], userInfoState[key])) {
          hasUpdates = true;
        }
      });

      if (hasUpdates) {
        dispatch({
          type: 'USER_INFO_UPDATE',
          payload: { ...userInfo },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, userInfoState]);

  useEffect(() => {
    if (currentAuth.isLoggedIn && !_.isEmpty(userInfo) && companies) {
      const managingCompanyInfo = determineManagingCompanyInfo({
        companies,
        userInfo,
      });

      if (!_.isEqual(managingCompanyInfo, managingCompanyInfoState)) {
        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: { ...managingCompanyInfo },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, userInfo, managingCompanyInfoState]);

  useEffect(() => {
    if (userInfoState) {
      const newInfo = {
        username: userInfoState.username,
        profilePic: userInfoState.profilePic,
      };

      if (!_.isEqual(newInfo, infoToUse)) {
        setInfoToUse(newInfo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoState]);

  useEffect(() => {
    // decrease font size by 1px until it fits
    if (displayNameRef.current?.offsetWidth) {
      if (displayNameRef.current.offsetWidth > 200) {
        const currentFontSize = _.replace(
          window.getComputedStyle(displayNameRef.current).fontSize,
          'px',
          ''
        );

        displayNameRef.current.style.fontSize = `${currentFontSize - 1}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNameRef.current?.offsetWidth]);

  const isSubscriptionBased = !!managingCompanyInfoState?.isSubscriptionBased;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {userInfoError && `An error occurred.`}
      {!userInfoError && !infoToUse && (
        <ContentLoader
          height={95}
          width={208}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="57" y="75" rx="3" ry="3" width="94" height="18" />
          <circle cx="104" cy="30" r="30" />
        </ContentLoader>
      )}
      {!userInfoError && infoToUse && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <FadeIn>
              {!isSubscriptionBased && (
                <Avatar
                  alt="Person"
                  className={classes.avatar}
                  src={infoToUse.profilePic}
                />
              )}
              <Typography
                ref={displayNameRef}
                className={classes.name}
                variant={isSubscriptionBased ? 'body1' : 'h5'}
                align="center"
              >
                {isSubscriptionBased ? userInfoState.email : infoToUse.username}
              </Typography>
            </FadeIn>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = ({ currentAuth, appState, userInfo }) => ({
  currentAuth,
  userInfoState: userInfo,
  managingCompanyInfoState: appState.managingCompanyInfo,
});

export default compose(
  GetCompanyInfoAction,
  GetMyUserInfoAction,
  connect(mapStateToProps)
)(UserDisplay);
