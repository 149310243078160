// @ts-check
import { QBO_SYNC_STATE } from './qbo-sync-constants';

/**
 * Return qbo sync state of content or customer
 * @param {Object} contentOrCustomer
 * @return {QBO_SYNC_STATE}
 */
export function getQboSyncState(contentOrCustomer) {
  if (!contentOrCustomer) {
    return null;
  }

  if (contentOrCustomer.qboCustomerId) {
    if (contentOrCustomer.qboCustomerId === 'NEW') {
      return QBO_SYNC_STATE.SYNCING;
    }

    if (contentOrCustomer.qboCustomerId.includes('ERROR')) {
      return QBO_SYNC_STATE.ERROR;
    }

    return QBO_SYNC_STATE.ACTIVE;
  }

  return QBO_SYNC_STATE.NOT_SYNCED;
}
