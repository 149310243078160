import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';

import { Button, Grid, Link, Typography } from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { useSnackbar } from 'notistack';

import _ from 'lodash';
import numeral from 'numeral';

import { useStyles } from './onboarding.styles';
import PricingOption from './pricing-option';

import GenerateCompanyCheckoutSession from '../../graphql/mutations/mutation_generate-company-checkout-session';
import GetStripeProductInfo from '../../graphql/queries/get-product-prices-by-type';

import LevelLogo from '../../components/level-logo/level-logo';
import LoadingCover from '../../components/LoadingCover/loadingCover';

const OnboardingSelectBookkeepingCopilotPrice = ({
  companyInfo,
  setLoading,
  companyBillingAddress,
  stepBack,
  hideLogo,
  customEndpoints,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [pricingOptions, setPricingOptions] = useState(null);
  const [yearlySavingsPercentage, setYearlySavingsPercentage] = useState(null);
  const [quarterlySavingsPercentage, setQuarterlySavingsPercentage] = useState(
    null
  );
  const [priceSelected, setPriceSelected] = useState(null);

  // run a query to get the pricing options, use - getProductPricesByType
  const {
    data: pricingQueryResponse,
    loading: pricingOptionsLoading,
  } = useQuery(GetStripeProductInfo, {
    variables: {
      productType: 'bookkeepingAlerts',
    },
  });
  // use lodash to get the items off the response data
  const pricingOptionsData = _.get(
    pricingQueryResponse,
    'getProductPricesByType.items',
    []
  );

  useEffect(() => {
    if (pricingOptionsData?.length > 0) {
      // figure out the yearly savings between the two pricing options
      const monthlyPlan = _.find(pricingOptionsData, {
        recurring: { interval: 'month', intervalCount: 1 },
      });
      const quarterlyPlan = _.find(pricingOptionsData, {
        recurring: { interval: 'month', intervalCount: 3 },
      });
      const annualPlan = _.find(pricingOptionsData, {
        recurring: { interval: 'year', intervalCount: 1 },
      });
      const yearlySavingsPercentageCalc =
        annualPlan && monthlyPlan
          ? numeral(
              ((monthlyPlan.unitAmount * 12 - annualPlan.unitAmount) /
                (monthlyPlan.unitAmount * 12)) *
                100
            ).format('0')
          : null;
      const quarterlySavingsPercentageCalc =
        quarterlyPlan && monthlyPlan
          ? numeral(
              ((monthlyPlan.unitAmount * 3 - quarterlyPlan.unitAmount) /
                (monthlyPlan.unitAmount * 3)) *
                100
            ).format('0')
          : null;

      const pricingOptionsInOrder = [
        // add each plan if they exist
        annualPlan || null,
        quarterlyPlan || null,
        monthlyPlan || null,
      ].filter(plan => plan !== null); // remove any nulls
      // set the monthly plan as the default selected price
      setPricingOptions(pricingOptionsInOrder);
      setYearlySavingsPercentage(yearlySavingsPercentageCalc);
      setQuarterlySavingsPercentage(quarterlySavingsPercentageCalc);
      setPriceSelected(annualPlan.id);
    }
  }, [pricingOptionsData]);

  const renderPricingOptions = () => {
    return (
      <Grid item container xs={12}>
        {pricingOptions.map(pricingInfo => {
          let savingsPercentage = null;
          if (pricingInfo.recurring.interval === 'year') {
            savingsPercentage = yearlySavingsPercentage;
          } else if (
            pricingInfo.recurring.interval === 'month' &&
            pricingInfo.recurring.intervalCount === 3
          ) {
            savingsPercentage = quarterlySavingsPercentage;
          }

          return (
            <PricingOption
              key={pricingInfo.id}
              pricingInfo={pricingInfo}
              savingsPercentage={savingsPercentage}
              selected={priceSelected === pricingInfo.id}
              onSelect={() => setPriceSelected(pricingInfo.id)}
            />
          );
        })}
      </Grid>
    );
  };

  const [generateCompanyCheckoutSession] = useMutation(
    GenerateCompanyCheckoutSession
  );

  const goToBilling = async () => {
    setLoading(true);

    // need to request a Stripe checkout session id from the server with the selected price
    // then redirect to the Stripe checkout page with the session id
    let checkoutSessionId;
    const { companyId } = companyInfo;
    try {
      const params = {
        companyId,
        price: priceSelected,
        billingAddress: companyBillingAddress,
        customEndpoints: {
          success: customEndpoints?.success || window.location.href,
          cancel: customEndpoints?.cancel || window.location.href,
        },
      };
      const checkoutSessionIdResponse = await generateCompanyCheckoutSession({
        variables: params,
      });
      if (checkoutSessionIdResponse && checkoutSessionIdResponse.data) {
        checkoutSessionId =
          checkoutSessionIdResponse.data.generateCompanyCheckoutSession
            .sessionId;
      }
    } catch (err) {
      enqueueSnackbar(
        'There was an error getting you to the checkout page. Please try again.',
        { variant: 'warning' }
      );
      // eslint-disable-next-line no-console
      console.error('Error while getting checkout session - abort', err);
      setLoading(false);
      return;
    }
    // once we have the checkout session id, redirect to the Stripe checkout page using that id
    // eslint-disable-next-line no-undef
    const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); // Stripe is on the window, loaded from index.html
    try {
      await stripe.redirectToCheckout({
        sessionId: checkoutSessionId,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error while redirecting to checkout page - abort', err);
      enqueueSnackbar(
        'There was an error getting you to the checkout page. Please try again.',
        { variant: 'warning' }
      );

      setLoading(false);
      return;
    }

    setLoading(false);
  };

  return (
    <>
      {!hideLogo && (
        <Grid item xs={12}>
          <LevelLogo logoVariation="new" className={classes.smallLevelLogo} />
        </Grid>
      )}
      <Grid
        container
        alignItems="center"
        item
        xs={12}
        style={{ marginBottom: 16 }}
      >
        <Grid item xs={12}>
          <Typography variant="body1" style={{ fontSize: 18 }}>
            Please choose which Level Bookkeeping Copilot pricing plan you would
            like to sign up for.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        item
        xs={12}
        style={{ paddingTop: 16 }}
      >
        {pricingOptionsLoading ? (
          <LoadingCover />
        ) : (
          <>
            {pricingOptions ? (
              renderPricingOptions()
            ) : (
              <>
                Sorry no pricing options are currently available.
                <br />
                <br />
                Please contact Level Support at{' '}
                <Link href="mailto:support@checkthelevel.com">
                  support@checkthelevel.com
                </Link>
                for assistance.
              </>
            )}
          </>
        )}
      </Grid>

      <Grid container item justifyContent="center" style={{ marginTop: 24 }}>
        <Button onClick={stepBack} className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!priceSelected}
          onClick={priceSelected ? goToBilling : null}
          className={classes.button}
        >
          Go to Billing
        </Button>
      </Grid>
    </>
  );
};

export default compose(OnboardingSelectBookkeepingCopilotPrice);
