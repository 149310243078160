import { Grid, List, ListItem, Typography } from '@material-ui/core';
import React from 'react';

function ImportProjectStepOverview({ nextButtonText }) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          <b>Overview</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Level projects are treated as sub-customers in QuickBooks Online. What
          this means is you can have multiple projects (sub-customers in QBO)
          assigned to a customer (the parent customer in QBO).
          <br />
          <br />
          To import a project to Level, we first need to know which QuickBooks
          Online parent customer the sub-customer or project belongs to! If the
          QuickBooks Online parent customer has not yet been imported, it will
          be imported with the project.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Our import tool will take you through the following steps:
        </Typography>
      </Grid>
      <Grid item>
        <List>
          <ListItem>
            <Typography variant="body1">
              1. Search and select the <b>parent customer</b> from QuickBooks
              Online that the QuickBooks Online sub-customer or project belongs
              to.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              2. Once the QuickBooks parent customer is selected, you will see a
              list of their sub-customers from QuickBooks. Choose which{' '}
              <b>sub-customer or project</b> should be imported.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              3. Confirm your choices and import!
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          When you&apos;re ready to choose the QuickBooks Online parent
          customer, click <b>{nextButtonText}</b>.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ImportProjectStepOverview;
