import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import styles from '../bookkeeping-alerts.styles';
import {
  WIDTH_OF_ALERT_TOGGLE_COLUMN,
  WIDTH_OF_WORDING_COLUMN,
} from './bookkeeping-alerts-settings.constants';
import theme from '../../../theme';
import palette from '../../../theme/palette';

const useStyles = makeStyles(styles);

const BookkeepingAlertsSettingsListHeader = ({
  alertLists,
  showSticky,
  onEditListNameButtonClick,
  width,
}) => {
  const classes = useStyles();

  const numberOfAlertColumns = _.size(alertLists);
  const totalColumns = numberOfAlertColumns + 1; // 1 for the enabled (RULE) column

  return (
    <div
      style={
        showSticky
          ? {
              position: 'sticky',
              top: 0,
              right: 0,
              left: 0,
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
              zIndex: 1,
              width,
              minWidth: '100%',
            }
          : { width, minWidth: '100%' }
      }
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        className={classes.listHeader}
        style={{ width, minWidth: '100%' }}
      >
        <Grid item style={{ width: WIDTH_OF_WORDING_COLUMN, paddingLeft: 16 }}>
          Bookkeeping Rules
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{
            flexWrap: 'nowrap',
            width: WIDTH_OF_ALERT_TOGGLE_COLUMN * totalColumns,
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="flex-end"
            style={{
              width: WIDTH_OF_ALERT_TOGGLE_COLUMN,
            }}
            id="bka-rules-list-header-enabled-label"
          >
            Enabled?
          </Grid>
          {numberOfAlertColumns > 0 && (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              style={{
                width: WIDTH_OF_ALERT_TOGGLE_COLUMN * numberOfAlertColumns,
              }}
            >
              <Grid item id="bka-rules-list-header-notify-by-email-label">
                <Typography
                  variant="subtitle1"
                  style={{ color: palette.brandColorDarkGrey75 }}
                >
                  Notify by Email
                </Typography>
              </Grid>
              <Grid
                container
                alignItems="flex-end"
                item
                style={{
                  width: WIDTH_OF_ALERT_TOGGLE_COLUMN * numberOfAlertColumns,
                  marginTop: 8,
                }}
              >
                {_.map(alertLists, ({ listId, listName }) => {
                  return (
                    <Grid
                      key={listId}
                      item
                      style={{
                        width: WIDTH_OF_ALERT_TOGGLE_COLUMN,
                        textAlign: 'center',
                        color: palette.brandColorDarkGrey,
                      }}
                    >
                      {listName}&nbsp;
                      <IconButton
                        size="small"
                        onClick={() => {
                          onEditListNameButtonClick({ listId });
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default BookkeepingAlertsSettingsListHeader;
