// @ts-check
import {
  CalendarToday as CalendarTodayIcon,
  TimerOutlined as TimerOutlinedIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
  PictureAsPdf as PictureAsPdfIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  Payment as PaymentIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  AttachMoney as AttachMoneyIcon,
  Assignment as AssignmentIcon,
  BurstModeOutlined as BurstModeOutlinedIcon,
  VideocamOutlined as VideocamOutlinedIcon,
  BorderColorOutlined as BorderColorOutlinedIcon,
  Dashboard as DashboardIcon,
} from '@material-ui/icons';

import _ from 'lodash';

import themePalette from '../theme/palette';

import ProductProjectManagementImage from '../assets/images/products/product-project-management.jpg';
import ProductFinancialScoreboardImage from '../assets/images/products/product-financial-scoreboard.jpg';
import ProductBookkeepingAlertImage from '../assets/images/products/product-bookkeeping-alert.jpg';

export const TOP_PROJECT_ID = '00000';

export const ADD_ADMINS_FORM_URL =
  'https://share.hsforms.com/1-uuoDMdzRvenTSAsh-flZg3m7k0';

// --- input defaults ---
const bytesInMegabyte = 1024 * 1024; // 1048576
export const uuidLength = 36;
export const MAX_IMG_FILE_SIZE = 10 * bytesInMegabyte; // 10Mb
export const MAX_PDF_FILE_SIZE = 20 * bytesInMegabyte; // 20Mb
// --- total filesize for image or video uploads (total of a gallery or single video) (100Mb)
export const MAX_UPLOAD_TOTAL_FILESIZE_BYTES = 99 * bytesInMegabyte;
// --- max filesize for a single image or raw file upload (20Mb)
//  note: limits are down the right side of the cloudinary interface
export const MAX_UPLOAD_SINGLE_FILESIZE_BYTES = 10 * bytesInMegabyte;
// --- images allowed in gallery
export const MAX_IMAGES_IN_GALLERY = 8;
export const SUPPORTED_IMG_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

// --- input defaults ---
export const timestampLength = 24;

// --- cloudinary defaults ---
export const cloudindaryCloudName = 'check-the-level';

// --- email defaults ---
export const MAX_CHARACTERS_IN_EMAIL = 254;
export const MAX_CHARACTERS_IN_EMAIL_WARNING_THRESHOLD = 250;

// --- username defaults ---
export const MAX_CHARACTERS_IN_USERNAME = 20;
export const MAX_CHARACTERS_IN_NAME = 30;
export const MAX_CHARACTERS_IN_COMPANYNAME = 50;

// --- password defaults ---
export const MAX_CHARACTERS_IN_PASSWORD = 256;
export const MAX_CHARACTERS_IN_PASSWORD_WARNING_THRESHOLD = 236;

// --- description fields defaults ---
export const MAX_CHARACTERS_IN_DESCRIPTION_FIELD = 10000;
export const MAX_CHARACTERS_IN_DESCRIPTION_FIELD_WARNING_THRESHOLD = 50;

// --- TITLE fields defaults ---
export const MAX_CHARACTERS_IN_JRN_TITLE_FIELD = 200;
export const MAX_CHARACTERS_IN_JRN_TITLE_FIELD_WARNING_THRESHOLD = 20;

// --- CUSTOMER fields defaults ---
export const MAX_CHARACTERS_IN_CUSTOMER_FIELD = 100; // Limit driven by QBO display name

// --- PHONENUMBER fields defaults ---
export const MAX_CHARACTERS_IN_PHONENUMBER_FIELD = 30; // Limit driven by QBO

// --- INVITE CODE fields defaults ---
export const INVITE_CODE_MIN_LENGTH = 5;
export const INVITE_CODE_MAX_LENGTH = 30;

// --- textnote defaults ---
// export const MAX_CHARACTERS_IN_TEXTNOTE = 20000; //set this once we move to S3 for textnotes
export const MAX_CHARACTERS_IN_TEXTNOTE = 1000;
export const MAX_CHARACTERS_IN_TEXTNOTE_WARNING_THRESHOLD = 100;

// --- video upload defaults ---
export const MAX_VIDEO_MILLISECONDS = 61000;

// --- shoebox upload defaults ---
export const SHOEBOX_MAX_UPLOAD_NUM_OF_ITEMS = 20;

// --- commenting defaults ---
export const MAX_CHARACTERS_IN_CONTENT_COMMENT = 1000;
export const MAX_CHARACTERS_IN_CONTENT_COMMENT_WARNING_THRESHOLD = 100;

// --- sharing defaults ---
export const SHARING_URL_PREFIX = 'https://share.checkthelevel.com/?link=';
export const SHARING_URL_MIN_LENGTH = 5;
export const SHARING_URL_MAX_LENGTH = 50;

// --- filename defaults ---
export const FILENAME_MAX_LENGTH = 150;
export const FILENAME_DATE_FORMAT = 'YYYYMMDD-HHmm_';

// --- visual defaults ---
export const TEXTNOTE_CONTENT_SHOWN_LENGTH = 250;

// --- content form options and defaults ---
export const TASK_STATUSES = {
  INCOMPLETE: 'incomplete',
  IN_PROGRESS: 'in-progress',
  PENDING_APPROVAL: 'pending-approval',
  COMPLETED: 'completed',
};

export const TASK_STATUS_OPTIONS = [
  {
    label: 'incomplete',
    value: TASK_STATUSES.INCOMPLETE,
  },
  {
    label: 'in-progress',
    value: TASK_STATUSES.IN_PROGRESS,
  },
  {
    label: 'completed',
    value: TASK_STATUSES.COMPLETED,
  },
];

export const adminTaskStatusesForNonAdmins = [
  {
    label: 'incomplete',
    value: TASK_STATUSES.INCOMPLETE,
  },
  {
    label: 'in-progress',
    value: TASK_STATUSES.IN_PROGRESS,
  },
  {
    label: 'pending approval',
    value: TASK_STATUSES.PENDING_APPROVAL,
  },
];

export const adminTaskStatusesForAdmins = [
  ...adminTaskStatusesForNonAdmins,
  {
    label: 'completed',
    value: TASK_STATUSES.COMPLETED,
  },
];

export const timetrackingStatuses = [
  {
    label: 'check in',
    value: 'checkin',
  },
  {
    label: 'check out',
    value: 'checkout',
  },
];

export const LEAD_STATUS_INITIAL = 'initial';
export const LEAD_STATUS_IN_PROGRESS_PENDING = 'in-progress-pending';
export const LEAD_STATUS_REJECTED = 'rejected';
export const LEAD_STATUS_IN_PROGRESS_ACCEPTED = 'in-progress-accepted';
export const leadStatuses = [
  {
    label: 'Open',
    value: LEAD_STATUS_INITIAL,
  },
  {
    label: 'Sent',
    value: LEAD_STATUS_IN_PROGRESS_PENDING,
  },
  {
    label: 'Rejected',
    value: LEAD_STATUS_REJECTED,
  },
  {
    label: 'Accepted',
    value: LEAD_STATUS_IN_PROGRESS_ACCEPTED,
  },
];
export const defaultLeadStatus = LEAD_STATUS_INITIAL;

export const taskPrioritiesArray = ['low', 'normal', 'high'];
export const taskPriorities = [
  {
    label: 'low',
    value: 'low',
  },
  {
    label: 'normal',
    value: 'normal',
  },
  {
    label: 'high',
    value: 'high',
  },
];

export const completionTypes = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Images',
    value: 'image',
  },
  {
    label: 'Video',
    value: 'video',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
];
export const completionTypesAsArray = completionTypes.map(
  labelValuePair => labelValuePair.value
);

export const adminOnlyContentTypes = [
  'timetracking',
  'shift',
  'project',
  'invoice',
];

// https://material.io/design/color/the-color-system.html#tools-for-picking-colors
export const colorsGoodForWhiteText = [
  '#1E88E5',
  '#388E3C',
  // '#616161',
  '#004D40',
  '#546E7A',
  '#1565C0',
  '#D84315',
  '#795548',
  '#78909C',
  '#0D47A1',
  '#37474F',
  '#00796B',
  '#7986CB',
  '#8D6E63',
  '#2E7D32',
  '#5C6BC0',
  '#424242',
  '#3949AB',
  '#E65100',
  '#1A237E',
  '#1B5E20',
  '#3F51B5',
  '#5D4037',
  '#880E4F',
  '#263238',
  '#1E88E5',
  '#455A64',
  '#6D4C41',
  '#0D47A1',
  '#009688',
  '#1976D2',
  '#00897B',
  '#A1887F',
  '#00695C',
  '#43A047',
  '#1565C0',
  '#3E2723',
  '#BF360C',
  '#757575',
  '#212121',
  '#607D8B',
  '#4E342E',
];

export const brokenImageUrl =
  'https://res.cloudinary.com/check-the-level/image/upload/v1601234509/Level-defaults/broken-image-20200927_turve2.jpg';

export const CONTENT_DETAILS_MODAL_MODE = {
  ADMIN_RECEIPT: 'adminReceipt',
  ADMIN_BILL: 'adminBill',
  ADMIN_INVOICE: 'adminInvoice',
  ADMIN_PAYMENT: 'adminPayment',
  ADMIN_DOC: 'adminDoc',
  ADMIN_RECEIPT_BILL_ADD: 'adminReceiptBillAdd',
  ADMIN_RECEIPT_BILL_DELETE: 'adminReceiptBillDelete',
  ADMIN_GLOBAL_BILL_ADD: 'adminGlobalBillAdd',
  ADMIN_GLOBAL_ITEM_VIEW: 'adminGlobalItemView',
  ADMIN_INVOICE_PAYMENT_ADD: 'adminInvoicePaymentAdd',
  ADD_FROM_PROJECT_ADMIN: 'addFromProjectAdmin',
  GLOBAL_ITEM_VIEW: 'globalItemView',
  EDIT_FROM_PROJECT_ADMIN: 'editFromProjectAdmin',
  CREATE_PROJECT_FROM_CUSTOMER_LEADS: 'createProjectFromCustomerLeads',
  CREATE_PROJECT_FROM_MANAGE_LEADS: 'createProjectFromManageLeads',
  EDIT: 'edit',
  MARK_BILL_AS_PAID: 'markBillAsPaid',
  ADD_TOP_LEVEL: 'addTopLevel',
};

export const CONTENT_TYPE = {
  GALLERY: 'gallery',
  IMAGE: 'image',
  PDF: 'pdf',
  PROJECT: 'project',
  RECEIPT: 'receipt',
  BILL: 'bill',
  INVOICE: 'invoice',
  PAYMENT: 'payment',
  SHIFT: 'shift',
  TASK: 'task',
  TEXTNOTE: 'textnote',
  TIMETRACKING: 'timetracking',
  VIDEO: 'video',
  CONVERSATION: 'conversation',
  LEAD: 'lead',
  TEMPLATE: 'template',
  GLOBAL_BILL: 'globalBill',
  GLOBAL_RECEIPT: 'globalReceipt',
  GLOBAL_PAYMENT: 'globalPayment',
  BILL_PAYMENT: 'billPayment',
};

export const JRN_TYPES = [
  CONTENT_TYPE.PROJECT,
  CONTENT_TYPE.LEAD,
  CONTENT_TYPE.TEMPLATE,
  CONTENT_TYPE.CONVERSATION,
  CONTENT_TYPE.GLOBAL_BILL,
  CONTENT_TYPE.GLOBAL_RECEIPT,
  CONTENT_TYPE.GLOBAL_PAYMENT,
];

export const GLOBAL_CONTENT_TYPES = [
  CONTENT_TYPE.GLOBAL_BILL,
  CONTENT_TYPE.GLOBAL_RECEIPT,
  CONTENT_TYPE.GLOBAL_PAYMENT,
];

export const GLOBAL_EXPENSE_TYPES = [
  CONTENT_TYPE.GLOBAL_BILL,
  CONTENT_TYPE.GLOBAL_RECEIPT,
];

export const GLOBAL_FINANCIAL_TYPES = [
  ...GLOBAL_EXPENSE_TYPES,
  CONTENT_TYPE.GLOBAL_PAYMENT,
];

export const FINANCIAL_TYPES = [
  CONTENT_TYPE.RECEIPT,
  CONTENT_TYPE.BILL,
  CONTENT_TYPE.INVOICE,
  CONTENT_TYPE.PAYMENT,
  ...GLOBAL_FINANCIAL_TYPES,
];

export const CONTENT_DEFINITION = {
  [CONTENT_TYPE.GLOBAL_BILL]: {
    name: 'Bill',
    pluralName: 'Bills',
    action: CONTENT_TYPE.GLOBAL_BILL,
    Icon: DescriptionOutlinedIcon,
    allowedOnProjects: true,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: true,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: true,
    category: 'financial',
    allowedMediaTypes: ['pdf', 'image'],
    isGlobalType: true,
  },
  [CONTENT_TYPE.GLOBAL_RECEIPT]: {
    name: 'Receipt',
    pluralName: 'Receipts',
    action: CONTENT_TYPE.GLOBAL_RECEIPT,
    Icon: ReceiptOutlinedIcon,
    allowedOnProjects: true,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: true,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: true,
    category: 'financial',
    allowedMediaTypes: ['pdf', 'image'],
    isGlobalType: true,
  },
  [CONTENT_TYPE.GLOBAL_PAYMENT]: {
    name: 'Bill Payment',
    pluralName: 'Bill Payments',
    action: CONTENT_TYPE.GLOBAL_PAYMENT,
    Icon: PaymentIcon,
    allowedOnProjects: false,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: true,
    allowedInSubproject: false,
    allowedInAdminReceiptBillAdd: false,
    category: 'financial',
    allowedMediaTypes: ['pdf', 'image'],
    isGlobalType: true,
  },
  [CONTENT_TYPE.RECEIPT]: {
    name: 'Receipt',
    pluralName: 'Receipts',
    action: CONTENT_TYPE.RECEIPT,
    Icon: ReceiptOutlinedIcon,
    allowedOnProjects: false,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInSubproject: false,
    allowedInAdminReceiptBillAdd: false,
    category: 'financial',
  },
  [CONTENT_TYPE.BILL]: {
    name: 'Bill',
    pluralName: 'Bills',
    action: CONTENT_TYPE.RECEIPT,
    Icon: DescriptionOutlinedIcon,
    allowedOnProjects: false,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInSubproject: false,
    allowedInAdminReceiptBillAdd: false,
    category: 'financial',
  },
  [CONTENT_TYPE.INVOICE]: {
    name: 'Invoice',
    pluralName: 'Invoice',
    action: CONTENT_TYPE.INVOICE,
    Icon: AttachMoneyIcon,
    allowedOnProjects: true,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: true,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'financial',
    allowedMediaTypes: ['pdf', 'image'],
  },
  [CONTENT_TYPE.PAYMENT]: {
    name: 'Payment',
    pluralName: 'Payments',
    action: CONTENT_TYPE.INVOICE,
    Icon: AttachMoneyIcon,
    allowedOnProjects: false,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'financial',
    allowedMediaTypes: ['pdf', 'image'],
  },
  [CONTENT_TYPE.IMAGE]: {
    name: 'Picture(s)',
    pluralName: 'Pictures',
    action: CONTENT_TYPE.IMAGE,
    Icon: BurstModeOutlinedIcon,
    allowedOnProjects: true,
    allowedInTemplate: true,
    allowedInExtendedTask: true,
    allowedInLead: true,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
    allowedMediaTypes: ['pdf', 'image'],
  },
  [CONTENT_TYPE.GALLERY]: {
    name: 'Media Gallery',
    pluralName: 'Gallery',
    action: CONTENT_TYPE.GALLERY,
    Icon: BurstModeOutlinedIcon,
    allowedOnProjects: false,
    allowedInTemplate: true,
    allowedInExtendedTask: true,
    allowedInLead: true,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
    allowsMultipleMedia: true,
    allowedMediaTypes: ['pdf', 'image'],
  },
  [CONTENT_TYPE.VIDEO]: {
    name: 'Video',
    pluralName: 'Videos',
    action: CONTENT_TYPE.VIDEO,
    Icon: VideocamOutlinedIcon,
    allowedOnProjects: true,
    allowedInTemplate: true,
    allowedInExtendedTask: true,
    allowedInLead: true,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
  },
  [CONTENT_TYPE.TEXTNOTE]: {
    name: 'Text Note',
    pluralName: 'Text Notes',
    action: CONTENT_TYPE.TEXTNOTE,
    Icon: BorderColorOutlinedIcon,
    allowedOnProjects: true,
    allowedInTemplate: true,
    allowedInExtendedTask: true,
    allowedInLead: true,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
  },
  [CONTENT_TYPE.TASK]: {
    name: 'Task',
    pluralName: 'Tasks',
    action: CONTENT_TYPE.TASK,
    Icon: CheckBoxOutlinedIcon,
    allowedOnProjects: true,
    allowedInTemplate: true,
    allowedInExtendedTask: true,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
    allowedMediaTypes: ['pdf', 'image'],
  },
  [CONTENT_TYPE.TIMETRACKING]: {
    name: 'Check in/out',
    pluralName: 'Check in/outs',
    action: CONTENT_TYPE.TIMETRACKING,
    Icon: TimerOutlinedIcon,
    allowedOnProjects: true,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'financial',
  },
  [CONTENT_TYPE.PROJECT]: {
    name: 'Sub-project',
    pluralName: 'Sub-projects',
    action: CONTENT_TYPE.PROJECT,
    Icon: DashboardIcon,
    allowedOnProjects: true,
    allowedInTemplate: true,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInSubproject: false,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
  },
  [CONTENT_TYPE.PDF]: {
    name: 'PDF',
    pluralName: 'PDFs',
    action: CONTENT_TYPE.PDF,
    Icon: PictureAsPdfIcon,
    allowedOnProjects: true,
    allowedInTemplate: true,
    allowedInExtendedTask: true,
    allowedInLead: true,
    allowedInShoebox: true,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
    allowedMediaTypes: ['pdf'],
  },
  [CONTENT_TYPE.SHIFT]: {
    name: 'Shift',
    pluralName: 'Shifts',
    action: CONTENT_TYPE.SHIFT,
    Icon: CalendarTodayIcon,
    allowedOnProjects: true,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInSubproject: true,
    allowedInAdminReceiptBillAdd: false,
    category: 'other',
  },
  [CONTENT_TYPE.LEAD]: {
    name: 'Lead',
    pluralName: 'Leads',
    action: CONTENT_TYPE.LEAD,
    Icon: AssignmentIcon,
    allowedOnProjects: false,
    allowedInTemplate: false,
    allowedInExtendedTask: false,
    allowedInLead: false,
    allowedInShoebox: false,
    allowedInAdminReceiptBillAdd: false,
    allowedInSubproject: false,
  },
};

export const FIELD_DEFINITIONS = {
  ADDRESS: 'address',
  CUSTOMER_ID: 'customerId',
};

export const IGNORE_FOR_TEMPLATES = [FIELD_DEFINITIONS.ADDRESS];

// BUDGET TYPES
export const BUDGET_TYPE_PAYMENTS = 'Payments';
export const BUDGET_TYPE_RECEIPTS_INVOICES = 'ReceiptsInvoices';
export const BUDGET_TYPE_USER_LABOR_HOURS = 'UserLaborHours';
export const BUDGET_TYPE_UNASSIGNED_LABOR_HOURS = 'LaborHours';
export const BUDGET_ENUMS_MONEY = 'MONEY';
export const BUDGET_ENUMS_HOURS = 'HOURS';

export const BUDGET_TYPE_TOTAL_LABOR_COST = 'TotalLaborCost';
export const BUDGET_TYPE_TOTAL_LABOR_HOURS = 'TotalLaborHours';

export const BASIC_PROJECT_BUDGETS = [
  {
    label: BUDGET_TYPE_PAYMENTS,
    value: 0,
    budgetType: BUDGET_ENUMS_MONEY,
    context: null,
  },
  {
    label: BUDGET_TYPE_RECEIPTS_INVOICES,
    value: 0,
    budgetType: BUDGET_ENUMS_MONEY,
    context: null,
  },
  {
    label: BUDGET_TYPE_TOTAL_LABOR_HOURS,
    value: 0,
    budgetType: BUDGET_ENUMS_HOURS,
    context: null,
  },
  {
    label: BUDGET_TYPE_TOTAL_LABOR_COST,
    value: 0,
    budgetType: BUDGET_ENUMS_MONEY,
    context: null,
  },
];

export const DURATION_UNITS = [
  {
    label: 'days',
    value: 1,
  },
  {
    label: 'weeks',
    value: 7,
  },
];

export const ADMIN_CONTENT_QUERIES = {
  // if more than 1 option, arrange alphabetically
  RECEIPTS_AND_BILLS: 'bill&&receipt',
  INVOICES_AND_PAYMENTS: 'invoice&&payment',
  DOCS: 'pdf',
};

export const TARGET_TYPE = {
  PERCENTAGE: 'percentage',
  NUMBER: 'number',
};

export const GRAPH_TYPE = {
  AREA: 'area',
  LINE: 'line',
  BAR: 'bar',
};

export const REPORT_PERIOD = {
  LAST_12_MONTHS: {
    key: 0,
    value: 'Last 12 Months',
    displayValue: 'Last 12 Months',
  },
  THIS_YEAR: { key: 1, value: 'This Year', displayValue: 'This Calendar Year' },
  LAST_YEAR: { key: 2, value: 'Last Year', displayValue: 'Last Calendar Year' },
  THIS_FISCAL_YEAR: {
    key: 3,
    value: 'This Fiscal Year',
    displayValue: 'This Fiscal Year',
  },
  LAST_FISCAL_YEAR: {
    key: 4,
    value: 'Last Fiscal Year',
    displayValue: 'Last Fiscal Year',
  },
};

export const BREAKEVEN_PERIOD = {
  LAST_3_MONTHS: {
    key: 0,
    value: 'Last 3 Months',
    displayValue: 'Last 3 Months',
  },
  LAST_6_MONTHS: {
    key: 1,
    value: 'Last 6 Months',
    displayValue: 'Last 6 Months',
  },
  LAST_12_MONTHS: {
    key: 2,
    value: 'Last 12 Months',
    displayValue: 'Last 12 Months',
  },
};

export const ACCOUNTING_METHOD = {
  CASH: {
    key: 0,
    value: 'Cash',
    displayValue: 'Cash',
  },
  ACCRUAL: {
    key: 1,
    value: 'Accrual',
    displayValue: 'Accrual',
  },
};

export const REPORTING_PERIOD = {
  THIS_WEEK: {
    key: 0,
    value: 'This Week',
    displayValue: 'This Week',
  },
  LAST_WEEK: {
    key: 1,
    value: 'Last Week',
    displayValue: 'Last Week',
  },
  THIS_MONTH: {
    key: 2,
    value: 'This Month',
    displayValue: 'This Month',
  },
  LAST_MONTH: {
    key: 3,
    value: 'Last Month',
    displayValue: 'Last Month',
  },
  THIS_QUARTER: {
    key: 4,
    value: 'This Quarter',
    displayValue: 'This Quarter',
  },
  THIS_FISCAL_QUARTER: {
    key: 5,
    value: 'This Fiscal Quarter',
    displayValue: 'This Fiscal Quarter',
  },
  LAST_QUARTER: {
    key: 6,
    value: 'Last Quarter',
    displayValue: 'Last Quarter',
  },
  LAST_FISCAL_QUARTER: {
    key: 7,
    value: 'Last Fiscal Quarter',
    displayValue: 'Last Fiscal Quarter',
  },
  THIS_CALENDAR_YEAR: {
    key: 8,
    value: 'This Calendar Year',
    displayValue: 'This Calendar Year',
  },
  LAST_CALENDAR_YEAR: {
    key: 9,
    value: 'Last Calendar Year',
    displayValue: 'Last Calendar Year',
  },
  THIS_FISCAL_YEAR: {
    key: 10,
    value: 'This Fiscal Year',
    displayValue: 'This Fiscal Year',
  },
  LAST_FISCAL_YEAR: {
    key: 11,
    value: 'Last Fiscal Year',
    displayValue: 'Last Fiscal Year',
  },
  SINCE_7_DAYS_AGO: {
    key: 12,
    value: 'Since 7 Days Ago',
    displayValue: 'Since 7 Days Ago',
  },
  SINCE_14_DAYS_AGO: {
    key: 13,
    value: 'Since 14 Days Ago',
    displayValue: 'Since 14 Days Ago',
  },
  SINCE_30_DAYS_AGO: {
    key: 14,
    value: 'Since 30 Days Ago',
    displayValue: 'Since 30 Days Ago',
  },
  SINCE_60_DAYS_AGO: {
    key: 15,
    value: 'Since 60 Days Ago',
    displayValue: 'Since 60 Days Ago',
  },
  SINCE_90_DAYS_AGO: {
    key: 16,
    value: 'Since 90 Days Ago',
    displayValue: 'Since 90 Days Ago',
  },
  SINCE_365_DAYS_AGO: {
    key: 17,
    value: 'Since 365 Days Ago',
    displayValue: 'Since 365 Days Ago',
  },
  CUSTOM: {
    key: 18,
    value: 'Custom',
    displayValue: 'Custom',
  },
};

export const BILLABLE_HOURS_GROUP_BY = {
  DAY: {
    key: 0,
    value: 'Day',
    displayValue: 'Day',
    availablePeriods: [
      REPORTING_PERIOD.THIS_WEEK,
      REPORTING_PERIOD.LAST_WEEK,
      REPORTING_PERIOD.SINCE_7_DAYS_AGO,
      REPORTING_PERIOD.SINCE_14_DAYS_AGO,
      REPORTING_PERIOD.CUSTOM,
    ],
  },
  WEEK: {
    key: 1,
    value: 'Week',
    displayValue: 'Week',
    availablePeriods: [
      REPORTING_PERIOD.THIS_WEEK,
      REPORTING_PERIOD.LAST_WEEK,
      REPORTING_PERIOD.THIS_MONTH,
      REPORTING_PERIOD.LAST_MONTH,
      REPORTING_PERIOD.SINCE_30_DAYS_AGO,
      REPORTING_PERIOD.SINCE_60_DAYS_AGO,
      REPORTING_PERIOD.CUSTOM,
    ],
  },
  MONTH: {
    key: 2,
    value: 'Month',
    displayValue: 'Month',
    availablePeriods: [
      REPORTING_PERIOD.THIS_WEEK,
      REPORTING_PERIOD.LAST_WEEK,
      REPORTING_PERIOD.THIS_MONTH,
      REPORTING_PERIOD.LAST_MONTH,
      REPORTING_PERIOD.THIS_QUARTER,
      REPORTING_PERIOD.LAST_QUARTER,
      REPORTING_PERIOD.THIS_CALENDAR_YEAR,
      REPORTING_PERIOD.LAST_CALENDAR_YEAR,
      REPORTING_PERIOD.THIS_FISCAL_YEAR,
      REPORTING_PERIOD.LAST_FISCAL_YEAR,
      REPORTING_PERIOD.SINCE_30_DAYS_AGO,
      REPORTING_PERIOD.SINCE_60_DAYS_AGO,
      REPORTING_PERIOD.SINCE_90_DAYS_AGO,
      REPORTING_PERIOD.SINCE_365_DAYS_AGO,
      REPORTING_PERIOD.CUSTOM,
    ],
  },
  QUARTER: {
    key: 3,
    value: 'Quarter',
    displayValue: 'Quarter',
    availablePeriods: [
      REPORTING_PERIOD.THIS_WEEK,
      REPORTING_PERIOD.LAST_WEEK,
      REPORTING_PERIOD.THIS_MONTH,
      REPORTING_PERIOD.LAST_MONTH,
      REPORTING_PERIOD.THIS_QUARTER,
      REPORTING_PERIOD.LAST_QUARTER,
      REPORTING_PERIOD.THIS_CALENDAR_YEAR,
      REPORTING_PERIOD.LAST_CALENDAR_YEAR,
      REPORTING_PERIOD.THIS_FISCAL_YEAR,
      REPORTING_PERIOD.LAST_FISCAL_YEAR,
      REPORTING_PERIOD.SINCE_90_DAYS_AGO,
      REPORTING_PERIOD.SINCE_365_DAYS_AGO,
      REPORTING_PERIOD.CUSTOM,
    ],
  },
  YEAR: {
    key: 4,
    value: 'Year',
    displayValue: 'Year',
    availablePeriods: [
      REPORTING_PERIOD.THIS_WEEK,
      REPORTING_PERIOD.LAST_WEEK,
      REPORTING_PERIOD.THIS_MONTH,
      REPORTING_PERIOD.LAST_MONTH,
      REPORTING_PERIOD.THIS_QUARTER,
      REPORTING_PERIOD.LAST_QUARTER,
      REPORTING_PERIOD.THIS_CALENDAR_YEAR,
      REPORTING_PERIOD.LAST_CALENDAR_YEAR,
      REPORTING_PERIOD.THIS_FISCAL_YEAR,
      REPORTING_PERIOD.LAST_FISCAL_YEAR,
      REPORTING_PERIOD.SINCE_90_DAYS_AGO,
      REPORTING_PERIOD.SINCE_365_DAYS_AGO,
      REPORTING_PERIOD.CUSTOM,
    ],
  },
};

export const BILLABLE_HOURS_PRESET_FILTERS = {
  THIS_WEEK: '__Preset Filter - This Week__',
  THIS_MONTH: '__Preset Filter - This Month__',
  THIS_CALENDAR_YEAR: '__Preset Filter - This Calendar Year__',
};

export const BILLABLE_HOURS_PRESET_FILTER_OPTIONS = [
  {
    key: BILLABLE_HOURS_PRESET_FILTERS.THIS_WEEK,
    value: BILLABLE_HOURS_PRESET_FILTERS.THIS_WEEK,
    displayValue: `${REPORTING_PERIOD.THIS_WEEK.displayValue} (Default)`,
    filter: {
      reportPeriod: REPORTING_PERIOD.THIS_WEEK.value,
      groupBy: BILLABLE_HOURS_GROUP_BY.DAY.value,
    },
  },
  {
    key: BILLABLE_HOURS_PRESET_FILTERS.THIS_MONTH,
    value: BILLABLE_HOURS_PRESET_FILTERS.THIS_MONTH,
    displayValue: REPORTING_PERIOD.THIS_MONTH.displayValue,
    filter: {
      reportPeriod: REPORTING_PERIOD.THIS_MONTH.value,
      groupBy: BILLABLE_HOURS_GROUP_BY.WEEK.value,
    },
  },
  {
    key: BILLABLE_HOURS_PRESET_FILTERS.THIS_CALENDAR_YEAR,
    value: BILLABLE_HOURS_PRESET_FILTERS.THIS_CALENDAR_YEAR,
    displayValue: REPORTING_PERIOD.THIS_CALENDAR_YEAR.displayValue,
    filter: {
      reportPeriod: REPORTING_PERIOD.THIS_CALENDAR_YEAR.value,
      groupBy: BILLABLE_HOURS_GROUP_BY.MONTH.value,
    },
  },
];

export const CUSTOMER_TXN_REPORT_PERIOD_OPTIONS = [
  REPORTING_PERIOD.THIS_WEEK,
  REPORTING_PERIOD.LAST_WEEK,
  REPORTING_PERIOD.THIS_MONTH,
  REPORTING_PERIOD.LAST_MONTH,
  REPORTING_PERIOD.THIS_FISCAL_QUARTER,
  REPORTING_PERIOD.LAST_FISCAL_QUARTER,
  REPORTING_PERIOD.CUSTOM,
];

export const CUSTOMER_TXN_OPTION = {
  EXCLUDE_PARENT_CUSTOMERS: 'excludeParentCustomers',
  EXCLUDE_SUB_CUSTOMERS: 'excludeSubCustomers',
  EXCLUDE_UNDEPOSITED_FUNDS: 'excludeUndepositedFunds',
  INCLUDE_UNASSIGNED_COGS_TXNS: 'includeUnassignedCogsTxns',
};

export const FAKE_INFINITY = 1e9;

export const SCOREBOARD_NAME = {
  GENERAL: 'general',
  BREAKEVEN: 'breakeven',
  PROFITABILITY: 'profitability',
  DIRECT_COSTS: 'direct-costs',
  PROJECT_PROFITABILITY: 'project-profitability',
  BILLABLE_HOURS: 'billable-hours',
};

export const PROJECT_COST_TYPE = {
  DIRECT: {
    value: 'Direct',
    displayValue: 'Direct',
  },
  INDIRECT: {
    value: 'Indirect',
    displayValue: 'Indirect',
  },
  OVERHEAD: {
    value: 'Overhead',
    displayValue: 'Overhead',
  },
  NA: {
    value: 'N/A',
    displayValue: 'N/A',
  },
};

export const PRODUCTION_COST_TYPE = {
  VARIABLE: { value: 'Variable', displayValue: 'Variable' },
  FIXED: { value: 'Fixed', displayValue: 'Fixed' },
  NOT_APPLICABLE: { value: 'N/A', displayValue: 'N/A' },
};

export const WORKFLOW_STAGE = {
  RECORDED: 'RECORDED',
  UNRECORDED: 'NOT_RECORDED',
};

export const MAX_FILES_ON_RFI = 8;

export const WORKFLOW_STAGE_REASON = {
  RFI_REQUIRED: 'RFI_REQUIRED',
};

export const RFI_SOURCES = {
  UNBOOKED: { value: 'unbooked', label: 'QuickBooks Txn' },
  INFORMATIONAL: { value: 'informational', label: 'General Info' },
  LEVEL: { value: 'level', label: 'Re: Existing Content' },
};

export const RFI_SOURCES_ARRAY = _.keys(RFI_SOURCES).map(source => {
  return RFI_SOURCES[source];
});

export const RFI_FIELDS_TO_SHOW_PER_SOURCE = {
  [RFI_SOURCES.UNBOOKED.value]: [
    'txnAccount',
    // 'txnType',
    'txnPayee',
    'txnDescription',
    'txnDate',
    'txnReceived',
    'txnSpent',
    'initialNotes',
    'clientNotes',
    'projectId',
    'projectList',
    'billList',
    'requestSource',
    'requestType',
    'bankAccount',
    'media',
  ],
  [RFI_SOURCES.INFORMATIONAL.value]: [
    // 'txnAccount',
    // 'txnType',
    // 'txnPayee',
    // 'txnDescription',
    // 'txnDate',
    // 'txnReceived',
    // 'txnSpent',
    'initialNotes',
    'clientNotes',
    // 'projectId',
    // 'projectList',
    // 'billList',
    // 'requestSource',
    // 'requestType',
    'media',
  ],
  [RFI_SOURCES.LEVEL.value]: [
    'txnAccount',
    // 'txnType',
    'txnPayee',
    'txnDescription',
    'txnDate',
    'txnReceived',
    'txnSpent',
    'initialNotes',
    'clientNotes',
    // 'projectId',
    // 'projectList',
    // 'billList',
    'requestSource',
    // 'requestType',
    'bankAccount',
    'media',
  ],
};

export const RFI_TXN_ANSWER_TYPES = {
  debitCreditCardPurchase: {
    value: 'debitCreditCardPurchase',
    label: 'Cash/Debit/Credit Card Purchase',
    hasProjectMultiSelect: true,
  },
  billPayment: {
    value: 'billPayment',
    label: 'Bill Payment',
    hasBillMultiSelect: true,
  },
  customerPayment: {
    value: 'customerPayment',
    label: 'Customer Payment',
    hasProjectSelect: true,
  },
  customerRefund: {
    value: 'customerRefund',
    label: 'Customer Refund',
    hasProjectSelect: true,
  },
  supplierRefund: {
    value: 'supplierRefund',
    label: 'Supplier Refund',
    hasProjectSelect: true,
  },
  payrollPaycheck: {
    value: 'payrollPaycheck',
    label: 'Payroll Paycheck',
    hasProjectSelect: true,
  },
  reimbursement: {
    value: 'reimbursement',
    label: 'Reimbursement',
    hasProjectSelect: true,
  },
  other: { value: 'other', label: 'Other', hasProjectSelect: true },
  unknown: { value: 'unknown', label: 'I do not know', hasProjectSelect: true },
  personal: { value: 'personal', label: 'Personal', hasProjectSelect: true },
};

export const RFI_TXN_ANSWER_TYPES_BY_GROUP = {
  UNBOOKED: {
    RECEIVED: [
      RFI_TXN_ANSWER_TYPES.customerPayment,
      RFI_TXN_ANSWER_TYPES.supplierRefund,
      RFI_TXN_ANSWER_TYPES.other,
      RFI_TXN_ANSWER_TYPES.unknown,
    ],
    SPENT: [
      RFI_TXN_ANSWER_TYPES.debitCreditCardPurchase,
      RFI_TXN_ANSWER_TYPES.billPayment,
      RFI_TXN_ANSWER_TYPES.customerRefund,
      RFI_TXN_ANSWER_TYPES.reimbursement,
      RFI_TXN_ANSWER_TYPES.payrollPaycheck,
      RFI_TXN_ANSWER_TYPES.personal,
      RFI_TXN_ANSWER_TYPES.other,
      RFI_TXN_ANSWER_TYPES.unknown,
    ],
  },
};

export const RFI_MODES = {
  INTERNAL: { value: 'INTERNAL', label: 'Internal' },
  ACTIVE: { value: 'ACTIVE', label: 'Active' },
  COMPLETE: { value: 'COMPLETE', label: 'Complete' },
};
export const RFI_STATUSES = {
  IN_DRAFT: { value: 'IN_DRAFT', label: 'Draft' },
  IN_PENDING_APPROVAL: {
    value: 'IN_PENDING_APPROVAL',
    label: 'Pending Approval',
  },
  IN_REJECTED: { value: 'IN_REJECTED', label: 'Rejected' },
  IN_APPROVED: { value: 'IN_APPROVED', label: 'Approved' },
  EXT_A_ACTIVE: { value: 'EXT_A_ACTIVE', label: 'Active' },
  EXT_A_PENDING_APPROVAL: {
    value: 'EXT_A_PENDING_APPROVAL',
    label: 'For Review',
  },
  EXT_A_REJECTED: { value: 'EXT_A_REJECTED', label: 'Needs Attention' },
  EXT_X_COMPLETE: { value: 'EXT_X_COMPLETE', label: 'Complete' },
};
export const RFI_MODES_TO_QUERY = {
  ACTIVE: 'EXT_A_ACTIVE',
  COMPLETE: 'EXT_X_COMPLETE',
};

export const RFI_QUERY_TO_MODE = {
  EXT_A_ACTIVE: 'ACTIVE',
  EXT_A_PENDING_APPROVAL: 'ACTIVE',
  EXT_A_REJECTED: 'ACTIVE',
  EXT_X_COMPLETE: 'COMPLETE',
};

export const RFI_STATUSES_ADMIN = [
  RFI_STATUSES.IN_DRAFT,
  RFI_STATUSES.EXT_A_ACTIVE,
  RFI_STATUSES.EXT_X_COMPLETE,
];

export const RFI_STATUSES_BOOKKEEPER_EXTERNAL_EDIT = [
  RFI_STATUSES.IN_DRAFT, // THEY MAY NEED TO SET IT BACK TO A DRAFT TO EDIT IT IF IT WENT LIVE BY ACCIDENT
  RFI_STATUSES.EXT_A_ACTIVE,
  RFI_STATUSES.EXT_A_PENDING_APPROVAL, // THEY MAY WANT TO SET IT BACK TO PENDING IF IT WAS REJECTED BY ACCIDENT
  RFI_STATUSES.EXT_A_REJECTED,
  RFI_STATUSES.EXT_X_COMPLETE,
];

export const RFI_STATUSES_INTERNAL_FILTERS = [
  RFI_STATUSES.IN_DRAFT,
  RFI_STATUSES.IN_PENDING_APPROVAL,
  RFI_STATUSES.IN_APPROVED,
  RFI_STATUSES.IN_REJECTED,
];

export const RFI_STATUSES_INTERNALLY_EDITABLE = [
  RFI_STATUSES.IN_DRAFT,
  RFI_STATUSES.IN_PENDING_APPROVAL,
  RFI_STATUSES.IN_REJECTED,
  RFI_STATUSES.IN_APPROVED,
];

export const RFI_STATUSES_INTERNALLY_BASIC = [
  RFI_STATUSES.IN_DRAFT,
  RFI_STATUSES.IN_PENDING_APPROVAL,
];

export const RFI_STATUSES_INTERNALLY_ADMIN = [
  RFI_STATUSES.IN_DRAFT,
  RFI_STATUSES.IN_PENDING_APPROVAL,
  RFI_STATUSES.IN_REJECTED,
  RFI_STATUSES.IN_APPROVED,
];

export const RFI_STATUSES_EXTERNAL_ALL = [
  RFI_STATUSES.EXT_A_ACTIVE,
  RFI_STATUSES.EXT_A_PENDING_APPROVAL,
  RFI_STATUSES.EXT_A_REJECTED,
  RFI_STATUSES.EXT_X_COMPLETE,
];

export const RFI_STATUSES_EXTERNAL_ACTIVE = [
  RFI_STATUSES.EXT_A_ACTIVE,
  RFI_STATUSES.EXT_A_PENDING_APPROVAL,
  RFI_STATUSES.EXT_A_REJECTED,
];

export const RFI_STATUSES_EXTERNAL_CLIENT_CHOICES = [
  RFI_STATUSES.EXT_A_ACTIVE,
  RFI_STATUSES.EXT_A_PENDING_APPROVAL,
];

export const RFI_STATUS_COLORS = {
  [RFI_STATUSES.IN_DRAFT.value]: {
    background: themePalette.brandColorLightGrey,
    color: '#666',
  },
  [RFI_STATUSES.IN_REJECTED.value]: {
    background: themePalette.brandColorError,
    color: '#fff',
  },
  [RFI_STATUSES.IN_PENDING_APPROVAL.value]: {
    background: themePalette.brandColorOrange,
    color: '#fff',
  },
  [RFI_STATUSES.IN_APPROVED.value]: {
    background: themePalette.brandColorGreen,
    color: '#fff',
  },
};

export const NOT_SAVED_WORDING = 'Not Saved';

export const REPORT_TIME_BASIS = {
  DAYS: 'Days',
  WEEKS: 'Weeks',
  MONTHS: 'Months',
  QUARTERS: 'Quarters',
  YEARS: 'Years',
};

export const TIME_UNIT = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
};

export const SERIES_DATA_TYPE = {
  DATE: 'date',
  NUMBER: 'number',
  PERCENTAGE: 'percentage',
  MONEY: 'money',
  HOUR: 'hour',
};

export const ACCOUNTING_CATEGORY = {
  INCOME: 'Income',
  COGS: 'Cost of Goods Sold',
  EXPENSES: 'Expenses',
  LIABILITIES: 'Liabilities',
};

export const ACCOUNTING_ACCOUNT_TYPE = {
  ACCOUNTS_PAYABLE: 'Accounts Payable',
  ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
  BANK: 'Bank',
  COGS: 'Cost of Goods Sold',
  CREDIT_CARD: 'Credit Card',
  EQUITY: 'Equity',
  EXPENSE: 'Expense',
  INCOME: 'Income',
  LONG_TERM_LIABILITY: 'Long Term Liability',
  OTHER_CURRENT_ASSET: 'Other Current Asset',
  OTHER_CURRENT_LIABILITY: 'Other Current Liability',
  OTHER_EXPENSE: 'Other Expense',
  OTHER_INCOME: 'Other Income',
};

export const EXPORT_FORMAT = {
  CSV: 'csv',
  XLSX: 'xslx',
};

export const QBO_SYNCABLE_TYPE = {
  LEAD: 'lead',
  PROJECT: 'project',
  CUSTOMER: 'customer',
};

export const TYPE_TO_STAT_LABEL = {
  [`${CONTENT_TYPE.TASK}&&${TASK_STATUSES.INCOMPLETE}`]: 'taskIncomplete',
  [`${CONTENT_TYPE.TASK}&&${TASK_STATUSES.IN_PROGRESS}`]: 'taskInProgress',
  [`${CONTENT_TYPE.TASK}&&${TASK_STATUSES.PENDING_APPROVAL}`]: 'taskPendingApproval',
  [`${CONTENT_TYPE.TASK}&&${TASK_STATUSES.COMPLETED}`]: 'taskCompleted',
  [CONTENT_TYPE.TIMETRACKING]: 'timetrackingTotal',
  [CONTENT_TYPE.RECEIPT]: 'receiptTotal',
  [CONTENT_TYPE.BILL]: 'billTotal',
  [CONTENT_TYPE.INVOICE]: 'invoiceTotal',
  [CONTENT_TYPE.PAYMENT]: 'paymentTotal',
  [`${CONTENT_TYPE.TIMETRACKING}Cost`]: 'timetrackingCostTotal',
};

export const ALLOWED_TYPES_TO_MOVE = [
  CONTENT_TYPE.IMAGE,
  CONTENT_TYPE.GALLERY,
  CONTENT_TYPE.VIDEO,
  CONTENT_TYPE.TEXTNOTE,
  // CONTENT_TYPE.TASK, // leaving these out since they have side effects we don't want to take into account right now
  // CONTENT_TYPE.SHIFT,
  // CONTENT_TYPE.TIMETRACKING,
  CONTENT_TYPE.RECEIPT,
  CONTENT_TYPE.BILL,
  CONTENT_TYPE.INVOICE,
  CONTENT_TYPE.PAYMENT,
  CONTENT_TYPE.PDF,
];

export const LEVEL_ROLE = {
  ADMIN: 'ADMIN',
  BOOKKEEPER: 'BOOKKEEPER',
  COMPANY_OWNER: 'COMPANY_OWNER',
};

export const CREW_ROLES = {
  EMPLOYEE: 'employee',
  SUB_1099: 'subcontractor_1099',
  CONSULTANT_ASSISTANT: 'consultant_assistant',
  CUSTOMER: 'customer',
  OTHER: 'other',
};
export const CREW_ROLES_OPTIONS = [
  { value: CREW_ROLES.EMPLOYEE, label: 'Employee' },
  { value: CREW_ROLES.SUB_1099, label: 'Subcontractor/1099' },
  { value: CREW_ROLES.CONSULTANT_ASSISTANT, label: 'Consultant/Assistant' },
  { value: CREW_ROLES.CUSTOMER, label: 'Customer' },
  { value: CREW_ROLES.OTHER, label: 'Other' },
];

export const CREW_DEPARTMENT = {
  FIELD_PRODUCTION: 'field_production',
  OFFICE: 'office',
  OWNER: 'owner',
  PROJECT_MANAGEMENT: 'project_management',
  OTHER: 'other',
};

export const CREW_DEPARTMENT_OPTIONS = [
  { value: CREW_DEPARTMENT.FIELD_PRODUCTION, label: 'Field/Production' },
  { value: CREW_DEPARTMENT.OFFICE, label: 'Office' },
  { value: CREW_DEPARTMENT.OWNER, label: 'Owner' },
  { value: CREW_DEPARTMENT.PROJECT_MANAGEMENT, label: 'Project Management' },
  { value: CREW_DEPARTMENT.OTHER, label: 'Other' },
];

export const CREW_PAY_TYPES = {
  VARIABLE_HOURLY: 'variable_hourly',
  FIXED_SALARY: 'fixed_salary',
  NO_PAY: 'no_pay',
};

export const CREW_PAY_TYPES_OPTIONS = [
  { value: CREW_PAY_TYPES.VARIABLE_HOURLY, label: 'Variable/Hourly' },
  { value: CREW_PAY_TYPES.FIXED_SALARY, label: 'Fixed/Salary' },
  { value: CREW_PAY_TYPES.NO_PAY, label: 'No Pay' },
];

export const CREW_VACA_ACCRUAL_TYPES = {
  ACCRUED: 'accrued',
  PAID_OUT: 'paid_out',
};

export const CREW_VACA_ACCRUAL_TYPES_OPTIONS = [
  { value: CREW_VACA_ACCRUAL_TYPES.ACCRUED, label: 'Accrued' },
  { value: CREW_VACA_ACCRUAL_TYPES.PAID_OUT, label: 'Paid Out' },
];

export const MAZUMAGO_ENDPOINT = 'https://app.trusspayments.com';

export const MAZUMAGO_MANAGE_CARDS_URL = `${MAZUMAGO_ENDPOINT}/dashboard/card/manageCards`;

export const MAZUMAGO_REGISTRATION_URL = `${MAZUMAGO_ENDPOINT}/auth/register/partner?integration=check_the_level&integration_id=`;

export const MAZUMAGO_STATUS = {
  CONNECTED: 'connected',
  PENDING: 'pending',
};

export const MAZUMAGO_CREDIT_CARD_STATUS = {
  INACTIVE: { value: 'Inactive', displayValue: 'Inactive' },
  ACTIVE: { value: 'Active', displayValue: 'Active' },
  CLOSED_BY_CUSTOMER: { value: 'ClosedByCustomer', displayValue: 'Closed' },
  FROZEN: { value: 'Frozen', displayValue: 'Frozen' },
};

export const CREDIT_CARD_TYPE = {
  MAZUMAGO: 'MazumaGo',
};

export const MAZUMAGO_SUPPORTED_COUNTRY_CODES = {
  US: { value: 'US', label: 'United States' },
};

export const BUDGET_INFO = {
  [BUDGET_TYPE_PAYMENTS]: {
    helperText: 'Total including taxes ($)',
    labelText: `How much did you quote for this project?`,
    adornment: '$',
    numberFormat: '0.00',
  },
  [BUDGET_TYPE_RECEIPTS_INVOICES]: {
    helperText: 'Total including taxes ($)',
    labelText: `What are your estimated project costs excluding labor?`,
    adornment: '$',
    numberFormat: '0.00',
  },
  [BUDGET_TYPE_TOTAL_LABOR_HOURS]: {
    helperText: 'Total hours',
    labelText: `What are your estimated labor hours?`,
    adornment: '#',
    numberFormat: '0.0',
  },
  [BUDGET_TYPE_TOTAL_LABOR_COST]: {
    helperText: 'Total including labor burden ($)',
    labelText: `What is your estimated labor cost?`,
    adornment: '$',
    numberFormat: '0.00',
  },
};

export const RFI_ADMIN_PW = 'temp';
// export const RFI_ADMIN_PW = 'levelheaded';
export const GLOBAL_EXPENSE_STATUS = {
  UNPAID: 'unpaid',
  PARTIALLY_PAID: 'partiallyPaid',
  PAID: 'paid',
  OVER_PAID: 'overPaid',
};

export const GLOBAL_EXPENSE_STATUS_LABEL = {
  [GLOBAL_EXPENSE_STATUS.UNPAID]: 'Unpaid',
  [GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID]: 'Partially Paid',
  [GLOBAL_EXPENSE_STATUS.PAID]: 'Paid',
  [GLOBAL_EXPENSE_STATUS.OVER_PAID]: 'Over Paid',
};

export const PAYMENT_METHOD = {
  CASH: 'cash',
  CHECK: 'check',
  CREDIT_MEMO: 'creditMemo',
  DEBIT: 'debit',
  TRANSFER: 'transfer',
  OTHER: 'other',
};

export const PAYMENT_METHOD_LABEL = {
  [PAYMENT_METHOD.CASH]: 'Cash',
  [PAYMENT_METHOD.CHECK]: 'Check',
  [PAYMENT_METHOD.CREDIT_MEMO]: 'Credit Memo',
  [PAYMENT_METHOD.DEBIT]: 'Debit/Credit Card',
  [PAYMENT_METHOD.TRANSFER]: 'Transfer',
  [PAYMENT_METHOD.OTHER]: 'Other',
};

export const VERYFI_KEY_TO_INFO = {
  category: { label: 'Possible Category', valueType: 'string' },
  currency_code: { label: 'Currency', valueType: 'string' },
  subtotal: { label: 'Subtotal', valueType: 'float-money' },
  tax: { label: 'Tax', valueType: 'float-money' },
  tip: { label: 'Tip', valueType: 'float-money' },
  total: { label: 'Total', valueType: 'float-money' },
  payment: {
    label: 'Payment Details',
    valueType: 'object',
    subFields: {
      card_number: { label: 'Card Number', valueType: 'string' }, // '2658',
      display_name: {
        label: 'Card',
        valueType: 'string',
      }, // 'Visa ***2658'
      terms: { label: 'Card Terms', valueType: 'string' }, // not actually sure yet
      type: { label: 'Card Type', valueType: 'string' }, // 'visa'
    },
  },
  vendor: {
    label: 'Vendor',
    valueType: 'object',
    subFields: {
      name: { label: 'Name', valueType: 'string' }, // 'Home Depot',
      address: {
        label: 'Address',
        valueType: 'string',
      }, // 19911 S Prairie Rd E, Bonney Lake, WA 98391
    },
  },
};

// class name gets stripped - we either need to allow it or find another way of doing this
export const FINANCE_INFO_OPENER = `<span>||---------Financial Metadata---------||</span>`;

export const FINANCE_INFO_CLOSER = `<span>||------------------------------------||</span>`;

export const OCR_WAIT_TIME = 1000 * 20; // 20 seconds

export const RESULT = {
  OK: 'ok',
  ERROR: 'error',
};

/** IMPORTANT: The order in the array will match the order of the data in the report view */
export const AUTOSELECT_UNCATEGORIZED_ACCOUNT_NAMES = [
  'Uncategorized Income',
  'Uncategorized Expense',
  'Uncategorized Asset',
];

export const RECEIVING_TYPE_ACCOUNTS = [
  'accountsPayable',
  'creditCard',
  'otherCurrentLiabilities',
  'longTermLiabilities',
  'equity',
  'income',
  'otherIncome',
];

export const SPENDING_TYPE_ACCOUNTS = [
  'bank',
  'accountsReceivable',
  'otherCurrentAssets',
  'fixedAsset',
  'otherAssets',
  'costOfGoodsSold',
  'expenses',
  'otherExpense',
];

/** @enum {string} */
export const PRODUCT = {
  ADVISORY: 'advisory', // No longer in use?
  PROJECT_MANAGEMENT: 'projectManagement',
  BOOKKEEPING: 'bookkeeping',
  BOOKKEEPING_ALERTS: 'bookkeepingAlerts',
  RFI: 'rfi',
};

export const PRODUCTS_SUBSCRIPTION_BASED = [PRODUCT.BOOKKEEPING_ALERTS];

export const PRODUCT_DEFINITIONS = [
  {
    id: PRODUCT.PROJECT_MANAGEMENT,
    name: 'CUSTOMER AND PROJECT MANAGEMENT',
    description:
      'Stay on top of your projects in Level with our customer and project management features, providing the ability to schedule shifts and tasks for your team, track hours worked and project expenses, and much more.',
    logoSrc: ProductProjectManagementImage,
  },
  {
    id: PRODUCT.BOOKKEEPING,
    name: 'FINANCIAL SCOREBOARDS',
    description:
      'Gain a competitive edge by syncing Level with Quickbooks Online to unlock our financial scoreboards to view your project profitability, identify trends in job costing and forecast your breakeven.',
    disabledTooltip:
      'To select this product, Customer and Project Management product must be selected.',
    logoSrc: ProductFinancialScoreboardImage,
  },
  {
    id: PRODUCT.BOOKKEEPING_ALERTS,
    name: 'BOOKKEEPING ALERTS',
    description:
      "The first step to developing an accurate picture of your company's financial health is to make sure your books are in order. Our bookeeping alerts help train your team to follow industry best practices so you can get the most out of Level!",
    logoSrc: ProductBookkeepingAlertImage,
  },
];

export const RFI_DESCRIPTION_QUICKSTARTS = [
  {
    value:
      'Please upload the supporting documentation for this transaction. Please be sure the vendor, date, amount and other relevant information is clear.',
    label: 'Upload Required - US Clients',
  },
  {
    value:
      'Please upload the supporting documentation for this transaction. Please be sure the supplier, date, amount, sales tax and other relevant information is clear.',
    label: 'Upload Required - Canada Client',
  },
  {
    value:
      "You're missing bills for your recent bill payment, please be sure to upload the missing bill numbers.",
    label: 'Bill Payment - Statement Provided',
  },
  {
    value:
      "You're missing the supplier statement for your recent payment, please be sure to upload the statement.",
    label: 'Missing Supplier Statement',
  },
  {
    value:
      'You are missing the invoice in QBO for this payment. Please confirm the invoice is entered and specify the invoice number being paid. ',
    label: 'Client Payment',
  },
];

export const SIDEBAR_WIDTH = 240;
export const HEADER_HEIGHT = 64;
export const LOCK_REASON = {
  FREE_TRIAL_EXPIRED: 'FREE_TRIAL_EXPIRED',
  SUBSCRIPTION_ENDED: 'SUBSCRIPTION_ENDED',
  PAYMENT_OVERDUE: 'PAYMENT_OVERDUE',
};

export const EMPTY_USERID = 'willBePulledFromCognitoSubContentInResolver';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY h:mma';
export const DEFAULT_TIME_FORMAT = 'h:mma';

export const SUBSCRIPTION_STATUSES = {
  ACTIVE_FREE_TRIAL: 'ACTIVE_FREE_TRIAL',
  EXPIRED_FREE_TRIAL: 'EXPIRED_FREE_TRIAL',
  ACTIVE_SUBSCRIPTION: 'ACTIVE_SUBSCRIPTION',
  EXPIRED_SUBSCRIPTION: 'EXPIRED_SUBSCRIPTION',
};

export const ACTIVE_SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUSES.ACTIVE_FREE_TRIAL,
  SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION,
];

export const EXPIRED_SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUSES.EXPIRED_FREE_TRIAL,
  SUBSCRIPTION_STATUSES.EXPIRED_SUBSCRIPTION,
];
export const MANAGEABLE_SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION,
  SUBSCRIPTION_STATUSES.EXPIRED_SUBSCRIPTION,
];
export const UNSUBSCRIBE_TYPES = {
  BOOKKEEPING_ALERTS_LIST: 'BOOKKEEPING_ALERTS_LIST',
};

export const ACTIVITY_STATUS = {
  ERROR: 'error',
  PENDING: 'pending',
  SUCCESS: 'success',
};

export const FREQUENCY_OF_BOOKKEEPING_TASKS = {
  ANNUALLY: 'Annually',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
};

export const COMPANY_INDUSTRY_OPTIONS = [
  {
    label: 'Retail Stores',
    value: 'retail-stores',
    secondaryValues: [
      'Independent boutiques',
      'Specialty shops',
      'Convenience stores',
      'Clothing stores',
      'Electronics shops',
      'Grocery stores',
      'Home goods stores',
      'Toy stores',
      'Pet stores',
      'Bookstores',
      'Stationery shops',
      'Sports equipment stores',
      'Furniture stores',
      'Jewelry stores',
      'Gift shops',
    ],
  },
  {
    label: 'Restaurants and Cafés',
    value: 'restaurants-and-cafés',
    secondaryValues: [
      'Family-owned restaurants',
      'Coffee shops',
      'Food trucks',
      'Bakeries',
      'Delis',
      'Ice cream parlors',
      'Pizzerias',
      'Fine dining restaurants',
      'Casual dining restaurants',
      'Fast food restaurants',
      'Ethnic restaurants',
      'Brunch spots',
      'Cafeterias',
      'Tea houses',
      'Juice bars',
    ],
  },
  {
    label: 'Service Providers',
    value: 'service-providers',
    secondaryValues: [
      'Salons and spas',
      'Cleaning services',
      'Repair shops (auto, appliance, etc.)',
      'Landscaping services',
      'Pest control services',
      'Event planning services',
      'Pet grooming services',
      'Tutoring services',
      'Handyman services',
      'Security services',
      'Moving services',
      'Courier services',
      'Personal training services',
      'Laundry services',
      'Travel agencies',
    ],
  },
  {
    label: 'Professional Services',
    value: 'professional-services',
    secondaryValues: [
      'Law firms',
      'Accounting firms',
      'Consulting agencies',
      'Marketing firms',
      'Advertising agencies',
      'Public relations firms',
      'Architectural firms',
      'Engineering firms',
      'Human resources consultants',
      'IT consultants',
      'Management consultants',
      'Financial advisors',
      'Recruitment agencies',
      'Business coaches',
      'Design studios',
    ],
  },
  {
    label: 'Healthcare Providers',
    value: 'healthcare-providers',
    secondaryValues: [
      'Small clinics',
      'Dental offices',
      'Physiotherapy centers',
      'Chiropractic offices',
      'Optometry clinics',
      'Mental health clinics',
      'Pediatric clinics',
      'Dermatology clinics',
      'Urgent care centers',
      'Home healthcare services',
      'Nursing homes',
      'Speech therapy centers',
      'Occupational therapy centers',
      'Orthopedic clinics',
      'Veterinary clinics',
    ],
  },
  {
    label: 'Real Estate Agencies',
    value: 'real-estate-agencies',
    secondaryValues: [
      'Property management firms',
      'Independent real estate brokers',
      'Commercial real estate agencies',
      'Residential real estate agencies',
      'Real estate investment firms',
      'Real estate appraisal firms',
      'Real estate development firms',
      'Real estate leasing firms',
      'Real estate marketing agencies',
      'Real estate auction firms',
      'Real estate consulting firms',
      'Real estate law firms',
      'Mortgage brokers',
      'Title companies',
      'Home inspection services',
    ],
  },
  {
    label: 'Construction and Trades',
    value: 'construction-and-trades',
    secondaryValues: [
      'Small construction companies',
      'Electricians',
      'Plumbers',
      'Carpenters',
      'Roofing contractors',
      'HVAC contractors',
      'Masonry contractors',
      'Painting contractors',
      'Flooring contractors',
      'General contractors',
      'Concrete contractors',
      'Landscaping contractors',
      'Demolition contractors',
      'Drywall contractors',
      'Insulation contractors',
    ],
  },
  {
    label: 'Creative Agencies',
    value: 'creative-agencies',
    secondaryValues: [
      'Marketing firms',
      'Graphic design studios',
      'Photography studios',
      'Web design agencies',
      'Content creation agencies',
      'Branding agencies',
      'Video production studios',
      'Animation studios',
      'Copywriting agencies',
      'Public relations firms',
      'Event planning firms',
      'Art studios',
      'Media agencies',
      'Publishing houses',
      'Interior design studios',
    ],
  },
  {
    label: 'Nonprofit Organizations',
    value: 'nonprofit-organizations',
    secondaryValues: [
      'Local charities',
      'Community organizations',
      'Environmental organizations',
      'Health and wellness organizations',
      'Educational nonprofits',
      'Animal welfare organizations',
      'Arts and culture nonprofits',
      'Social services nonprofits',
      'Advocacy groups',
      'Human rights organizations',
      'Religious organizations',
      'Youth organizations',
      'Senior services organizations',
      'Housing and shelter organizations',
      'Food banks',
    ],
  },
  {
    label: 'E-commerce Businesses',
    value: 'e-commerce-businesses',
    secondaryValues: [
      'Online retail stores',
      'Dropshipping businesses',
      'Subscription box services',
      'Print-on-demand businesses',
      'Digital product stores',
      'Marketplace sellers',
      'E-commerce consultancies',
      'Affiliate marketing websites',
      'Online wholesale stores',
      'Online consignment stores',
      'Handmade product stores',
      'Niche product stores',
      'Online fashion stores',
      'Online electronics stores',
      'Online furniture stores',
    ],
  },
  {
    label: 'Educational Services',
    value: 'educational-services',
    secondaryValues: [
      'Tutoring centers',
      'Small private schools',
      'Online learning platforms',
      'Language schools',
      'Music schools',
      'Art schools',
      'Dance schools',
      'Test preparation centers',
      'Vocational training centers',
      'Special education services',
      'STEM education centers',
      'Adult education centers',
      'Montessori schools',
      'Preschools',
      'Educational consultants',
    ],
  },
  {
    label: 'Hospitality Businesses',
    value: 'hospitality-businesses',
    secondaryValues: [
      'Bed and breakfasts',
      'Small hotels and motels',
      'Vacation rentals',
      'Event venues',
      'Travel agencies',
      'Tour operators',
      'Wedding planners',
      'Catering services',
      'Conference centers',
      'Resorts',
      'Guest houses',
      'Hostels',
      'Campgrounds',
      'RV parks',
      'Cruise services',
    ],
  },
  {
    label: 'Health and Fitness',
    value: 'health-and-fitness',
    secondaryValues: [
      'Gyms and fitness centers',
      'Yoga studios',
      'Pilates studios',
      'Martial arts schools',
      'CrossFit gyms',
      'Personal training studios',
      'Dance studios',
      'Wellness centers',
      'Health clubs',
      'Boot camps',
      'Spas',
      'Nutrition counseling services',
      'Weight loss centers',
      'Sports coaching services',
      'Athletic training facilities',
    ],
  },
  {
    label: 'Wholesale and Distribution',
    value: 'wholesale-and-distribution',
    secondaryValues: [
      'Small distributors',
      'Wholesale suppliers',
      'Import/export businesses',
      'Warehousing services',
      'Logistics companies',
      'Freight forwarding companies',
      'B2B wholesale companies',
      'Supply chain management firms',
      "Manufacturers' representatives",
      'Industrial equipment suppliers',
      'Agricultural product distributors',
      'Pharmaceutical distributors',
      'Food and beverage distributors',
      'Automotive parts distributors',
      'Office supplies distributors',
    ],
  },
  {
    label: 'Technology Startups',
    value: 'technology-startups',
    secondaryValues: [
      'Software development firms',
      'IT support services',
      'App development companies',
      'Tech consultancies',
      'Web development firms',
      'Cybersecurity firms',
      'Fintech startups',
      'Healthtech startups',
      'E-commerce platforms',
      'AI and machine learning firms',
      'SaaS companies',
      'Blockchain startups',
      'IoT startups',
      'Edtech startups',
      'Tech incubators',
    ],
  },
];

export const USER_ACTIVITY_STATUS = {
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
};
