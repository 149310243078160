import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import {
  MAX_CHARACTERS_IN_EMAIL,
  MAX_CHARACTERS_IN_PASSWORD,
} from '../../config/appDefaults';

import AuthTextField from './auth-text-field/auth-text-field';
import BigButton from './big-button';
import PasswordRequirementsTooltip from './auth-text-field/password-requirements-tooltip';
import { LINK_EXPIRED } from './auth-errors.json';

import styles from './auth.styles';

const useStyles = makeStyles(styles);

const SignUpForm = ({
  onSignUp,
  onEmailChange,
  email,
  onPasswordChange,
  onConfirmPasswordChange,
  password,
  confirmPassword,
  authError,
  loading,
  title,
  signUpTokenMode,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const linkHasExpired = authError?.form === LINK_EXPIRED;

  const goToSignIn = () => {
    history.replace('/auth/signin');
  };

  return (
    <>
      {!!title && (
        <Typography className={classes.formTitle}>{title}</Typography>
      )}
      <Grid container item xs={12} className={classes.formBody}>
        <Grid container item xs={12}>
          <AuthTextField
            name="email"
            label="Email"
            value={email}
            required={!signUpTokenMode}
            maxLength={MAX_CHARACTERS_IN_EMAIL}
            errorText={authError?.email}
            onChange={onEmailChange}
            disabled={signUpTokenMode}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthTextField
            name="password"
            label="Password"
            lableTooltip={<PasswordRequirementsTooltip />}
            type="password"
            value={password}
            required
            maxLength={MAX_CHARACTERS_IN_PASSWORD}
            errorText={authError?.password}
            onChange={onPasswordChange}
            disabled={linkHasExpired}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthTextField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            required
            maxLength={MAX_CHARACTERS_IN_PASSWORD}
            errorText={authError?.confirmPassword}
            onChange={onConfirmPasswordChange}
            onEnterKeyPress={onSignUp}
            disabled={linkHasExpired}
          />
        </Grid>
        {!!authError?.form && (
          <Grid item xs={12} className={classes.formErrorContainer}>
            <Typography style={{ color: 'red' }}>{authError.form}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.formActions}
      >
        {!linkHasExpired && (
          <BigButton
            buttonText="Create Account"
            fullWidth
            isProcessing={loading}
            onClick={onSignUp}
            style={{ minWidth: isMobile ? 180 : 220 }}
          />
        )}
        {linkHasExpired && (
          <BigButton
            buttonText="Go to Sign In"
            fullWidth
            isProcessing={loading}
            onClick={goToSignIn}
            style={{ minWidth: isMobile ? 180 : 220 }}
          />
        )}
      </Grid>
    </>
  );
};

export default SignUpForm;
