import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import _ from 'lodash';
import { GoogleApiWrapper } from 'google-maps-react';

import GetCompanyInfo from '../../graphql/queries/get-company-info';
import GetUserInfo from '../../graphql/queries/GetUserInfo';
import constructionSign from '../../assets/images/construction-sign@4x.png';

import LevelSupportEmailAddressLink from '../../components/level-support-email-address-link/level-support-email-address-link';

import {
  EXPIRED_SUBSCRIPTION_STATUSES,
  LOCK_REASON,
  SUBSCRIPTION_STATUSES,
} from '../../config/appDefaults';
import { determineManagingCompanyInfo } from '../../helpers';
import ManageSubscriptionDialog from '../../layouts/Main/components/Topbar/manage-subscription-dialog/manage-subscription-dialog';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 100,
    textAlign: 'center',
  },
  title: {
    marginBottom: 32,
  },
  description: {
    marginBottom: 32,
  },
}));

const CompanyLocked = ({ dispatch, currentAuth, managingCompanyInfoState }) => {
  const classes = useStyles();
  const [showGoToPaymentModal, setShowGoToPaymentModal] = useState(false);

  const { data: companyInfoData } = useQuery(GetCompanyInfo, {
    pollInterval: 5000,
  });

  const { data: userInfoData } = useQuery(GetUserInfo, {
    variables: {
      userId: 'willBePulledFromCognitoSubContentInResolver',
    },
    pollInterval: 5000,
  });

  useEffect(() => {
    const companies = _.get(companyInfoData, 'getCompanyInfo.items', null);
    const userInfo = _.get(userInfoData, 'getMyUserInfo', null);

    if (companies && !_.isEmpty(userInfo)) {
      const managingCompanyInfo = determineManagingCompanyInfo({
        companies,
        userInfo,
      });

      if (
        currentAuth.isLoggedIn &&
        !_.isEqual(managingCompanyInfo, managingCompanyInfoState)
      ) {
        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: { ...managingCompanyInfo },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfoData, userInfoData, managingCompanyInfoState]);

  const renderLockedMessage = () => {
    if (managingCompanyInfoState?.isLockedReason) {
      switch (managingCompanyInfoState?.isLockedReason) {
        case LOCK_REASON.FREE_TRIAL_EXPIRED: {
          return (
            <>
              <Typography variant="h1" className={classes.title}>
                Your free trial has expired.
              </Typography>
              <Typography variant="h4" className={classes.description}>
                To continue using our project management, advisory or
                bookkeeping features, please contact&nbsp;
                <LevelSupportEmailAddressLink />.
              </Typography>
            </>
          );
        }
        case LOCK_REASON.SUBSCRIPTION_ENDED: {
          return (
            <>
              <Typography variant="h1" className={classes.title}>
                Your subscription has expired.
              </Typography>
              <Typography variant="h4" className={classes.description}>
                To continue using our project management, advisory or
                bookkeeping features, please contact&nbsp;
                <LevelSupportEmailAddressLink />.
              </Typography>
            </>
          );
        }
        case LOCK_REASON.PAYMENT_OVERDUE:
        default: {
          return (
            <>
              <Typography variant="h1" className={classes.title}>
                You do not have access.
              </Typography>
              <Typography variant="h4" className={classes.description}>
                This account has been locked out by a Level administrator. If
                you believe this was a mistake, please contact&nbsp;
                <LevelSupportEmailAddressLink /> to regain access.
              </Typography>
            </>
          );
        }
      }
    } else if (
      EXPIRED_SUBSCRIPTION_STATUSES.includes(
        managingCompanyInfoState?.subscriptionStatus
      )
    ) {
      const wasFreeTrial =
        managingCompanyInfoState?.subscriptionStatus ===
        SUBSCRIPTION_STATUSES.EXPIRED_FREE_TRIAL;
      // Show based on subscriptionStatus for subscription based users
      const { isCompanyOwner, isCompanyBookkeeper } =
        managingCompanyInfoState || {};
      const isOwnerOrBookkeeper = isCompanyOwner || isCompanyBookkeeper;
      return (
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {isOwnerOrBookkeeper ? (
                <>
                  {wasFreeTrial ? (
                    <>
                      <Typography variant="h1" className={classes.title}>
                        Your free trial has expired.
                      </Typography>
                      <Typography variant="h4" className={classes.description}>
                        To continue using Level, please purchase a subscription.
                      </Typography>
                      <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Grid container justifyContent="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowGoToPaymentModal(true)}
                          >
                            Purchase A Subscription
                          </Button>
                        </Grid>
                      </Grid>
                      <Typography variant="h4" style={{ marginTop: 64 }}>
                        If you have any issues, please contact Level Support
                        at&nbsp;
                        <LevelSupportEmailAddressLink />.
                      </Typography>

                      {showGoToPaymentModal && (
                        <ManageSubscriptionDialog
                          closeDialog={() => {
                            setShowGoToPaymentModal(false);
                          }}
                          managingCompanyInfo={managingCompanyInfoState}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="h1" className={classes.title}>
                        Your subscription has expired.
                      </Typography>
                      <Typography variant="h4" className={classes.description}>
                        To continue using Level, please update your
                        subscription.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        href={
                          process.env.REACT_APP_STRIPE_SELF_SERVE_PORTAL_LINK
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        endIcon={<OpenInNew />}
                        style={{ marginBottom: 64 }}
                      >
                        Manage billing and subscription
                      </Button>
                      <Typography
                        variant="h4"
                        style={{ paddingBottom: 16, fontStyle: 'italic' }}
                      >
                        We use Stripe to manage subscriptions in a safe,
                        reliable way. If you don&apos;t recall which email
                        address you use for billing, check the email address
                        that you&apos;re receiving current invoices to.
                      </Typography>
                      <Typography variant="h4" style={{ marginTop: 64 }}>
                        If you have any issues, please contact Level Support
                        at&nbsp;
                        <LevelSupportEmailAddressLink />.
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <Typography
                  variant="body1"
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                >
                  You do not have permission to change billing and subscription
                  details. Please ask the user who created your company account
                  to manage billing and subscription.
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Typography variant="h2" className={classes.title}>
          You do not have access.
        </Typography>
        <Button variant="contained" component={NavLink} to="/">
          Try Again
        </Button>
        <Typography variant="h5" style={{ marginTop: 64 }}>
          Please contact Level Support at <LevelSupportEmailAddressLink />.
        </Typography>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={7} xs={12}>
          <div className={classes.content}>
            {renderLockedMessage()}
            <img alt="construction sign" src={constructionSign} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  currentAuth: state.currentAuth,
  managingCompanyInfoState: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  })(CompanyLocked)
);
