import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Company}
  mutation AddOrUpdateCompany(
    $companyId: ID!
    $companyName: String
    $companyLogo: String
    $invitationId: String
    $bookkeepingAlertEmailLists: [BookkeepingAlertEmailSingleListInput]
    $uncategorizedTxnAccounts: [String]
    $rfiRecipients: [String]
    $timetrackingSettings: TimetrackingSettingsInput
    $userRole: String
    $products: [String]
    $address: String
    $industry: String
  ) {
    addOrUpdateCompany(
      input: {
        companyId: $companyId
        companyName: $companyName
        companyLogo: $companyLogo
        invitationId: $invitationId
        bookkeepingAlertEmailLists: $bookkeepingAlertEmailLists
        uncategorizedTxnAccounts: $uncategorizedTxnAccounts
        rfiRecipients: $rfiRecipients
        timetrackingSettings: $timetrackingSettings
        userRole: $userRole
        products: $products
        address: $address
        industry: $industry
      }
    ) {
      ...CompanyFields
    }
  }
`;
