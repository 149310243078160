import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { Grid } from '@material-ui/core';

import { PRODUCT, PRODUCT_DEFINITIONS } from '../../config/appDefaults';
import LevelLogo from '../../components/level-logo/level-logo';
import { AddOrUpdateCompanyAction } from '../../graphql/graphql';
import { useStyles } from './onboarding.styles';
import ProductItem from './product-item';
import OnboardingStepButtons from './onboarding-step-buttons';

const OnboardingSelectProductsStep = ({
  companyInfo,
  setLoading,
  onAddOrUpdateCompany,
}) => {
  const classes = useStyles();
  const [expandedProductId, setExpandedProductId] = useState(
    PRODUCT.PROJECT_MANAGEMENT
  );
  const [selectedProductsMap, setSelectedProductsMap] = useState({});

  useEffect(() => {
    if (!_.isEmpty(companyInfo)) {
      if (companyInfo) {
        // set default products if selectedProductsMap is empty
        if (_.isEmpty(selectedProductsMap)) {
          const updatedSelectedProductsMap = {};
          if (_.isEmpty(companyInfo.products)) {
            updatedSelectedProductsMap[PRODUCT.PROJECT_MANAGEMENT] = true;
          } else {
            _.forEach(companyInfo.products, productId => {
              updatedSelectedProductsMap[productId] = true;
            });
          }

          setSelectedProductsMap(updatedSelectedProductsMap);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);

  const handleProductClick = productId => {
    setSelectedProductsMap(currentMap => {
      const newMap = { ...currentMap, [productId]: !currentMap[productId] };

      // Deselect bookkeeping if project management is not selected
      if (!newMap[PRODUCT.PROJECT_MANAGEMENT]) {
        newMap[PRODUCT.BOOKKEEPING] = false;
      }

      return newMap;
    });
  };

  // Check if at least one product is selected
  const isAtLeastOneProductSelected = _.keys(selectedProductsMap).some(
    productId => !!selectedProductsMap[productId]
  );

  const onNextAction = async () => {
    setLoading(true);
    const selectedProducts = [];
    _.keys(selectedProductsMap).forEach(productId => {
      if (selectedProductsMap[productId]) {
        selectedProducts.push(productId);
      }
    });

    await onAddOrUpdateCompany({
      companyId: companyInfo?.companyId,
      products: selectedProducts,
    });

    setLoading(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <LevelLogo className={classes.smallLevelLogo} />
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Choose Your Products
      </Grid>
      <Grid item xs={12} className={classes.description}>
        Almost there! Choose which products you would like access to.
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="column"
        style={{ flexGrow: 3, justifyContent: 'flex-start' }}
      >
        {_.map(PRODUCT_DEFINITIONS, product => {
          return (
            <ProductItem
              key={product.id}
              productId={product.id}
              name={product.name}
              logoSrc={product.logoSrc}
              description={product.description}
              selected={!!selectedProductsMap[product.id]}
              expanded={expandedProductId === product.id}
              disabled={
                product.id === PRODUCT.BOOKKEEPING &&
                !selectedProductsMap[PRODUCT.PROJECT_MANAGEMENT]
              }
              disabledTooltip={product.disabledTooltip}
              onClick={handleProductClick}
              onExpandClick={() => {
                setExpandedProductId(currentProductId =>
                  currentProductId === product.id ? null : product.id
                );
              }}
            />
          );
        })}
      </Grid>
      <OnboardingStepButtons
        allowGoToNext={isAtLeastOneProductSelected}
        nextAction={onNextAction}
      />
    </>
  );
};

export default compose(AddOrUpdateCompanyAction)(OnboardingSelectProductsStep);
