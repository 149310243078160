import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { compose, withApollo } from 'react-apollo';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import {
  getUserInfoAndManagingCompanyInfo,
  runAnalytics,
  setAnalyticsUser,
} from '../../helpers';
import {
  INVALID_CREDENTIALS,
  PASSWORD_MISMATCH,
  PASSWORD_NOT_UPDATED,
  PASSWORD_REQUIREMENTS,
  INVALID_USERNAME,
  CODE_MISMATCH_EXCEPTION,
  UNEXPECTED_ERROR,
} from './auth-errors.json';

import SignInForm from './sign-in-form';
import ChangePasswordForm from './change-password-form';
import SmsMfaForm from './sms-mfa-form';

import styles from './auth.styles';

// Determine which form to show
const FORM_STATE = {
  SIGN_IN: 0,
  FORCE_PASSWORD_CHANGE: 1,
  SMS_MFA: 2,
};

const useStyles = makeStyles(styles);

const SignInView = props => {
  const classes = useStyles();
  const { client } = props;

  // Set state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validUsername, setValidUsername] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [authError, setAuthError] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [formState, setFormState] = useState(FORM_STATE.SIGN_IN);
  const [pendingUser, setPendingUser] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');

  const dispatch = useDispatch();

  const validUsernameChecker = usernameToCheck => {
    if (usernameToCheck === '') {
      setUsername('');
    }
    const usernameRegex = new RegExp(
      /^(?!.*__.*)(?!.*\.\..*)(?!.*--.*)[-\w.]{2,30}$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const usernameValid = usernameRegex.test(usernameToCheck);
    if (usernameValid) {
      setValidUsername(true);
    } else {
      setValidUsername(false);
    }
  };

  const handleUsernameChange = (
    passedUsername,
    { requireValidation = true } = {}
  ) => {
    setUsername(passedUsername);
    if (requireValidation) {
      validUsernameChecker(passedUsername);
    } else {
      setValidUsername(true);
    }
  };

  const validPasswordChecker = passwordToCheck => {
    const matchCognitoRequirementsRegex = new RegExp(
      '^(((?=.*[a-z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[0-9])))(?=.{6,})'
    );
    const passwordValid = matchCognitoRequirementsRegex.test(passwordToCheck);
    if (passwordValid) {
      setPassword(passwordToCheck);
      setValidPassword(true);
      return;
    }
    setValidPassword(false);
  };

  const handlePasswordChange = (
    passedPassword,
    { requireValidation = true } = {}
  ) => {
    setPassword(passedPassword);

    if (requireValidation) {
      validPasswordChecker(passedPassword);
    } else {
      setValidPassword(true);
    }
  };

  const handleVerificationCodeChange = passedVerificationCode => {
    setVerificationCode(passedVerificationCode);
  };

  // Perform app login steps after AWS auth state is set
  const onSignInSuccess = async () => {
    await client.resetStore();

    const currentSession = await Auth.currentSession();
    const userId = currentSession.getAccessToken().payload.sub;

    const {
      attributes: { email },
    } = await Auth.currentUserInfo();

    // Update analytics
    setAnalyticsUser({ userId, email });
    runAnalytics('Active User', { userId, email });
    runAnalytics('Log', { userId, username, type: 'Log In', email });

    const {
      userInfo,
      managingCompanyInfo,
    } = await getUserInfoAndManagingCompanyInfo({ client });

    // This dispatch will cause the UnauthRoute HOC to redirect
    dispatch({
      type: 'SIGNIN_SUCCEEDED',
      payload: {
        auth: { isLoggedIn: true },
        userInfo: { ...userInfo },
        managingCompanyInfo: { ...managingCompanyInfo },
      },
    });
  };

  // Sign in with AWS
  const handleSignIn = async () => {
    setAuthError({});
    if (!validUsername) {
      setAuthError({ username: INVALID_USERNAME });
      return;
    }

    setIsProcessing(true);

    try {
      // Sign in user
      const user = await Auth.signIn(username, password);

      // Check if password update is required
      const AWS_NEW_PASSWORD_CHALLENGE = 'NEW_PASSWORD_REQUIRED';
      if (user.challengeName === AWS_NEW_PASSWORD_CHALLENGE) {
        setPendingUser(user);
        setFormState(FORM_STATE.FORCE_PASSWORD_CHANGE);
        setIsProcessing(false);
        return;
      }

      const SMS_MFA_CHALLENGE = 'SMS_MFA';
      if (user.challengeName === SMS_MFA_CHALLENGE) {
        setPendingUser(user);
        setFormState(FORM_STATE.SMS_MFA);
        setIsProcessing(false);
        return;
      }

      onSignInSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('handleSignIn Auth.signIn err: ', err);
      setAuthError({ form: INVALID_CREDENTIALS });
      setIsProcessing(false);
    }
  };

  // Change password with AWS
  const handlePasswordSet = async () => {
    setAuthError({});
    setIsProcessing(true);

    // Check if password meets requirements
    if (!validPassword) {
      setAuthError({ password: PASSWORD_REQUIREMENTS });
      setIsProcessing(false);
      return;
    }

    // Check to see if confirm password matches the set password
    if (confirmPassword !== password) {
      setAuthError({ form: PASSWORD_MISMATCH });
      setIsProcessing(false);
      return;
    }

    try {
      // Update the password
      await Auth.completeNewPassword(pendingUser, password);
    } catch (err) {
      setAuthError({ form: PASSWORD_NOT_UPDATED });
      setIsProcessing(false);
    }

    onSignInSuccess();
  };

  const handleVerificationCodeSubmit = async () => {
    setAuthError({});
    setIsProcessing(true);

    try {
      await Auth.confirmSignIn(pendingUser, verificationCode, 'SMS_MFA');
    } catch (err) {
      if (err.code === 'CodeMismatchException') {
        setVerificationCode('');
        setAuthError({ form: CODE_MISMATCH_EXCEPTION });
        setIsProcessing(false);
        return;
      }

      setVerificationCode('');
      setAuthError({ form: UNEXPECTED_ERROR });
      setIsProcessing(false);
      return;
    }

    onSignInSuccess();
  };

  const reSignInToIssueNewCode = async () => {
    setAuthError({});

    try {
      const user = await Auth.signIn(username, password);
      setPendingUser(user);
    } catch (err) {
      setAuthError({ form: INVALID_CREDENTIALS });
    }
  };

  return (
    <Grid container item xs={12} className={classes.formContainer}>
      {formState === FORM_STATE.SIGN_IN && (
        <SignInForm
          isProcessing={isProcessing}
          authError={authError}
          username={username}
          password={password}
          onUsernameChange={handleUsernameChange}
          onPasswordChange={handlePasswordChange}
          onSignIn={handleSignIn}
        />
      )}
      {formState === FORM_STATE.FORCE_PASSWORD_CHANGE && (
        <ChangePasswordForm
          password={password}
          confirmPassword={confirmPassword}
          isProcessing={isProcessing}
          authError={authError}
          onPasswordChange={handlePasswordChange}
          onConfirmPasswordChange={setConfirmPassword}
          onPasswordSet={handlePasswordSet}
        />
      )}
      {formState === FORM_STATE.SMS_MFA && (
        <SmsMfaForm
          pendingUser={pendingUser}
          verificationCode={verificationCode}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          authError={authError}
          setAuthError={setAuthError}
          onVerificationCodeChange={handleVerificationCodeChange}
          onVerificationCodeSubmit={handleVerificationCodeSubmit}
          reSignIn={reSignInToIssueNewCode}
        />
      )}
    </Grid>
  );
};

export default compose(withApollo)(SignInView);
