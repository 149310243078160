import React from 'react';
import { compose } from 'react-apollo';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import FadeIn from 'react-fade-in/lib/FadeIn';

import { GetCompanyInfoAction } from '../../../../graphql/graphql';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  companyLogo: {
    height: 20,
    margin: '0 auto',
  },
  name: {
    marginTop: theme.spacing(1),
    overflowWrap: 'anywhere',
    pointerEvents: 'none',
  },
}));

const CompanyDisplay = props => {
  const { getCompanyInfo: companies } = props;
  const classes = useStyles();

  let companyInfo;
  if (companies?.length) {
    [companyInfo] = companies;
  }

  return (
    <div className={clsx(classes.root)}>
      {companyInfo && (
        <FadeIn>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ paddingBottom: 8, paddingHorizontal: 8 }}
          >
            {companyInfo.companyLogo && (
              <img
                alt="Company Logo"
                className={classes.companyLogo}
                src={companyInfo.companyLogo}
              />
            )}
            <Typography
              className={classes.name}
              variant="caption"
              align="center"
            >
              {companyInfo.companyName}
            </Typography>
          </Grid>
        </FadeIn>
      )}
    </div>
  );
};

export default compose(GetCompanyInfoAction)(CompanyDisplay);
