import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import _ from 'lodash';

import LoadingCover from '../LoadingCover/loadingCover';

const OkCancelDialog = props => {
  const {
    onClose,
    onConfirm,
    open,
    title,
    customTitle,
    cancelButtonText,
    cancelButtonVariant,
    okButtonText,
    okButtonVariant,
    children,
    maxWidth,
    disableBackdropClick,
    disableEscapeKeyDown,
    disableOkButton,
    hideCancel,
    autoFocusButton = 'okButton',
    dividers,
    loaderMessage,
    loaderStyle,
    ...other
  } = props;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowLoader(false);
    }
  }, [open]);

  const handleCancel = () => {
    onClose('cancel');
  };

  const handleOk = async () => {
    if (onConfirm) {
      setShowLoader(true);
      await onConfirm();
    }
    if (onClose) {
      onClose('ok');
    }
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && !disableBackdropClick) {
          onClose(event, reason);
        }
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth || 'sm'}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      {!!customTitle && customTitle}
      {!customTitle && !!title && (
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      )}

      <DialogContent dividers={_.isBoolean(dividers) ? dividers : true}>
        {children}
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button
            autoFocus={autoFocusButton === 'cancelButton'}
            variant={cancelButtonVariant || 'text'}
            onClick={handleCancel}
            color="primary"
          >
            {cancelButtonText || 'Cancel'}
          </Button>
        )}
        <Button
          autoFocus={autoFocusButton === 'okButton'}
          variant={okButtonVariant || 'contained'}
          onClick={handleOk}
          color="primary"
          disabled={disableOkButton}
        >
          {okButtonText || 'Ok'}
        </Button>
      </DialogActions>
      {showLoader && (
        <LoadingCover loader="linear" customStyles={loaderStyle}>
          {loaderMessage}
        </LoadingCover>
      )}
    </Dialog>
  );
};

export default OkCancelDialog;
