import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Company}
  mutation UpdateCompanyCustomerSyncStatus(
    $customerSyncEnabled: Boolean!
    $companyId: String!
  ) {
    updateCompanyCustomerSyncStatus(
      input: {
        companyId: $companyId
        customerSyncEnabled: $customerSyncEnabled
      }
    ) {
      ...CompanyFields
    }
  }
`;
