import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';

import { MAX_CHARACTERS_IN_EMAIL } from '../../config/appDefaults';

import AuthTextField from './auth-text-field/auth-text-field';
import BigButton from './big-button';

import palette from '../../theme/palette';
import styles from './auth.styles';

const useStyles = makeStyles(styles);

const InitiateSignUpForm = ({
  onEmailChange,
  email,
  authError,
  loading,
  onRecaptchaChange,
  onInitiateSignUp,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="body1"
        style={{ color: palette.brandColorDarkestGrey }}
      >
        To start your journey with Level, please enter your email.
      </Typography>
      <Grid container item xs={12} className={classes.formBody}>
        <Grid container item xs={12}>
          <AuthTextField
            name="email"
            label="Email"
            value={email}
            required
            maxLength={MAX_CHARACTERS_IN_EMAIL}
            errorText={authError?.email}
            onChange={onEmailChange}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={onRecaptchaChange}
          />
        </Grid>

        {!!authError?.form && (
          <Grid item xs={12} className={classes.formErrorContainer}>
            <Typography style={{ color: 'red' }}>{authError.form}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.formActions}
      >
        <BigButton
          buttonText="Get started"
          fullWidth
          isProcessing={loading}
          onClick={onInitiateSignUp}
          style={{ minWidth: isMobile ? 180 : 220 }}
        />
      </Grid>
    </>
  );
};

export default InitiateSignUpForm;
