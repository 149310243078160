import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  MenuItem,
  TextField,
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import { monetaryRender } from '../../helpers';

const useStyles = makeStyles(() => ({
  searchWrapper: {
    position: 'relative',
  },
  textBoxWrapper: {
    flex: 1,
    backgroundColor: 'transparent',
    minWidth: 100,
  },
  clearButton: {
    position: 'absolute',
    right: 0,
    color: '#a0a0a0',
  },
}));

const FilterSearch = forwardRef((props, ref) => {
  const {
    data,
    passBack,
    isFiltered,
    searchBy,
    isUserSearch,
    initialSelectValue,
    initialTextToSearch,
    fromAdminView,
    selectOptions,
    placeholder,
  } = props;

  const [dataToSearch, setDataToSearch] = useState(data);
  const [textToSearch, setTextToSearch] = useState(initialTextToSearch || '');
  const [selectValue, setSelectValue] = useState(initialSelectValue);

  const doTheFiltering = ({ searchTermUse = '', dropdownFilter }) => {
    const textFind = (searchThis, forThis) => {
      return (
        searchThis &&
        forThis &&
        searchThis.toLowerCase().indexOf(forThis.toLowerCase()) >= 0
      );
    };
    return _.filter(data, item => {
      if (searchBy) {
        // search each field
        const searchByList = [];
        if (typeof searchBy === 'string') {
          searchByList.push(searchBy);
        } else {
          searchByList.push(...searchBy);
        }
        let foundSearch = false;

        let allFieldsInOneString = '';
        searchByList.forEach(fieldname => {
          if (fieldname === 'amount') {
            allFieldsInOneString += ` ${monetaryRender({
              value: item?.amount?.value || 0,
              withDecimals: true,
            })}`;
          } else {
            allFieldsInOneString += ` ${_.get(item, fieldname)}`;
          }
        });
        foundSearch = textFind(allFieldsInOneString, searchTermUse);

        // is there even anything in the search?
        const isNoSearchTerm = !searchTermUse;
        const matchesDropdown =
          !dropdownFilter || dropdownFilter === 'all' // if the drop down is  not available or it's set to all, just return true otherwise apply it
            ? true
            : item.questionType === dropdownFilter;
        return (isNoSearchTerm || foundSearch) && matchesDropdown;
      }
      return (
        textFind(item.title, searchTermUse) ||
        textFind(item.description, searchTermUse)
      );
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!dataToSearch) {
        return;
      }

      let results;

      if (textToSearch || (selectValue && selectValue !== initialSelectValue)) {
        results = doTheFiltering({
          searchTermUse: textToSearch,
          dropdownFilter: selectValue,
        });

        if (isFiltered) {
          isFiltered(true);
        }
      } else {
        results = dataToSearch;
        if (isFiltered) {
          isFiltered(false);
        }
      }

      passBack(results);
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textToSearch, selectValue, dataToSearch]);

  useEffect(() => {
    if (!_.isEqual(data, dataToSearch)) {
      setDataToSearch(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const classes = useStyles();

  const handleSearchTermChange = event => {
    let searchThis = event.target.value;
    if (isUserSearch) {
      searchThis = event.target.value.trim();
    }
    setTextToSearch(searchThis);
  };

  const handleTextClear = () => {
    setTextToSearch('');
    handleSearchTermChange({ target: { value: '' } });
    if (
      (!selectValue || (selectValue && selectValue !== initialSelectValue)) &&
      isFiltered
    ) {
      isFiltered(false);
    }
  };

  useImperativeHandle(ref, () => ({
    clearSearch() {
      handleTextClear();
    },
  }));

  const handleQuestionTypeChange = event => {
    const type = event.target.value;
    setSelectValue(type);
  };

  return (
    <Grid container className={classes.searchWrapper} alignItems="flex-end">
      {fromAdminView && (
        <Grid item style={{ minWidth: 150, flex: 0, marginRight: 16 }}>
          <FormControl
            className={classes.formControl}
            style={{ width: '100%' }}
          >
            <InputLabel shrink id="type">
              Type
            </InputLabel>
            <Select
              id="type"
              value={selectValue}
              onChange={handleQuestionTypeChange}
              displayEmpty
              className={classes.selectEmpty}
              style={{ width: '100%' }}
            >
              {_.map(selectOptions, type => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item className={classes.textBoxWrapper}>
        <InputLabel shrink id="search-label">
          Search
        </InputLabel>
        <TextField
          onChange={handleSearchTermChange}
          fullWidth
          value={textToSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={placeholder}
        />
      </Grid>
      {textToSearch && (
        <IconButton
          onClick={handleTextClear}
          className={classes.clearButton}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Grid>
  );
});

export default FilterSearch;
