import React, { useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { EXPIRED_SUBSCRIPTION_STATUSES } from '../../config/appDefaults';
import GetUserInfo from '../../graphql/queries/GetUserInfo';
import GetCompanyInfo from '../../graphql/queries/get-company-info';
import { determineManagingCompanyInfo } from '../../helpers';

const AuthRoute = ({
  component: Component,
  layout: Layout = null,
  currentAuth,
  dispatch,
  location,
  redirectToNoAccess,
  managingCompanyInfo,
  ...rest
}) => {
  const [wasLoggedIn, setWasLoggedIn] = useState(currentAuth.isLoggedIn);
  const [unauthRedirectTo, setUnauthRedirectTo] = useState(null);

  // use useRef to check if managingCompanyInfo has been checked before
  const hasManagingCompanyInfoCheckedRef = useRef(false);
  const isCheckingManagingCompanyInfo = useRef(false);

  const { refetch: getCompanyInfo } = useQuery(GetCompanyInfo, {
    skip: true, // skip the query until we need it
    fetchPolicy: 'network-only',
  });

  const { refetch: getUserInfo } = useQuery(GetUserInfo, {
    skip: true, // skip the query until we need it
    fetchPolicy: 'network-only',
    variables: {
      userId: 'willBePulledFromCognitoSubContentInResolver',
    },
  });

  useEffect(() => {
    const recheckManagingCompanyInfo = async () => {
      isCheckingManagingCompanyInfo.current = true;
      const getUserInfoResult = await getUserInfo();
      const userInfo = _.get(getUserInfoResult, 'data.getMyUserInfo', null);

      const getCompanyInfoResult = await getCompanyInfo();
      const companies = _.get(
        getCompanyInfoResult,
        'data.getCompanyInfo.items',
        null
      );

      if (companies && !_.isEmpty(userInfo)) {
        const freshManagingCompanyInfo = determineManagingCompanyInfo({
          companies,
          userInfo,
        });

        if (!_.isEqual(freshManagingCompanyInfo, managingCompanyInfo)) {
          dispatch({
            type: 'SET_MANAGING_COMPANY_INFO',
            payload: { ...freshManagingCompanyInfo },
          });
        }
      }

      hasManagingCompanyInfoCheckedRef.current = true;
      isCheckingManagingCompanyInfo.current = false;
    };

    // NOTE: For unknown reasons, the managingCompanyInfo is sometimes empty after returning
    // from a successful connection to QBO. This is a workaround to recheck the managingCompanyInfo
    // if it's empty to prevent the user from being redirected to the no-company-access page.
    if (
      _.isEmpty(managingCompanyInfo) &&
      currentAuth.isLoggedIn && // only recheck if the user is logged in
      !hasManagingCompanyInfoCheckedRef.current && // we haven't run this re-check yet
      !isCheckingManagingCompanyInfo.current // no other re-check is in progress
    ) {
      recheckManagingCompanyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managingCompanyInfo, currentAuth?.isLoggedIn]);

  useEffect(() => {
    // If trying to access an authenticated endpoint while not authenticated...
    if (!currentAuth.isLoggedIn) {
      const linkRedirect = location.pathname + location.search;
      // ... and they didn't just log out, set the redirect for the resource they were trying to access
      if (!wasLoggedIn) {
        dispatch({
          type: 'LINK_REDIRECT',
          payload: linkRedirect,
        });
      } else if (wasLoggedIn) {
        // If they just logged out, update the state
        setWasLoggedIn(currentAuth.isLoggedIn);
      }

      let redirectTo = '/auth';
      if (currentAuth.redirectToOnSignOut) {
        redirectTo = currentAuth.redirectToOnSignOut;
      }

      setUnauthRedirectTo(redirectTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAuth]);

  // If the user is not authenticated, redirect them to the login page
  if (unauthRedirectTo) {
    return <Redirect to={unauthRedirectTo} replace />;
  }

  // If the user is authenticated, but doesn't belong to a company,
  // redirect them to the no company access page
  if (
    managingCompanyInfo && // if managingCompanyInfo exists
    !managingCompanyInfo.managingCompanyId && // but it does not have a managingCompanyId property
    location.pathname !== '/no-company-access' && // and we're not already on the path
    hasManagingCompanyInfoCheckedRef.current // and we've already checked for the company info
  ) {
    const linkRedirect = location.pathname + location.search;
    return (
      <Redirect
        to={{
          pathname: '/no-company-access',
          state: { linkRedirect },
        }}
      />
    );
    // return <Redirect to="/no-company-access" />;
  }

  // If the user is authenticated, but the company is locked, redirect them to the no access page
  const isLockedReasonBased =
    managingCompanyInfo && managingCompanyInfo.isLockedReason;
  const subscriptionEnded = EXPIRED_SUBSCRIPTION_STATUSES.includes(
    managingCompanyInfo?.subscriptionStatus
  );
  const notOnLockedScreenAlready = location.pathname !== '/company-locked';
  if ((isLockedReasonBased || subscriptionEnded) && notOnLockedScreenAlready) {
    return <Redirect to="/company-locked" />;
  }

  // If the user is authenticated, but doesn't have access to the restricted resource,
  // redirect them to the no access (no authorization) page
  if (managingCompanyInfo && redirectToNoAccess) {
    return <Redirect to="/no-access" />;
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        Layout ? (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        ) : (
          <Component {...routeProps} />
        )
      }
    />
  );
};
const mapStateToProps = state => {
  return {
    currentAuth: state.currentAuth,
    managingCompanyInfo: state.appState.managingCompanyInfo,
  };
};

export default connect(mapStateToProps)(AuthRoute);
