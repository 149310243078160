const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SIGNIN_SUCCEEDED': {
      return { ...action.payload?.userInfo };
    }
    case 'USER_INFO_UPDATE': {
      const updatedInfo = { ...state, ...action.payload };
      return updatedInfo;
    }
    case 'SIGNOUT_SUCCEEDED': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
