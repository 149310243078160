import gql from 'graphql-tag';

export default gql`
  query GetCompanyShoeboxItems($companyId: String!, $mode: ShoeboxItemsModes) {
    getCompanyShoeboxItems(input: { companyId: $companyId, mode: $mode }) {
      items {
        companyId
        contentId
        dateCreated
        status
        subject
        notes
        amount {
          value
        }
        media {
          aspectRatio
          type
          uri
          ocrData {
            status
            error
            uri
            dataJson
          }
        }
      }
      nextToken
      totalCount
    }
  }
`;
