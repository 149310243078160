import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { subDays } from 'date-fns';
import _ from 'lodash';

import palette from '../../theme/palette';
import { IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER } from '../bookkeeping-alerts/bookkeeping-alerts.constants';
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip';
import CustomScanSettingsDatePicker from './custom-scan-settings-date-picker';
import CustomScanSettingsRuleMultiSelect from './custom-scan-settings-rule-multi-select';

export const LAYOUT = {
  TOOLBAR: 'toolbar',
  BOX: 'box',
};

const CustomScanSettings = ({
  loading,
  disabled,
  initialSettings,
  defaultBookkeepingRules,
  onScanClick,
  containerStyle,
  layout = LAYOUT.BOX,
  error,
  setError,
}) => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [allSupportedRules, setAllSupportedRules] = useState([]);
  const isInitialStartDateApplied = useRef(false);
  const isInitialEndDateApplied = useRef(false);

  useEffect(() => {
    if (!isInitialStartDateApplied.current && initialSettings?.startDate) {
      setStartDate(initialSettings.startDate);
      isInitialStartDateApplied.current = true;
    }

    if (!isInitialEndDateApplied.current && initialSettings?.endDate) {
      setEndDate(initialSettings.endDate);
      isInitialEndDateApplied.current = true;
    }
  }, [initialSettings]);

  const noRulesSelected = useMemo(() => {
    const selectedRules = _.filter(allSupportedRules, rule => {
      return (
        rule.enabled &&
        rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER
      );
    });

    return _.isEmpty(selectedRules);
  }, [allSupportedRules]);

  const clearInvalidDateRangeError = () => {
    setError(crrentState => {
      return {
        ...crrentState,
        startDate: false,
        endDate: false,
        form: '',
      };
    });
  };

  const handleStartDateChange = date => {
    clearInvalidDateRangeError();
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    clearInvalidDateRangeError();
    setEndDate(date);
  };

  const handleOnScanClick = () => {
    onScanClick({
      startDate,
      endDate,
      selectedRules: _.filter(allSupportedRules, rule => rule.enabled),
    });
  };

  const tooltipText = noRulesSelected ? 'Please select at least one rule' : '';

  if (layout === LAYOUT.TOOLBAR) {
    return (
      <Grid
        container
        item
        direction="column"
        style={{ padding: error?.form ? 0 : 4, ...containerStyle }}
      >
        <Grid
          container
          item
          alignItems="center"
          wrap="nowrap"
          style={containerStyle}
        >
          <Grid item style={{ width: 140, marginRight: 8 }}>
            <CustomScanSettingsDatePicker
              label="Start Date"
              value={startDate}
              maxDate={new Date()}
              disabled={disabled}
              loading={loading}
              error={!!error?.startDate}
              onChange={handleStartDateChange}
            />
          </Grid>
          <Grid item style={{ width: 140, marginRight: 8 }}>
            <CustomScanSettingsDatePicker
              label="End Date"
              value={endDate}
              maxDate={new Date()}
              disabled={disabled}
              loading={loading}
              error={!!error?.endDate}
              onChange={handleEndDateChange}
            />
          </Grid>
          <Grid item style={{ width: 180, marginRight: 8 }}>
            <CustomScanSettingsRuleMultiSelect
              label="Rules"
              initialSelectedRules={initialSettings?.selectedRules}
              defaultBookkeepingRules={defaultBookkeepingRules}
              allSupportedRules={allSupportedRules}
              setAllSupportedRules={setAllSupportedRules}
              disabled={disabled}
              loading={loading}
              error={noRulesSelected}
            />
          </Grid>
          <Grid item>
            <ButtonWithTooltip
              variant="contained"
              color="primary"
              tooltipText={tooltipText}
              style={{ borderRadius: 32 }}
              disabled={noRulesSelected || disabled || loading}
              onClick={handleOnScanClick}
            >
              Scan
            </ButtonWithTooltip>
          </Grid>
        </Grid>
        {!!error?.form && (
          <Grid
            item
            xs={12}
            style={{
              fontSize: 11,
              color: palette.brandColorError,
              marginTop: 4,
            }}
          >
            {error?.form}
          </Grid>
        )}
      </Grid>
    );
  }

  // render box layout
  return (
    <Grid
      container
      item
      style={{
        width: 480,
        minHeight: 350,
        padding: '28px 32px 32px 32px',
        backgroundColor: '#fff',
        ...containerStyle,
      }}
    >
      <Typography variant="h3">Custom Scan Tool</Typography>
      <Typography
        style={{
          marginTop: 8,
          marginBottom: 24,
          color: palette.brandColorDarkGrey,
          fontSize: 14,
        }}
      >
        Scan a specific reporting period for transactions that may need to be
        looked at or actioned, based on the rules you choose.
      </Typography>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <CustomScanSettingsDatePicker
            label="Start Date"
            value={startDate}
            maxDate={new Date()}
            disabled={disabled}
            loading={loading}
            error={!!error?.startDate}
            onChange={handleStartDateChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomScanSettingsDatePicker
            label="End Date"
            value={endDate}
            maxDate={new Date()}
            disabled={disabled}
            loading={loading}
            error={!!error?.endDate}
            onChange={handleEndDateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomScanSettingsRuleMultiSelect
            label="Rules"
            initialSelectedRules={initialSettings?.selectedRules}
            defaultBookkeepingRules={defaultBookkeepingRules}
            allSupportedRules={allSupportedRules}
            setAllSupportedRules={setAllSupportedRules}
            showChangeAdornment
            disabled={disabled}
            loading={loading}
            error={noRulesSelected}
          />
        </Grid>
        {!!error?.form && (
          <Grid item xs={12}>
            <Typography style={{ color: palette.brandColorError }}>
              {error?.form}
            </Typography>
          </Grid>
        )}
        <Grid container item justifyContent="center">
          <ButtonWithTooltip
            variant="contained"
            color="primary"
            tooltipText={tooltipText}
            style={{ borderRadius: 32, marginTop: 8 }}
            disabled={noRulesSelected || disabled || loading}
            onClick={handleOnScanClick}
          >
            Start Scan
          </ButtonWithTooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomScanSettings;
