/* eslint-disable import/no-cycle */
import { graphql } from 'react-apollo';

import _ from 'lodash';

import { appsyncClient } from '../index';
import store from '../store';

import Api from '../api/api';
import { nonProdConsoleLog, removeAttribute } from '../helpers';

import AddJrnMutation from './mutations/mutation_createJrn';
import GetJrn from './queries/GetJrn';
import GetBudgetInfo from './queries/get-budget-info';
import ListMyProjectsAndSubprojects from './queries/ListMyProjectsAndSubprojects';
import ListMyJrns from './queries/ListMyJrns';
import ListMyConversations from './queries/ListMyConversations';
import PinChatToConvosActions from './mutations/mutation_pinChatToConvosActions';
import UpdateJrnMutation from './mutations/mutation_updateJrn';
import DeleteJrn from './mutations/mutation_deleteJrn';
import LeaveJrnMutation from './mutations/mutation_leaveJrn';
import AddOrUpdateCompanyQuestions from './mutations/mutation_add-or-update-company-questions';

import CreateContentMutation from './mutations/mutation_createContent';
import GetAllContentByJrnId from './queries/GetAllContentByJrnId';
import GetAdminContentByType from './queries/GetAdminContentByType';
import GetContentById from './queries/GetContentById';
import UpdateContentMutation from './mutations/mutation_updateContent';
import UpdateContentStatusMutation from './mutations/mutation_updateContentStatus';
import DeleteContent from './mutations/mutation_deleteContent';
import MoveOrCopyContent from './mutations/mutation_moveOrCopyContent';

import AddSlideshowToJrn from './mutations/mutation_addSlideshowToJrn';
import UpdateSlideshowOnJrn from './mutations/mutation_updateSlideshowOnJrn';
import GetSlideshowInfo from './queries/GetSlideshowInfo';

import GetNotificationPrefs from './queries/GetNotificationPrefs';
import UpdateNotificationPrefs from './mutations/mutation_updateNotificationPrefs';

import GetUserInfo from './queries/GetUserInfo';
import UpdateUserMutation from './mutations/mutation_updateUser';
import UpdateUserAdminMutation from './mutations/mutation_update-user-admin';
import UpdateUnreadCountMutation from './mutations/mutation_updateUnreadCount';

import GetMessagesByJrnId from './queries/GetMessagesByJrnId';
import CreateMessageMutation from './mutations/mutation_createMessage';
import SubscribeToNewMessages from './subscriptions/subscribe_createMessage';

import CreateCommentMutation from './mutations/mutation_createComment';
import CreateInternalCommentMutation from './mutations/mutation_createInternalComment';
import CreateLoveMutation from './mutations/mutation_createLove';
// import SubscribeToNewComment from './subscriptions/subscribe_createComment';

import GenerateNewCustomerOrderMutation from './mutations/mutation_generateNewCustomerOrder';

import GetUsersByJrnId from './queries/GetUsersByJrnId';
import UpdateNotificationDetails from './mutations/mutation_updateNotificationDetails';

import SaveProjectAsTemplate from './mutations/mutation_saveProjectAsTemplate';
import CreateProjectFromTemplate from './mutations/mutation_createProjectFromTemplate';
import GetStripeProductInfo from './queries/GetStripeProductInfo';
import AddOrUpdateCompany from './mutations/mutation_add-or-update-company';
import GetCompanyInfo from './queries/get-company-info';
import GetCompanyCrew from './queries/get-company-crew';
import GetCompanyCrewAdmin from './queries/get-company-crew-admin';
import GetCompanyShoeboxItems from './queries/get-company-shoebox-items';
import SendCrewMemberInvitation from './mutations/mutation_send-crew-member-invitation';
import GetCompanyCrewMemberInvitations from './queries/get-company-crew-member-invitations';
import DeleteCrewMemberInvitation from './mutations/mutation_delete-crew-member-invitation';
import RemoveCompanyCrewMember from './mutations/mutation_remove-company-crew-member';
import AddCompanyCrewMember from './mutations/mutation_add-company-crew-member';
import MakeCompanyAdmin from './mutations/mutation_make-company-admin';
import TransferCompanyAdmin from './mutations/mutation_transfer-company-admin';
import ListCompanyProjects from './queries/list-company-projects';
import ListCompanyArchivedProjects from './queries/list-company-archived-projects';
import ArchiveCompanyProject from './mutations/mutation_archiveCompanyProject';
import UnarchiveCompanyProject from './mutations/mutation_unarchiveCompanyProject';
import ListCompanyTemplates from './queries/list-company-templates';
import GetCompanyAdminContent from './queries/get-company-admin-content';
import GetCompanyAdminProjectData from './queries/get-company-admin-project-data';
import ArchiveOrRestoreCompanyQuestion from './mutations/mutation_archive-or-restore-company-question';
import GetCompanyQuestions from './queries/get-company-questions';
import GetCompanyCustomers from './queries/get-company-customers';
import GetCompanyLeads from './queries/get-company-leads';
import AddCustomer from './mutations/mutation_add-customer';
import UpdateCustomer from './mutations/mutation_update-customer';
import GetCustomerLeads from './queries/get-customer-leads';
import GetCustomerProjects from './queries/get-customer-projects';
import ListCompanyGlobalFinancialItems from './queries/list-company-global-financial-items';
import AddCompanyShoeboxItems from './mutations/mutation_add-company-shoebox-items';
import UpdateCompanyShoeboxItem from './mutations/mutation_update-company-shoebox-item';
import RemoveCompanyShoeboxItem from './mutations/mutation_remove-company-shoebox-item';
import GetCompanyRfi from './queries/get-company-rfi';
import GetCompanyRfis from './queries/get-company-rfis';
import AddCompanyRfis from './mutations/mutation_add-company-rfis';
import UpdateCompanyRfis from './mutations/mutation_update-company-rfis';
import RemoveCompanyRfi from './mutations/mutation_remove-company-rfi';

import DisconnectFromQuickBooks from './mutations/mutation_disconnect-from-quickbooks';
import GetCompanyTargets from './queries/get-company-targets';
import UpdateCompanyTargets from './mutations/mutation_update-company-targets';
import GetCompanyJobCostAccounts from './queries/get-job-cost-accounts';
import GetCompanyAccounts from './queries/get-company-accounts';
import UpdateJobCostAccounts from './mutations/mutation_update-job-cost-accounts';
import ListScoreboardSettings from './queries/list-scoreboard-settings';
import UpdateScoreboardSettings from './mutations/mutation_update-scoreboard-settings';
import UpdateCompanyAccount from './mutations/mutation_update-company-account';
import UpdateCompanyCustomerSyncStatus from './mutations/mutation_update-customer-sync-status';
import SyncCustomerToNew from './mutations/mutation_sync-customer-to-new';
import SyncJrnToNew from './mutations/mutation_sync-jrn-to-new';
import DisconnectSyncedCustomer from './mutations/mutation_disconnect-synced-customer';
import DisconnectSyncedJrn from './mutations/mutation_disconnect-synced-jrn';
import GetCustomerById from './queries/get-customer-by-id';
import SyncJrnToExisting from './mutations/mutation_sync-jrn-to-existing';
import SyncCustomerToExisting from './mutations/mutation_sync-customer-to-existing';
import ImportJrnFromQbo from './mutations/mutation_import-jrn-from-qbo';
import CheckForQboUpdatesToCustomer from './mutations/mutation_check-for-qbo-updates-to-customer';
import CheckForQboUpdatesToJrn from './mutations/mutation_check-for-qbo-updates-to-jrn';
import MoveContentToOtherProject from './mutations/mutation_move-content-to-other-project';
import MarkAllExpenseAccountsApproved from './mutations/mutation_mark-all-expense-accounts-approved';

// BOOKKEEPING ALERTS
import GetBookkeepingAlertsPreferences from './queries/bookkeeping-alert-preferences-get';
import CreateBookkeepingAlertPreferences from './mutations/bookkeeping-alert-preferences-create';
import UpdateBookkeepingAlertPreferences from './mutations/bookkeeping-alert-preferences-update';
import GetBookkeepingAlerts from './queries/get-bookkeeping-alerts';
import CreateBookkeepingAlert from './mutations/create-bookkeeping-alert';
import UpdateBookkeepingAlert from './mutations/update-bookkeeping-alert';
import GetBookkeepingRules from './queries/get-bookkeeping-rules';
import UpsertBookkeepingRule from './mutations/upsert-bookkeeping-rule';
import ListBookkeepingSnapshotEventMeta from './queries/list-bookkeeping-snapshot-event-meta';
import UpsertBookkeepingEventMeta from './mutations/upsert-bookkeeping-event-meta';

import AddCardAccessForUser from './mutations/mutation_add-card-access-for-user';
import RemoveCardAccess from './mutations/mutation_remove-card-access';
import ListMazumagoCardsForUser from './queries/list-mazumago-cards-for-user';
import GetInternalComments from './queries/get-internal-comments';
import GetRelationsByType from './queries/get-relations-by-type';
import AddRelation from './mutations/mutation_add-relation';
import UpdateRelation from './mutations/mutation_update-relation';
import GetRelationById from './queries/get-relation-by-id';
import GetAllContentByGlobalExpenseId from './queries/get-all-content-by-global-expense-id';
import UpdateBookkeepingReport from './mutations/update-bookkeeping-report';

import addItemToQuery from './utils/add-item-to-query';
import GetAdminTimetrackingData from './queries/get-admin-timetracking-data';

import {
  ACCOUNTING_ACCOUNT_TYPE,
  TOP_PROJECT_ID,
  RFI_MODES,
  RFI_STATUSES,
  // RFI_MODES_TO_QUERY,
  RFI_QUERY_TO_MODE,
  CONTENT_TYPE,
  GLOBAL_FINANCIAL_TYPES,
  ADMIN_CONTENT_QUERIES,
  LEVEL_ROLE,
} from '../config/appDefaults';
import ListContentByType from './queries/list-content-by-type';

const USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER =
  'willBePulledFromCognitoSubContentInResolver';

const figureOutRfiMode = ({ requestStatus }) => {
  let toReturn;
  if (
    [
      RFI_STATUSES.IN_DRAFT.value,
      RFI_STATUSES.IN_PENDING_APPROVAL.value,
      RFI_STATUSES.IN_REJECTED.value,
      RFI_STATUSES.IN_APPROVED.value,
    ].includes(requestStatus)
  ) {
    toReturn = RFI_MODES.INTERNAL.value;
  } else {
    toReturn = RFI_QUERY_TO_MODE[requestStatus];
  }
  return toReturn;
};

const getCompanyId = () => {
  return _.get(
    store.getState().appState,
    'managingCompanyInfo.managingCompanyId',
    null
  );
};

const getCurrentUserId = () => {
  return _.get(store.getState().userInfo, 'userId', null);
};

const isCompanyOwner = () => {
  return _.get(
    store.getState().appState,
    'managingCompanyInfo.isCompanyOwner',
    false
  );
};

const isCompanyAdmin = () => {
  return _.get(
    store.getState().appState,
    'managingCompanyInfo.isCompanyAdmin',
    false
  );
};

// upload helpers/////////////////
const addTypenameWhereNeeded = (
  item,
  mutationName,
  mutationType = 'Content'
) => {
  const toReturn = {
    __typename: 'Mutation',
    [mutationName]: {
      ...item,
      __typename: mutationType,
      synced: false,
    },
  };
  if (item.completionInfo) {
    toReturn[mutationName].completionInfo = {
      __typename: 'CompletionInfo',
      ...item.completionInfo,
    };
    if (item.completionInfo.mediaInfo) {
      const mediaInfo = item.completionInfo.mediaInfo.map(info => {
        return {
          __typename: 'MediaInfo',
          ...info,
        };
      });
      toReturn[mutationName].completionInfo.mediaInfo = mediaInfo;
    }
  }
  if (item.amount) {
    toReturn[mutationName].amount = {
      __typename: 'Money',
      ...item.amount,
    };
  }
  if (item.recurrence) {
    toReturn[mutationName].recurrence = {
      __typename: 'RecurringTimeRange',
      ...item.recurrence,
    };
  }
  if (item.timetrackingAnswers) {
    toReturn[mutationName].timetrackingAnswers = item.timetrackingAnswers.map(
      answer => {
        return {
          __typename: 'TimetrackingAnswerInput',
          ...answer,
        };
      }
    );
  }
  if (item.loves) {
    toReturn[mutationName].loves = {
      __typename: 'LoveConnection',
      ...item.loves,
    };
  }
  if (item.comments) {
    toReturn[mutationName].comments = {
      __typename: 'CommentConnection',
      ...item.comments,
    };
  }
  if (item.media) {
    toReturn[mutationName].media = item.media.map(mediaInfo => {
      const typenamedMediaInfo = { ...mediaInfo };
      if (typenamedMediaInfo.ocrData) {
        typenamedMediaInfo.ocrData = {
          __typename: 'OcrData',
          ...mediaInfo.ocrData,
        };
      }
      return {
        __typename: 'MediaInfo',
        ...typenamedMediaInfo,
      };
    });
  }
  return toReturn;
};

const addCommentToContentList = ({
  query,
  queryDataKey,
  variables,
  userAddedItem,
  proxy,
  keyName = 'contentId',
  addTo = 'bottom',
}) => {
  try {
    const data = _.cloneDeep(proxy.readQuery({ query, variables }));

    // find the right piece of content
    const targetContent = _.find(data[queryDataKey].items, {
      [keyName]: userAddedItem.contentId,
    });
    if (!targetContent) return;

    // Find the comment array to update
    const targetContentComments = targetContent.comments.items;

    // check if this comment is already there or not
    const thisExistingComment = _.find(targetContentComments, {
      commentId: userAddedItem.commentId,
    });

    // if it's already there AND the synced isn't
    //  true then do nothing (shouldnt happen, but, you know)
    if (thisExistingComment && !userAddedItem.synced) {
      return;
    }
    if (thisExistingComment && userAddedItem.synced) {
      // if it's already there AND the incoming one has sync set to true
      //  then merge the existing one with the new one
      _.merge(thisExistingComment, userAddedItem);
      // console.log('new data: ', data);
    } else if (!thisExistingComment) {
      // if it's not already there add it
      if (addTo === 'top') {
        targetContentComments.unshift(userAddedItem);
      } else {
        targetContentComments.push(userAddedItem);
      }
    }

    proxy.writeQuery({
      query,
      data,
      variables,
    });
  } catch (error) {
    // ignore error
  }
};

const addUpdateItemAdminContentQuery = ({
  variables,
  userAddedItem,
  proxy,
  userInfoToSet,
  includeArchive,
}) => {
  let data;
  const companyId = getCompanyId();
  const query = companyId ? GetCompanyAdminContent : GetAdminContentByType;
  const queryDataKey = companyId
    ? 'getCompanyAdminContent'
    : 'getAdminContentByType';
  const typename = 'ContentAndUsersConnection';
  const variablesToUse = { ...variables };
  if (companyId) {
    variablesToUse.companyId = companyId;
  }

  if (_.isBoolean(includeArchive)) {
    variablesToUse.includeArchive = includeArchive;
  }

  try {
    data = _.cloneDeep(
      proxy.readQuery({
        query,
        variables: variablesToUse,
      })
    );
  } catch (e) {
    data = false;
    // eslint-disable-next-line no-console
    console.log(`addUpdateItemAdminContentQuery: ${queryDataKey} error:`, e);
  }

  if (data) {
    // for both items and users, run a check and update
    const updateSubList = ({ whatList, whatToAdd }) => {
      const addThis = { ...whatToAdd };
      const whatToCheck =
        whatList === 'items'
          ? {
              contentId: addThis.contentId,
            }
          : {
              userId: addThis.userId,
            };
      const existingIndex = _.findIndex(
        data[queryDataKey][whatList],
        whatToCheck
      );
      const hasBeenAdded = existingIndex >= 0;
      if (hasBeenAdded) {
        _.assign(data[queryDataKey][whatList][existingIndex], addThis);
      } else {
        data[queryDataKey][whatList].push(addThis);
      }
    };

    const thingsToAdd = [];
    if (userAddedItem) {
      thingsToAdd.push({ whatList: 'items', whatToAdd: userAddedItem });
    }
    if (userInfoToSet) {
      thingsToAdd.push({ whatList: 'users', whatToAdd: userInfoToSet });
    }

    thingsToAdd.forEach(addItem =>
      updateSubList({
        whatList: addItem.whatList,
        whatToAdd: addItem.whatToAdd,
      })
    );
  } else {
    data = {
      [queryDataKey]: {
        items: [userAddedItem],
        users: [userInfoToSet],
        __typename: typename,
      },
    };
  }
  proxy.writeQuery({ query, data, variables: variablesToUse });
};

const addUpdateListContentByType = ({ contentTypes, userAddedItem, proxy }) => {
  const query = ListContentByType;
  const variables = {
    companyId: getCompanyId(),
    contentTypes,
  };
  const accessor = 'listContentByType';

  try {
    const data = _.cloneDeep(
      proxy.readQuery({
        query,
        variables,
      })
    );

    if (data) {
      const existingIndex = _.findIndex(
        data[accessor].items,
        ({ contentId }) => contentId === userAddedItem.contentId
      );

      const hasBeenAdded = existingIndex >= 0;
      if (hasBeenAdded) {
        _.assign(data[accessor].items[existingIndex], userAddedItem);
      } else {
        data[accessor].items.push(userAddedItem);
      }

      proxy.writeQuery({ query, data, variables });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`could not update cache for listContentByType. error:`, e);
  }
};

const addUpdateAdminTimetrackingItemQuery = ({
  userAddedItem,
  proxy,
  userInfoToSet,
  includeArchive,
}) => {
  const companyId = getCompanyId();
  if (!companyId) {
    return;
  }

  const query = GetAdminTimetrackingData;
  const queryDataKey = 'getAdminTimetrackingData';

  const variables = {
    companyId,
    includeArchive,
  };

  let data;
  try {
    data = _.cloneDeep(
      proxy.readQuery({
        query,
        variables,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`addUpdateAdminTimetrackingItemQuery error:`, e);
  }

  if (!data) {
    return;
  }

  // for both items and users, run a check and update
  const updateSubList = ({ whatList, whatToAdd }) => {
    const addThis = { ...whatToAdd };
    const whatToCheck =
      whatList === 'items'
        ? {
            contentId: addThis.contentId,
          }
        : {
            userId: addThis.userId,
          };

    const existingIndex = _.findIndex(
      data[queryDataKey][whatList],
      whatToCheck
    );
    const hasBeenAdded = existingIndex >= 0;
    if (hasBeenAdded) {
      _.assign(data[queryDataKey][whatList][existingIndex], addThis);
    } else {
      data[queryDataKey][whatList].push(addThis);
    }
  };

  const addToItems = { whatList: 'items', whatToAdd: userAddedItem };
  const addToUsers = { whatList: 'users', whatToAdd: userInfoToSet };

  [addToItems, addToUsers].forEach(addItem =>
    updateSubList({
      whatList: addItem.whatList,
      whatToAdd: addItem.whatToAdd,
    })
  );

  proxy.writeQuery({ query, data, variables });
};

const removeItemFromQuery = ({
  query,
  queryDataKey,
  variables,
  passedItem,
  passedItems,
  proxy,
  keyName = 'contentId',
}) => {
  let data;
  try {
    data = _.cloneDeep(proxy.readQuery({ query, variables }));
  } catch (e) {
    // the query was never run before
  }
  const removeMatchingId = thing => thing[keyName] === passedItem[keyName];
  let toReturn;
  if (data) {
    if (passedItem) {
      const removedItem = _.remove(data[queryDataKey].items, removeMatchingId);
      proxy.writeQuery({ query, data, variables });
      toReturn = removedItem;
    } else {
      toReturn = [];
      const itemsToProcess = passedItems;
      itemsToProcess.forEach(item => {
        const removedItem = _.remove(
          data[queryDataKey].items,
          thing => thing[keyName] === item[keyName]
        );
        toReturn.push(removedItem);
      });
      proxy.writeQuery({ query, data, variables });
    }
  }
  return toReturn;
};

export const getAPresignedUrl = async (options = {}) => {
  // console.log('getAPresignedUrl options: ', options);
  const deviceWidth = window.outerWidth;
  const maxHeight = window.outerHeight - 100;
  const isProfileImage = options.isProfileImage || false;
  const typeOfFile = options.typeOfFile || 'image';
  const isAsyncVideo = options.typeOfFile === 'video' || false;
  const customFilename = options.customFilename
    ? options.customFilename
    : false;
  const customFolder = options.customFolder ? options.customFolder : false;
  // console.log('isAsyncVideo: ', isAsyncVideo);
  try {
    const getPresignedUrlRequestParams = {
      method: 'POST',
      body: {
        deviceWidth,
        maxHeight,
        isProfileImage,
        typeOfFile,
        isAsyncVideo,
        customFilename,
        customFolder,
      },
    };
    // console.log('getPresignedUrlRequestParams: ', getPresignedUrlRequestParams);
    const getAPresignedUrlResponse = await Api.restRequest(
      getPresignedUrlRequestParams,
      'get_signedURL_for_upload_media'
    );
    // console.log('getAPresignedUrlResponse: ', getAPresignedUrlResponse);
    return {
      message: 'success',
      signature: getAPresignedUrlResponse.signature,
      timestamp: getAPresignedUrlResponse.timestamp,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('getAPresignedUrl -> error: ', error);
    return error;
  }
};

const addProjectToCompanyProjectAdminData = (
  proxy,
  contentDetails,
  includeArchive
) => {
  // if it's coming from an admin tool only update this query
  let data = null;
  const query = GetCompanyAdminProjectData;
  const queryDataKey = 'getCompanyAdminProjectData';
  const variables = { companyId: getCompanyId() };

  if (_.isBoolean(includeArchive)) {
    variables.includeArchive = includeArchive;
  }

  try {
    data = _.cloneDeep(proxy.readQuery({ query, variables }));
  } catch (e) {
    data = false;
    // eslint-disable-next-line no-console
    console.log(
      `addProjectToCompanyProjectAdminData: ${queryDataKey} error:`,
      e
    );
  }
  if (data) {
    let hasBeenAdded = false;
    let existingIndex = -1;
    data[queryDataKey].projects.forEach((item, index) => {
      if (item.contentId === contentDetails.contentId) {
        hasBeenAdded = true;
        existingIndex = index;
      }
    });
    if (hasBeenAdded && !contentDetails.synced) {
      // do nothing
    } else if (hasBeenAdded && contentDetails.synced) {
      Object.assign(data[queryDataKey].projects[existingIndex], contentDetails);
    } else if (!hasBeenAdded) {
      data[queryDataKey].projects.push(contentDetails);
    }
    proxy.writeQuery({ query, data, variables });
  }
};

export const uploadToPresignedUrl = async (
  file,
  signature,
  timestamp,
  typeOfFile,
  isProfileImage,
  customFilename,
  customFolder
) => {
  // console.log('customFolder: ', customFolder);
  // console.log('uploadToPresignedUrl typeOfFile: ', typeOfFile);
  // const filenameParts = pictureLocation.split('/');
  // const filename = filenameParts[filenameParts.length - 1];
  const makeCloudinaryRequest = {
    file,
    signature,
    timestamp,
    // localLocation: pictureLocation,
    typeOfFile,
    isProfileImage: isProfileImage || false,
  };
  if (customFilename) makeCloudinaryRequest.customFilename = customFilename;
  if (customFolder) makeCloudinaryRequest.customFolder = customFolder;
  // console.log('makeCloudinaryRequest: ', makeCloudinaryRequest);
  try {
    // should make a queue so we know which piece of content is done
    //  could create and pass in an internal ID
    // if (typeOfFile === 'video') { // only show status if upload is a video
    //   store.dispatch({
    //     type: 'SET_UPLOADING_STATUS',
    //     payload: {
    //       status: 'uploading',
    //       contentType: typeOfFile,
    //     },
    //   });
    // }
    // const uploadDirectlyToCloudinary = await Api.cloudinaryUpload(
    const uploadDirectlyToCloudinary = await Api.cloudinaryUpload(
      makeCloudinaryRequest
    );
    // if (typeOfFile === 'video') { // only show status if upload is a video
    //   store.dispatch({
    //     type: 'SET_UPLOADING_STATUS',
    //     payload: {
    //       status: false,
    //       contentType: null,
    //     }
    //   });
    // }

    // console.log('uploadDirectlyToCloudinary: ', uploadDirectlyToCloudinary);
    // if (typeOfFile === 'video') { // only show status if upload is a video
    //   store.dispatch({
    //     type: 'RESET_UPLOADING_STATUS',
    //   });
    // }
    return {
      message: 'success',
      response: uploadDirectlyToCloudinary.response,
      newUrl: uploadDirectlyToCloudinary.response.secure_url,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error: ', error);
    // if (typeOfFile === 'video') { // only show status if upload is a video
    //   store.dispatch({
    //     type: 'SET_UPLOADING_STATUS',
    //     payload: {
    //       status: 'failed',
    //       contentType: null,
    //     },
    //   });
    // }
    return error;
  }
};

export const uploadContentNoMutation = async (passedContent, options = {}) => {
  // console.log('passedContent: ', passedContent);
  // 1. get A PresignedUrl
  // 2. use that presignedUrl to upload the file
  const localUri = passedContent.contentUrl;
  const contentType = passedContent.type || 'image';

  // 1.
  // const presignedUrl = await getAPresignedUrl(localUri);
  let presignedUrl;
  try {
    const presignUrlParams = {
      typeOfFile: contentType,
    };
    if (options.customFilename) {
      presignUrlParams.customFilename = options.customFilename;
    }
    if (options.customFolder) {
      presignUrlParams.customFolder = options.customFolder;
    }
    presignedUrl = await getAPresignedUrl(presignUrlParams);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('e: ', e);
    presignedUrl.message = 'error';
  }
  if (presignedUrl.message !== 'success') {
    return {
      status: 'error',
      msg: 'could not get presignedUrl',
    };
  }

  // 2.
  let uploadedResponse;
  try {
    uploadedResponse = await uploadToPresignedUrl(
      localUri,
      presignedUrl.signature,
      presignedUrl.timestamp,
      // need to check for video here too or make sure
      //    "video" is passed in when the upload
      //  is requested since we can base that on what screen they're on
      // 'image',
      contentType,
      false, // since this will never be called for profile photos
      options.customFilename || null, // pass the custome filename if it's there
      options.customFolder || null // pass the custome filename if it's there
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('e: ', e);
    uploadedResponse.message = 'error';
  }
  if (uploadedResponse.message !== 'success') {
    return {
      status: 'error',
      msg: 'could not uploadToPresignedUrl',
    };
  }

  // 3.
  const toPassForUpdate = { ...passedContent };
  toPassForUpdate.contentUrl = uploadedResponse.newUrl;
  toPassForUpdate.synced = false;
  return {
    status: 'success',
    data: toPassForUpdate,
  };
};

export const uploadContent = async (passedContent, options = {}) => {
  // 1. get A PresignedUrl
  // 2. use that presignedUrl to upload the file
  // 3. once the file is uploaded, update the content via a
  //    mutation to change the contentUrl location
  //  if we can't know the progress, just update the content right
  //    away (hopeful at that point that it will upload)
  const localUri = passedContent.contentUrl;
  const contentType = passedContent.type || 'image';
  // console.log('contentType: ', contentType);
  // 1.
  // const presignedUrl = await getAPresignedUrl(localUri);
  let presignedUrl;
  try {
    const presignUrlParams = {
      typeOfFile: contentType,
    };
    if (options.customFilename) {
      presignUrlParams.customFilename = options.customFilename;
    }
    if (options.customFolder) {
      presignUrlParams.customFolder = options.customFolder;
    }
    presignedUrl = await getAPresignedUrl(presignUrlParams);
  } catch (e) {
    return {
      status: 'error',
      msg: 'could not get presignedUrl',
    };
  }

  if (presignedUrl.message === 'success') {
    // 2.
    let uploadedResponse;
    try {
      uploadedResponse = await uploadToPresignedUrl(
        localUri,
        presignedUrl.signature,
        presignedUrl.timestamp,
        // need to check for video here too or make sure
        //    "video" is passed in when the upload
        //  is requested since we can base that on what screen they're on
        // 'image',
        contentType,
        false, // since this will never be called for profile photos
        options.customFilename || null, // pass the custome filename if it's there
        options.customFolder || null // pass the custome filename if it's there
      );
    } catch (e) {
      return {
        status: 'error',
        msg: 'could not uploadToPresignedUrl',
      };
    }

    if (uploadedResponse.message === 'success') {
      const toPassForUpdate = { ...passedContent };
      toPassForUpdate.contentUrl = uploadedResponse.newUrl;
      toPassForUpdate.synced = false;
      if (toPassForUpdate.amount.__typename) {
        delete toPassForUpdate.amount.__typename;
      }

      try {
        await appsyncClient.mutate({
          mutation: UpdateContentMutation,
          variables: toPassForUpdate,
          optimisticResponse: () => {
            // set back to local URI so interface isnt janky??? Will
            //    be updated next time they pull down the content
            // return {
            //   __typename: 'Mutation',
            //   updateContent: {
            //     ...toPassForUpdate,
            //     __typename: 'Content',
            //   },
            // };
            const toReturn = {
              __typename: 'Mutation',
              updateContent: {
                __typename: 'Content',
                ...toPassForUpdate,
                synced: true,
              },
            };
            if (toPassForUpdate.completionInfo) {
              toReturn.updateContent.completionInfo = {
                __typename: 'CompletionInfo',
                ...toPassForUpdate.completionInfo,
              };
              if (toPassForUpdate.completionInfo.mediaInfo) {
                const mediaInfo = toPassForUpdate.completionInfo.mediaInfo.map(
                  info => {
                    return {
                      __typename: 'MediaInfo',
                      ...info,
                    };
                  }
                );
                toReturn.updateContent.completionInfo.mediaInfo = mediaInfo;
              }
            }
            if (toPassForUpdate.amount) {
              toReturn.updateContent.amount = {
                __typename: 'Money',
                ...toPassForUpdate.amount,
              };
            }
            return toReturn;
          }, // end optimisticResponse
          update: (proxy, { data: { updateContent } }) => {
            const query = GetAllContentByJrnId;
            const data = proxy.readQuery({
              query,
              variables: { jrnId: updateContent.jrnId },
            });
            const existingItemIndex = _.findIndex(
              data.getAllContentByJrnId.items,
              { contentId: updateContent.contentId }
            );
            // merge the changes from the update with the existing item IN the existing list
            if (existingItemIndex > -1) {
              _.assign(
                data.getAllContentByJrnId.items[existingItemIndex],
                updateContent
              );
              proxy.writeQuery({
                query,
                data,
                variables: { jrnId: updateContent.jrnId },
              });
            }
          }, // end update
        });
      } catch (e) {
        return {
          status: 'error',
          msg: 'problem uploading',
        };
      }
    } else {
      return {
        status: 'error',
        msg: 'could not get presignedUrl',
      };
    }
  } else {
    return {
      status: 'error',
      msg: 'could not get presignedUrl',
    };
  }

  // if nothing went wrong then send success message
  return {
    status: 'success',
    msg: 'uploaded successfully',
  };
};
export const uploadContentStatusUpdate = async (
  passedContent,
  options = {},
  originalFiles
) => {
  // 1. get A PresignedUrl
  // 2. use that presignedUrl to upload the file
  // 3. once the file is uploaded, update the content via a
  //    mutation to change the contentUrl location
  //  if we can't know the progress, just update the content right
  //    away (hopeful at that point that it will upload)

  const doAnUpload = async ({ localFile, contentType = 'image' }) => {
    // 1.
    // const presignedUrl = await getAPresignedUrl(localUri);
    let presignedUrl;
    try {
      const presignUrlParams = {
        typeOfFile: contentType,
      };
      if (options.customFilename) {
        presignUrlParams.customFilename = options.customFilename;
      }
      if (options.customFolder) {
        presignUrlParams.customFolder = options.customFolder;
      }
      presignedUrl = await getAPresignedUrl(presignUrlParams);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('uploadContentStatusUpdate getAPresignedUrl e: ', e);
      return {
        status: 'error',
        msg: 'could not get presignedUrl',
      };
    }
    if (presignedUrl.message === 'success') {
      // 2.
      let uploadedResponse;
      try {
        uploadedResponse = await uploadToPresignedUrl(
          localFile, // pass in file here
          presignedUrl.signature,
          presignedUrl.timestamp,
          contentType,
          false, // since this will never be called for profile photos
          options.customFilename || null, // pass the custom filename if it's there
          options.customFolder || null // pass the custom filename if it's there
        );
      } catch (e) {
        return {
          status: 'error',
          msg: 'could not uploadToPresignedUrl',
        };
      }
      return uploadedResponse;
    }
    return {
      status: 'error',
      msg: 'could not get presignedUrl',
    };
  };

  const doMutationUpdate = async newMediaInfo => {
    const content = {
      completionInfo: {
        ...passedContent.completionInfo,
        mediaInfo: newMediaInfo,
      },
      contentId: passedContent.contentId,
      contentStatus: passedContent.contentStatus,
      jrnId: passedContent.jrnId,
    };
    content.synced = true;
    // these are both server side things that need to be remove to do the update properly
    if (content.completionInfo && content.completionInfo.__typename) {
      delete content.completionInfo.__typename;
    }
    if (content.completionInfo && content.completionInfo.completedByDate) {
      delete content.completionInfo.completedByDate;
    }

    try {
      await appsyncClient.mutate({
        mutation: UpdateContentStatusMutation,
        variables: content,
        optimisticResponse: () => {
          const toReturn = {
            __typename: 'Mutation',
            updateContentStatus: {
              __typename: 'Content',
              ...content,
              synced: true,
            },
          };
          if (content.completionInfo) {
            toReturn.updateContentStatus.completionInfo = {
              __typename: 'CompletionInfo',
              ...content.completionInfo,
            };
            if (content.completionInfo.mediaInfo) {
              const mediaInfo = content.completionInfo.mediaInfo.map(info => {
                return {
                  __typename: 'MediaInfo',
                  ...info,
                };
              });
              toReturn.updateContentStatus.completionInfo.mediaInfo = mediaInfo;
            }
          }
          return toReturn;
        }, // end optimisticResponse
        update: (proxy, { data: { updateContentStatus } }) => {
          const query = GetAllContentByJrnId;
          const data = proxy.readQuery({
            query,
            variables: { jrnId: content.jrnId },
          });
          const existingItemIndex = _.findIndex(
            data.getAllContentByJrnId.items,
            { contentId: content.contentId }
          );
          // merge the changes from the update with the existing item IN the existing list
          if (existingItemIndex > -1) {
            _.assign(
              data.getAllContentByJrnId.items[existingItemIndex],
              updateContentStatus
              // content
            );
            proxy.writeQuery({
              query,
              data,
              variables: { jrnId: content.jrnId },
            });
          }
        }, // end update
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('uploadContentStatusUpdate doMutationUpdate e: ', e);
      return {
        status: 'error',
        msg: 'problem uploading',
      };
    }
    // if everything went well, dont need to return anything
    return null;
  };

  const mediaInfo = _.get(passedContent, 'completionInfo.mediaInfo');
  if (!mediaInfo) {
    return {
      status: 'error',
      msg: 'no mediaInfo',
    };
  }
  // console.log('originalFiles: ', originalFiles);
  const uploadedFiles = originalFiles.map(async (oneMediaInfo, index) => {
    const uploadInfo = await doAnUpload({
      // localUri: oneMediaInfo.uri,
      localFile: oneMediaInfo,
      contentType: options.type, // completionType isn't included in this call so we need to specify it in the options
    });
    // console.log(`uploadInfo ${index}: `, uploadInfo);
    if (uploadInfo.message === 'success') {
      const aspectRatio =
        uploadInfo.response.width / uploadInfo.response.height;

      // console.log('aspectRatio: ', aspectRatio);
      const toReturn = {
        ...mediaInfo[index],
        uri: uploadInfo.newUrl,
        aspectRatio,
      };
      // console.log('uploadInfo.newUrl: ', uploadInfo.newUrl);
      delete toReturn.__typename;
      return toReturn;
    }

    return null;
  });

  const newMediaInfo = await Promise.all(uploadedFiles);
  // console.log('newMediaInfo: ', newMediaInfo);
  doMutationUpdate(newMediaInfo);
  // if nothing went wrong then send success message
  return {
    status: 'success',
    msg: 'uploaded successfully',
  };
};

const updateGetUserInfoCache = ({ proxy, updatedUserInfo }) => {
  const query = GetUserInfo;
  const variables = { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER };
  let data;
  try {
    data = _.cloneDeep(proxy.readQuery({ query, variables }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('updateGetUserInfoCache err: ', err);
  }

  if (data) {
    // merge the changes from the update with the existing item
    _.assign(data.getMyUserInfo, updatedUserInfo);

    proxy.writeQuery({ query, data, variables });
  }
};

export const uploadProfilePhoto = async (passedUser, fileForPhoto) => {
  // console.log('passedUser: ', passedUser);
  // 1. get A PresignedUrl
  // 2. use that presignedUrl to upload the file
  // 3. once the file is uploaded, update the jrn via a mutation to change the coverImage location
  //  if we can't know the progress, just update the jrn
  //    right away (hopeful at that point that it will upload)
  // const localUri = passedUser.profilePic;
  // 1.
  const presignedUrl = await getAPresignedUrl({ isProfileImage: true });
  if (presignedUrl.message === 'success') {
    // 2.
    const uploadedResponse = await uploadToPresignedUrl(
      fileForPhoto,
      presignedUrl.signature,
      presignedUrl.timestamp,
      'image',
      true
    );

    // console.log('uploadedResponse: ', uploadedResponse);

    if (uploadedResponse.message === 'success') {
      const toPassForUpdate = { ...passedUser };
      toPassForUpdate.synced = false;
      toPassForUpdate.profilePic = _.get(
        uploadedResponse,
        'response.secure_url'
      );

      // console.log('toPassForUpdate: ', toPassForUpdate);

      await appsyncClient.mutate({
        mutation: UpdateUserMutation,
        variables: toPassForUpdate,
        optimisticResponse: () => {
          // console.log('UPDATE optimisticResponse ran');
          return {
            __typename: 'Mutation',
            updateUser: {
              ...toPassForUpdate,
              __typename: 'User',
            },
          };
        }, // end optimisticResponse
        update: (proxy, { data: { updateUser } }) => {
          if (!updateUser) return;

          updateGetUserInfoCache({ proxy, updatedUserInfo: updateUser });
        }, // end update
      });
    }
    return uploadedResponse;
  }

  return null;
};

export const uploadCoverPhoto = async (passedJrn, options = {}) => {
  // eslint-disable-next-line no-console
  console.log('uploadCoverPhoto run');
  // console.log('passedJrn: ', passedJrn);
  // 1. get A PresignedUrl
  // 2. use that presignedUrl to upload the file
  // 3. once the file is uploaded, update the jrn via a mutation to change the coverImage location
  //  if we can't know the progress, just update the jrn
  //    right away (hopeful at that point that it will upload)
  const localUri = passedJrn.contentUrl;
  const customFolderOrNot = options.customFolder ? options.customFolder : null;
  // 1.
  // const presignedUrl = await getAPresignedUrl(localUri);
  const presignedUrl = await getAPresignedUrl({
    customFolder: customFolderOrNot,
  });
  if (presignedUrl.message === 'success') {
    // 2.
    const uploadedResponse = await uploadToPresignedUrl(
      localUri,
      presignedUrl.signature,
      presignedUrl.timestamp,
      'image',
      false,
      null,
      customFolderOrNot
    );
    if (uploadedResponse.message === 'success') {
      // passedJrn.contentUrl = uploadedResponse.newUrl;
      const toPassForUpdate = { ...passedJrn };
      toPassForUpdate.synced = false;
      toPassForUpdate.contentUrl = uploadedResponse.newUrl;
      // console.log('toPassForUpdate: ', toPassForUpdate);
      // can we just run the "UpdateContentMutation" call above
      //    so the Optimistic response and update are the same???
      await appsyncClient.mutate({
        mutation: UpdateJrnMutation,
        variables: toPassForUpdate,

        optimisticResponse: () => {
          // console.log('UPDATE optimisticResponse ran');
          return {
            updateJrn: {
              ...toPassForUpdate,
              __typename: 'Mutation',
            },
          };
        }, // end optimisticResponse

        update: (proxy, { data: { updateJrn } }) => {
          if (!updateJrn) return;
          const query = ListMyJrns;
          const variables = {
            userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
          };
          const data = proxy.readQuery({ query, variables });
          // find the matching jrn in the existing list
          const existingItemIndex = _.findIndex(data.listMyJrns.items, {
            contentId: updateJrn.contentId,
          });
          // console.log('existingItemIndex: ', existingItemIndex);
          // merge the changes from the update with the existing item IN the existing list
          if (existingItemIndex > -1) {
            // _.assign(data.listMyJrns.items[existingItemIndex], updateJrn);
            Object.assign(data.listMyJrns.items[existingItemIndex], updateJrn);
            // console.log('cover update about to write');
            proxy.writeQuery({ query, data, variables });
          }
        }, // end update
      });
    }
  }
};

// export const operations = {

// SUBSCRIPTIONS
// onCreateMessage:

// QUERIES
export const GetJrnBasicInfoAction = params => {
  return graphql(GetJrn, {
    options: () => {
      const { contentId } = params;
      // console.log('GetJrnBasicInfo -> jrnId: ', jrnId);
      return {
        variables: { jrnId: contentId },
        fetchPolicy: 'cache-and-network',
      };
    },
    props: props => ({
      basicInfoRefetch: props.data.refetch,
      basicInfoLoading: props.data.loading,
      jrnInfo: props.data.getJrn ? props.data.getJrn : {},
    }),
  });
};

export const GetBudgetInfoAction = graphql(GetBudgetInfo, {
  skip: props => {
    if (!isCompanyAdmin()) {
      return true;
    }

    return !props.projectId && !props.projectInfo;
  },
  options: props => {
    return {
      variables: {
        jrnId: props.projectId || props.projectInfo?.contentId,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getBudgetInfoRefetch: props.data.refetch,
      getBudgetInfoLoading: props.data.loading,
      getBudgetInfoError: props.data.error,
      budgetInfo: props.data.getBudgetInfo ? props.data.getBudgetInfo : null,
    };
  },
});

export const GetContentByJrnIdLocalAction = graphql(GetAllContentByJrnId, {
  options: props => {
    const { jrnId } = props.navigation.state.params;
    // console.log('GetAllContentByJrnIdLocal jrnId: ', jrnId);
    return {
      variables: { jrnId },
      fetchPolicy: 'cache-only',
    };
  },
  props: props => ({
    jrnContent: props.data.getAllContentByJrnId
      ? props.data.getAllContentByJrnId.items
      : [],
  }),
});

export const GetAllContentByJrnIdAction = graphql(GetAllContentByJrnId, {
  options: props => {
    const jrnId = props.jrnId || props.globalPaymentId;
    return {
      variables: { jrnId },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      contentRefetch: props.data.refetch,
      contentLoading: props.data.loading,
      jrnContent: props.data.getAllContentByJrnId
        ? props.data.getAllContentByJrnId.items
        : [],
    };
  },
});

export const GetAllContentByGlobalExpenseIdAction = graphql(
  GetAllContentByGlobalExpenseId,
  {
    skip: props => !props.globalExpenseId,
    options: props => {
      const { globalExpenseId } = props;
      return {
        variables: { globalExpenseId },
        fetchPolicy: 'cache-and-network',
      };
    },
    props: props => {
      return {
        globalExpenseContentRefetch: props.data.refetch,
        globalExpenseContentLoading: props.data.loading,
        globalExpenseContentError: props.data.error,
        globalExpenseContentItems:
          props.data.getAllContentByGlobalExpenseId &&
          props.data.getAllContentByGlobalExpenseId.items,
      };
    },
  }
);

export const GetJrnAction = graphql(GetJrn, {
  options: props => {
    return {
      variables: {
        jrnId: props.targetContent && props.targetContent.contentId,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getJrnRefetch: props.data.refetch,
      getJrnLoading: props.data.loading,
      getJrnError: props.data.error,
      jrnInfo: props.data.getJrn ? props.data.getJrn : null,
    };
  },
});

export const GetContentByIdAction = graphql(GetContentById, {
  options: props => {
    return {
      variables: {
        contentId: props.targetContent && props.targetContent.contentId,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      contentInfoRefetch: props.data.refetch,
      contentInfoLoading: props.data.loading,
      contentInfoError: props.data.error,
      contentInfo: props.data.getContentById ? props.data.getContentById : null,
    };
  },
});

export const GetUsersByJrnIdAction = graphql(GetUsersByJrnId, {
  options: props => {
    const contentId = _.get(props.navigation.state, 'params.jrn.contentId');
    // console.log('GetUsersByJrnId contentId: ', contentId);
    return {
      variables: { jrnId: contentId },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    // console.log('GetUsersByJrnId props.data: ', props.data);
    return {
      usersRefetch: props.data.refetch,
      usersLoading: props.data.loading,
      jrnUsers: props.data.getUsersByJrnId
        ? props.data.getUsersByJrnId.items
        : null,
    };
  },
});

export const ListMyProjectsAndSubprojectsAction = graphql(
  ListMyProjectsAndSubprojects,
  {
    options: {
      variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
      fetchPolicy: 'cache-and-network',
    },
    props: props => {
      return {
        loadingListMyProjectsAndSubprojects: props.data.loading,
        refetchListMyProjectsAndSubprojects: props.data.refetch,
        myProjectsAndSubprojects: props.data.listMyProjectsAndSubprojects
          ? props.data.listMyProjectsAndSubprojects.items
          : null,
        errorListMyProjectsAndSubprojects: props.data.error,
      };
    },
  }
);

export const ListMyJrnsAction = graphql(ListMyJrns, {
  options: {
    variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
    fetchPolicy: 'cache-and-network',
    // fetchPolicy: 'cache-and-network',
    // pollInterval: 5000
  },
  props: props => {
    // console.log('ListMyJrns props: ', props);
    return {
      loadingListMyJrns: props.data.loading,
      refetchListMyJrns: props.data.refetch,
      myjrns: props.data.listMyJrns ? props.data.listMyJrns.items : [],
      myjrnsError: props.data.error,
    };
  },
});

export const ListMyConversationsAction = graphql(ListMyConversations, {
  options: {
    variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
    fetchPolicy: 'cache-and-network',
  },
  props: props => {
    const convos = props.data.listMyConversations
      ? props.data.listMyConversations.items
      : null;

    return {
      listMyConversationsLoading: props.data.loading,
      listMyConversationsRefetch: props.data.refetch,
      listMyConversationsError: props.data.error,
      myConversations: convos,
    };
  },
});

export const GetMyUserInfoAction = graphql(GetUserInfo, {
  options: {
    variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
    pollInterval: 60 * 1000,
    fetchPolicy: 'cache-and-network',
  },
  props: props => {
    return {
      refetchGetUserInfo: props.data.refetch,
      userInfoLoading: props.data.loading,
      userInfoError: props.data.error,
      userInfo: props.data.getMyUserInfo,
    };
  },
});

export const GetMessagesByJrnIdAction = graphql(GetMessagesByJrnId, {
  options: props => {
    const jrnId = props.navigation.state.params.jrn.contentId;
    return {
      variables: { jrnId },
      fetchPolicy: 'cache-and-network',
      // fetchPolicy: 'network-only',
      pollInterval: 3000,
      // notifyOnNetworkStatusChange: true,
    };
  },
  props: props => {
    // console.log('GetMessagesByJrnId props: ', props);
    return {
      messageRefetch: props.data.refetch,
      messageLoading: props.data.loading,
      messages: props.data.getMessagesByJrnId
        ? props.data.getMessagesByJrnId.items
        : null,
      messagesNextToken: props.data.getMessagesByJrnId
        ? props.data.getMessagesByJrnId.nextToken
        : null,

      onLoadMore: nextToken => {
        props.data.fetchMore({
          variables: {
            jrnId: props.ownProps.navigation.state.params.jrn.contentId,
            after: nextToken,
          },
          updateQuery: (previousResult, newResult) => {
            const newMessageConnection =
              newResult.fetchMoreResult.getMessagesByJrnId;
            if (
              newMessageConnection.items.length &&
              nextToken !== newMessageConnection.nextToken
            ) {
              const giveBack = {
                ...previousResult,
                ...{
                  getMessagesByJrnId: {
                    ...previousResult.getMessagesByJrnId,
                    __typename: 'MessageConnection',
                    items: [
                      ...previousResult.getMessagesByJrnId.items,
                      ...newMessageConnection.items,
                    ],
                    nextToken: newMessageConnection.nextToken,
                  },
                },
              };
              return giveBack;
            }
            return previousResult;
          },
        });
      },

      subscribeToNewMessages: () => {
        props.data.subscribeToMore({
          document: SubscribeToNewMessages,
          variables: {
            jrnId: props.ownProps.navigation.state.params.jrn.contentId,
          },
          updateQuery: (
            prev,
            {
              subscriptionData: {
                data: { subscribeToNewMessages },
              },
            }
          ) => {
            store.dispatch({
              type: 'SET_A_LAST_READ_TIMESTAMP',
              payload: {
                contentId: subscribeToNewMessages.jrnId,
                lastMessageRead: subscribeToNewMessages.createdAt,
              },
            });
            const giveBack = {
              ...prev,
              ...{
                getMessagesByJrnId: {
                  ...prev.getMessagesByJrnId,
                  __typename: 'MessageConnection',
                  items: [
                    subscribeToNewMessages,
                    ...prev.getMessagesByJrnId.items.filter(message => {
                      return (
                        message.messageId !== subscribeToNewMessages.messageId
                      );
                    }),
                  ],
                },
              },
            };
            return giveBack;
          },
        }); // end subscribeToMore
      },
    };
  },
});

export const GetSlideshowInfoAction = graphql(GetSlideshowInfo, {
  options: props => {
    const jrnId = props.projectId;
    return {
      variables: {
        jrnId,
        requestorId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    // console.log('GetSlideshowInfo -> props: ', props);
    return {
      refetchGetSlideshowInfo: props.data.refetch,
      slideshowLoading: props.data.loading,
      slideshowInfo: props.data.getSlideshowInfo || {},
    };
  },
});

export const GetNotificationPrefsAction = graphql(GetNotificationPrefs, {
  options: () => {
    return {
      variables: {
        requestorId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    // console.log('GetNotificationPrefs -> props: ', props);
    return {
      refetchGetNotificationPrefs: props.data.refetch,
      notificationPrefsLoading: props.data.loading,
      notificationPrefsInfo: props.data.getNotificationPrefs || {},
    };
  },
});

// MUTATIONS
// AddSlideshowToJrn

export const PinChatToConvosActionsAction = graphql(PinChatToConvosActions, {
  options: {
    errorPolicy: 'ignore',
  },
  props: props => {
    return {
      onPinChatToConvosActions: (projectId, action, projectInfo) => {
        return props.mutate({
          variables: { projectId, action },
          optimisticResponse: () => {
            return {
              __typename: 'Mutation',
              pinChatToConvosActions: {
                __typename: 'ActionResponse',
                status: 'success',
                msg: 'done',
              },
            };
          }, // end optimisticResponse
          update: proxy => {
            const query = ListMyConversations;
            const variables = {
              userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
            };
            const originalData = proxy.readQuery({ query, variables });
            let data = _.cloneDeep(originalData);
            let hasBeenAdded = false;
            if (!_.get(data, 'listMyConversations.items')) {
              data = { listMyConversations: { items: [] } };
            }
            data.listMyConversations.items.forEach(item => {
              if (item.contentId === projectId) {
                hasBeenAdded = true;
              }
            });

            if (action === 'pin') {
              // add it
              if (!hasBeenAdded) {
                data.listMyConversations.items.push(projectInfo);
              }
            } else if (action === 'unpin') {
              // remove it
              const removeMatchingId = eachProject =>
                eachProject.contentId === projectId;
              _.remove(data.listMyConversations.items, removeMatchingId);
            }
            proxy.writeQuery({
              query,
              data,
              variables,
            });
          },
        });
      },
    };
  },
});

export const AddSlideshowToJrnAction = graphql(AddSlideshowToJrn, {
  options: {
    errorPolicy: 'ignore',
  },
  props: props => {
    // console.log('onAddSlideShowToJrn -> props: ', props);
    return {
      onAddSlideShowToJrn: slideshowInfo => {
        return props.mutate({
          variables: slideshowInfo,
        });
      },
    };
  },
});

export const UpdateSlideshowOnJrnAction = graphql(UpdateSlideshowOnJrn, {
  options: {
    errorPolicy: 'ignore',
  },
  props: props => {
    // console.log('UpdateSlideshowOnJrn -> props: ', props);
    return {
      onUpdateSlideshowOnJrn: slideshowInfo => {
        return props.mutate({
          variables: slideshowInfo,
        });
      },
    };
  },
});

export const UpdateNotificationPrefsAction = graphql(UpdateNotificationPrefs, {
  options: {
    errorPolicy: 'ignore',
  },
  props: props => {
    // console.log('UpdateNotificationPrefs -> props: ', props);
    return {
      onUpdateNotificationPrefs: notificationPref => {
        return props.mutate({
          variables: notificationPref,
        });
      },
    };
  },
});

export const UpdateNotificationDetailsAction = graphql(
  UpdateNotificationDetails,
  {
    options: () => {
      return {
        // fetchPolicy: 'no-cache',
        // refetchQueries: [{
        //   query: GetNotificationPrefs,
        //   variables: {
        //     requestorId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
        //   },
        // }],
      };
    },
    props: props => {
      return {
        onUpdateNotificationDetails: notificationDetails => {
          // console.log('1 - notificationDetails: ', notificationDetails);
          return props.mutate({
            variables: notificationDetails,
            optimisticResponse: () => {
              return {
                __typename: 'Mutation',
                updateNotificationDetails: {
                  __typename: 'NotificationPrefsInfo',
                  ...notificationDetails,
                  status: 'success',
                },
              };
            }, // end optimisticResponse
            update: (proxy, { data: { updateNotificationDetails } }) => {
              // console.log('2 - updateNotificationDetails: ', updateNotificationDetails);
              const query = GetNotificationPrefs;
              const originalData = proxy.readQuery({
                query,
                variables: {
                  requestorId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                },
              });
              const data = _.cloneDeep(originalData);
              // console.log('3 - data: ', data);

              data.getNotificationPrefs = {
                ...data.getNotificationPrefs,
                ...updateNotificationDetails,
                __typename: 'NotificationPrefsInfo',
              };
              proxy.writeQuery({
                query,
                data,
                variables: {
                  requestorId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                },
              });
              // console.log('4 - wrote data: ', data);
              // const afterData = proxy.readQuery({ query, variables: { requestorId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER } });
              // console.log('afterData: ', afterData);
            },
          }); // end mutate
        }, // end onUpdateNotificationDetails
      };
    }, // end props
  }
);

export const CreateLoveMutationAction = graphql(CreateLoveMutation, {
  options: () => {
    return {
      // fetchPolicy: 'cache-and-network'
    };
  },
  props: props => {
    // const { contentId } = props.ownProps.navigation.state.params;
    return {
      onAddLove: (love, jrnId) => {
        props.mutate({
          variables: love,

          optimisticResponse: () => {
            return {
              __typename: 'Mutation',
              createLove: {
                ...love,
                __typename: 'Love',
              },
            };
          }, // end optimisticResponse

          update: (proxy, { data: { createLove } }) => {
            const query = GetAllContentByJrnId;
            const data = _.cloneDeep(
              proxy.readQuery({ query, variables: { jrnId } })
            );
            let hasBeenAdded = false;
            let existingIndex = -1;

            const idOfContentLookingFor = createLove.contentId;
            // find the right piece of content
            const IndexWhereLoveIsOn = _.findIndex(
              data.getAllContentByJrnId.items,
              { contentId: idOfContentLookingFor }
            );

            const allLoves =
              data.getAllContentByJrnId.items[IndexWhereLoveIsOn].loves.items;

            // check if this love is already there or not
            allLoves.map((thisLove, index) => {
              if (thisLove.loveId === createLove.loveId) {
                hasBeenAdded = true;
                existingIndex = index;
              }
              return null;
            });
            // if it's already there AND the synced isn't true then
            //  do nothing (shouldnt happen, but, you know)
            if (hasBeenAdded && !createLove.synced) {
              return;
            }
            if (hasBeenAdded && createLove.synced) {
              // if it's already there AND the incoming one has sync set to true
              //  then merge the existing one with the new one
              Object.assign(
                data.getAllContentByJrnId.items[IndexWhereLoveIsOn].loves.items[
                  existingIndex
                ],
                createLove
              );
            } else if (!hasBeenAdded) {
              // if it's not already there add it
              data.getAllContentByJrnId.items[
                IndexWhereLoveIsOn
              ].loves.items.push(createLove);
            }
            proxy.writeQuery({
              query,
              data,
              variables: { jrnId },
            });
          },
        }); // end mutate
      }, // end onAddLove
    };
  }, // end props
});

export const CreateCommentMutationAction = graphql(CreateCommentMutation, {
  props: props => {
    return {
      onAddComment: (comment, { jrnId } = {}) => {
        props.mutate({
          variables: comment,
          optimisticResponse: () => {
            return {
              __typename: 'Mutation',
              createComment: {
                ...comment,
                __typename: 'Comment',
              },
            };
          }, // end optimisticResponse
          update: (proxy, { data: { createComment } }) => {
            if (comment?.contentType === 'rfi') {
              // The RFI would have been queried wherever comments are displayed
              const query = GetCompanyRfi;
              const variables = {
                requestId: comment.contentId,
                companyId: getCompanyId() || null, // Just in case
              };

              const initialRfiCachedData = proxy.readQuery({
                query,
                variables,
              });
              const rfiQueryDataForCache = _.cloneDeep(initialRfiCachedData);

              // Update RFI
              const rfiData = _.get(rfiQueryDataForCache, 'getCompanyRfi');
              if (rfiData?.comments?.items) {
                const existingComment = _.find(
                  rfiData.comments.items,
                  { commentId: createComment.commentId },
                  null
                );

                if (!existingComment) {
                  // If it is new, add it
                  rfiData.comments.items.unshift(createComment);
                } else {
                  // If it exists, merge the data
                  Object.assign(existingComment, createComment);
                }
              }

              proxy.writeQuery({
                query,
                data: {
                  getCompanyRfi: rfiData,
                },
                variables,
              });
              return;
            }
            if (jrnId && jrnId !== TOP_PROJECT_ID) {
              // Try adding the comment to the content in the content list.
              addCommentToContentList({
                query: GetAllContentByJrnId,
                queryDataKey: 'getAllContentByJrnId',
                variables: { jrnId },
                userAddedItem: createComment,
                proxy,
              });
            }

            try {
              let queryToUse;
              let variablesToUse;
              let dataAccessor;
              if (
                [
                  CONTENT_TYPE.PROJECT,
                  CONTENT_TYPE.GLOBAL_BILL,
                  CONTENT_TYPE.GLOBAL_RECEIPT,
                  CONTENT_TYPE.GLOBAL_PAYMENT,
                ].includes(comment.contentType) // From input
              ) {
                queryToUse = GetJrn;
                variablesToUse = { jrnId: createComment.contentId };
                dataAccessor = 'getJrn';
              } else {
                queryToUse = GetContentById;
                variablesToUse = { contentId: createComment.contentId };
                dataAccessor = 'getContentById';
              }

              // Try to update using the type's query
              const data = _.cloneDeep(
                proxy.readQuery({
                  query: queryToUse,
                  variables: variablesToUse,
                })
              );

              const targetContent = data[dataAccessor];

              // Find the comment array to update
              const targetContentComments = targetContent.comments.items;

              // check if this comment is already there or not
              const thisExistingComment = _.find(targetContentComments, {
                commentId: createComment.commentId,
              });

              if (thisExistingComment) {
                // if it's already there, merge it
                _.merge(thisExistingComment, createComment);
              } else if (!thisExistingComment) {
                // if it's not already there add it
                targetContentComments.push(createComment);
              }

              proxy.writeQuery({
                query: queryToUse,
                variables: variablesToUse,
                data,
              });
            } catch (err) {
              // Eat error
            }
          },
        }); // end mutate
      }, // end onAddComment
    };
  }, // end props
});

export const CreateInternalCommentMutationAction = graphql(
  CreateInternalCommentMutation,
  {
    props: props => {
      return {
        onCreateInternalComment: ({ comment, itemType, itemId } = {}) => {
          // console.log('{ comment, itemType, itemId }: ', {
          //   comment,
          //   itemType,
          //   itemId,
          // });
          props.mutate({
            variables: { ...comment, companyId: getCompanyId() },
            optimisticResponse: () => {
              return {
                __typename: 'Mutation',
                createInternalComment: {
                  ...comment,
                  __typename: 'Comment',
                },
              };
            }, // end optimisticResponse
            update: (proxy, { data: { createInternalComment } }) => {
              // console.log('createInternalComment: ', createInternalComment);
              addItemToQuery({
                query: GetInternalComments,
                queryDataKey: 'getInternalComments',
                variables: {
                  companyId: getCompanyId(),
                  itemType,
                  itemId,
                },
                userAddedItem: createInternalComment,
                proxy,
                typename: 'CommentConnection',
                keyName: 'commentId',
                // addTo: 'top',
              });
            },
          }); // end mutate
        }, // end onAddComment
      };
    }, // end props
  }
);

export const CreateMessageMutationAction = graphql(CreateMessageMutation, {
  options: () => {
    return {
      // fetchPolicy: 'cache-and-network'
    };
  },
  props: props => ({
    onAddMessage: message => {
      props.mutate({
        variables: message,

        optimisticResponse: () => {
          return {
            __typename: 'Mutation',
            createMessage: {
              ...message,
              __typename: 'Message',
            },
          };
        }, // end optimisticResponse

        update: (proxy, { data: { createMessage } }) => {
          const query = GetMessagesByJrnId;
          const data = _.cloneDeep(
            proxy.readQuery({
              query,
              variables: { jrnId: createMessage.jrnId },
            })
          );
          let hasBeenAdded = false;
          let existingIndex = -1;
          data.getMessagesByJrnId.items.map((item, index) => {
            if (item.messageId === createMessage.messageId) {
              hasBeenAdded = true;
              existingIndex = index;
            }
            return null;
          });
          // allow to be written if the item doesnt exist or
          //  synced = true meaning it's coming from the server
          // so, STOP if the item exists AND synced = false

          // if it's already there AND the synced isn't
          //  true then do nothing (shouldnt happen, but, you know)
          if (hasBeenAdded && !createMessage.synced) {
            return;
          }
          if (hasBeenAdded && createMessage.synced) {
            // if it's already there AND the incoming one has sync set to true
            //  then merge the existing one with the new one
            Object.assign(
              data.getMessagesByJrnId.items[existingIndex],
              createMessage
            );
          } else if (!hasBeenAdded) {
            // if it's not already there add it
            // data.getMessagesByJrnId.items.push(createMessage);
            data.getMessagesByJrnId.items.unshift(createMessage);
          }
          proxy.writeQuery({
            query,
            data,
            variables: { jrnId: createMessage.jrnId },
          });
        },
      }); // end mutate
    }, // end onAddMessage
  }), // end props
});

export const CreateContentMutationAction = graphql(CreateContentMutation, {
  props: props => ({
    onAddContent: (content, options = {}) => {
      // As `skip` option is not supported in `refetchQueries` we need to manually
      // skip the queries that are not needed
      const refetchQueries = [
        {
          query: GetUserInfo,
          variables: {
            userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
          },
        },
      ];

      if (content.globalExpenseId) {
        refetchQueries.push({
          query: GetAllContentByGlobalExpenseId,
          variables: { globalExpenseId: content.globalExpenseId },
        });
      }

      const optionsParam = options || {};
      // create an object to be passed into mutate that takes into account
      //  whether we want to upload automatically or not
      let paramsForMutate = {
        refetchQueries,
        variables: content,
        update: (proxy, { data: { createContent } }) => {
          if (createContent.synced && createContent.globalExpenseId) {
            // delay 3 seconds to make sure the global expense is updated on the server
            setTimeout(() => {
              appsyncClient.query({
                query: GetJrn,
                variables: { jrnId: createContent.globalExpenseId },
                fetchPolicy: 'network-only',
              });
            }, 3000);
          }

          if (!optionsParam.fromShoebox) {
            if (optionsParam.fromWhichAdminTool && optionsParam.user) {
              if (createContent.type === CONTENT_TYPE.BILL_PAYMENT) {
                return;
              }
              if (optionsParam.fromWhichAdminTool === 'timetracking') {
                try {
                  addUpdateAdminTimetrackingItemQuery({
                    proxy,
                    userAddedItem: createContent,
                    userInfoToSet: optionsParam.user,
                    includeArchive: optionsParam.includeArchive,
                  });
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.log(
                    `addUpdateAdminTimetrackingItemQuery onAddContent err: `,
                    err
                  );
                }
              } else if (
                optionsParam.fromWhichAdminTool ===
                ADMIN_CONTENT_QUERIES.RECEIPTS_AND_BILLS
              ) {
                addUpdateListContentByType({
                  proxy,
                  contentTypes: [CONTENT_TYPE.BILL, CONTENT_TYPE.RECEIPT],
                  userAddedItem: createContent,
                });
              } else if (
                optionsParam.fromWhichAdminTool ===
                ADMIN_CONTENT_QUERIES.INVOICES_AND_PAYMENTS
              ) {
                addUpdateListContentByType({
                  proxy,
                  contentTypes: [CONTENT_TYPE.INVOICE, CONTENT_TYPE.PAYMENT],
                  userAddedItem: createContent,
                });
              } else if (
                optionsParam.fromWhichAdminTool === CONTENT_TYPE.GLOBAL_BILL
              ) {
                // no need to update cache
                return;
              } else {
                try {
                  addUpdateItemAdminContentQuery({
                    proxy,
                    variables: { contentType: optionsParam.fromWhichAdminTool },
                    userAddedItem: createContent,
                    userInfoToSet: optionsParam.user,
                    includeArchive: optionsParam.includeArchive,
                  });
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.log(
                    `addUpdateItemAdminContentQuery onAddContent err: `,
                    err
                  );
                }
              }
              return;
            }

            try {
              addItemToQuery({
                query: GetAllContentByJrnId,
                queryDataKey: 'getAllContentByJrnId',
                variables: { jrnId: createContent.jrnId },
                userAddedItem: createContent,
                proxy,
                typename: 'ContentConnection',
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(
                `addItemToQuery subproject ListMyProjectsAndSubprojects err: `,
                err
              );
            }

            if (optionsParam.createdByAdminUser) {
              try {
                addItemToQuery({
                  query: GetUsersByJrnId,
                  queryDataKey: 'getUsersByJrnId',
                  variables: { jrnId: createContent.jrnId },
                  userAddedItem: optionsParam.createdByAdminUser,
                  keyName: 'userId',
                  proxy,
                  typename: 'SimpleUser',
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(
                  'addItemToQuery onAddContent GetUsersByJrnId err:',
                  err
                );
              }
            }
          }

          if (createContent.synced && options.noUpload !== true) {
            if (createContent.contentUrl) {
              if (createContent.contentUrl.indexOf('file://') > -1) {
                // then the file is still local and we need to upload it
                uploadContent(createContent, {
                  customFolder:
                    createContent.type === 'project'
                      ? createContent.contentId
                      : createContent.jrnId,
                });
              }
            }
          }
        },
      };

      if (options.noUpload !== true) {
        paramsForMutate = {
          ...paramsForMutate,
          optimisticResponse: () => {
            const optimisticContent = _.cloneDeep(content);

            const attributeDefaults = {
              globalExpenseId: null,
              amount: null,
              recurrence: null,
              qboCustomerId: null,
              timetrackingAnswers: null,
              globalExpense: options.globalExpense || null,
              completionType: null,
              comments: { items: [] },
              loves: { items: [] },
              contentStatus: null,
              completionInfo: null,
              fromTemplate: null,
              media: null,
              dateCreated: new Date().toISOString(),
              allowedToEdit: [],
              allowedToAdd: [],
              allowedToView: [],
              usersEverOnJrn: [],
              useTimetracking: false,
              workflowStage: null,
              workflowStageReason: null,
              stats: null,
              budgets: null,
              customerId: null,
              useTimetrackingQuestions: null,
              timetrackingQuestions: null,
              timetrackingQuestionsCheckout: null,
              includeSubsInStats: null,
              excludeFromStats: null,
            };

            return addTypenameWhereNeeded(
              { ...attributeDefaults, ...optimisticContent },
              'createContent',
              'Content'
            );
          }, // end optimisticResponse
        };
      }
      return props.mutate(paramsForMutate); // end mutate
    }, // end onAddContent
  }), // end props
});

export const UpdateContentMutationAction = graphql(UpdateContentMutation, {
  props: props => ({
    onUpdateContent: (content, options = {}) => {
      // As `skip` option is not supported in `refetchQueries` we need to manually
      // skip the queries that are not needed
      const refetchQueries = [
        {
          query: GetUserInfo,
          variables: {
            userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
          },
        },
      ];

      const skipOptimisticResponse = options && options.skipOptimisticResponse;

      return props.mutate({
        refetchQueries,
        variables: content,

        optimisticResponse: !skipOptimisticResponse
          ? () => {
              return addTypenameWhereNeeded(content, 'updateContent');
            }
          : undefined, // end optimisticResponse

        update: (proxy, { data: { updateContent } }) => {
          if (options.skipUpdate) {
            return;
          }

          if (updateContent.synced && updateContent.globalExpenseId) {
            // delay 3 seconds to make sure the global expense is updated on the server
            setTimeout(() => {
              appsyncClient.query({
                query: GetJrn,
                variables: { jrnId: updateContent.globalExpenseId },
                fetchPolicy: 'network-only',
              });
            }, 3000);
          }

          if (options.fromWhichAdminTool && options.user) {
            try {
              addUpdateItemAdminContentQuery({
                variables: { contentType: options.fromWhichAdminTool },
                userAddedItem: updateContent,
                proxy,
                userInfoToSet: options.user,
                includeArchive: options.includeArchive,
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(
                `addUpdateItemAdminContentQuery onUpdateContent err: `,
                err
              );
            }
            return;
          }

          const query = GetAllContentByJrnId;
          let data;
          try {
            data = proxy.readQuery({
              query,
              variables: { jrnId: updateContent.jrnId },
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('onUpdateContent GetAllContentByJrnId err: ', err);
          }
          if (data) {
            const existingItemIndex = _.findIndex(
              data.getAllContentByJrnId.items,
              { contentId: updateContent.contentId }
            );
            // merge the changes from the update with the existing item IN the existing list
            if (existingItemIndex > -1) {
              _.assign(
                data.getAllContentByJrnId.items[existingItemIndex],
                updateContent
              );
              proxy.writeQuery({
                query,
                data,
                variables: { jrnId: updateContent.jrnId },
              });
            }
          }

          if (!options.skipUploadCheck) {
            if (updateContent.synced) {
              if (updateContent.contentUrl) {
                if (
                  updateContent.contentUrl.indexOf('file://') > -1 ||
                  options.customFilename
                ) {
                  // then the file is still local and we need to upload it
                  uploadContent(updateContent, options);
                }
              }
            }
          }
        }, // end update
      }); // end mutate
    }, // end onAddContent
  }), // end props
});

export const UpdateContentStatusMutationAction = graphql(
  UpdateContentStatusMutation,
  {
    props: props => ({
      onUpdateContentStatus: (content, options = {}, originalFiles) => {
        return props.mutate({
          variables: content,
          optimisticResponse: () => {
            const toReturn = {
              __typename: 'Mutation',
              updateContentStatus: {
                ...content,
                __typename: 'Content',
                synced: false,
              },
            };
            if (content.completionInfo) {
              toReturn.updateContentStatus.completionInfo = {
                __typename: 'CompletionInfo',
                ...content.completionInfo,
              };
              if (content.completionInfo.mediaInfo) {
                const mediaInfo = content.completionInfo.mediaInfo.map(info => {
                  return {
                    __typename: 'MediaInfo',
                    ...info,
                  };
                });
                toReturn.updateContentStatus.completionInfo.mediaInfo = mediaInfo;
              }
            }
            return toReturn;
          }, // end optimisticResponse
          update: (proxy, returnedData) => {
            const { updateContentStatus } = returnedData.data;
            const query = GetAllContentByJrnId;
            const data = proxy.readQuery({
              query,
              variables: { jrnId: updateContentStatus.jrnId },
            });
            const existingItemIndex = _.findIndex(
              data.getAllContentByJrnId.items,
              { contentId: updateContentStatus.contentId }
            );
            // if somewhow the item isn't found, leave
            if (existingItemIndex === -1) return;
            // merge the changes from the update with the existing item IN the existing list
            _.assign(
              data.getAllContentByJrnId.items[existingItemIndex],
              updateContentStatus
            );
            proxy.writeQuery({
              query,
              data,
              variables: { jrnId: updateContentStatus.jrnId },
            });

            // this means the response is from the server
            if (_.get(returnedData, 'data.updateContentStatus.synced')) {
              if (
                _.get(updateContentStatus, 'completionInfo.mediaInfo[0].uri')
              ) {
                if (
                  updateContentStatus.completionInfo.mediaInfo[0].uri.indexOf(
                    'cloudinary'
                  ) === -1
                ) {
                  // then the file is still local and we need to upload it
                  // eslint-disable-next-line no-console
                  console.log(
                    '-------uploadContentStatusUpdate upload happening-----'
                  );
                  uploadContentStatusUpdate(
                    updateContentStatus,
                    options,
                    originalFiles
                  );
                }
              }
            }
          }, // end update

          // update: (proxy, { data: { onUpdateContentStatus } }) => {
          //   const query = GetAllContentByJrnId;
          //   const data = proxy.readQuery({
          //     query,
          //     variables: { jrnId: content.jrnId },
          //   });
          //   const existingItemIndex = _.findIndex(
          //     data.getAllContentByJrnId.items,
          //     { contentId: content.contentId }
          //   );
          //   // merge the changes from the update with the existing item IN the existing list
          //   if (existingItemIndex > -1) {
          //     _.assign(
          //       data.getAllContentByJrnId.items[existingItemIndex],
          //       content
          //     );
          //     proxy.writeQuery({
          //       query,
          //       data,
          //       variables: { jrnId: content.jrnId },
          //     });
          //   }

          //   if (!options.skipUploadCheck) {
          //     if (content.synced) {
          //       if (content.contentUrl) {
          //         if (
          //           content.contentUrl.indexOf('file://') > -1 ||
          //           options.customFilename
          //         ) {
          //           // then the file is still local and we need to upload it
          //           console.log('content upload ran');
          //           uploadContent(content, options);
          //         }
          //       }
          //     }
          //   }
          // }, // end update
        }); // end mutate
      }, // end onAddContent
    }), // end props
  }
);

export const DeleteContentAction = graphql(DeleteContent, {
  props: props => {
    return {
      onDeleteContent: (contentId, jrnId, options = {}) => {
        const refetchQueries = [
          {
            query: GetUserInfo,
            variables: {
              userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
            },
          },
        ];

        return props.mutate({
          refetchQueries,
          variables: { contentId, jrnId },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteContent: {
              __typename: 'Content',
              contentId,
              jrnId,
              synced: false,
            },
          },
          update: (proxy, { data: { deleteContent } }) => {
            if (
              deleteContent.synced &&
              options.content?.type === CONTENT_TYPE.BILL_PAYMENT &&
              options.content?.globalExpenseId
            ) {
              // delay 3 seconds to make sure the global expense is updated on the server
              setTimeout(() => {
                appsyncClient.query({
                  query: GetJrn,
                  variables: { jrnId: options.content.globalExpenseId },
                  fetchPolicy: 'network-only',
                });
              }, 3000);
            }

            if (options.fromWhichAdminTool) {
              const companyId = getCompanyId();

              if (!companyId) {
                return;
              }

              if (options.fromWhichAdminTool === 'adminTimetrackingData') {
                // Handle timetracking special case
                const query = GetAdminTimetrackingData;
                const queryDataKey = 'getAdminTimetrackingData';
                const variables = {
                  companyId,
                  includeArchive: options.includeArchive,
                };

                removeItemFromQuery({
                  query,
                  queryDataKey,
                  variables,
                  passedItem: deleteContent,
                  proxy,
                });
              } else if (
                options.fromWhichAdminTool ===
                  ADMIN_CONTENT_QUERIES.RECEIPTS_AND_BILLS ||
                options.fromWhichAdminTool === ADMIN_CONTENT_QUERIES.DOCS
              ) {
                // Try to delete from active and archived lists
                const query = ListContentByType;
                const queryDataKey = 'listContentByType';
                const contentTypes =
                  options.fromWhichAdminTool === ADMIN_CONTENT_QUERIES.DOCS
                    ? [CONTENT_TYPE.PDF]
                    : [CONTENT_TYPE.BILL, CONTENT_TYPE.RECEIPT];
                const variables = {
                  companyId,
                  contentTypes,
                };

                removeItemFromQuery({
                  query,
                  queryDataKey,
                  variables,
                  passedItem: deleteContent,
                  proxy,
                });

                removeItemFromQuery({
                  query,
                  queryDataKey,
                  variables: {
                    ...variables,
                    archived: true,
                  },
                  passedItem: deleteContent,
                  proxy,
                });
              } else {
                const query = GetCompanyAdminContent;
                const queryDataKey = 'getCompanyAdminContent';
                const variables = {
                  contentType: options.fromWhichAdminTool,
                  companyId,
                  includeArchive: options.includeArchive,
                };

                removeItemFromQuery({
                  query,
                  queryDataKey,
                  variables,
                  passedItem: deleteContent,
                  proxy,
                });

                return;
              }
            }

            removeItemFromQuery({
              query: GetAllContentByJrnId,
              queryDataKey: 'getAllContentByJrnId',
              variables: { jrnId },
              passedItem: deleteContent,
              keyName: 'contentId',
              proxy,
            });

            if (options.content && options.content.globalExpenseId) {
              removeItemFromQuery({
                query: GetAllContentByGlobalExpenseId,
                queryDataKey: 'getAllContentByGlobalExpenseId',
                variables: { globalExpenseId: options.content.globalExpenseId },
                passedItem: deleteContent,
                keyName: 'contentId',
                proxy,
              });
            }
          },
        });
      },
    };
  },
});

export const MoveOrCopyContentAction = graphql(MoveOrCopyContent, {
  options: {
    // fetchPolicy: 'cache-and-network',
  },
  props: props => {
    return {
      onMoveOrCopyContent: requestInput =>
        props.mutate({
          variables: requestInput,
          update: proxy => {
            if (requestInput.contentAction === 'move') {
              const data = _.cloneDeep(
                proxy.readQuery({
                  query: GetAllContentByJrnId,
                  variables: { jrnId: requestInput.srcJrnId },
                })
              );

              let changed = false;
              const removeMatchingId = value => {
                if (value.contentId === requestInput.contentId) {
                  changed = true;
                  return true;
                }
                return false;
              };

              _.remove(data.getAllContentByJrnId.items, removeMatchingId);

              if (changed) {
                proxy.writeQuery({
                  query: GetAllContentByJrnId,
                  data,
                  variables: { jrnId: requestInput.srcJrnId },
                });
              }
            } // <-- end of update
          },
        }),
    };
  },
});

const updateCompanyList = ({
  proxy,
  companyId,
  projectInfo,
  isRemoved = false,
}) => {
  try {
    // Update list company projects
    const data = _.cloneDeep(
      proxy.readQuery({
        query: ListCompanyProjects,
        variables: {
          companyId,
          first: null,
          after: null,
        },
      })
    );

    if (isRemoved) {
      const removeMatchingId = project =>
        project.contentId === projectInfo.contentId;
      _.remove(data.listCompanyProjects.items, removeMatchingId);
    } else if (
      _.findIndex(data.listCompanyProjects.items, {
        contentId: projectInfo.contentId,
      }) === -1
    ) {
      data.listCompanyProjects.items.push(projectInfo);
    }

    proxy.writeQuery({
      query: ListCompanyProjects,
      data,
      variables: {
        companyId,
        first: null,
        after: null,
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('onArchiveCompanyProject updateCompanyProjects error', err);
  }
};

export const ArchiveCompanyProjectAction = graphql(ArchiveCompanyProject, {
  options: {
    refetchQueries: () => {
      const companyId = getCompanyId();
      return [
        {
          query: ListCompanyArchivedProjects,
          variables: { companyId, first: null, after: null },
        },
        {
          query: ListMyJrns,
          variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
        },
      ];
    },
  },
  props: props => {
    return {
      onArchiveCompanyProject: (projectId, options = {}) =>
        props.mutate({
          variables: { projectId },
          optimisticResponse: {
            archiveCompanyProject: {
              __typename: 'Mutation',
              status: 'success',
              msg: projectId,
            },
          },
          update: (proxy, { data: { archiveCompanyProject } }) => {
            const companyId = getCompanyId();
            if (!companyId) {
              return;
            }

            updateCompanyList({
              proxy,
              projectInfo: { contentId: archiveCompanyProject.msg },
              companyId,
              isRemoved: true,
            });

            if (options.fromWhichAdminTool) {
              try {
                // if includeArchive is false, then we need to remove the project from the list
                if (!options.includeArchive) {
                  const query = GetCompanyAdminProjectData;
                  const queryDataKey = 'getCompanyAdminProjectData';
                  const variables = {
                    companyId,
                    includeArchive: options.includeArchive,
                  };
                  const data = _.cloneDeep(
                    proxy.readQuery({ query, variables })
                  );
                  // remove top projects
                  _.remove(data[queryDataKey].projects, project => {
                    return (
                      project.contentId === archiveCompanyProject.msg || // remove top projects
                      project.jrnId === archiveCompanyProject.msg // remove subprojects
                    );
                  });
                  _.remove(
                    data[queryDataKey].adminData,
                    project => project.projectId === archiveCompanyProject.msg
                  );
                  proxy.writeQuery({ query, data, variables });
                }
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(
                  'onArchiveCompanyProject GetCompanyAdminProjectData error',
                  err
                );
              }
            }

            try {
              const data = _.cloneDeep(
                proxy.readQuery({
                  query: ListMyJrns,
                  variables: {
                    userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                  },
                })
              );
              const removeMatchingId = project =>
                project.contentId === archiveCompanyProject.msg;
              _.remove(data.listMyJrns.items, removeMatchingId);
              proxy.writeQuery({
                query: ListMyJrns,
                data,
                variables: {
                  userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                },
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('onArchiveCompanyProject ListMyJrns error', err);
            }
          },
        }),
    };
  },
});

export const UnarchiveCompanyProjectAction = graphql(UnarchiveCompanyProject, {
  options: {
    refetchQueries: () => {
      const companyId = getCompanyId();
      return [
        {
          query: ListCompanyProjects,
          variables: { companyId, first: null, after: null },
        },
        {
          query: ListMyJrns,
          variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
        },
      ];
    },
  },
  props: props => {
    return {
      onUnarchiveCompanyProject: (projectId, options = {}) =>
        props.mutate({
          variables: { projectId },
          optimisticResponse: {
            unarchiveCompanyProject: {
              __typename: 'Mutation',
              status: 'success',
              msg: projectId,
            },
          },
          update: (proxy, { data: { unarchiveCompanyProject } }) => {
            if (options.fromWhichAdminTool) {
              const { projectInfo } = options;

              updateCompanyList({
                proxy,
                projectInfo,
                companyId: getCompanyId(),
                isRemoved: false,
              });
              // No need to update company projects
              // we refetch the list of projects in the admin tool
              return;
            }

            const data = _.cloneDeep(
              proxy.readQuery({
                query: ListCompanyArchivedProjects,
                variables: {
                  companyId: getCompanyId(),
                  first: null,
                  after: null,
                },
              })
            );
            const removeMatchingId = project =>
              project.contentId === unarchiveCompanyProject.msg;
            _.remove(data.listCompanyArchivedProjects.items, removeMatchingId);

            proxy.writeQuery({
              query: ListCompanyArchivedProjects,
              data,
              variables: {
                companyId: getCompanyId(),
                first: null,
                after: null,
              },
            });
          },
        }),
    };
  },
});

export const DeleteJrnAction = graphql(DeleteJrn, {
  props: props => {
    return {
      onDeleteJrn: (jrnId, type, fromAdminTool, customerId, includeArchive) => {
        const refetchQueries = [];
        if (!GLOBAL_FINANCIAL_TYPES.includes(type)) {
          refetchQueries.push(
            ...[
              {
                query: ListMyJrns,
                variables: {
                  userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                },
              },
              {
                query: ListMyConversations,
                variables: {
                  userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                },
              },
            ]
          );
        }
        return props.mutate({
          refetchQueries,
          variables: { jrnId },
          optimisticResponse: {
            deleteJrn: { contentId: jrnId, __typename: 'Mutation' },
          },
          update: (proxy, { data: { deleteJrn } }) => {
            const queriesToUpdate = [];

            const companyId = getCompanyId();

            switch (type) {
              case CONTENT_TYPE.LEAD: {
                queriesToUpdate.push({
                  query: GetCompanyLeads,
                  queryDataKey: 'getCompanyLeads',
                  variables: { companyId },
                });
                queriesToUpdate.push({
                  query: GetCustomerLeads,
                  queryDataKey: 'getCustomerLeads',
                  variables: { companyId, customerId },
                });
                break;
              }
              case CONTENT_TYPE.CONVERSATION: {
                queriesToUpdate.push({
                  query: ListMyConversations,
                  queryDataKey: 'listMyConversations',
                  variables: {
                    userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                  },
                });
                break;
              }
              case CONTENT_TYPE.TEMPLATE: {
                if (companyId) {
                  queriesToUpdate.push({
                    query: ListCompanyTemplates,
                    queryDataKey: 'listCompanyTemplates',
                    variables: { companyId, first: null, after: null },
                  });
                }
                break;
              }
              case CONTENT_TYPE.GLOBAL_PAYMENT: {
                queriesToUpdate.push({
                  query: ListCompanyGlobalFinancialItems,
                  queryDataKey: 'listCompanyGlobalFinancialItems',
                  variables: {
                    companyId: getCompanyId(),
                    typesToInclude: ['PAYMENT'],
                  },
                });
                break;
              }
              case CONTENT_TYPE.GLOBAL_BILL: {
                queriesToUpdate.push({
                  query: ListCompanyGlobalFinancialItems,
                  queryDataKey: 'listCompanyGlobalFinancialItems',
                  variables: {
                    companyId: getCompanyId(),
                    typesToInclude: ['BILL'],
                  },
                });
                break;
              }
              case CONTENT_TYPE.GLOBAL_RECEIPT: {
                break;
              }
              default: {
                // Project case
                queriesToUpdate.push({
                  query: ListMyJrns,
                  queryDataKey: 'listMyJrns',
                  variables: {
                    userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                  },
                });

                if (companyId) {
                  // if it came from the admin tool, we need to update the GetCompanyAdminProjectDataAction query cache
                  if (fromAdminTool) {
                    const query = GetCompanyAdminProjectData;
                    const queryDataKey = 'getCompanyAdminProjectData';
                    const variables = { companyId, includeArchive };
                    const data = _.cloneDeep(
                      proxy.readQuery({ query, variables })
                    );
                    _.remove(
                      data[queryDataKey].projects,
                      project => project.contentId === deleteJrn.contentId
                    );
                    _.remove(
                      data[queryDataKey].adminData,
                      project => project.projectId === deleteJrn.contentId
                    );
                    proxy.writeQuery({ query, data, variables });
                  } else {
                    queriesToUpdate.push({
                      query: ListCompanyProjects,
                      queryDataKey: 'listCompanyProjects',
                      variables: {
                        companyId,
                        first: null,
                        after: null,
                      },
                    });
                    queriesToUpdate.push({
                      query: ListCompanyArchivedProjects,
                      queryDataKey: 'listCompanyArchivedProjects',
                      variables: {
                        companyId,
                        first: null,
                        after: null,
                      },
                    });
                  }
                }
                break;
              }
            }

            queriesToUpdate.forEach(({ query, queryDataKey, variables }) => {
              try {
                const data = _.cloneDeep(
                  proxy.readQuery({
                    query,
                    variables,
                  })
                );

                const removeMatchingId = jrn =>
                  jrn.contentId === deleteJrn.contentId;
                _.remove(data[queryDataKey].items, removeMatchingId);

                proxy.writeQuery({
                  query,
                  data,
                  variables,
                });
              } catch (err) {
                // Likely not initialized
              }
            });
          },
        });
      },
    };
  },
});

const updateCacheOnProjectCreation = (proxy, newProject, uploadOnly) => {
  if (!uploadOnly) {
    if (newProject.jrnId && newProject.jrnId !== TOP_PROJECT_ID) {
      // this means it's a subproject and should be treated like content
      try {
        addItemToQuery({
          query: GetAllContentByJrnId,
          queryDataKey: 'getAllContentByJrnId',
          variables: { jrnId: newProject.jrnId },
          userAddedItem: newProject,
          proxy,
          typename: 'ContentConnection',
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`CreateJrn GetAllContentByJrnId err: `, err);
      }
    } else {
      const queriesToRun = [];
      if (newProject.type === 'conversation') {
        queriesToRun.push({
          query: ListMyConversations,
          queryDataKey: 'listMyConversations',
          variables: {
            userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
          },
          userAddedItem: newProject,
        });
      } else if (newProject.type === 'project') {
        queriesToRun.push({
          query: ListMyJrns,
          queryDataKey: 'listMyJrns',
          variables: {
            userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
          },
          userAddedItem: newProject,
        });
        queriesToRun.push({
          query: ListCompanyProjects,
          queryDataKey: 'listCompanyProjects',
          variables: { companyId: getCompanyId(), first: null, after: null },
          userAddedItem: newProject,
        });
      }

      _.each(queriesToRun, queryParams => {
        const { query, queryDataKey, variables, userAddedItem } = queryParams;
        try {
          addItemToQuery({
            query,
            queryDataKey,
            variables,
            userAddedItem,
            proxy,
            typename: 'JrnConnection',
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(`CreateJrn ${query} err: `, err);
        }
      });
    }
  }

  // do upload of cover image
  if (newProject.synced) {
    if (newProject.contentUrl) {
      if (newProject.contentUrl.indexOf('file://') > -1) {
        // then the file is still local and we need to upload it
        const options = {};
        // options.customFolder = createJrn.jrnId && createJrn.jrnId !== TOP_PROJECT_ID ? createJrn.jrnId : createJrn.contentId;
        options.customFolder =
          newProject.type === 'project'
            ? newProject.contentId
            : newProject.jrnId;

        uploadCoverPhoto(newProject, options);
      }
    }
  }
};

export const AddJrnMutationAction = graphql(AddJrnMutation, {
  props: props => ({
    onAddJrn: (jrn, options = {}) => {
      let refetchQueries = [];
      if (
        jrn.type !== CONTENT_TYPE.GLOBAL_BILL &&
        jrn.type !== CONTENT_TYPE.GLOBAL_RECEIPT &&
        jrn.type !== CONTENT_TYPE.GLOBAL_PAYMENT
      ) {
        refetchQueries = [
          {
            query: ListMyJrns,
            variables: {
              userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
            },
          },
          {
            query: ListMyConversations,
            variables: {
              userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
            },
          },
          {
            query: GetBudgetInfo,
            variables: { jrnId: jrn.contentId },
          },
        ];
      }
      return props.mutate({
        variables: jrn,
        refetchQueries,
        options: {
          errorPolicy: 'ignore',
          awaitRefetchQueries: true,
        },
        optimisticResponse: () => {
          const optimisticJrn = _.cloneDeep(jrn);

          // Remove fields not on Jrn type
          delete optimisticJrn.fromLeadId;

          // Add fields expected by Jrn type
          const attributeDefaults = {
            lastMessageSent: new Date().getTime(),
            amount: null,
            qboCustomerId: null,
            customerInfo: null,
            phoneNumber: null,
            phoneNumberActive: null,
            timetrackingAnswers: null,
            timetrackingQuestions: null,
            timetrackingQuestionsCheckout: null,
            useTimetracking: null,
            useTimetrackingQuestions: null,
            includeSubsInStats: null,
            excludeFromStats: null,
            comments: { items: [] },
            loves: { items: [] },
            recurrence: null,
            budgets: null,
            stats: null,
            workflowStage: null,
            workflowStageReason: null,
            allowedToAdd: [],
            allowedToEdit: [],
            allowedToView: [],
            usersEverOnJrn: [
              ...(jrn.allowedToEdit || []),
              ...(jrn.allowedToView || []),
              ...(jrn.allowedToAdd || []),
            ],
            balance: null,
            rfiIds: null,
          };

          const defaultedAndTypenamedContent = addTypenameWhereNeeded(
            { ...attributeDefaults, ...optimisticJrn },
            'createJrn',
            'Jrn'
          );

          return defaultedAndTypenamedContent;
        },
        update: (proxy, { data: { createJrn } }) => {
          if (options.fromWhichAdminTool) {
            if (
              options.fromWhichAdminTool === 'createProjectFromCustomerLeads'
            ) {
              try {
                const params = {
                  query: GetCustomerProjects,
                  queryDataKey: 'getCustomerProjects',
                  variables: {
                    companyId: getCompanyId(),
                    customerId: createJrn.customerId,
                  },
                  userAddedItem: createJrn,
                  proxy,
                  typename: 'JrnConnection',
                };
                if (createJrn.type === 'lead') {
                  params.userAddedItem = {
                    contentId: jrn.fromLeadId,
                    jrnId: createJrn.contentId,
                  };
                }
                addItemToQuery(params);
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(`CreateJrn GetCustomerProjects err: `, err);
              }
            } else if (
              options.fromWhichAdminTool === 'createProjectFromManageLeads'
            ) {
              try {
                addItemToQuery({
                  query: GetCompanyLeads,
                  queryDataKey: 'getCompanyLeads',
                  variables: {
                    companyId: getCompanyId(),
                  },
                  userAddedItem: {
                    contentId: jrn.fromLeadId,
                    jrnId: createJrn.contentId,
                  },
                  proxy,
                  typename: 'JrnConnection',
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(`CreateJrn GetCompanyLeads err: `, err);
              }
            } else if (
              options.fromWhichAdminTool === 'createLeadFromCustomerLeads'
            ) {
              try {
                addItemToQuery({
                  query: GetCustomerLeads,
                  queryDataKey: 'getCustomerLeads',
                  variables: {
                    companyId: getCompanyId(),
                    customerId: createJrn.customerId,
                  },
                  userAddedItem: createJrn,
                  proxy,
                  typename: 'JrnConnection',
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(`CreateJrn createLeadFromCustomerLeads err: `, err);
              }
            } else {
              if (
                createJrn.type === CONTENT_TYPE.GLOBAL_BILL ||
                createJrn.type === CONTENT_TYPE.GLOBAL_RECEIPT ||
                createJrn.type === CONTENT_TYPE.GLOBAL_PAYMENT
              ) {
                let vendor;

                try {
                  const data = _.clone(
                    proxy.readQuery({
                      query: GetRelationById,
                      variables: {
                        relationId: createJrn.vendorId,
                      },
                    })
                  );

                  vendor = _.get(data, 'getRelationById', null);
                } catch (err) {
                  // ignore
                }

                if (!vendor) {
                  let allVendors = [];

                  // try to get all vendors from getRelationsByType cache
                  try {
                    const data = _.clone(
                      proxy.readQuery({
                        query: GetRelationsByType,
                        variables: {
                          companyId: getCompanyId(),
                          type: 'VENDOR',
                        },
                      })
                    );

                    allVendors = _.get(data, 'getRelationsByType.items', []);
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log('CreateJrn ~ getRelationsByType err: ', err);
                  }

                  // find the vendor info
                  vendor = _.find(allVendors, {
                    relationId: createJrn.vendorId,
                  });
                }

                const userAddedItem = {
                  ...createJrn,
                  dateCreated: new Date().toISOString(),
                };

                if (vendor) {
                  userAddedItem.vendor = { ...vendor, __typename: 'Relation' };
                }

                if (
                  options.fromWhichAdminTool ===
                    ADMIN_CONTENT_QUERIES.RECEIPTS_AND_BILLS &&
                  (createJrn.type === CONTENT_TYPE.GLOBAL_BILL ||
                    createJrn.type === CONTENT_TYPE.GLOBAL_RECEIPT)
                ) {
                  addItemToQuery({
                    query: ListCompanyGlobalFinancialItems,
                    queryDataKey: 'listCompanyGlobalFinancialItems',
                    variables: {
                      companyId: getCompanyId(),
                      typesToInclude: ['RECEIPT', 'BILL'],
                    },
                    userAddedItem,
                    proxy,
                    typename: 'JrnConnection',
                  });
                }

                if (
                  options.fromWhichAdminTool === CONTENT_TYPE.GLOBAL_BILL &&
                  createJrn.type === CONTENT_TYPE.GLOBAL_BILL
                ) {
                  addItemToQuery({
                    query: ListCompanyGlobalFinancialItems,
                    queryDataKey: 'listCompanyGlobalFinancialItems',
                    variables: {
                      companyId: getCompanyId(),
                      typesToInclude: ['BILL'],
                    },
                    userAddedItem,
                    proxy,
                    typename: 'JrnConnection',
                  });
                }

                if (
                  options.fromWhichAdminTool === CONTENT_TYPE.GLOBAL_PAYMENT &&
                  createJrn.type === CONTENT_TYPE.GLOBAL_PAYMENT
                ) {
                  addItemToQuery({
                    query: ListCompanyGlobalFinancialItems,
                    queryDataKey: 'listCompanyGlobalFinancialItems',
                    variables: {
                      companyId: getCompanyId(),
                      typesToInclude: ['PAYMENT'],
                    },
                    userAddedItem,
                    proxy,
                    typename: 'JrnConnection',
                  });
                }

                return;
              }

              addProjectToCompanyProjectAdminData(
                proxy,
                createJrn,
                options.includeArchive
              );
            }
            return;
          }

          if (createJrn.type === 'lead') {
            try {
              addItemToQuery({
                query: GetCustomerLeads,
                queryDataKey: 'getCustomerLeads',
                variables: {
                  companyId: getCompanyId(),
                  customerId: createJrn.customerId,
                },
                userAddedItem: createJrn,
                proxy,
                typename: 'JrnConnection',
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(`CreateJrn GetCustomerLeads err: `, err);
            }
            try {
              addItemToQuery({
                query: GetCompanyLeads,
                queryDataKey: 'getCompanyLeads',
                variables: {
                  companyId: getCompanyId(),
                },
                userAddedItem: createJrn,
                proxy,
                typename: 'JrnConnection',
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(`CreateJrn GetCompanyLeads err: `, err);
            }
            return;
          }

          const uploadOnly = [
            CONTENT_TYPE.LEAD,
            CONTENT_TYPE.GLOBAL_BILL,
            CONTENT_TYPE.GLOBAL_RECEIPT,
            CONTENT_TYPE.GLOBAL_PAYMENT,
          ].includes(createJrn.type);

          updateCacheOnProjectCreation(proxy, createJrn, uploadOnly);
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const LeaveJrnMutationAction = graphql(LeaveJrnMutation, {
  options: {
    // fetchPolicy: 'cache-and-network',
    refetchQueries: [
      {
        query: ListMyJrns,
        variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
      },
    ],
  },
  props: props => {
    // console.log('LeaveJrnMutation -> props: ', props);
    return {
      onLeaveJrn: (jrnId, type) =>
        props.mutate({
          variables: { jrnId },
          optimisticResponse: {
            leaveJrn: { jrnId, __typename: 'Mutation' },
          },
          update: (proxy, { data: { leaveJrn } }) => {
            let query = ListMyJrns;
            let queryDataKey = 'listMyJrns';
            if (type === 'conversation') {
              query = ListMyConversations;
              queryDataKey = 'listMyConversations';
            }
            const data = _.cloneDeep(
              proxy.readQuery({
                query,
                variables: {
                  userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
                },
              })
            );
            const removeMatchingId = jrn => jrn.contentId === leaveJrn.jrnId;
            _.remove(data[queryDataKey].items, removeMatchingId);
            proxy.writeQuery({
              query,
              data,
              variables: {
                userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
              },
            });
          },
        }),
    };
  },
});

export const UpdateJrnMutationAction = graphql(UpdateJrnMutation, {
  props: props => ({
    onUpdateJrn: (jrn, options = {}) => {
      const refetchQueries = [
        {
          query: GetUsersByJrnId,
          variables: { jrnId: jrn.contentId },
        },
        {
          query: GetBudgetInfo,
          variables: { jrnId: jrn.contentId },
        },
      ];

      if (options.refetchParentBudgetInfo && jrn.jrnId !== TOP_PROJECT_ID) {
        refetchQueries.push({
          query: GetBudgetInfo,
          variables: { jrnId: jrn.jrnId },
        });
      }

      return props.mutate({
        variables: jrn,
        refetchQueries,
        // TODO: Repair optimistic
        // optimisticResponse: () => {
        //   const optimisticJrn = _.cloneDeep(jrn);

        //   // Add fields expected by Jrn type
        //   let newUsersEverOnJrn = [];
        //   if (jrn.usersEverOnJrn) {
        //     newUsersEverOnJrn = jrn.usersEverOnJrn;
        //   }
        //   if (jrn.permissionsChangesAdd) {
        //     newUsersEverOnJrn = newUsersEverOnJrn.concat(
        //       jrn.permissionsChangesAdd
        //     );
        //   }

        //   const attributeDefaults = {
        //     lastMessageSent: new Date().getTime(),
        //     usersEverOnJrn: newUsersEverOnJrn,
        //     amount: null,
        //     qboCustomerId: null,
        //     timetrackingQuestions: null,
        //     useTimetracking: null,
        //     useTimetrackingQuestions: null,
        //     includeSubsInStats: null,
        //     excludeFromStats: null,
        //     customerInfo: options.fullCustomerDetails || null,
        //   };

        //   return addTypenameWhereNeeded(
        //     { ...attributeDefaults, ...optimisticJrn },
        //     'updateJrn',
        //     'Jrn'
        //   );
        // }, // end optimisticResponse
        update: (proxy, { data: { updateJrn } }) => {
          if (!updateJrn || !updateJrn.contentId) {
            return;
          }

          const currentUserId = getCurrentUserId();

          // Only required to update queries where it is either added or removed from a list
          // (eg. permissions changes)
          if (_.includes(jrn.permissionsChangesAdd, currentUserId)) {
            // Add to my jrns
            addItemToQuery({
              query: ListMyJrns,
              queryDataKey: 'listMyJrns',
              variables: {
                userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
              },
              userAddedItem: updateJrn,
              proxy,
              typename: 'JrnConnection',
            });
          } else if (_.includes(jrn.permissionsChangesRemove, currentUserId)) {
            removeItemFromQuery({
              query: ListMyJrns,
              queryDataKey: 'listMyJrns',
              variables: {
                userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
              },
              keyToRemove: { contentId: jrn.contentId },
              proxy,
            });
          }
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const UpdateUserMutationAction = graphql(UpdateUserMutation, {
  options: {
    // fetchPolicy: 'cache-and-network',
  },
  props: props => ({
    onUpdateUser: user => {
      return props.mutate({
        variables: user,
        update: (proxy, { data: { updateUser } }) => {
          if (!updateUser) return;

          updateGetUserInfoCache({ proxy, updatedUserInfo: updateUser });
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const UpdateUserAdminMutationAction = graphql(UpdateUserAdminMutation, {
  skip: () => !getCompanyId(),
  props: props => ({
    onUpdateUserAdmin: user => {
      return props.mutate({
        variables: { ...user, companyId: getCompanyId() },
        optimisticResponse: () => {
          return {
            __typename: 'Mutation',
            addToCrewList: {
              __typename: 'User',
              ...user,
            },
          };
        },
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const UpdateUnreadCountMutationAction = graphql(
  UpdateUnreadCountMutation,
  {
    options: {
      // fetchPolicy: 'cache-and-network',
      variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
    },
    props: props => ({
      onUpdateUnreadCount: unreadCountUpdate => {
        return props.mutate({
          variables: unreadCountUpdate,
        }); // end mutate
      }, // end onAdd
    }), // end props
  }
);

export const SaveProjectAsTemplateAction = graphql(SaveProjectAsTemplate, {
  props: props => ({
    onSaveProjectAsTemplate: ({
      contentTypesToInclude,
      projectId,
      startDate,
      templateName,
    }) => {
      return props.mutate({
        variables: {
          contentTypesToInclude,
          projectId,
          startDate,
          templateName,
        },
        update: (proxy, { data: { createTemplateFromProject: template } }) => {
          let data;
          if (template.managingCompanyId) {
            try {
              // Load from cache
              data = {
                ...proxy.readQuery({
                  query: ListCompanyTemplates,
                  variables: {
                    companyId: template.managingCompanyId,
                    first: null,
                    after: null,
                  },
                }),
              };

              // Check if already in data
              const indexOfTemplate = _.findIndex(
                data.listCompanyTemplates.items,
                ({ contentId: existingContentId }) =>
                  existingContentId === template.contentId
              );

              // If not in list, push to list
              if (indexOfTemplate === -1) {
                data.listCompanyTemplates.items.push(template);
              }

              // Update cache
              proxy.writeQuery({
                query: ListCompanyTemplates,
                data,
                variables: {
                  companyId: template.managingCompanyId,
                  first: null,
                  after: null,
                },
              });
            } catch (e) {
              // Query has not run
            }
          }
        },
      });
    },
  }),
});

export const CreateProjectFromTemplateAction = graphql(
  CreateProjectFromTemplate,
  {
    props: props => ({
      onCreateProjectFromTemplate: ({
        templateId,
        projectInfo,
        startDate,
        fromLeadId,
        options = {},
      }) => {
        return props.mutate({
          variables: {
            templateId,
            projectInfo,
            startDate,
            fromLeadId,
          },
          update: (proxy, { data: { createProjectFromTemplate } }) => {
            if (options.fromWhichAdminTool) {
              if (
                options.fromWhichAdminTool === 'createProjectFromManageLeads'
              ) {
                try {
                  addItemToQuery({
                    query: GetCompanyLeads,
                    queryDataKey: 'getCompanyLeads',
                    variables: {
                      companyId: getCompanyId(),
                    },
                    userAddedItem: {
                      contentId: fromLeadId,
                      jrnId: createProjectFromTemplate.contentId,
                    },
                    proxy,
                    typename: 'JrnConnection',
                  });
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.log(`CreateJrn GetCompanyLeads err: `, err);
                }
                return null;
              }

              if (
                options.fromWhichAdminTool === 'createProjectFromCustomerLeads'
              ) {
                // this just updates the existing lead's jrnId
                try {
                  addItemToQuery({
                    query: GetCustomerLeads,
                    queryDataKey: 'getCustomerLeads',
                    variables: {
                      companyId: getCompanyId(),
                      customerId: createProjectFromTemplate.customerId,
                    },
                    userAddedItem: {
                      contentId: fromLeadId,
                      jrnId: createProjectFromTemplate.contentId,
                    },
                    proxy,
                    typename: 'JrnConnection',
                  });
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.log(`CreateJrn GetCustomerLeads err: `, err);
                }
                // this adds the project to the customer's list of projects
                try {
                  addItemToQuery({
                    query: GetCustomerProjects,
                    queryDataKey: 'getCustomerProjects',
                    variables: {
                      companyId: getCompanyId(),
                      customerId: createProjectFromTemplate.customerId,
                    },
                    userAddedItem: createProjectFromTemplate,
                    proxy,
                    typename: 'JrnConnection',
                  });
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.log(`CreateJrn GetCustomerProjects err: `, err);
                }

                return null;
              }
            }

            if (options.fromWhichAdminTool) {
              addProjectToCompanyProjectAdminData(
                proxy,
                createProjectFromTemplate,
                options.includeArchive
              );
              return null;
            }

            return updateCacheOnProjectCreation(
              proxy,
              createProjectFromTemplate
            );
          },
        });
      },
    }),
  }
);

export const GetStripeProductInfoAction = graphql(GetStripeProductInfo, {
  options: {
    fetchPolicy: 'network-only',
  },
  props: props => {
    return {
      stripeProductInfoRefetch: props.data.refetch,
      stripeProductInfoLoading: props.data.loading,
      stripeProductInfo: props.data.getStripeProductInfo,
    };
  },
});

export const GenerateNewCustomerOrderAction = graphql(
  GenerateNewCustomerOrderMutation,
  {
    props: props => ({
      generateNewCustomerOrder: ({
        companyName,
        email,
        price,
        count,
        taxRate,
        coupon,
      }) =>
        props.mutate({
          variables: { companyName, email, price, count, taxRate, coupon },
        }),
    }),
  }
);

export const AddOrUpdateCompanyAction = graphql(AddOrUpdateCompany, {
  props: props => ({
    onAddOrUpdateCompany: (
      {
        companyId = getCompanyId(),
        companyName,
        companyLogo = null,
        invitationId = null,
        uncategorizedTxnAccounts = null,
        bookkeepingAlertEmailLists = null,
        rfiRecipients = null,
        userRole = null,
        products = null,
        address = null,
        industry = null,
      },
      options
    ) =>
      props.mutate({
        variables: {
          companyId,
          companyName,
          companyLogo,
          invitationId,
          uncategorizedTxnAccounts,
          bookkeepingAlertEmailLists,
          rfiRecipients,
          userRole,
          products,
          address,
          industry,
        },
        update: proxy => {
          if (options?.updatedUserInfo) {
            updateGetUserInfoCache({
              proxy,
              updatedUserInfo: options.updatedUserInfo,
            });
          }
        },
      }),
  }),
  options: {
    refetchQueries: [
      {
        query: GetUserInfo,
        variables: { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER },
      },
    ],
  },
});

export const GetCompanyInfoAction = graphql(GetCompanyInfo, {
  options: () => {
    return {
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyInfoRefetch: props.data.refetch,
      getCompanyInfoLoading: props.data.loading,
      getCompanyInfoError: props.data.error,
      getCompanyInfo: props.data.getCompanyInfo
        ? props.data.getCompanyInfo.items
        : null,
    };
  },
});

export const GetCompanyCrewAction = graphql(GetCompanyCrew, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyCrewRefetch: props.data.refetch,
      getCompanyCrewLoading: props.data.loading,
      getCompanyCrewError: props.data.error,
      companyCrew: props.data.getCompanyCrew
        ? props.data.getCompanyCrew.items
        : null,
    };
  },
});

export const GetCompanyCrewAdminAction = graphql(GetCompanyCrewAdmin, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyCrewAdminRefetch: props.data.refetch,
      getCompanyCrewAdminLoading: props.data.loading,
      getCompanyCrewAdminError: props.data.error,
      companyCrew: props.data.getCompanyCrewAdmin
        ? props.data.getCompanyCrewAdmin.items
        : null,
    };
  },
});

export const SendCrewMemberInvitationAction = graphql(
  SendCrewMemberInvitation,
  {
    props: props => ({
      onSendCrewMemberInvitation: ({ email, role }) =>
        props.mutate({
          variables: { companyId: getCompanyId(), email, role },
        }),
    }),
    options: {
      update: (proxy, { data: { sendCrewMemberInvitation } }) => {
        const query = GetCompanyCrewMemberInvitations;

        const { companyId } = sendCrewMemberInvitation;

        const data = proxy.readQuery({
          query,
          variables: { companyId },
        });

        const updatedData = _.cloneDeep(data);
        updatedData.getCompanyCrewMemberInvitations.items.push(
          sendCrewMemberInvitation
        );

        proxy.writeQuery({
          query,
          data: updatedData,
          variables: { companyId },
        });
      },
    },
  }
);

export const GetCompanyCrewMemberInvitationsAction = graphql(
  GetCompanyCrewMemberInvitations,
  {
    skip: () => !getCompanyId(),
    options: () => {
      return {
        variables: { companyId: getCompanyId() },
        fetchPolicy: 'cache-and-network',
      };
    },
    props: props => {
      return {
        getCompanyCrewMemberInvitationsRefetch: props.data.refetch,
        getCompanyCrewMemberInvitationsLoading: props.data.loading,
        getCompanyCrewMemberInvitationsError: props.data.error,
        getCompanyCrewMemberInvitations:
          props.data.getCompanyCrewMemberInvitations,
      };
    },
  }
);

export const DeleteCrewMemberInvitationAction = graphql(
  DeleteCrewMemberInvitation,
  {
    props: props => ({
      onDeleteCrewMemberInvitation: ({ invitationId }) =>
        props.mutate({
          variables: { invitationId },
        }),
    }),
  }
);

const updateCompanyInCache = (proxy, newCompanyObject) => {
  const query = GetCompanyInfo;

  const data = proxy.readQuery({
    query,
  });

  if (data.getCompanyInfo && data.getCompanyInfo.items) {
    const updatedData = _.cloneDeep(data);

    const indexToUpdate = _.findIndex(
      updatedData.getCompanyInfo.items,
      ({ companyId: cachedCompanyId }) =>
        cachedCompanyId === newCompanyObject.companyId
    );

    if (indexToUpdate !== -1) {
      updatedData.getCompanyInfo.items[indexToUpdate] = newCompanyObject;

      proxy.writeQuery({
        query,
        data: updatedData,
      });
    }
  }
};

const updateUserTypeInGetCompanyCrewAdminCache = ({ proxy, userId, type }) => {
  const query = GetCompanyCrewAdmin;
  const variables = { companyId: getCompanyId() };
  let data;
  try {
    data = _.cloneDeep(proxy.readQuery({ query, variables }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('updateUserTypeInGetCompanyCrewAdminCache err: ', err);
  }

  if (data) {
    const indexToUpdate = _.findIndex(
      data.getCompanyCrewAdmin?.items,
      ({ userId: cachedUserId }) => cachedUserId === userId
    );

    if (
      indexToUpdate >= 0 &&
      data.getCompanyCrewAdmin.items[indexToUpdate].type !== type
    ) {
      data.getCompanyCrewAdmin.items[indexToUpdate].type = type;

      proxy.writeQuery({ query, data, variables });
    }
  }
};

const updateUserTypeInGetUserInfoCache = ({ proxy, userId, type }) => {
  const query = GetUserInfo;
  const variables = { userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER };
  let data;
  try {
    data = _.cloneDeep(proxy.readQuery({ query, variables }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('updateUserTypeInGetUserInfoCache err: ', err);
  }

  if (data?.getMyUserInfo?.userId === userId) {
    if (data.getMyUserInfo.type !== type) {
      data.getMyUserInfo.type = type;

      proxy.writeQuery({ query, data, variables });
    }
  }
};

export const RemoveCompanyCrewMemberAction = graphql(RemoveCompanyCrewMember, {
  props: props => ({
    onRemoveCompanyCrewMember: ({ userId }) =>
      props.mutate({
        variables: { companyId: getCompanyId(), userId },
      }),
  }),
  options: {
    update: (proxy, { data: { removeCompanyCrewMember } }) =>
      updateCompanyInCache(proxy, removeCompanyCrewMember),
  },
});

export const AddCompanyCrewMemberAction = graphql(AddCompanyCrewMember, {
  props: props => ({
    onAddCompanyCrewMember: ({ userId }) =>
      props.mutate({
        variables: { companyId: getCompanyId(), userId },
      }),
  }),
  options: {
    update: (proxy, { data: { addCompanyCrewMember } }) =>
      updateCompanyInCache(proxy, addCompanyCrewMember),
  },
});

export const MakeCompanyAdminAction = graphql(MakeCompanyAdmin, {
  props: props => ({
    onMakeCompanyAdmin: ({ userId, role = null }) =>
      props.mutate({
        variables: { companyId: getCompanyId(), userId, role },
        update: (proxy, { data: { makeCompanyAdmin } }) => {
          updateCompanyInCache(proxy, makeCompanyAdmin);

          if (role === LEVEL_ROLE.BOOKKEEPER) {
            updateUserTypeInGetCompanyCrewAdminCache({
              proxy,
              userId,
              type: 'bookkeeper',
            });

            updateUserTypeInGetUserInfoCache({
              proxy,
              userId,
              type: 'bookkeeper',
            });
          } else if (role === null) {
            updateUserTypeInGetCompanyCrewAdminCache({
              proxy,
              userId,
              type: 'crew',
            });

            updateUserTypeInGetUserInfoCache({ proxy, userId, type: 'crew' });
          }
        },
      }),
  }),
});

export const TransferCompanyAdminAction = graphql(TransferCompanyAdmin, {
  props: props => ({
    onTransferCompanyAdmin: ({ fromUserId, toUserId = null, role = null }) =>
      props.mutate({
        variables: { companyId: getCompanyId(), fromUserId, toUserId, role },
      }),
  }),
  options: {
    update: (proxy, { data: { transferCompanyAdmin } }) =>
      updateCompanyInCache(proxy, transferCompanyAdmin),
  },
});

export const ListCompanyProjectsAction = graphql(ListCompanyProjects, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId(), first: null, after: null },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      listCompanyProjectsLoading: props.data.loading,
      listCompanyProjectsRefetch: props.data.refetch,
      companyProjects: props.data.listCompanyProjects
        ? props.data.listCompanyProjects.items
        : [],
      listCompanyProjectsError: props.data.error,
    };
  },
});

export const ListCompanyTemplatesAction = graphql(ListCompanyTemplates, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId(), first: null, after: null },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      listCompanyTemplatesLoading: props.data.loading,
      listCompanyTemplatesRefetch: props.data.refetch,
      companyTemplates: props.data.listCompanyTemplates
        ? props.data.listCompanyTemplates.items
        : [],
      listCompanyTemplatesError: props.data.error,
    };
  },
});

export const ListCompanyArchivedProjectsAction = graphql(
  ListCompanyArchivedProjects,
  {
    skip: () => !getCompanyId(),
    options: () => {
      return {
        variables: { companyId: getCompanyId(), first: null, after: null },
        fetchPolicy: 'cache-and-network',
      };
    },
    props: props => {
      return {
        listCompanyArchivedProjectsLoading: props.data.loading,
        listCompanyArchivedProjectsRefetch: props.data.refetch,
        listCompanyArchivedProjectsError: props.data.error,
        companyArchivedProjects: props.data.listCompanyArchivedProjects
          ? props.data.listCompanyArchivedProjects.items
          : [],
      };
    },
  }
);

export const AddOrUpdateCompanyQuestionsAction = graphql(
  AddOrUpdateCompanyQuestions,
  {
    options: {
      errorPolicy: 'ignore',
    },
    props: props => {
      return {
        onAddOrUpdateCompanyQuestions: input => {
          return props.mutate({
            variables: input,
          });
        },
      };
    },
  }
);

export const ArchiveOrRestoreCompanyQuestionAction = graphql(
  ArchiveOrRestoreCompanyQuestion,
  {
    options: {
      errorPolicy: 'ignore',
    },
    props: props => {
      return {
        archiveOrRestoreCompanyQuestion: input => {
          return props.mutate({
            variables: input,
          });
        },
      };
    },
  }
);

export const GetCompanyQuestionsAction = graphql(GetCompanyQuestions, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyQuestionsLoading: props.data.loading,
      getCompanyQuestionsRefetch: props.data.refetch,
      companyQuestions: props.data.getCompanyQuestions
        ? props.data.getCompanyQuestions.items
        : [],
      getCompanyQuestionsError: props.data.error,
    };
  },
});

export const GetCompanyCustomersAction = graphql(GetCompanyCustomers, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyCustomersLoading: props.data.loading,
      getCompanyCustomersRefetch: props.data.refetch,
      companyCustomers: props.data.getCompanyCustomers
        ? props.data.getCompanyCustomers.items
        : null,
      getCompanyCustomersError: props.data.error,
    };
  },
});

export const AddCustomerAction = graphql(AddCustomer, {
  options: () => {
    return {
      errorPolicy: 'ignore',
    };
  },
  props: props => ({
    onAddCustomer: customerInfo => {
      return props.mutate({
        variables: customerInfo,
        optimisticResponse: () => {
          const customerDefaults = {
            firstName: null,
            lastName: null,
            companyName: null,
            qboCustomerId: null,
          };

          const optimisticCustomer = _.cloneDeep(customerInfo);

          return {
            __typename: 'Mutation',
            addCustomer: {
              __typename: 'Customer',
              ...{ ...customerDefaults, ...optimisticCustomer },
            },
          };
        }, // end optimisticResponse
        update: (proxy, { data: { addCustomer } }) => {
          try {
            addItemToQuery({
              query: GetCompanyCustomers,
              queryDataKey: 'getCompanyCustomers',
              variables: { companyId: getCompanyId() },
              userAddedItem: addCustomer,
              proxy,
              typename: 'CustomerConnection',
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`AddCustomerAction GetCompanyCustomers err: `, err);
          }
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const UpdateCustomerAction = graphql(UpdateCustomer, {
  options: () => {
    return {
      errorPolicy: 'ignore',
    };
  },
  props: props => ({
    onUpdateCustomer: customerInfo => {
      return props.mutate({
        variables: customerInfo,
        optimisticResponse: () => {
          return {
            __typename: 'Mutation',
            updateCustomer: {
              __typename: 'Customer',
              ...customerInfo,
            },
          };
        }, // end optimisticResponse
        update: (proxy, { data: { updateCustomer } }) => {
          try {
            addItemToQuery({
              query: GetCompanyCustomers,
              queryDataKey: 'getCompanyCustomers',
              variables: { managingCompanyId: getCompanyId() },
              userAddedItem: updateCustomer,
              proxy,
              typename: 'CustomerConnection',
              keyName: 'customerId',
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`AddCustomerAction GetCompanyCustomers err: `, err);
          }
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const AddCompanyRfisAction = graphql(AddCompanyRfis, {
  options: () => {
    return {
      errorPolicy: 'ignore',
    };
  },
  props: props => ({
    onAddCompanyRfis: ({
      listOfRfis,
      relatedContentProjectId,
      fromWhichAdminTool,
      includeArchive,
      fromWhichJrnContent,
      skipGetCompanyRfisUpdate,
    }) => {
      return props.mutate({
        variables: {
          companyId: getCompanyId(),
          listOfRfis,
        },
        update: (proxy, { data: { addCompanyRfis } }) => {
          // this is misleading since we never actually add more than 1 rfi at once, but pass it in as an array
          addCompanyRfis.items.forEach(rfi => {
            if (!skipGetCompanyRfisUpdate) {
              const query = GetCompanyRfis;
              const queryDataKey = 'getCompanyRfis';
              const keyName = 'requestId';
              const variables = {
                companyId: getCompanyId(),
              };
              const typename = 'RfiConnection';
              variables.mode = figureOutRfiMode({
                requestStatus: rfi.requestStatus,
              });
              try {
                addItemToQuery({
                  query,
                  queryDataKey,
                  keyName,
                  variables,
                  userAddedItem: rfi,
                  proxy,
                  typename,
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(`AddCompanyRfisAction GetCompanyRfis err: `, err);
              }
            }

            //  > If it was related to a global bill, global receipt or global bill payment, getJrn would have run for the item
            //  > Otherwise, if it was related to an old bill or receipt, or invoice or payment
            //    > it could have been added via one of the admin tables (will have fromWhichAdminTool attribute)
            //    > it could have been added via the content listing (will have fromWhichJrnContent attribute)

            if (
              !fromWhichAdminTool &&
              !fromWhichJrnContent &&
              rfi.idOfRelatedItem
            ) {
              // It was a global item, so use the getJrn query to update the cache
              try {
                const getJrnQuery = GetJrn;
                const getJrnVariables = {
                  jrnId: rfi.idOfRelatedItem,
                };

                const getJrnData = proxy.readQuery({
                  query: getJrnQuery,
                  variables: getJrnVariables,
                });

                const existingItem = getJrnData?.getJrn || null;

                if (existingItem) {
                  _.assign(existingItem, {
                    rfiIds: [rfi.requestId],
                  });

                  proxy.writeQuery({
                    query: getJrnQuery,
                    variables: getJrnVariables,
                    data: getJrnData,
                  });
                }
              } catch (err) {
                // eslint-disable-next-line no-console
                console.error(
                  `error updating global content for onAddCompanyRfis: ${err}`
                );
              }
            } else if (fromWhichAdminTool) {
              // It was a non-global financial item updated via admin query
              try {
                addUpdateItemAdminContentQuery({
                  proxy,
                  variables: { contentType: fromWhichAdminTool },
                  userAddedItem: {
                    contentId: rfi.idOfRelatedItem,
                    rfiIds: [rfi.requestId],
                  },
                  includeArchive,
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(
                  `error updating non-global financial item via admin tool for onAddCompanyRfis err: `,
                  err
                );
              }
            } else if (fromWhichJrnContent) {
              // It was a non-global financial item updated via project content listing
              try {
                const contentQuery = GetAllContentByJrnId;
                const jrnId = fromWhichJrnContent
                  ? fromWhichJrnContent.jrnId
                  : relatedContentProjectId;
                const contentId = fromWhichJrnContent
                  ? fromWhichJrnContent.contentId
                  : rfi.idOfRelatedItem;

                const contentData = proxy.readQuery({
                  query: contentQuery,
                  variables: { jrnId },
                });

                const existingItem = _.find(
                  contentData?.getAllContentByJrnId?.items || {},
                  {
                    contentId,
                  }
                );

                if (existingItem) {
                  let rfiIdsUpdated = false;
                  if (existingItem.contentId === rfi.idOfRelatedItem) {
                    _.assign(existingItem, {
                      rfiIds: [rfi.requestId],
                    });
                    rfiIdsUpdated = true;
                  } else if (
                    // Note: This shouldn't be used if onAddCompanyRfis is implemented correctly, but just in case...
                    existingItem.globalExpense?.contentId ===
                    rfi.idOfRelatedItem
                  ) {
                    _.assign(existingItem.globalExpense, {
                      rfiIds: [rfi.requestId],
                    });
                    rfiIdsUpdated = true;
                  }

                  if (rfiIdsUpdated) {
                    proxy.writeQuery({
                      query: contentQuery,
                      data: contentData,
                      variables: { jrnId: relatedContentProjectId },
                    });
                  }
                }
              } catch (err) {
                // eslint-disable-next-line no-console
                console.error(
                  `error updating non-global content via project listing for onAddCompanyRfis: ${err}`
                );
              }
            }
          });
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const UpdateCompanyRfisAction = graphql(UpdateCompanyRfis, {
  options: () => {
    return {
      errorPolicy: 'ignore',
    };
  },
  props: props => ({
    onUpdateCompanyRfis: (
      rfisPassedIn,
      prevMode,
      skipAdd,
      figureOutSkipAddForEach
    ) => {
      const companyId = getCompanyId();

      const rfis = rfisPassedIn.map(rfi => {
        const rfiToSave = removeAttribute(rfi, '__typename');

        // Delete uneditable attributes
        delete rfiToSave.dateCreated;
        delete rfiToSave.dateModified;
        delete rfiToSave.comments;
        delete rfiToSave.internalComments;
        delete rfiToSave.parentPath;
        delete rfiToSave.companyId;

        // Delete synthetic attributes
        delete rfiToSave.relatedProjects;
        delete rfiToSave.relatedBills;
        delete rfiToSave.local;

        if (!rfiToSave.txnId) {
          // remove txnId if it's null since it's used for an index
          delete rfiToSave.txnId;
        }

        return { ...rfiToSave, managingCompanyId: companyId };
      });

      return props.mutate({
        variables: { rfis, companyId },
        update: (proxy, { data: { updateCompanyRfis } }) => {
          // if the modes are different
          const query = GetCompanyRfis;
          const queryDataKey = 'getCompanyRfis';
          const keyName = 'requestId';
          const typename = 'RfiConnection';

          updateCompanyRfis.items.forEach(rfi => {
            // const updatedItem = updateCompanyRfis.items;
            const skipAddLocal =
              rfi.requestStatus === RFI_STATUSES.EXT_A_ACTIVE.value;

            const addAction = item => {
              try {
                addItemToQuery({
                  query,
                  queryDataKey,
                  keyName,
                  variables: {
                    companyId: getCompanyId(),
                    mode: figureOutRfiMode({
                      requestStatus: rfi.requestStatus,
                    }),
                  },
                  userAddedItem: item,
                  proxy,
                  typename,
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(
                  `UpdateCompanyRfisAction GetCompanyRfis err: `,
                  err
                );
              }
            };

            const needToRemove = prevMode && prevMode !== rfi.requestStatus;
            if (needToRemove) {
              //  remove from the previous query using the mode
              //  and add it to the new mode

              // remove the item from the previous query

              try {
                removeItemFromQuery({
                  query,
                  queryDataKey,
                  keyName,
                  variables: {
                    companyId: getCompanyId(),
                    mode: figureOutRfiMode({ requestStatus: prevMode }),
                  },
                  passedItem: rfi,
                  proxy,
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(`UpdateCompanyRfisAction remove RFI err: `, err);
              }
              if (skipAdd || (figureOutSkipAddForEach && skipAddLocal)) {
                // do nothing
              } else {
                addAction(rfi);
              }
            } else {
              // just update the item
              addAction(rfi);
            }
          });
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const RemoveCompanyRfiAction = graphql(RemoveCompanyRfi, {
  options: () => {
    return {
      errorPolicy: 'ignore',
    };
  },
  props: props => ({
    onRemoveCompanyRfi: rfi => {
      return props.mutate({
        variables: {
          requestId: rfi.requestId,
          managingCompanyId: getCompanyId(),
        },
        optimisticResponse: () => {
          return {
            __typename: 'Mutation',
            removeCompanyRfi: {
              __typename: 'Rfi',
              ...rfi,
            },
          };
        }, // end optimisticResponse
        update: (proxy, { data: { removeCompanyRfi } }) => {
          const query = GetCompanyRfis;
          const queryDataKey = 'getCompanyRfis';
          const keyName = 'requestId';
          const variables = {
            companyId: getCompanyId(),
            mode: figureOutRfiMode({ requestStatus: rfi.requestStatus }),
          };
          const updatedItem = removeCompanyRfi;
          try {
            removeItemFromQuery({
              query,
              queryDataKey,
              keyName,
              variables,
              passedItem: updatedItem,
              proxy,
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`RemoveCompanyRfiAction remove RFI err: `, err);
          }
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const GetCompanyRfiAction = graphql(GetCompanyRfi, {
  skip: props => !getCompanyId() || !props.requestId,
  options: props => {
    return {
      variables: { companyId: getCompanyId(), requestId: props.requestId },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      rfi: props.data.getCompanyRfi || null,
      getCompanyRfiRefetch: props.data.refetch,
      getCompanyRfiLoading: props.data.loading,
      getCompanyRfiError: props.data.error,
    };
  },
});

export const GetCompanyRfisAction = graphql(GetCompanyRfis, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId(), mode: RFI_MODES.ACTIVE.value },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyRfisLoading: props.data.loading,
      getCompanyRfisRefetch: props.data.refetch,
      companyRfis: props.data.getCompanyRfis
        ? props.data.getCompanyRfis.items
        : [],
      getCompanyRfisError: props.data.error,
    };
  },
});

export const DisconnectFromQuickBooksAction = graphql(
  DisconnectFromQuickBooks,
  {
    options: {
      update: (dataProxy, { data: { disconnectFromQuickBooks } }) => {
        const query = GetCompanyInfo;
        const cache = dataProxy.readQuery({ query });
        const data = _.cloneDeep(cache);

        const companyList = _.get(data, 'getCompanyInfo.items');

        if (companyList) {
          _.remove(
            companyList,
            company => company.companyId === disconnectFromQuickBooks.companyId
          );
          companyList.push(disconnectFromQuickBooks);
        }

        dataProxy.writeQuery({ query, data });
      },
    },
    props: props => {
      return {
        disconnectFromQuickBooks: () => {
          return props.mutate({
            variables: {
              companyId: getCompanyId(),
            },
          });
        },
      };
    },
  }
);

export const SetCompanyCustomerSyncStatusAction = graphql(
  UpdateCompanyCustomerSyncStatus,
  {
    options: {
      update: (dataProxy, { data: { updateCompanyCustomerSyncStatus } }) => {
        const query = GetCompanyInfo;
        const cache = dataProxy.readQuery({ query });
        const data = _.cloneDeep(cache);

        const companyList = _.get(data, 'getCompanyInfo.items');

        if (companyList) {
          _.remove(
            companyList,
            company =>
              company.companyId === updateCompanyCustomerSyncStatus.companyId
          );
          companyList.push(updateCompanyCustomerSyncStatus);
        }

        dataProxy.writeQuery({ query, data });
      },
    },
    props: props => {
      return {
        updateCompanyCustomerSyncStatus: customerSyncEnabled => {
          return props.mutate({
            variables: {
              customerSyncEnabled,
              companyId: getCompanyId(),
            },
          });
        },
      };
    },
  }
);

export const GetCompanyTargetsAction = graphql(GetCompanyTargets, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyTargetsLoading: props.data.loading,
      getCompanyTargetsRefetch: props.data.refetch,
      companyTargets: props.data.getCompanyTargets
        ? props.data.getCompanyTargets.items
        : [],
      getCompanyTargetsError: props.data.error,
    };
  },
});

export const UpdateCompanyTargetsAction = graphql(UpdateCompanyTargets, {
  options: {
    update: (
      dataProxy,
      {
        data: {
          updateCompanyTargets: { __typename, items },
        },
      }
    ) => {
      if (items) {
        dataProxy.writeQuery({
          query: GetCompanyTargets,
          variables: { companyId: getCompanyId() },
          data: {
            getCompanyTargets: {
              __typename,
              items,
            },
          },
        });
      }
    },
  },
  props: props => {
    return {
      onUpdateCompanyTargets: targets => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            targets,
          },
        });
      },
    };
  },
});

export const GetCompanyJobCostAccountsAction = graphql(
  GetCompanyJobCostAccounts,
  {
    skip: () => !getCompanyId(),
    options: () => {
      return {
        variables: { companyId: getCompanyId() },
        fetchPolicy: 'cache-and-network',
      };
    },
    props: props => {
      return {
        getCompanyJobCostAccountsLoading: props.data.loading,
        getCompanyJobCostAccountsRefetch: props.data.refetch,
        getCompanyJobCostAccountsError: props.data.error,
        jobCostAccounts: props.data.getCompanyJobCostAccounts
          ? props.data.getCompanyJobCostAccounts.items
          : [],
      };
    },
  }
);

export const UpdateJobCostAccountsAction = graphql(UpdateJobCostAccounts, {
  props: props => {
    return {
      onUpdateJobCostAccounts: jobCostAccountUpdates => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            jobCostAccountUpdates,
          },
        });
      },
    };
  },
});

export const ListScoreboardSettingsAction = graphql(ListScoreboardSettings, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      listScoreboardSettingsLoading: props.data.loading,
      listScoreboardSettingsRefetch: props.data.refetch,
      listScoreboardSettingsError: props.data.error,
      scoreboardSettings: props.data.listScoreboardSettings
        ? props.data.listScoreboardSettings.items
        : null,
    };
  },
});

export const UpdateScoreboardSettingsAction = graphql(
  UpdateScoreboardSettings,
  {
    options: {
      update: (
        dataProxy,
        {
          data: {
            updateScoreboardSettings: { __typename, items },
          },
        }
      ) => {
        if (items) {
          dataProxy.writeQuery({
            query: ListScoreboardSettings,
            variables: { companyId: getCompanyId() },
            data: {
              listScoreboardSettings: {
                __typename,
                items,
              },
            },
          });
        }
      },
    },
    props: props => {
      return {
        onUpdateScoreboardSettings: scoreboardSettings => {
          return props.mutate({
            variables: {
              companyId: getCompanyId(),
              scoreboardSettings,
            },
          });
        },
      };
    },
  }
);

export const GetCompanyExpenseAccountsAction = graphql(GetCompanyAccounts, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: {
        companyId: getCompanyId(),
        accountTypes: [
          ACCOUNTING_ACCOUNT_TYPE.EXPENSE,
          ACCOUNTING_ACCOUNT_TYPE.COGS,
        ],
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyExpenseAccountsLoading: props.data.loading,
      getCompanyExpenseAccountsRefetch: props.data.refetch,
      getCompanyExpenseAccountsError: props.data.error,
      expenseAccounts: props.data.getCompanyAccounts
        ? props.data.getCompanyAccounts.items
        : null,
    };
  },
});

export const GetCompanyLiabilityAccountsAction = graphql(GetCompanyAccounts, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: {
        companyId: getCompanyId(),
        accountTypes: [
          ACCOUNTING_ACCOUNT_TYPE.BANK,
          ACCOUNTING_ACCOUNT_TYPE.CREDIT_CARD,
          ACCOUNTING_ACCOUNT_TYPE.OTHER_CURRENT_LIABILITY,
          ACCOUNTING_ACCOUNT_TYPE.LONG_TERM_LIABILITY,
        ],
        topLevelOnly: false,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyLiabilityAccountsLoading: props.data.loading,
      getCompanyLiabilityAccountsRefetch: props.data.refetch,
      getCompanyLiabilityAccountsError: props.data.error,
      liabilityAccounts: props.data.getCompanyAccounts
        ? props.data.getCompanyAccounts.items
        : null,
    };
  },
});

export const GetAllCompanyAccountsAction = graphql(GetCompanyAccounts, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: {
        companyId: getCompanyId(),
        accountTypes: Object.values(ACCOUNTING_ACCOUNT_TYPE),
        topLevelOnly: false,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getAllCompanyAccountsLoading: props.data.loading,
      getAllCompanyAccountsRefetch: props.data.refetch,
      getAllCompanyAccountsError: props.data.error,
      allCompanyAccounts: props.data.getCompanyAccounts
        ? props.data.getCompanyAccounts.items
        : null,
    };
  },
});

export const UpdateCompanyAccountAction = graphql(UpdateCompanyAccount, {
  skip: () => !getCompanyId(),
  props: props => {
    return {
      onUpdateCompanyAccount: ({
        accountId,
        projectCostType,
        productionCostType,
        laborBurden,
        isApproved,
      }) => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            accountId,
            projectCostType,
            productionCostType,
            laborBurden,
            isApproved,
          },
        });
      },
    };
  },
});

export const SyncCustomerToNewAction = graphql(SyncCustomerToNew, {
  skip: () => !getCompanyId(),
  props: props => {
    return {
      onSyncCustomerToNew: customerId => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            customerId,
          },
          update: (proxy, { data: { syncCustomerToNew } }) => {
            try {
              addItemToQuery({
                query: GetCompanyCustomers,
                queryDataKey: 'getCompanyCustomers',
                variables: { companyId: getCompanyId() },
                userAddedItem: syncCustomerToNew,
                proxy,
                typename: 'CustomerConnection',
                keyName: 'customerId',
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(
                `SyncCustomerToNewAction GetCompanyCustomers err: `,
                err
              );
            }
          },
        });
      },
    };
  },
});

export const SyncJrnToNewAction = graphql(SyncJrnToNew, {
  skip: () => !getCompanyId(),
  props: props => {
    return {
      onSyncJrnToNew: contentId => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            contentId,
          },
        });
      },
    };
  },
});

export const SyncCustomerToExistingAction = graphql(SyncCustomerToExisting, {
  skip: () => !getCompanyId(),
  props: props => {
    return {
      onSyncCustomerToExisting: ({ customerId, qboCustomerId }) => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            customerId,
            qboCustomerId,
          },
        });
      },
    };
  },
});

export const SyncJrnToExistingAction = graphql(SyncJrnToExisting, {
  skip: () => !getCompanyId(),
  props: props => {
    return {
      onSyncJrnToExisting: ({ contentId, qboCustomerId }) => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            contentId,
            qboCustomerId,
          },
        });
      },
    };
  },
});

export const GetCompanyShoeboxItemsAction = graphql(GetCompanyShoeboxItems, {
  skip: () => !getCompanyId(),
  options: () => {
    return {
      variables: { companyId: getCompanyId(), mode: 'ACTIVE' },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      getCompanyShoeboxItemsLoading: props.data.loading,
      getCompanyShoeboxItemsRefetch: props.data.refetch,
      companyShoeboxItems: props.data.getCompanyShoeboxItems
        ? props.data.getCompanyShoeboxItems.items
        : [],
      getCompanyShoeboxItemsError: props.data.error,
    };
  },
});

export const DisconnectSyncedCustomerAction = graphql(
  DisconnectSyncedCustomer,
  {
    skip: () => !getCompanyId(),
    props: props => {
      return {
        onDisconnectSyncedCustomer: customerId => {
          return props.mutate({
            variables: {
              companyId: getCompanyId(),
              customerId,
            },
            update: (proxy, { data: { disconnectSyncedCustomer } }) => {
              try {
                addItemToQuery({
                  query: GetCompanyCustomers,
                  queryDataKey: 'getCompanyCustomers',
                  variables: { companyId: getCompanyId() },
                  userAddedItem: disconnectSyncedCustomer,
                  proxy,
                  typename: 'CustomerConnection',
                  keyName: 'customerId',
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(
                  `DisconnectSyncedCustomerAction GetCompanyCustomers err: `,
                  err
                );
              }
            },
          });
        },
      };
    },
  }
);

export const DisconnectSyncedJrnAction = graphql(DisconnectSyncedJrn, {
  skip: () => !getCompanyId(),
  props: props => {
    return {
      onDisconnectSyncedJrn: contentId => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            contentId,
          },
        });
      },
    };
  },
});

export const GetCustomerByIdAction = graphql(GetCustomerById, {
  skip: props =>
    !_.get(props, 'customerId', null) &&
    !_.get(props, 'projectInfo.customerInfo.customerId', null),
  options: props => {
    return {
      variables: {
        customerId:
          _.get(props, 'customerId', null) ||
          _.get(props, 'projectInfo.customerInfo.customerId', null),
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: props.pollForCustomerUpdate ? 5000 : null,
    };
  },
  props: props => ({
    customerInfoRefetch: props.data.refetch,
    customerInfoLoading: props.data.loading,
    customerInfoError: props.data.error,
    customerInfo: _.get(props, 'data.getCustomerById', null),
  }),
});

export const AddCompanyShoeboxItemsAction = graphql(AddCompanyShoeboxItems, {
  options: () => {
    return {
      errorPolicy: 'ignore',
    };
  },
  props: props => ({
    onAddCompanyShoeboxItems: ({ listOfShoeboxItems }) => {
      return props.mutate({
        variables: {
          companyId: getCompanyId(),
          listOfShoeboxItems,
        },
        update: (proxy, { data: { addCompanyShoeboxItems } }) => {
          if (addCompanyShoeboxItems && addCompanyShoeboxItems.items) {
            addCompanyShoeboxItems.items.forEach(item => {
              try {
                addItemToQuery({
                  query: GetCompanyShoeboxItems,
                  queryDataKey: 'getCompanyShoeboxItems',
                  variables: { companyId: getCompanyId(), mode: 'ACTIVE' },
                  userAddedItem: item,
                  proxy,
                  typename: 'ShoeboxItemsConnection',
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(
                  `AddCompanyShoeboxItemsAction GetCompanyShoeboxItems err: `,
                  err
                );
              }
            });
          }
        }, // end update
      }); // end mutate
    }, // end onAdd
  }), // end props
});

export const UpdateCompanyShoeboxItemAction = graphql(
  UpdateCompanyShoeboxItem,
  {
    options: () => {
      return {
        errorPolicy: 'ignore',
      };
    },
    props: props => ({
      onUpdateCompanyShoeboxItem: shoeboxItem => {
        return props.mutate({
          variables: shoeboxItem,
          optimisticResponse: () => {
            const sbi = _.cloneDeep(shoeboxItem);

            const toReturn = addTypenameWhereNeeded(
              sbi,
              'updateCompanyShoeboxItem',
              'ShoeboxItem'
            );

            return toReturn;
          }, // end optimisticResponse
          update: (proxy, { data: { updateCompanyShoeboxItem } }) => {
            const query = GetCompanyShoeboxItems;
            const queryDataKey = 'getCompanyShoeboxItems';
            const keyName = 'contentId';
            const variables = { companyId: getCompanyId(), mode: 'ACTIVE' };
            const updatedItem = updateCompanyShoeboxItem;
            const typename = 'ShoeboxItemsConnection';
            try {
              addItemToQuery({
                query,
                queryDataKey,
                keyName,
                variables,
                userAddedItem: updatedItem,
                proxy,
                typename,
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(
                `UpdateCompanyShoeboxItemAction GetCompanyShoeboxItems err: `,
                err
              );
            }
          }, // end update
        }); // end mutate
      }, // end onAdd
    }), // end props
  }
);

export const RemoveCompanyShoeboxItemAction = graphql(
  RemoveCompanyShoeboxItem,
  {
    options: () => {
      return {
        errorPolicy: 'ignore',
      };
    },
    props: props => ({
      onRemoveCompanyShoeboxItem: contentId => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            contentId,
          },
          update: (proxy, { data: { removeCompanyShoeboxItem } }) => {
            const query = GetCompanyShoeboxItems;
            const queryDataKey = 'getCompanyShoeboxItems';
            const keyName = 'contentId';
            const variables = { companyId: getCompanyId(), mode: 'ACTIVE' };
            const removedItem = removeCompanyShoeboxItem;
            try {
              removeItemFromQuery({
                query,
                queryDataKey,
                keyName,
                variables,
                passedItem: removedItem,
                proxy,
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(`onRemoveCompanyShoeboxItem err: `, err);
            }
          }, // end update
        }); // end mutate
      }, // end onAdd
    }), // end props
  }
);

export const MoveContentToOtherProjectAction = graphql(
  MoveContentToOtherProject,
  {
    props: props => ({
      onMoveContentToOtherProjectAction: ({
        contentToMoveId,
        oldProjectId,
        newProjectId,
        existingContent,
        skipOptimisticResponse = false,
      }) => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            contentToMoveId,
            oldProjectId,
            newProjectId,
          },

          optimisticResponse: !skipOptimisticResponse
            ? () => {
                return addTypenameWhereNeeded(
                  { ...existingContent, jrnId: newProjectId },
                  'moveContentToOtherProject'
                );
              }
            : undefined, // end optimisticResponse

          update: (proxy, { data: { moveContentToOtherProject } }) => {
            // remove from the current project
            const query = GetAllContentByJrnId;
            const queryDataKey = 'getAllContentByJrnId';
            const variables = {
              jrnId: oldProjectId,
            };
            removeItemFromQuery({
              query,
              queryDataKey,
              variables,
              passedItem: moveContentToOtherProject,
              proxy,
            });

            // If timetimetracking, try to update simple timetracking
            if (moveContentToOtherProject.type === 'timetracking') {
              // TODO: Update simple type
            }
          }, // end update
        }); // end mutate
      }, // end onAddContent
    }), // end props
  }
);

export const ImportJrnFromQboAction = graphql(ImportJrnFromQbo, {
  skip: () => !getCompanyId(),
  props: props => ({
    onImportJrnFromQbo: qboCustomerId => {
      return props.mutate({
        variables: {
          companyId: getCompanyId(),
          qboCustomerId,
        },
        // Refetch customers to imported customers
        refetchQueries: [
          {
            query: GetCompanyCustomers,
            variables: {
              companyId: getCompanyId(),
            },
          },
        ],
        update: (proxy, { data: { importJrnFromQbo } }) => {
          if (importJrnFromQbo.type === 'project') {
            // Add new project to active project list
            addItemToQuery({
              proxy,
              query: ListMyJrns,
              queryDataKey: 'listMyJrns',
              variables: {
                userId: USER_ID_WILL_BE_PULLED_FROM_COGNITO_RESOLVER,
              },
              typename: 'JrnConnection',
              keyName: 'contentId',
              userAddedItem: importJrnFromQbo,
            });

            // Add new project to company projects list
            addItemToQuery({
              proxy,
              query: ListCompanyProjects,
              queryDataKey: 'listCompanyProjects',
              variables: {
                companyId: getCompanyId(),
                first: null,
                after: null,
              },
              typename: 'JrnConnection',
              keyName: 'contentId',
              userAddedItem: importJrnFromQbo,
            });
          }
        },
      });
    },
  }),
});

export const CheckForQboUpdatesToCustomerAction = graphql(
  CheckForQboUpdatesToCustomer,
  {
    skip: () => !getCompanyId(),
    props: props => ({
      onCheckForCustomerUpdates: customerId => {
        return props.mutate({
          variables: {
            companyId: getCompanyId(),
            customerId,
          },
        });
      },
    }),
  }
);

export const CheckForQboUpdatesToJrnAction = graphql(CheckForQboUpdatesToJrn, {
  skip: () => !getCompanyId(),
  props: props => ({
    onCheckForJrnUpdates: contentId => {
      return props.mutate({
        variables: {
          companyId: getCompanyId(),
          contentId,
        },
      });
    },
  }),
});

export const MarkAllExpenseAccountsApprovedAction = graphql(
  MarkAllExpenseAccountsApproved,
  {
    skip: () => !getCompanyId(),
    props: props => {
      return {
        onMarkAllExpenseAccountsApproved: () => {
          return props.mutate({
            variables: {
              companyId: getCompanyId(),
            },
          });
        },
      };
    },
  }
);

export const AddCardAccessForUserAction = graphql(AddCardAccessForUser, {
  props: props => ({
    onAddCardAccessForUser: ({
      userId,
      cardId,
      cardType,
      options: { card },
    }) => {
      return props.mutate({
        variables: {
          companyId: getCompanyId(),
          cardType,
          userId,
          cardId,
        },
        update: (proxy, { data: { addCardAccessForUser } }) => {
          addItemToQuery({
            query: ListMazumagoCardsForUser,
            queryDataKey: 'listMazumaGoCardsForUser',
            variables: {
              companyId: getCompanyId(),
              userId,
            },
            userAddedItem: card,
            proxy,
            keyName: 'cardId',
            typename: 'Card',
          });

          if (isCompanyOwner()) {
            // Update GetCompanyCrewAdmin cached data
            const query = GetCompanyCrewAdmin;
            const variables = {
              companyId: getCompanyId(),
            };
            let data;
            try {
              data = _.cloneDeep(proxy.readQuery({ query, variables }));
            } catch (e) {
              nonProdConsoleLog(
                `AddCardAccessForUserAction: getCompanyCrewAdmin query was never run before, e:`,
                e
              );
            }
            if (data) {
              const userIndex = _.findIndex(data.getCompanyCrewAdmin.items, {
                userId,
              });
              if (userIndex >= 0) {
                // if card is not in the list
                if (
                  _.findIndex(data.getCompanyCrewAdmin.items[userIndex].cards, {
                    cardId: addCardAccessForUser.cardId,
                  }) === -1
                ) {
                  // add it
                  data.getCompanyCrewAdmin.items[userIndex].cards.push(
                    addCardAccessForUser
                  );

                  proxy.writeQuery({ query, variables, data });
                }
              }
            }
          }
        }, // end update
      }); // end mutate
    }, // end onAddContent
  }), // end props
});

export const RemoveCardAccessAction = graphql(RemoveCardAccess, {
  props: props => ({
    onRemoveCardAccess: ({ userId, cardId }) => {
      return props.mutate({
        variables: {
          companyId: getCompanyId(),
          userId,
          cardId,
        },
        optimisticResponse: {
          removeCardAccess: {
            __typename: 'Mutation',
            status: 'success',
            msg: cardId,
          },
        }, // end optimisticResponse
        update: proxy => {
          removeItemFromQuery({
            query: ListMazumagoCardsForUser,
            queryDataKey: 'listMazumaGoCardsForUser',
            variables: {
              companyId: getCompanyId(),
              userId,
            },
            passedItem: { cardId },
            proxy,
            keyName: 'cardId',
          });

          if (isCompanyOwner()) {
            // Update GetCompanyCrewAdmin cached data
            const query = GetCompanyCrewAdmin;
            const variables = {
              companyId: getCompanyId(),
            };
            let data;
            try {
              data = _.cloneDeep(proxy.readQuery({ query, variables }));
            } catch (e) {
              nonProdConsoleLog(
                `RemoveCardAccessAction: getCompanyCrewAdmin query was never run before, e:`,
                e
              );
            }
            if (data) {
              const userIndex = _.findIndex(data.getCompanyCrewAdmin.items, {
                userId,
              });
              if (userIndex >= 0) {
                // remove card from the list
                _.remove(data.getCompanyCrewAdmin.items[userIndex].cards, {
                  cardId,
                });

                proxy.writeQuery({ query, variables, data });
              }
            }
          }
        }, // end update
      }); // end mutate
    }, // end onAddContent
  }), // end props
});

export const GetVendorsAction = graphql(GetRelationsByType, {
  skip: () => !getCompanyId(),
  options: () => ({
    variables: {
      companyId: getCompanyId(),
      type: 'VENDOR',
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: props => {
    return {
      vendorsError: props.data.error,
      vendorsLoading: props.data.loading,
      vendorsRefetch: props.data.refetch,
      vendors: props.data.getRelationsByType
        ? props.data.getRelationsByType.items
        : null,
    };
  },
});

export const GetBookkeepingAlertsPreferencesAction = graphql(
  GetBookkeepingAlertsPreferences,
  {
    options: () => {
      return {
        variables: { companyId: getCompanyId() },
        fetchPolicy: 'cache-and-network',
      };
    },
    props: props => {
      return {
        bookkeepingAlertPreferencesRefetch: props.data.refetch,
        bookkeepingAlertPreferencesLoading: props.data.loading,
        bookkeepingAlertPreferences: props.data.getBookkeepingAlertPreferences,
      };
    },
  }
);

export const CreateBookkeepingAlertPreferencesAction = graphql(
  CreateBookkeepingAlertPreferences,
  {
    props: props => {
      return {
        onCreateBookkeepingAlertPreferences: alertDetails => {
          return props.mutate({
            variables: alertDetails,
            update: (
              proxy,
              { data: { createBookkeepingAlertPreferences } }
            ) => {
              proxy.writeQuery({
                query: GetBookkeepingAlertsPreferences,
                variables: { companyId: getCompanyId() },
                data: {
                  getBookkeepingAlertPreferences: createBookkeepingAlertPreferences,
                },
              });
            },
            optimisticResponse: () => {
              return {
                __typename: 'Mutation',
                createBookkeepingAlertPreferences: {
                  ...alertDetails,
                  __typename: 'BookkeepingAlertsPreferences',
                },
              };
            }, // end optimisticResponse
          }); // end mutate
        }, // end onUpdateNotificationDetails
      };
    }, // end props
  }
);

export const UpdateBookkeepingAlertPreferencesAction = graphql(
  UpdateBookkeepingAlertPreferences,
  {
    props: props => {
      return {
        onUpdateBookkeepingAlertPreferences: alertPreferencesDetails => {
          return props.mutate({
            variables: alertPreferencesDetails,
            optimisticResponse: () => {
              return {
                __typename: 'Mutation',
                updateBookkeepingAlertPreferences: {
                  ...alertPreferencesDetails,
                  __typename: 'BookkeepingAlertsPreferences',
                },
              };
            },
          }); // end mutate
        }, // end onUpdateBookkeepingAlertPreferences
      };
    }, // end props
  }
);
export const GetBookkeepingAlertsAction = graphql(GetBookkeepingAlerts, {
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      bookkeepingAlertsRefetch: props.data.refetch,
      bookkeepingAlertsLoading: props.data.loading,
      bookkeepingAlerts: props.data.getBookkeepingAlerts?.items,
    };
  },
});

export const GetBookkeepingRulesAction = graphql(GetBookkeepingRules, {
  options: () => {
    return {
      variables: { companyId: getCompanyId() },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: props => {
    return {
      bookkeepingRulesRefetch: props.data.refetch,
      bookkeepingRulesLoading: props.data.loading,
      bookkeepingRules: props.data.getBookkeepingRules?.items,
    };
  },
});

export const AddVendorAction = graphql(AddRelation, {
  skip: () => !getCompanyId(),
  props: props => ({
    onAddVendor: vendorInfo => {
      return props.mutate({
        variables: {
          ...vendorInfo,
          managingCompanyId: getCompanyId(),
          type: 'VENDOR',
        },
        optimisticResponse: () => {
          return {
            __typename: 'Mutation',
            addRelation: {
              __typename: 'Relation',
              ...vendorInfo,
              managingCompanyId: getCompanyId(),
              type: 'VENDOR',
            },
          };
        },
        update: (proxy, { data: { addRelation } }) => {
          try {
            addItemToQuery({
              query: GetRelationsByType,
              queryDataKey: 'getRelationsByType',
              variables: { companyId: getCompanyId(), type: 'VENDOR' },
              userAddedItem: addRelation,
              proxy,
              keyName: 'relationId',
              typename: 'RelationConnection',
            });
          } catch (err) {
            // Just eat it
          }
        },
      });
    },
  }),
});

export const UpdateVendorAction = graphql(UpdateRelation, {
  skip: () => !getCompanyId(),
  props: props => ({
    onUpdateVendor: vendorInfo => {
      return props.mutate({
        variables: { ...vendorInfo },
        optimisticResponse: () => {
          return {
            __typename: 'Mutation',
            updateRelation: {
              __typename: 'Relation',
              ...vendorInfo,
              managingCompanyId: getCompanyId(),
              type: 'VENDOR',
            },
          };
        },
        update: (proxy, { data: { updateRelation } }) => {
          try {
            addItemToQuery({
              query: GetRelationsByType,
              queryDataKey: 'getRelationsByType',
              variables: { companyId: getCompanyId(), type: 'VENDOR' },
              userAddedItem: updateRelation,
              proxy,
              keyName: 'relationId',
              typename: 'RelationConnection',
            });
          } catch (err) {
            // ignore it
          }
        },
      });
    },
  }),
});

export const GetVendorAction = graphql(GetRelationById, {
  skip: props => !props.vendorId,
  options: props => ({
    variables: {
      relationId: props.vendorId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: props => ({
    vendorError: props.data.error,
    vendorLoading: props.data.loading,
    vendorRefetch: props.data.refetch,
    vendor: props.data.getRelationById || null,
  }),
});

export const CreateBookkeepingAlertAction = graphql(CreateBookkeepingAlert, {
  props: props => {
    return {
      onCreateBookkeepingAlert: alertDetails => {
        return props.mutate({
          variables: { ...alertDetails, companyId: getCompanyId() },
          // NOTE: Should not need optimisticResponse here,
          // as it will cause the UI not to show the loading spinner
          // and sometimes it cause duplicate items to be added to the list (two users adding at the same time)
          update: (proxy, { data: { createBookkeepingAlert } }) => {
            const query = GetBookkeepingAlerts;
            addItemToQuery({
              query,
              queryDataKey: 'getBookkeepingAlerts',
              variables: {
                companyId: getCompanyId(),
              },
              userAddedItem: createBookkeepingAlert,
              proxy,
              keyName: 'id',
              typename: 'BookkeepingAlert',
            });
          },
        }); // end mutate
      }, // end onCreateBookkeepingAlert
    };
  }, // end props
});

export const UpsertBookkeepingEventMetaAction = graphql(
  UpsertBookkeepingEventMeta,
  {
    props: props => {
      return {
        onUpsertBookkeepingEventMeta: eventMetaInfo => {
          return props.mutate({
            variables: { ...eventMetaInfo, companyId: getCompanyId() },
            optimisticResponse: () => {
              const eventMeta = { ...eventMetaInfo };

              if (!eventMeta.creatorId) {
                // no creatorId means adding a new item

                const nowString = new Date().toISOString();
                const userId = getCurrentUserId();
                eventMeta.creatorId = userId;
                eventMeta.dateCreated = nowString;
                eventMeta.dateModified = nowString;
                eventMeta.modifierId = userId;
              }

              return {
                __typename: 'Mutation',
                upsertBookkeepingEventMeta: {
                  ...eventMeta,
                  __typename: 'BookkeepingEventMeta',
                },
              };
            }, // end optimisticResponse
            update: (proxy, { data: { upsertBookkeepingEventMeta } }) => {
              const query = ListBookkeepingSnapshotEventMeta;
              addItemToQuery({
                query,
                queryDataKey: 'listBookkeepingSnapshotEventMeta',
                variables: {
                  companyId: getCompanyId(),
                  snapshotId: eventMetaInfo.snapshotId,
                  metaType: eventMetaInfo.metaType,
                },
                userAddedItem: upsertBookkeepingEventMeta,
                proxy,
                keyName: 'id',
                typename: 'BookkeepingEventMeta',
              });
            },
          }); // end mutate
        }, // end onUpsertBookkeepingRule
      };
    }, // end props
  }
);

export const UpsertBookkeepingRuleAction = graphql(UpsertBookkeepingRule, {
  props: props => {
    return {
      onUpsertBookkeepingRule: ruleDetails => {
        return props.mutate({
          variables: { ...ruleDetails, companyId: getCompanyId() },
          // NOTE: Should not need optimisticResponse here,
          // as it will cause the UI not to show the loading spinner
          // and sometimes it cause duplicate items to be added to the list (two users adding at the same time)
          update: (proxy, { data: { upsertBookkeepingRule } }) => {
            const query = GetBookkeepingRules;
            addItemToQuery({
              query,
              queryDataKey: 'getBookkeepingRules',
              variables: {
                companyId: getCompanyId(),
              },
              userAddedItem: upsertBookkeepingRule,
              proxy,
              keyName: 'id',
              typename: 'BookkeepingRule',
            });
          },
        }); // end mutate
      }, // end onUpsertBookkeepingRule
    };
  }, // end props
});

export const UpdateBookkeepingAlertAction = graphql(UpdateBookkeepingAlert, {
  props: props => {
    return {
      onUpdateBookkeepingAlert: alertDetails => {
        return props.mutate({
          variables: {
            id: alertDetails.id,
            enabled: alertDetails.enabled,
            companyId: getCompanyId(),
          },
          // NOTE: Should not need optimisticResponse here,
          // as it will cause the UI not to show the loading spinner
          // and sometimes it cause duplicate items to be added to the list (two users adding at the same time)
          update: (proxy, { data: { updateBookkeepingAlert } }) => {
            const query = GetBookkeepingAlerts;
            addItemToQuery({
              query,
              queryDataKey: 'getBookkeepingAlerts',
              variables: {
                companyId: getCompanyId(),
              },
              userAddedItem: updateBookkeepingAlert,
              proxy,
              keyName: 'id',
              typename: 'BookkeepingAlert',
            });
          },
        }); // end mutate
      }, // end onUpdateBookkeepingAlert
    };
  }, // end props
});

export const GetInternalCommentsAction = graphql(GetInternalComments, {
  skip: props => {
    return !getCompanyId() || !props.bookkeeperMode || !props.rfi?.requestId; // Need truthy bookkeeperMode in props to execute
  },
  options: props => {
    return {
      fetchPolicy: 'cache-and-network',
      variables: {
        companyId: getCompanyId(),
        itemType: 'RFI',
        itemId: props.rfi?.requestId, // Need requestId in props
      },
    };
  },
  props: props => {
    return {
      getInternalCommentsRefetch: props.data.refetch,
      getInternalCommentsLoading: props.data.loading,
      internalComments: props.data.getInternalComments?.items,
    };
  },
});

export const UpdateBookkeepingReportAction = graphql(UpdateBookkeepingReport, {
  props: props => {
    return {
      onUpdateBookkeepingReport: ({ snapshotId, hidden }) => {
        return props.mutate({
          variables: { companyId: getCompanyId(), snapshotId, hidden },
        });
      },
    };
  },
});
